import uuid from 'uuid/v4';
import {SAVE_APPS_WITH_RATINGS, SET_IS_LOADING_APPS_WITH_RATINGS} from '../actions/ratings';
import momentZ from 'moment-timezone';
import {AppGroupRelationType, REMOVE_APP_FROM_GROUP, SAVE_APP} from '../actions/apps';

const getInitialState = () => {
  return {
    stateId: uuid(),
    apps: {},
    groups: {},
    isLoadingApps: false,
    timeStampSaveApps: 0,
    timeStampSaveApp: 0,
    isLoadApps: true,
    maxRatingsByCountries: {},
    lastUpdateAppsDate: null,
    countries: ['us', 'gb', 'ca', 'au', 'de', 'ru', 'ua', 'mx', 'es', 'pt', 'br', 'cn', 'it', 'fr'],
  };
};

const unwrapApp = (app, countries) => {
  const ratings = {};
  countries.map((country) => {
    ratings[country] = [];
  });

  app.ratings
      .sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        return 0;
      })
      .forEach((rating) => {
        if (rating && ratings[rating.country]) {
          const ratingNew = {
            rating: rating.rating,
            updatedAt: rating.updatedAt,
          };
          ratings[rating.country].push(ratingNew);
        }
      });

  return {
    id: app.id,
    name: app.name,
    icon: app.icon,
    vendorId: app.vendorId,
    ratings,
  };
};

const findMetaDataApps = (apps, countries, groups) => {
  const meta = {
    maxByGroups: {},
    lastUpdateDate: momentZ().subtract(7, 'days'),
  };

  Object.keys(apps).forEach((group) => {
    meta.maxByGroups[group] = {};

    countries.map((country) => {
      meta.maxByGroups[group][country] = -1;
    });

    apps[group].forEach((app) => {
      Object.keys(app.ratings).forEach((country) => {
        const lastRating = app.ratings[country][app.ratings[country].length -1];

        // LastUpdateDate
        if (lastRating && momentZ(lastRating.updatedAt).isAfter(meta.lastUpdateDate)) {
          meta.lastUpdateDate = momentZ(lastRating.updatedAt);
        }

        // MaxRatingByGroup
        if (lastRating && meta.maxByGroups[group][country] < lastRating.rating) {
          meta.maxByGroups[group][country] = lastRating.rating;
        }
      });
    });
  });

  return meta;
};

const sortApps = (apps, groups) => {
  Object.keys(apps).forEach((groupId) => {
    apps[groupId] = apps[groupId].sort((appA, appB) => {
      const appARatings = appA.ratings.us;
      const appBRatings = appB.ratings.us;

      const appALength = appARatings.length;
      const appBLength = appBRatings.length;

      if (appALength === 0 && appBLength === 0) {
        return 0;
      }

      if (appALength === 0) {
        return 1;
      }

      if (appBLength === 0) {
        return -1;
      }

      if (appARatings[appALength - 1].rating > appBRatings[appBLength - 1].rating) {
        return -1;
      }
      if (appARatings[appALength - 1].rating < appBRatings[appBLength - 1].rating) {
        return 1;
      }

      return 0;
    });
  });
};

export default function ratings(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_APPS_WITH_RATINGS: {
      const timeStampSaveApps = action.requestTimeEpoch;
      const stateIdRequestedSaveApps = action.stateId;

      if (
        timeStampSaveApps > state.timeStampSaveApps &&
              stateIdRequestedSaveApps === state.stateId
      ) {
        const apps = {};
        const groups = {};
        action.apps
            .forEach((app) => {
              const unwrappedApp = unwrapApp(app, state.countries);
              app.appToGroups.forEach((appToGroup) => {
                groups[appToGroup.groupId] = appToGroup.group;
                if (!apps[appToGroup.groupId]) {
                  apps[appToGroup.groupId] = [];
                }
                apps[appToGroup.groupId].push(unwrappedApp);
              });
            });
        sortApps(apps, groups);
        const metaApps = findMetaDataApps(apps, state.countries, groups);

        return Object.assign({}, state, {
          apps: apps,
          groups: groups,
          isLoadingApps: false,
          timeStampSaveApps: timeStampSaveApps,
          maxRatingsByCountries: metaApps.maxByGroups,
          lastUpdateAppsDate: metaApps.lastUpdateDate,
          isLoadApps: false,
        });
      }
      return state;
      break;
    }


    case SAVE_APP: {
      const timeStampSaveApp = action.requestTimeEpoch;

      if (
        action.appGroupRelationType === AppGroupRelationType.RATINGS &&
              timeStampSaveApp > state.timeStampSaveApp &&
              action.stateId === state.stateId
      ) {
        const apps = {...state.apps};
        const groups = {...state.groups};
        console.log(action);
        const unwrappedApp = unwrapApp(action.app, state.countries);

        action.app.appToGroups.forEach((appToGroup) => {
          if (!apps[appToGroup.groupId]) {
            apps[appToGroup.groupId] = [];
          }
          groups[appToGroup.groupId] = appToGroup.group;

          let isExistApp = false;
          apps[appToGroup.groupId] = apps[appToGroup.groupId].map((appFromState) => {
            if (appFromState.name === unwrappedApp.name) {
              isExistApp = true;
              return unwrappedApp;
            } else {
              return appFromState;
            }
          });
          if (!isExistApp) {
            apps[appToGroup.groupId].push(unwrappedApp);
          }
        });

        sortApps(apps, groups);
        const metaApps = findMetaDataApps(apps, state.countries, groups);

        return Object.assign({}, state, {
          apps: apps,
          groups: groups,
          isLoadingApps: false,
          timeStampSaveApp: timeStampSaveApp,
          isLoadApps: false,
          lastUpdateAppsDate: metaApps.lastUpdateDate,
          maxRatingsByCountries: metaApps.maxByGroups,
        });
      }
      return state;
      break;
    }

    case REMOVE_APP_FROM_GROUP: {
      const apps = {...state.apps};
      const groups = {...state.groups};

      Object.keys(apps).forEach((group) => {
        if (group === String(action.groupId)) {
          apps[group] = apps[group].filter((app) => app.id !== action.appId);
          if (apps[group].length === 0) {
            delete apps[group];
            delete groups[group];
          }
        }
      });

      sortApps(apps, groups);
      const metaApps = findMetaDataApps(apps, state.countries, groups);

      return Object.assign({}, state, {
        apps: apps,
        maxRatingsByCountries: metaApps.maxByGroups,
      });
      break;
    }

    case SET_IS_LOADING_APPS_WITH_RATINGS:
      return Object.assign({}, state, {
        isLoadingApps: action.isLoading,
      });

    default: return state;
  }
}
