import {getGradientAso} from './StyleUtils';
import uuid from 'uuid/v4';

String.prototype.replaceAll = function(search, replacement) {
  const target = this;
  return target.split(search).join(replacement);
};

export function splitPhase(phrase) {
  if (typeof phrase !== 'string' && !(phrase instanceof String)) {
    return [];
  }

  const filteredPhase = phrase
      .replaceAll(`)`, '')
      .replaceAll(`(`, '')
      .replaceAll(`.`, '')
      .replaceAll(`,`, '')
      .replaceAll(`!`, '')
      .replaceAll(`?`, '')
      .replaceAll(`<`, '')
      .replaceAll(`>`, '')
      .replaceAll(`/`, '')
      .replaceAll(`+`, '')
      .replaceAll(`*`, '')
      .replaceAll(`^`, '')
      .replaceAll(`%`, '')
      .replaceAll(`$`, '')
      .replaceAll(`#`, '')
      .replaceAll(`@`, '')
      .replaceAll(`:`, '')
      .replaceAll(`;`, '')
      .replaceAll(`"`, '')
      .replaceAll(`'`, '')
      .replaceAll(`&`, '')
      .replaceAll(`_`, '')
      .replaceAll(`-`, '')
      .trim();

  return filteredPhase.split(' ')
      .filter((word) => {
        return word.trim() !== '';
      })
      .map((word) => word.toLowerCase().trim());
}

export function splitKeywords(keywords) {
  if (typeof keywords !== 'string' && !(keywords instanceof String)) {
    return [];
  }

  const filteredKeywords = keywords
      .replaceAll(`,`, ' ')
      .trim();

  return filteredKeywords.split(' ').map((word) => word.toLowerCase().trim());
}

export function splitBySeparator(phrase, separator) {
  if (typeof phrase !== 'string' && !(phrase instanceof String)) {
    return [];
  }

  return phrase.split(separator).map((phrase) => phrase.trim()).filter((phase) => phase !== '');
}

export function isTextContainWord(text, word) {
  word = word === '+' ? '/+' : word;
  const regex = new RegExp(`(?<=[\\s,.:;"]|^)${word}(?=[\\s,.:;"]|$)`);
  const res = text.match(regex);
  return res && res.length > 0;
}

export function matchKeywords(phrases, formsWords) {
  let res = phrases;
  const mapMatch = {};
  const wordsColor = {};
  const formsNames = ['main', 'additional', 'old', 'sub_1', 'sub_2', 'sub_3'];

  formsNames.forEach((formName) => {
    if (formsWords[formName]) {
      formsWords[formName].forEach((word) => {
        if (!wordsColor[word]) {
          wordsColor[word] = {};
        }
        wordsColor[word][formName] = true;
      });
    }
  });

  Object.keys(wordsColor).forEach((word) => {
    const color = getGradientAso(wordsColor[word].main, wordsColor[word].additional, wordsColor[word].old, wordsColor[word].sub_1, wordsColor[word].sub_2, wordsColor[word].sub_3);
    const id = uuid();
    const style = `"border-radius: 3px; background: ${color}"`;
    mapMatch[id] = `<span style=${style}>${word}</span>`;
    word = word === '+' ? '/+' : word;

    const regex = new RegExp(`(?<=[\\s(),.:;"]|^)${word}(?=[\\s(),.:;"]|$)`);

    res = res.replaceAll(regex, id);
  });

  Object.keys(mapMatch).forEach((match) => {
    res = res.replaceAll(match, mapMatch[match]);
  });
  return res;
}


export const cutTextOnParts = (text, lineLength) => {
  const words = text.split(' ');
  const strings = [];

  let buffString = '';

  words.forEach((word) => {
    if (buffString.length + word.length < lineLength) {
      buffString = buffString + ' ' + word;
    } else {
      strings.push(buffString.replace(' ', ''));
      buffString = word;
    }
  });

  strings.push(buffString.replace(' ', ''));
  return strings;
};

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? ',' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
