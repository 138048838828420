import uuid from 'uuid/v4';
import {SAVE_PURCHASES_EVENTS, SAVE_USERS, SET_IS_LOADING_PURCHASES_EVENTS, SET_IS_LOADING_USERS} from '../actions/qa';

const getInitialState = () => {
  return {
    stateId: uuid(),
    users: [],
    isLoadingUsers: false,
    timeStampSaveUsers: 0,

    purchasesEvents: [],
    isLoadingPurchasesEvents: false,
    timeStampSavePurchasesEvents: 0,
  };
};

export default function qa(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_USERS:
      const timeStampSaveUsers = action.requestTimeEpoch;
      const stateIdRequestedSaveUsers = action.stateId;

      if (
        timeStampSaveUsers > state.timeStampSaveUsers &&
        stateIdRequestedSaveUsers === state.stateId
      ) {
        return Object.assign({}, state, {
          users: action.users,
          isLoadingUsers: false,
        });
      }

    case SAVE_PURCHASES_EVENTS:
      const timeStampSavePurchasesEvents = action.requestTimeEpoch;
      const stateIdRequestedSavePurchaseEvents = action.stateId;

      if (
        timeStampSavePurchasesEvents > state.timeStampSavePurchasesEvents &&
        stateIdRequestedSavePurchaseEvents === state.stateId
      ) {
        return Object.assign({}, state, {
          purchasesEvents: action.purchasesEvents,
          isLoadingPurchasesEvents: false,
        });
      }

    case SET_IS_LOADING_USERS:
      return Object.assign({}, state, {
        isLoadingUsers: action.isLoading,
      });

    case SET_IS_LOADING_PURCHASES_EVENTS:
      return Object.assign({}, state, {
        isLoadingPurchasesEvents: action.isLoading,
      });

    default: return state;
  }
}
