import React from 'react';
import momentZ from 'moment-timezone';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setError, showModal, typeModal} from '../../actions/general';
import AddApp from '../apps/AppAdd';
import Modal from './Modal';
import {ErrorDetails} from './ErrorDetails';
import DetailsAsoExperiment from '../aso/DetailsAsoExperiment';
import uuid from 'uuid/v4';
import {deleteAsoExperiment, setDeleteAsoExperiment} from '../../actions/aso';
import {DeleteAttention} from './DeleteAttention';
import DetailsKeyword from '../keywordsRanks/KeywordDetails';
import {deleteKeyword, setKeywordForEdit, setKeywordToDelete} from '../../actions/keywords';
import EventDetail from '../metrics/EventDetail';
import {deleteEvent, setEventForDelete, setEventForEdit} from '../../actions/events';
import {eventTypes, eventTypesEnum} from './EventTypeSelector';
import {AsoKeywordStatus} from '../keywordsRanks/KeywordStatus';
import {deletePromoCode, setDeletePromoCode} from '../../actions/promo';
import DetailsPromoCode from '../promo/DetailsPromoCode';
import DetailsWhatsNew from '../whatsNew/DetailsWhatsNew';
import {deleteWhatsNew, setDeleteWhatsNew} from '../../actions/whatsNew';
import {KeywordsReportType} from '../../models/onelight';
import {deleteCrossPromo, setDeleteCrossPromo} from '../../actions/crossPromo';

const Modals = ({
  showModal, setError, error, setDeleteAsoExperiment, asoExperimentForCopy,
  keywordForEdit, setKeywordForEdit, setKeywordToDelete, deleteKeyword,
  asoExperimentForDelete, deleteAsoExperiment, asoExperimentForEdit,
  selectedAppKeyword, isPositionsReport, keywordForDelete, eventForEdit, setEventForEdit,
  setEventForDelete, eventForDelete, deleteEvent, promoCodeForDelete, deletePromoCode, setDeleteCrossPromo,
  setDeletePromoCode, whatsNewForEdit, whatsNewForDelete, setDeleteWhatsNew, deleteWhatsNew, deleteCrossPromo,
  crossPromoForDelete,
}) => {
  return <div style={{position: 'relative'}}>
    <Modal modalType={typeModal.APP_ADD}>
      <AddApp
        mode={'ADD'}
        app={{
          vendorId: '',
        }}
        cancel={() => {
          showModal(typeModal.APP_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.KEYWORD_ADD}>
      <DetailsKeyword
        app={selectedAppKeyword}
        mode={'ADD'}
        keyword={{
          notes: '',
          isPositionsReport: isPositionsReport,
          isIncentMonitor: !isPositionsReport,
          keyword: '',
          isHide: false,
          status: AsoKeywordStatus.TO_DO,
        }}
        cancel={() => {
          showModal(typeModal.KEYWORD_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.KEYWORD_EDIT}>
      <DetailsKeyword
        app={selectedAppKeyword}
        mode={'EDIT'}
        keyword={keywordForEdit}
        cancel={() => {
          setKeywordForEdit(null);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.EVENT_ADD}>
      <EventDetail
        mode={'ADD'}
        event={{
          name: eventTypes[eventTypesEnum.APP_RELEASE].label,
          type: eventTypesEnum.APP_RELEASE,
          meta: {color: eventTypes[eventTypesEnum.APP_RELEASE].color},
          date: momentZ().format('yyyy-MM-DD HH:mm'),
        }}
        cancel={() => {
          showModal(typeModal.EVENT_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.EVENT_EDIT}>
      <EventDetail
        mode={'EDIT'}
        event={eventForEdit}
        cancel={() => {
          setEventForEdit(null);
        }}
        deleteEvent={(event) => {
          setEventForEdit(null);
          setEventForDelete(event);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.EVENT_DELETE} onClose={() => setEventForDelete(null)}>
      <DeleteAttention
        description={'Event ' + (eventForDelete ? eventForDelete.name : '') + ' ?'}
        cancel={() => {
          setEventForDelete(null);
        }}
        onDelete={() => {
          setEventForDelete(null);
          deleteEvent(eventForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={typeModal.KEYWORD_DELETE} onClose={() => setKeywordToDelete(null)}>
      <DeleteAttention
        description={'Keyword ' + (keywordForDelete ? keywordForDelete.keyword : '') + ' ?'}
        cancel={() => {
          setKeywordToDelete(null);
        }}
        onDelete={() => {
          setKeywordToDelete(null);
          deleteKeyword(keywordForDelete.id);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ASO_EXPERIMENT_ADD}>
      <DetailsAsoExperiment
        mode={'ADD'}
        asoExperiment={{
          country: 'us',
          uuid: uuid(),
          data: {},
          updatedAt: new Date().toUTCString(),
        }}
        cancel={() => {
          showModal(typeModal.ASO_EXPERIMENT_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ASO_EXPERIMENT_EDIT}>
      <DetailsAsoExperiment
        mode={'EDIT'}
        asoExperiment={asoExperimentForEdit}
        cancel={() => {
          showModal(typeModal.ASO_EXPERIMENT_EDIT, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ASO_EXPERIMENT_COPY}>
      <DetailsAsoExperiment
        mode={'COPY'}
        asoExperiment={asoExperimentForCopy}
        cancel={() => {
          showModal(typeModal.ASO_EXPERIMENT_COPY, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ERROR} onClose={() => setError(null)}>
      <ErrorDetails
        error={error}
        cancel={() => {
          setError(null);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.ASO_EXPERIMENT_DELETE} onClose={() => setDeleteAsoExperiment(null)}>
      <DeleteAttention
        description={'Metadata ?'}
        cancel={() => {
          setDeleteAsoExperiment(null);
        }}
        onDelete={() => {
          setDeleteAsoExperiment(null);
          deleteAsoExperiment(asoExperimentForDelete.uuid);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.PROMO_ADD}>
      <DetailsPromoCode
        mode={'ADD'}
        promoCode={{
          code: '',
          onelink: '',
          expirationDate: momentZ().add(1, 'year'),
          updatedAt: new Date().toUTCString(),
        }}
        cancel={() => {
          showModal(typeModal.PROMO_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.PROMO_DELETE} onClose={() => setDeletePromoCode(null)}>
      <DeleteAttention
        description={`Promo ${promoCodeForDelete ? promoCodeForDelete.id : ''} ?`}
        cancel={() => {
          setDeletePromoCode(null);
        }}
        onDelete={() => {
          setDeletePromoCode(null);
          deletePromoCode(promoCodeForDelete.id);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.WHATS_NEW_ADD}>
      <DetailsWhatsNew
        mode={'ADD'}
        whatsNew={{
          name: '',
          version: '',
          description: '',
          isActiveTryNow: false,
          localization: {},
          mediaType: null,
        }}
        cancel={() => {
          showModal(typeModal.WHATS_NEW_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.WHATS_NEW_EDIT}>
      <DetailsWhatsNew
        mode={'EDIT'}
        whatsNew={whatsNewForEdit}
        deleteWhatsNew={() => {
          setDeleteWhatsNew(whatsNewForEdit);
          showModal(typeModal.WHATS_NEW_EDIT, false);
        }}
        cancel={() => {
          showModal(typeModal.WHATS_NEW_EDIT, false);
        }}
      />
    </Modal>


    <Modal modalType={typeModal.WHATS_NEW_DELETE} onClose={() => setDeleteWhatsNew(null)}>
      <DeleteAttention
        description={`Whats new ${whatsNewForDelete ? whatsNewForDelete.name : ''} ?`}
        cancel={() => {
          setDeleteWhatsNew(null);
        }}
        onDelete={() => {
          setDeleteWhatsNew(null);
          deleteWhatsNew(whatsNewForDelete.id);
        }}
      />
    </Modal>

    <Modal modalType={typeModal.CROSS_PROMO_DELETE} onClose={() => setDeleteCrossPromo(null)}>
      <DeleteAttention
        description={`Cross promo ${crossPromoForDelete ? crossPromoForDelete.id : ''} ?`}
        cancel={() => {
          setDeleteCrossPromo(null);
        }}
        onDelete={() => {
          setDeleteCrossPromo(null);
          deleteCrossPromo(crossPromoForDelete.id);
        }}
      />
    </Modal>
  </div>;
};

const mapStateToProps = (state) => ({
  error: state.appState.error,
  asoExperimentForDelete: state.appState.asoExperimentForDelete,
  asoExperimentForEdit: state.appState.asoExperimentForEdit,
  asoExperimentForCopy: state.appState.asoExperimentForCopy,
  selectedAppKeyword: state.keywordsRanks.selectedApp,
  keywordForEdit: state.appState.keywordForEdit,
  keywordForDelete: state.appState.keywordForDelete,
  isPositionsReport: state.keywordsRanks.selectedReportType === KeywordsReportType.POSITION_REPORT,
  eventForEdit: state.appState.eventForEdit,
  eventForDelete: state.appState.eventForDelete,
  promoCodeForDelete: state.appState.promoCodeForDelete,
  whatsNewForEdit: state.appState.whatsNewForEdit,
  whatsNewForDelete: state.appState.whatsNewForDelete,
  crossPromoForDelete: state.appState.crossPromoForDelete,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  setError: bindActionCreators(setError, dispatch),
  setDeleteAsoExperiment: bindActionCreators(setDeleteAsoExperiment, dispatch),
  deleteAsoExperiment: bindActionCreators(deleteAsoExperiment, dispatch),
  setKeywordForEdit: bindActionCreators(setKeywordForEdit, dispatch),
  setKeywordToDelete: bindActionCreators(setKeywordToDelete, dispatch),
  deleteKeyword: bindActionCreators(deleteKeyword, dispatch),
  setEventForEdit: bindActionCreators(setEventForEdit, dispatch),
  setEventForDelete: bindActionCreators(setEventForDelete, dispatch),
  deleteEvent: bindActionCreators(deleteEvent, dispatch),
  deletePromoCode: bindActionCreators(deletePromoCode, dispatch),
  setDeletePromoCode: bindActionCreators(setDeletePromoCode, dispatch),
  setDeleteWhatsNew: bindActionCreators(setDeleteWhatsNew, dispatch),
  deleteWhatsNew: bindActionCreators(deleteWhatsNew, dispatch),
  deleteCrossPromo: bindActionCreators(deleteCrossPromo, dispatch),
  setDeleteCrossPromo: bindActionCreators(setDeleteCrossPromo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
