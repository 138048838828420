import React from 'react';
import {AiFillApple} from 'react-icons/ai';
import {ReactComponent as AndroidIcon} from '../../resources/imgs/androidIcon.svg';

export const AppBadge = ({app, style, isIconOnly = false, isIconOnlyV2 = false}) => {
  if (isIconOnly) {
    return <div style={{...style, ...{display: 'flex', alignItems: 'center'}}}>
      <img
        src={appToAppIconOnlyImgPath(app)}
        style={{width: 32, height: 32, borderRadius: '5px'}}
        alt={app.name}
      />
    </div>;
  }

  if (isIconOnlyV2) {
    return <div style={{...style, ...{display: 'flex', alignItems: 'center'}}}>
      <img
        src={app.icon}
        style={{width: 32, height: 32, borderRadius: '5px'}}
        alt={app.name}
      />
      <div className={'select-app-label'}>
        {app.store === 'apple' ? <AiFillApple style={{minWidth: '18', minHeight: '18'}}/> :
            <AndroidIcon style={{minWidth: '13', minHeight: '18'}}/>}
      </div>
    </div>;
  }

  return <div style={{...style, ...{display: 'flex', alignItems: 'center'}}}>
    <img
      src={app.icon}
      style={{width: 32, height: 32, borderRadius: '5px'}}
      alt={app.name}
    />
    <div className={'select-app-label'}>
      {app.store === 'apple' ? <AiFillApple style={{minWidth: '18', minHeight: '18'}}/> :
                <AndroidIcon style={{minWidth: '13', minHeight: '18'}}/>}
    </div>
    <div style={{paddingLeft: '6px'}}>
      {app.name}
    </div>
  </div>;
};

const appToAppIconOnlyImgPath = (app) => {
  const platform = app.store === 'apple' ? 'iOS' : 'android';
  const appIdentifier = app.appIdentifier ? app.appIdentifier.toLowerCase() : 'grid';

  return require(`../../resources/imgs/apps/${appIdentifier}_${platform}.png`).default;
};
