import {combineReducers} from 'redux';
import appState from './appState';
import auth from './auth';
import ranks from './ranks';
import ratings from './ratings';
import aso from './aso';
import metrics from './metrics';
import keywordsRanks from './keywordsRanks';
import asoReport from './asoReport';
import promo from './promo';
import whatsNew from './whatsNew';
import crossPromo from './crossPromo';
import qa from './qa';

const appReducer = combineReducers({
  appState,
  auth,
  ranks,
  ratings,
  aso,
  metrics,
  keywordsRanks,
  asoReport,
  promo,
  whatsNew,
  crossPromo,
  qa,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
