import React, {useState} from 'react';
import {SearchInput} from '../../general/SearchInput';

export const DebugHeader = ({search}) => {
  const [deviceId, setDeviceId] = useState('');

  return <div className={'debug-header'}>
    <SearchInput
      value={deviceId}
      placeholder={'device id'}
      style={{width: '400px'}}
      handleChange={(value) => {setDeviceId(value)}}
      handleKeyPress={(e) => {
        if (e.key === 'Enter') {
          if(deviceId.length !== 0){
            search(deviceId)
          }
        }
      }}
    />
    <button
      className={'onelight-btn-apply'}
      onClick={() => {search(deviceId)}}
      style={{marginLeft: '20px', minWidth: '70px'}}
    >
      Search
    </button>
  </div>;
};
