import {getItem} from './LocalStoargeUtill';

export const getLocalizedValue = (localization, propName, localeEternal) => {
  const locale = localeEternal ? localeEternal : getItem('currentLang', 'en');

  if (!localization || !localization[locale] || !localization[locale][propName]) {
    return '';
  }

  return localization[locale][propName];
};

export const changeLocalization = (newValue, propName, localization, setDefValue, setLocalization, localeEternal) => {
  if (!newValue) {
    return;
  }
  const locale = localeEternal ? localeEternal : getItem('currentLang', 'en');

  if (locale === 'en') {
    setDefValue(newValue);
  }

  const newLocalization = Object.assign({}, localization, {
    [locale]: Object.assign({}, localization[locale], {
      [propName]: newValue,
    }),
  });

  setLocalization(newLocalization);
};

