import React from 'react';
import momentZ from 'moment-timezone';
import {roundCommands, parsePath} from 'svg-round-corners';
import {cutTextOnParts} from '../../../utils/StringUtils';
const formatDate = 'DD/MM/yyyy';

export const CursorEvent = ({x, y, orientation, datum, width, height}) => {
  const stringsName = cutTextOnParts(datum.name, 19);

  x = orientation === 'right' ? x + 20 : x - 20;
  height = height + 25 * stringsName.length;
  y = y - height / 2;
  width = width < 82 ? 82 : width;
  width = orientation === 'right' ? width : width * -1;
  return <g>
    <path style={{
      zIndex: 4,
      strokeAlignment: 'center',
    }} d={roundCommands(parsePath(`M${x} ${y} L${x + width} ${y} ${x + width} ${y + height} ${x} ${y + height} z`), 10, 2).path}
    fill='#ffffff' stroke={datum.color} paintOrder='stroke fill markers'
    strokeLinecap='round'
    strokeWidth='2' vectorEffect='non-scaling-stroke' transform={`translate(${orientation === 'right' ? 5 : -5} 0)`} />

    <g className='event-tooltip-name' transform='translate(0 18)'>
      {
        stringsName.map((partName, index) => {
          return <text x={x - (orientation === 'right' ? 0 : -width)} y={y + (index - 1) * 20} fill={datum.color} className='event-tooltip-name' transform='translate(7 18)'>
            {partName}
          </text>;
        })
      }
    </g>
    <text x={x - (orientation === 'right' ? 0 : -(width))} y={y + (stringsName.length) * 15} className='event-tooltip-date' transform='translate(7 18)'>
      {momentZ(datum.x).format(formatDate)}
    </text>
  </g>;
};


