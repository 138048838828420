import React from 'react';
import momentZ from 'moment-timezone';
import {AppSelector} from '../general/AppSelector';
import {CountrySelector} from '../general/CountrySelector';
import {MetricsSourceTypesSelector} from '../general/MetricsSourceTypesSelector';
import {RegionSelector} from '../general/RegionSelector';
import {SingleDatePicker} from '../general/SingleDatePicker';
import {StoreSelector} from '../general/StoreSelector';
import {Store} from '../../models/store';
import {SingleSelector} from '../general/SingleSelector';
import {Switcher} from '../general/Switcher';
import {colors} from '../../utils/StyleUtils';

export const AsoReportHeader = ({
  searchParams, changeSearchParams, onelightApps, createAsoReportXML, isHideTabelDays, setIsHideTabelDays,
}) => {
  const changeDate = (date, propName) => {
    changeSearchParams(
        momentZ(date).format('YYYY-MM-DD'),
        propName,
    );
  };

  return <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '4px'}}>
      <div className={'aso-report-release-label'} style={{paddingRight: '6px'}}>
        Release:
      </div>
      <SingleDatePicker
        style={{display: 'flex', alignItems: 'center', paddingLeft: '10px', flex: 2, paddingBottom: '0px'}}
        date={searchParams.date}
        dropdownStyle={{transform: 'translate(-80%, 40px)'}}
        onChange={(date) => changeDate(date, 'date')}
      />
    </div>
    <div className={'metrics-header-container'} style={{zIndex: '2', borderRadius: '6px'}}>
      <StoreSelector
        style={{paddingLeft: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40px'}}
        selectedValue={searchParams.store}
        change={(store) => {
          changeSearchParams([], 'appIds');
          changeSearchParams(store, 'store');
        }}
      />
      <AppSelector
        selectedApps={searchParams.appIds}
        style={{paddingLeft: '10px'}}
        allApps={onelightApps.filter((app) => {
          return app.store === searchParams.store;
        })}
        changeApps={(apps) => {
          changeSearchParams(apps, 'appIds');
        }}
      />
      <CountrySelector
        selectedCountries={searchParams.countries}
        style={{paddingLeft: '10px'}}
        changeCountries={(countries) => {
          changeSearchParams(countries, 'countries');
        }}
      />
      {
        searchParams.store === Store.APPLE.value ?
            <RegionSelector
              selectedRegions={searchParams.regions}
              style={{paddingLeft: '10px'}}
              changeRegions={(regions) => {
                changeSearchParams(regions, 'regions');
              }}
            /> : null
      }
      <CountrySelector
        selectedCountries={searchParams.excludeCountries}
        style={{paddingLeft: '10px'}}
        placeholder={'Exclude country'}
        changeCountries={(countries) => {
          changeSearchParams(countries, 'excludeCountries');
        }}
      />
      <MetricsSourceTypesSelector
        style={{paddingLeft: '10px'}}
        selectedSourceTypes={searchParams[(searchParams.store === Store.APPLE.value ? 'appleSourceTypes' : 'googleSourceTypes')]}
        changeSourceTypes={(sourceTypes) => {
          const type = searchParams.store === Store.APPLE.value ? 'appleSourceTypes' : 'googleSourceTypes';
          changeSearchParams(sourceTypes, type);
        }}
        store={searchParams.store}
      />
      <SingleSelector
        change={(period) => {
          if (!isNaN(period) && Number(period) <= 50 && Number(period) > 0) {
            changeSearchParams(Number(period), 'period');
          }
        }}
        isClearable={true}
        selectedValue={searchParams.period}
        style={{
          alignItems: 'center',
          paddingLeft: '10px',
          width: '100px',
        }}
        options={[{value: 7, label: 7}, {value: 14, label: 14}, {value: 30, label: 30}]}
      />
      <div style={{display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px'}}>
        <div className={'switcher-label-text'}>
          Collapse table
        </div>
        <Switcher
          isActive={isHideTabelDays}
          colorOn={colors.defSwitcherActive}
          colorOff={colors.defSwitcherUnActive}
          switch={(isActive) => {
            setIsHideTabelDays(isActive);
          }}
          id={'event-display'}
        />
      </div>
      <button
        className={'onelight-btn-apply'}
        onClick={createAsoReportXML}
        style={{marginLeft: 'auto', marginRight: '20px', minWidth: '70px'}}
      >
        Export
      </button>
    </div>
  </div>;
};


