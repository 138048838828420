import {configs} from '../resources/configs';
import {getAccessToken} from './AuthUtill';
import {LOGOUT} from '../actions/auth';

export const createRequest = (dispatch, method, path, onload, isAsync = true, onerror) => {
  const xhr = new XMLHttpRequest();
  xhr.open(method, configs.apiUrl + path, isAsync);
  xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('authorization', getAccessToken());

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        onload(xhr.responseText);
      } else {
        if (xhr.status === 401) {
          dispatch({type: LOGOUT});
        } else {
          onerror(xhr.responseText);
        }
      }
    }
  };

  return xhr;
};

export const getFileArrayBuffer = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'arraybuffer';
  if (!configs.isLocal) {
    xhr.withCredentials = true;
  }

  xhr.open('GET', url, true);
  xhr.onload = function(e) {
    callback(xhr.response);
  };
  xhr.onerror = function(e) {
    callback(xhr.response);
  };
  xhr.send();
};
