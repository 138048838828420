import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Badge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.state.isActive !== nextProps.isActive) {
            this.setState({ isActive:  nextProps.isActive});
        }
    }

    onChange = debounce(this.props.debounce ? this.props.debounce : 200, () => this.props.onChange(this.state.isActive))

    handleChange = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.onChange());
    }

    render () {
        return (
            <div className={'onelight-badge' + (this.state.isActive ? "-active" : "")}
                 onClick={this.handleChange}
                 style={this.props.style}
            >
                <div className={"onelight-badge-text" + (this.state.isActive ? "-active" : "")}>
                    {this.props.value}
                </div>
            </div>
        )
    }
}
