import React, { Component } from 'react'
import { ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';

export class ErrorDetails extends Component {

    renderBody = () => {

        return <div className={"attention-body"}>
            <AttentionIcon/>
            <div className={"onelight-attention-text"} style={{marginTop: "12px"}}>
                ERROR
            </div>
            <div className={"onelight-attention-description-text"} style={{marginTop: "25px"}}>
                 <b>{this.props.error}</b>
            </div>
        </div>
    }

    renderFooter = () => {

        return <div className={"attention-footer"}>
            <button className={"onelight-second-btn"} onClick={()=>{this.props.cancel()}}>
                Cancel
            </button>

        </div>
    }

    render () {
        return (
            <div className={"attention-container"}>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }
}