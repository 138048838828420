import React from 'react';
import {appleGenreIdName, googleGenreIdName} from '../../actions/apps';
import {ReactComponent as Delete} from '../../resources/imgs/delete-icon.svg';
import {AiFillAndroid, AiFillApple} from 'react-icons/ai';
import {Store} from '../../models/store';

export const RanksTableHeader = ({countries, group}) => {
  return <div className={'rank-list-header'}>
    <div className={'rank-list-header-app'}>
      {group.store === Store.APPLE.value ? appleGenreIdName[group.genreId] : googleGenreIdName[group.genreId]}
      {group.store === Store.APPLE.value ? <AiFillApple/> : <AiFillAndroid/>}
    </div>

    {countries.map((country) => {
      return <div className={'rank-list-header-country'} key={country}>
        {country}
      </div>;
    })}
    <Delete
      style={{marginLeft: '10px', alignSelf: 'center', opacity: 0}}
      onClick={() => {}}
    />
  </div>;
};
