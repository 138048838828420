import React from 'react';
import {AppSelector} from '../general/AppSelector';
import {typeModal} from '../../actions/general';
import {Switcher} from '../general/Switcher';
import {colors} from '../../utils/StyleUtils';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import {UpdateTimeHeader} from '../general/UpdateTimeHeader';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete-icon.svg';
import {KeywordsReportType} from '../../models/onelight';
import {FromToDatePicker} from '../general/FromToDatePicker';
import {EmptyDates} from './EmptyDates';

export const KeywordsRanksHeader = ({
  selectedApp, onelightApps, selectKeywordApp, showModal, selectedReportType, selectReport,
  setIsDetailsKeywords, isDetailsKeywords, isHideKeywords, setIsHideKeywords, lastUpdateDate, keywordsIdsForDelete, emptyDates,
  clearKeywordForDelete, deleteSelectedKeywords, compareParams, changeCompareParams, setIsShowChangesOnly, isShowChangesOnly,
}) => {
  return <div style={{display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 3}}>
    <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}}>
      <div style={{display: 'flex'}}>
        <div className={'metrics-header-tab' + (selectedReportType === KeywordsReportType.POSITION_REPORT ? '-active' : '')} onClick={() => {
          selectReport(KeywordsReportType.POSITION_REPORT);
        }} style={{width: '180px'}}>
          Positions Report
        </div>
        <div className={'metrics-header-tab' + (selectedReportType === KeywordsReportType.INCENT_MONITOR ? '-active' : '')} onClick={() => {
          selectReport(KeywordsReportType.INCENT_MONITOR);
        }} style={{width: '180px'}}>
          Incent Monitor
        </div>
        <div className={'metrics-header-tab' + (selectedReportType === KeywordsReportType.COMPARATIVE_REPORT ? '-active' : '')} onClick={() => {
          selectReport(KeywordsReportType.COMPARATIVE_REPORT);
        }} style={{width: '180px'}}>
          Comparative Report
        </div>
      </div>
      {selectedReportType === KeywordsReportType.COMPARATIVE_REPORT ? <div style={{display: 'flex', alignItems: 'center'}}>
        <div className={'onelight-second-text'} style={{paddingRight: '6px'}}>
          compare:
        </div>
        <FromToDatePicker
          style={{display: 'flex', alignItems: 'center', paddingBottom: '4px', alignSelf: 'flex-end'}}
          fromDate={compareParams.firstDate}
          toDate={compareParams.secondDate}
          changeFromDate={(firstDate) => changeCompareParams({firstDate})}
          changeToDate={(secondDate) => changeCompareParams({secondDate})}
          changeLastDays={(firstDate, secondDate) => {
            changeCompareParams({firstDate, secondDate});
          }}
          daysAgo={[3, 7, 14, 30]}
          isReverseDates={true}
        />
      </div> : null
      }
      <UpdateTimeHeader
        date={toUserTimeZoneDate(lastUpdateDate)}
      />
    </div>
    <div className={'metrics-header-container'}>
      <AppSelector
        selectedApps={selectedApp ? [selectedApp.id] : []}
        style={{paddingLeft: '10px', flex: 2, maxWidth: '390px'}}
        allApps={onelightApps}
        isMulti={false}
        changeApps={(appId) => {
          selectKeywordApp(onelightApps.find((onelightApp) => {
            return appId === onelightApp.id;
          }));
        }}
      />
      <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto', paddingRight: '20px'}}>
        {
          keywordsIdsForDelete.length !== 0 ? <div className={'onelight-second-text'} style={{display: 'flex', marginRight: '100px'}}>
            {'Selected ' + keywordsIdsForDelete.length}
            <DeleteIcon className={'pointer-element'} style={{paddingLeft: '20px'}}
              onClick={() => {
                deleteSelectedKeywords();
              }}
            />
            <div className={'pointer-element'} style={{paddingLeft: '10px'}} onClick={() => {
              clearKeywordForDelete();
            }}>
              Clear
            </div>
          </div> : null
        }

        <EmptyDates
          emptyDates={emptyDates}
          className={'empty-dates-icon'}
        />

        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className={'switcher-label-text'}>
            Changes Only
          </div>
          <Switcher
            isActive={isShowChangesOnly}
            colorOn={colors.defSwitcherActive}
            colorOff={colors.defSwitcherUnActive}
            switch={(isActive) => {
              setIsShowChangesOnly(isActive);
            }}
            id={'show_changes_only'}
          />
        </div>

        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '20px'}}>
          <div className={'switcher-label-text'}>
            Hide Keywords
          </div>
          <Switcher
            isActive={isHideKeywords}
            colorOn={colors.defSwitcherActive}
            colorOff={colors.defSwitcherUnActive}
            switch={(isActive) => {
              setIsHideKeywords(isActive);
            }}
            id={'keywords_hide'}
          />
        </div>

        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '20px'}}>
          <div className={'switcher-label-text'}>
            Keywords Details
          </div>
          <Switcher
            isActive={isDetailsKeywords}
            colorOn={colors.defSwitcherActive}
            colorOff={colors.defSwitcherUnActive}
            switch={(isActive) => {
              setIsDetailsKeywords(isActive);
            }}
            id={'keyword_details'}
          />
        </div>
      </div>
    </div>

    <div className={'add-event-container'} style={{flex: 1}} onClick={() => {
      showModal(typeModal.KEYWORD_ADD, true, {});
    }}>
      <span>Add Keyword</span>
      <PlusIcon
        style={{paddingLeft: '9px'}}
      />
    </div>
  </div>;
};


