export const SELECT_CROSS_PROMO_APP = 'SELECT_CROSS_PROMO_APP';
export const SELECT_CROSS_PROMO = 'SELECT_CROSS_PROMO';
export const CREATE_CROSS_PROMO = 'CREATE_CROSS_PROMO';
export const MOVE_CROSS_PROMO = 'MOVE_CROSS_PROMO';
export const UPDATE_CROSS_PROMO = 'UPDATE_CROSS_PROMO';
export const IMPORT_CROSS_PROMO = 'IMPORT_CROSS_PROMO';
export const DELETE_CROSS_PROMO = 'DELETE_CROSS_PROMO';
export const SET_DELETE_CROSS_PROMO = 'SET_DELETE_CROSS_PROMO';
export const UPDATE_CROSS_PROMO_BATCH = 'UPDATE_CROSS_PROMO_BATCH';

export const selectCrossPromoApp = (app) => (dispatch) => {
  dispatch({
    type: SELECT_CROSS_PROMO_APP,
    app,
  });
};

export const selectCrossPromo = (crossPromo) => (dispatch) => {
  dispatch({
    type: SELECT_CROSS_PROMO,
    crossPromo,
  });
};

export const createCrossPromo = (app) => (dispatch) => {
  dispatch({
    type: CREATE_CROSS_PROMO,
    app,
  });
};

export const moveCrossPromo = (crossPromoList) => (dispatch) => {
  dispatch({
    type: MOVE_CROSS_PROMO,
    crossPromoList,
  });
};

export const updateCrossPromo = (propName, value) => (dispatch) => {
  dispatch({
    type: UPDATE_CROSS_PROMO,
    propName,
    value,
  });
};

export const updateCrossPromoBatch = (batch) => (dispatch) => {
  dispatch({
    type: UPDATE_CROSS_PROMO_BATCH,
    batch
  });
};

export const importCrossPromo = (crossPromo) => (dispatch) => {
  dispatch({
    type: IMPORT_CROSS_PROMO,
    crossPromo,
  });
};

export const deleteCrossPromo = (crossPromoId) => (dispatch) => {
  dispatch({
    type: DELETE_CROSS_PROMO,
    crossPromoId,
  });
};

export const setDeleteCrossPromo = (crossPromo) => (dispatch) => {
  dispatch({
    type: SET_DELETE_CROSS_PROMO,
    crossPromo,
  });
};
