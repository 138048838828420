import uuid from 'uuid/v4';
import {
  ADD_ASO_EXPERIMENT,
  CHANGE_FORM_INPUT,
  CHANGE_PHRASES,
  CHECK_KEYWORD,
  DELETE_ASO_EXPERIMENT,
  ORDER_ASO_KEYWORDS,
  SAVE_ASO_EXPERIMENT,
  SAVE_ASO_EXPERIMENTS,
  SET_IS_LOADING_ASO_EXPERIMENT,
  SET_IS_LOADING_ASO_EXPERIMENTS,
  SET_IS_SAVE_ASO_EXPERIMENT,
  UPDATE_ASO_EXPERIMENT,
  SET_IS_CHANGED,
  MOVE_MAIN_TO_OLD,
  MOVE_OLD_TO_MAIN,
  CHANGE_PHRASES_RANKS,
  CHANGE_PHRASES_POSITIONS,
  ORDER_ASO_PHRASES,
  DELETE_PHRASE, CHANGE_ACTIVE_SUB_FORMS,
} from '../actions/aso';
import {splitBySeparator, splitPhase} from '../utils/StringUtils';
import {getAdminIamActions} from '../utils/AuthUtill';

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function() {
  return this.filter(function(value, index, self) {
    return self.indexOf(value) === index;
  });
};

const getInitialState = () => {
  return {
    stateId: uuid(),
    asoExperiments: [],
    selectedAsoExperiment: null,
    selectedAsoExperimentData: null,
    isLoadingAsoExperiments: false,
    isLoadingAsoExperiment: false,
    timeStampSaveAsoExperiments: 0,
    timeStampSaveAsoExperiment: 0,
    isLoadAsoExperiments: true,
    isSave: false,
    isChanged: false,
    orderPhrasesParams: {
      orderBy: 'ranks',
      orderIndex: 'ASC',
    },
    orderParams: {
      orderBy: 'count',
      orderIndex: 'ASC',
    },
    isIndividualAsoExperiment: (() => {
      const iamActions = getAdminIamActions();
      return (iamActions.indexOf('webUI:AccessIndividualAsoMetadata') !== -1 && iamActions.indexOf('webUI:AccessAsoMetadata') === -1);
    })(),
  };
};

export default function aso(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_ASO_EXPERIMENTS:
      const timeStampSaveAsoExperiments = action.requestTimeEpoch;
      const stateIdRequestedSaveApps = action.stateId;

      if (
        timeStampSaveAsoExperiments > state.timeStampSaveAsoExperiments &&
        stateIdRequestedSaveApps === state.stateId
      ) {
        return Object.assign({}, state, {
          asoExperiments: action.asoExperiments,
          isLoadingAsoExperiments: false,
          timeStampSaveAsoExperiments: timeStampSaveAsoExperiments,
          isLoadAsoExperiments: false,
        });
      }

    case ADD_ASO_EXPERIMENT: {
      const asoExperiments = [...state.asoExperiments];
      asoExperiments.unshift(action.asoExperiment);

      return Object.assign({}, state, {
        asoExperiments,
      });
    }

    case DELETE_ASO_EXPERIMENT: {
      const asoExperiments = state.asoExperiments.filter((asoExperiment) => {
        return asoExperiment.uuid !== action.uuid;
      });

      return Object.assign({}, state, {
        asoExperiments,
        selectedAsoExperiment: state.selectedAsoExperiment &&
        state.selectedAsoExperiment.uuid === action.uuid ? null : state.selectedAsoExperiment,
      });
    }

    case UPDATE_ASO_EXPERIMENT: {
      const asoExperiments = state.asoExperiments.map((asoExperiment) => {
        if (asoExperiment.uuid === action.asoExperiment.uuid) {
          asoExperiment.country = action.asoExperiment.country;
          asoExperiment.appId = action.asoExperiment.appId;
          asoExperiment.creatorId = action.asoExperiment.creatorId;
          asoExperiment.updatedAt = action.asoExperiment.updatedAt;
        }
        return asoExperiment;
      });

      return Object.assign({}, state, {
        asoExperiments,
        selectedAsoExperiment: state.selectedAsoExperiment &&
          state.selectedAsoExperiment.uuid === action.asoExperiment.uuid ? action.asoExperiment : state.selectedAsoExperiment,
      });
    }

    case SAVE_ASO_EXPERIMENT: {
      const timeStampSaveAsoExperiment = action.requestTimeEpoch;
      const stateIdRequestedSaveApps = action.stateId;

      if (
        timeStampSaveAsoExperiment > state.timeStampSaveAsoExperiment &&
        stateIdRequestedSaveApps === state.stateId
      ) {
        const asoExperimentData = Object.keys(action.asoExperiment.data).length !== 0 ?
          action.asoExperiment.data :
          {
            phrases: '',
            positions: '',
            ranks: '',
            keywords: {},
            countActiveSubForms: 0,
            forms: [
              {name: 'main', title: '', subtitle: '', notes: '', keywords: '', words: []},
              {name: 'additional', title: '', subtitle: '', notes: '', keywords: '', words: []},
              {name: 'old', title: '', subtitle: '', notes: '', keywords: '', words: []},
            ],
          };

        return Object.assign({}, state, {
          selectedAsoExperiment: action.asoExperiment,
          selectedAsoExperimentData: asoExperimentData,
          isLoadingAsoExperiment: false,
          timeStampSaveAsoExperiment: timeStampSaveAsoExperiment,
        });
      }
    }

    case CHANGE_PHRASES: {
      const splitPhrase = splitBySeparator(action.phrases, '\n');
      const keywords = createKeywords(splitPhrase, state.selectedAsoExperimentData.keywords);

      const forms = [...state.selectedAsoExperimentData.forms];

      forms.forEach((form) => {
        const checkedName = formNameToCheckedName(form.name);
        form.keywords.split(',').forEach((word) => {
          if (!keywords[word]) {
            keywords[word] = {
              keyword: word,
              length: word.length,
            };
          }
          keywords[word][checkedName] = true;
        });
      });
      const keywordsForForm = createKeywordsForForm(keywords);

      forms.forEach((form) => {
        form.keywords = keywordsForForm[form.name];
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          phrases: action.phrases,
          keywords,
          forms,
        }),
      });
    }

    case CHANGE_PHRASES_RANKS: {
      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          ranks: action.ranks,
        }),
      });
    }

    case CHANGE_PHRASES_POSITIONS: {
      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          positions: action.positions,
        }),
      });
    }

    case CHANGE_ACTIVE_SUB_FORMS: {
      const countActiveSubForms = action.countActiveSubForms < 3 ? action.countActiveSubForms : 3;
      const formsCopy = [...state.selectedAsoExperimentData.forms];

      if (!state.selectedAsoExperimentData.countActiveSubForms ||
          countActiveSubForms > state.selectedAsoExperimentData.countActiveSubForms
      ) {
        formsCopy.push({name: 'sub_' + countActiveSubForms, title: '', subtitle: '', notes: '', keywords: '', words: []});
      }
      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          countActiveSubForms: countActiveSubForms,
          forms: formsCopy,
        }),
      });
    }

    case CHANGE_FORM_INPUT: {
      const forms = [...state.selectedAsoExperimentData.forms];

      forms.forEach((form) => {
        if (form.name === action.name) {
          form[action.typeInput] = action.value;
          const titleSubtitleWords = splitPhase(form.title).concat(splitPhase(form.subtitle));
          form.overlapping = titleSubtitleWords;
          form.words = titleSubtitleWords.concat(splitPhase(form.keywords.replaceAll(',', ' ')));
        }
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          forms,
          keywords: (action.typeInput === 'keywords' ?
              switchKeywordsByForms(state.selectedAsoExperimentData.keywords, forms, state.selectedAsoExperimentData.phrases) : state.selectedAsoExperimentData.keywords),
        }),
      });
    }

    case MOVE_MAIN_TO_OLD: {
      const forms = [...state.selectedAsoExperimentData.forms];
      const keywords = {...state.selectedAsoExperimentData.keywords};

      const formMain = forms.find((form) => form.name === 'main');
      const formOld = forms.find((form) => form.name === 'old');

      formOld.title = formMain.title;
      formOld.subtitle = formMain.subtitle;
      formOld.keywords = formMain.keywords;
      formOld.notes = formMain.notes;
      const titleSubtitleWords = splitPhase(formOld.title)
          .concat(splitPhase(formOld.subtitle));
      formOld.overlapping = titleSubtitleWords;
      formOld.words = titleSubtitleWords
          .concat(splitPhase(formOld.keywords.replaceAll(',', ' ')));

      formMain.title = '';
      formMain.subtitle = '';
      formMain.keywords = '';
      formMain.notes = '';
      formMain.words = [];

      Object.keys(keywords).forEach((keyword) => {
        keywords[keyword].isChecked3 = keywords[keyword].isChecked1;
        keywords[keyword].isChecked1 = false;
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          forms,
          keywords,
        }),
      });
      break;
    }

    case MOVE_OLD_TO_MAIN: {
      const forms = [...state.selectedAsoExperimentData.forms];
      const keywords = {...state.selectedAsoExperimentData.keywords};

      const formMain = forms.find((form) => form.name === 'main');
      const formOld = forms.find((form) => form.name === 'old');

      formMain.title = formOld.title;
      formMain.subtitle = formOld.subtitle;
      formMain.keywords = formOld.keywords;
      formMain.notes = formOld.notes;
      const titleSubtitleWords = splitPhase(formMain.title)
          .concat(splitPhase(formMain.subtitle));
      formMain.overlapping = titleSubtitleWords;
      formMain.words = titleSubtitleWords
          .concat(splitPhase(formMain.keywords.replaceAll(',', ' ')));

      Object.keys(keywords).forEach((keyword) => {
        keywords[keyword].isChecked1 = keywords[keyword].isChecked3;
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          forms,
          keywords,
        }),
      });
      break;
    }

    case CHECK_KEYWORD: {
      const keywords = {...state.selectedAsoExperimentData.keywords};

      Object.keys(keywords).forEach((keyword) => {
        if (keyword === action.keyword) {
          keywords[keyword][action.propName] = action.isActive;
        }
      });

      const forms = [...state.selectedAsoExperimentData.forms];
      const keywordsForForm = createKeywordsForForm(keywords);

      forms.forEach((form) => {
        form.keywords = keywordsForForm[form.name];
        const titleSubtitleWords = splitPhase(form.title)
            .concat(splitPhase(form.subtitle));
        form.overlapping = titleSubtitleWords;
        form.words = titleSubtitleWords
            .concat(splitPhase(form.keywords.replaceAll(',', ' ')));
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          keywords,
          forms,
        }),
      });
    }

    case ORDER_ASO_KEYWORDS:
      return Object.assign({}, state, {
        orderParams: action.orderParams ||
          (action.orderParams.orderBy !== 'order' || action.orderParams.orderIndex !== 'DESC'),
      });

    case ORDER_ASO_PHRASES:
      const sortedEntities = sortPhrases(
          {
            phrases: state.selectedAsoExperimentData.phrases,
            ranks: state.selectedAsoExperimentData.ranks,
            positions: state.selectedAsoExperimentData.positions,
          },
          action.orderParams,
      );
      return Object.assign({}, state, {
        orderPhrasesParams: action.orderParams,
        selectedAsoExperimentData: {
          ...state.selectedAsoExperimentData,
          ...{phrases: sortedEntities.phrases, ranks: sortedEntities.ranks, positions: sortedEntities.positions}},
      });

    case SET_IS_SAVE_ASO_EXPERIMENT:
      return Object.assign({}, state, {
        isSave: action.isSave,
      });

    case SET_IS_CHANGED:
      return Object.assign({}, state, {
        isChanged: action.isChanged,
      });

    case SET_IS_LOADING_ASO_EXPERIMENT:
      return Object.assign({}, state, {
        isLoadingAsoExperiment: action.isLoading,
      });

    case SET_IS_LOADING_ASO_EXPERIMENTS:
      return Object.assign({}, state, {
        isLoadingAsoExperiments: action.isLoading,
      });

    case DELETE_PHRASE: {
      const splitPhrase = splitBySeparator(state.selectedAsoExperimentData.phrases, '\n')
          .filter((phrase, index) => {
            return action.phraseIndexes.indexOf(String(index)) === -1;
          });
      const keywords = createKeywords(splitPhrase, state.selectedAsoExperimentData.keywords);

      const forms = [...state.selectedAsoExperimentData.forms];

      forms.forEach((form) => {
        const checkedName = formNameToCheckedName(form.name);
        form.keywords.split(',').forEach((word) => {
          if (!keywords[word]) {
            keywords[word] = {
              keyword: word,
              length: word.length,
            };
          }
          keywords[word][checkedName] = true;
        });
      });
      const keywordsForForm = createKeywordsForForm(keywords);

      forms.forEach((form) => {
        form.keywords = keywordsForForm[form.name];
      });

      return Object.assign({}, state, {
        selectedAsoExperimentData: Object.assign({}, state.selectedAsoExperimentData, {
          phrases: splitPhrase.reduce((prev, curr) => {
            return prev + curr + '\n';
          }, ''),
          ranks: splitBySeparator(state.selectedAsoExperimentData.ranks, '\n')
              .filter((rank, index) => {
                return action.phraseIndexes.indexOf(String(index)) === -1;
              }).reduce((prev, curr) => {
                return prev + curr + '\n';
              }, ''),
          positions: splitBySeparator(state.selectedAsoExperimentData.positions, '\n')
              .filter((positions, index) => {
                return action.phraseIndexes.indexOf(String(index)) === -1;
              }).reduce((prev, curr) => {
                return prev + curr + '\n';
              }, ''),
          keywords,
          forms,
        }),
      });
    }

    default: return state;
  }
}

const createKeywords = (phrases, keywords) => {
  let words = [];

  phrases.forEach((phrase) => {
    if (phrase && phrase !== '') {
      words = words.concat(splitPhase(phrase).unique());
    }
  });

  const newKeywords = {};

  words.forEach((word) => {
    if (!newKeywords[word]) {
      newKeywords[word] = {
        count: 0,
        length: word.length,
        keyword: word,
        isChecked1: false,
        isChecked2: false,
        isChecked3: false,
        isChecked4: false,
        isChecked5: false,
        isChecked6: false,
      };

      if (keywords[word]) {
        newKeywords[word].isChecked1 = keywords[word].isChecked1;
        newKeywords[word].isChecked2 = keywords[word].isChecked2;
        newKeywords[word].isChecked3 = keywords[word].isChecked3;
        newKeywords[word].isChecked4 = keywords[word].isChecked4;
        newKeywords[word].isChecked5 = keywords[word].isChecked5;
        newKeywords[word].isChecked6 = keywords[word].isChecked6;
      }
    }
    newKeywords[word].count = newKeywords[word].count + 1;
  });

  return newKeywords;
};

const switchKeywordsByForms = (keywords, forms, phrases) => {
  const splitPhrase = splitBySeparator(phrases, '\n');
  let phraseWords = [];

  splitPhrase.forEach((phrase) => {
    if (phrase && phrase !== '') {
      phraseWords = phraseWords.concat(splitPhase(phrase).unique());
    }
  });

  const formWords = forms.map((form) => {
    return {
      name: form.name,
      words: form.keywords.split(','),
    };
  });

  const newKeywords = {...keywords};
  Object.values(newKeywords).forEach((keyword) => {
    formWords.forEach((form) => {
      keyword[formNameToCheckedName(form.name)] = form.words.indexOf(keyword.keyword) !== -1;
    });
  });
  Object.keys(newKeywords).forEach((keyword) => {
    if (!newKeywords[keyword].isChecked1 &&
        !newKeywords[keyword].isChecked2 &&
        !newKeywords[keyword].isChecked3 &&
        !newKeywords[keyword].isChecked4 &&
        !newKeywords[keyword].isChecked5 &&
        !newKeywords[keyword].isChecked6 &&
        phraseWords.indexOf(keyword) === -1
    ) {
      delete newKeywords[keyword];
    }
  });

  return newKeywords;
};

const createKeywordsForForm = (keywords) => {
  const keywordsForForm = {
    main: '',
    additional: '',
    old: '',
    sub_1: '',
    sub_2: '',
    sub_3: '',
  };

  const addToKeywords = (keyword, target) => {
    const isFirst = keywordsForForm[target] === '';
    keywordsForForm[target] += (isFirst ? '' : ',') + keyword;
  };

  Object.keys(keywords)
      .sort()
      .forEach((keyword) => {
        if (keywords[keyword].isChecked1) {
          addToKeywords(keyword, 'main');
        }
        if (keywords[keyword].isChecked2) {
          addToKeywords(keyword, 'additional');
        }
        if (keywords[keyword].isChecked3) {
          addToKeywords(keyword, 'old');
        }
        if (keywords[keyword].isChecked4) {
          addToKeywords(keyword, 'sub_1');
        }
        if (keywords[keyword].isChecked5) {
          addToKeywords(keyword, 'sub_2');
        }
        if (keywords[keyword].isChecked6) {
          addToKeywords(keyword, 'sub_3');
        }
      });

  return keywordsForForm;
};

const sortPhrases = (entities, orderParams) => {
  const {phrases, ranks, positions} = entities;

  const phrasesArr = phrases.split('\n');
  const ranksArr = ranks.split('\n');
  const positionsArr = positions.split('\n');
  const entitiesArr = {
    phrases: phrasesArr,
    ranks: ranksArr,
    positions: positionsArr,
  };
  let maxLength = phrasesArr.length;
  let maxLengthAt = 'phrases';

  if (ranksArr.length > maxLength) {
    maxLength = ranksArr.length;
    maxLengthAt = 'ranks';
  }

  if (positionsArr.length > maxLength) {
    maxLengthAt = 'positions';
  }

  let commonArr = [];

  entitiesArr[maxLengthAt].forEach((entity, index) => {
    commonArr.push({
      ranks: ranksArr.length > index ? ranksArr[index] : '',
      phrases: phrasesArr.length > index ? phrasesArr[index] : '',
      positions: positionsArr.length > index ? positionsArr[index] : '',
    });
  });

  commonArr = commonArr.sort((a, b) => {
    let orderA = a[orderParams.orderBy];
    let orderB = b[orderParams.orderBy];
    if (orderParams.orderBy === 'positions' || orderParams.orderBy === 'ranks') {
      orderA = orderA === '' ? (orderParams.orderIndex === 'ASC' ? -1 : 100000) : Number(orderA.trim());
      orderB = orderB === '' ? (orderParams.orderIndex === 'ASC' ? -1 : 100000) : Number(orderB.trim());
    }
    if (orderA > orderB) {
      return orderParams.orderIndex === 'ASC' ? -1 : 1;
    }

    if (orderA < orderB) {
      return orderParams.orderIndex === 'ASC' ? 1 : -1;
    }

    return 0;
  });

  const res = {
    ranks: '',
    positions: '',
    phrases: '',
  };

  commonArr.forEach((entity, index) => {
    res.ranks = res.ranks + entity.ranks + (index + 1 === commonArr.length ? '' : '\n');
    res.positions = res.positions + entity.positions + (index + 1 === commonArr.length ? '' : '\n');
    res.phrases = res.phrases + entity.phrases + (index + 1 === commonArr.length ? '' : '\n');
  });
  return res;
};

const formNameToCheckedName = (formName) => {
  const naming = {
    'main': 'isChecked1',
    'additional': 'isChecked2',
    'old': 'isChecked3',
    'sub_1': 'isChecked4',
    'sub_2': 'isChecked5',
    'sub_3': 'isChecked6',
  };

  return naming[formName];
};
