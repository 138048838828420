import React from 'react';
import {connect} from 'react-redux';
import {convertPropNameToNameChart, NAMES_TO_POSITION} from './index';
import MetricsChart from './charts/MetricsChart';
import {bindActionCreators} from 'redux';
import {setEventForEdit} from '../../actions/events';
import {MetricsLegend} from './MetricsLegend';

const MetricsDashboard = ({
  metrics, asaSubscribeMetrics, isHideEventsNames,
  selectedChart, events, setEventForEdit, isHideEvents,
}) => {
  const joinedMetrics = {...metrics, ...asaSubscribeMetrics};
  const metricsKeys = Object.keys(joinedMetrics);

  return <div className={'chart-container'}>
    {metricsKeys
        .sort((a, b) => {
          return NAMES_TO_POSITION[a] - NAMES_TO_POSITION[b];
        })
        .map((metricsType, index) => {
          return <MetricsChart
            selectedChart={selectedChart}
            name={convertPropNameToNameChart(metricsType)}
            key={metricsType}
            style={{height: '120px'}}
            metrics={joinedMetrics[metricsType]}
            events={events}
            isShowXAxis={true}
            setEventForEdit={setEventForEdit}
            isHideEventsNames={isHideEventsNames}
            isHideEvents={isHideEvents}
            isShowLegend={index === 0}
          />;
        })}
    <MetricsLegend/>
  </div>;
};

const mapStateToProps = (state) => ({
  metrics: state.metrics.metrics,
  asaSubscribeMetrics: state.metrics.asaSubscribeMetrics,
  selectedChart: state.metrics.selectedChart,
  events: state.metrics.events,
  isHideEventsNames: state.metrics.isHideEventsNames,
  isHideEvents: state.metrics.isHideEvents,
});

const mapDispatchToProps = (dispatch) => ({
  setEventForEdit: bindActionCreators(setEventForEdit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsDashboard);
