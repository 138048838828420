import React from 'react';
import momentZ from 'moment-timezone';
import {VictoryChart, VictoryStack, VictoryArea, VictoryScatter,
  VictoryAxis, VictoryTooltip, VictoryBar, VictoryLabel, VictoryLegend,
} from 'victory';
import sizeMe from 'react-sizeme';
import {getAreaMetricsGradient} from '../../../utils/StyleUtils';
import {ChartType} from '../../../reducers/metrics';
import {CursorEvent} from './CursorEvent';
import {compareString, getEvery} from '../../../utils/ArrayUtils';
import {getFirstsTimesOfMonth} from '../../../utils/TimeUtils';

const MetricsChart = ({
  metrics, name, style, size, isHideEventsNames, key, isShowXAxis, selectedChart,
  events, setEventForEdit, isHideEvents, isShowLegend = false,
}) => {
  let axisXDatesRation = Math.round((metrics.maxLengthDataSource * 0.857) / 17);
  axisXDatesRation = axisXDatesRation === 0 ? 1 : axisXDatesRation;

  const axisXDates = getEvery(metrics.allDates, axisXDatesRation);
  const firstMonthDates = getFirstsTimesOfMonth(axisXDates);
  const firstMonthDatesObj = {};
  firstMonthDates.forEach((date) => {
    firstMonthDatesObj[date.format('YYYY-MM-DD')] = true;
  });
  const gradientId = 'metricsAreaGradientId';

  const renderArea = () => {
    return <VictoryStack>
      {
        Object.values(metrics.sources)
            .sort(compareString('order'))
            .map((source, index) => {
              if (source.data.length === 0) {
                return null;
              }
              return <VictoryArea
                key={source.value + index}
                cornerRadius={{topLeft: 1, topRight: 1}}
                style={{
                  data: {fill: selectedChart[ChartType.AREA] ? `url(#${gradientId}${source.color})` : '#FFFFFFFF', stroke: source.color, strokeWidth: selectedChart[ChartType.LINE] ? 2 : 0},
                }}
                labelComponent={<VictoryTooltip
                  pointerWidth={20}
                  style={{fontSize: 15, fill: '#FFFFFF', fontFamily: 'sans-serif'}}
                  flyoutStyle={{strokeWidth: 1, fill: '#545454'}}
                />}
                alignment="start"
                data={source.data}
              />;
            })
      }
    </VictoryStack>;
  };

  const renderLegend = () => {
    if (!isShowLegend) {
      return null;
    }
    const data = Object.values(metrics.sources)
        .filter((source) => {
          return source.value !== 'none';
        })
        .sort(compareString('order'))
        .map((source, index) => {
          return {name: source.value, symbol: {fill: source.color}};
        });
    return <div><VictoryLegend
      orientation="horizontal"
      gutter={15}
      width={size.width}
      height={25}
      padding={{top: 0, bottom: 0}}
      style={{border: {stroke: 'white'}}}
      data={data}
    /></div>;
  };

  const renderScatter = () => {
    return <VictoryStack>
      {
        Object.values(metrics.sources).sort(compareString('order')).map((source, index) => {
          if (source.data.length === 0) {
            return null;
          }
          return <VictoryScatter
            key={(source.value + index)}
            labels={({datum}) => `${name}: ${datum.value} \n source: ${source.value} \n Date: ${momentZ(datum.x).format('YYYY-MM-DD')}`}
            labelComponent={<VictoryTooltip
              pointerWidth={20}
              style={{fontSize: 15, fill: '#FFFFFF', fontFamily: 'sans-serif'}}
              flyoutStyle={{strokeWidth: 1, fill: '#545454'}}
            />}
            style={{data: {fill: source.color}}}
            size={(d) => {
              return d.datum.y !== 0 ? 2 : 0;
            }}
            data={source.data}
          />;
        })
      }
    </VictoryStack>;
  };

  const renderBars = () => {
    return <VictoryStack>
      {
        Object.values(metrics.sources).sort(compareString('order')).map((source, index) => {
          if (source.data.length === 0) {
            return null;
          }
          return <VictoryBar
            key={(source.value + index)}
            labels={({datum}) => `${name}\n source: ${source.value} \n Date: ${momentZ(datum.x).format('YYYY-MM-DD')},\n y: ${datum.value}`}
            labelComponent={<VictoryTooltip
              pointerWidth={20}
              style={{fontSize: 15, fill: '#FFFFFF', fontFamily: 'sans-serif'}}
              flyoutStyle={{strokeWidth: 1, fill: '#545454'}}
            />}
            cornerRadius={{topLeft: 1, topRight: 1}}
            style={{data: {fill: source.color}}}
            barRatio={0.8}
            alignment="middle"
            data={source.data}
          />;
        })
      }
    </VictoryStack>;
  };

  const renderEvents = () => {
    if (isHideEvents) {
      return null;
    }
    return events.map((event) => {
      const meta = event.meta;
      const color = meta && meta.color ? meta.color : 'rgba(36,248,248,0.74)';
      return <VictoryBar
        barRatio={0.005}
        style={{data: {fill: color}}}
        labels={({datum}) => datum.y === metrics.maxY && !isHideEventsNames ? `${datum.name}` : ''}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => {
                setEventForEdit(event);
              },
            },
          },
        ]}
        labelComponent={
          isHideEventsNames ?
          <VictoryTooltip
            orientation={(d) => {
              return metrics.middleX < Number(momentZ(d.x).format('x')) ? 'left' : 'right';
            }}
            flyoutComponent={<CursorEvent/>}
          /> : <VictoryLabel angle={-90} textAnchor="end"/>
        }
        data={[
          {
            x: momentZ(event.date),
            y: metrics.minY,
            name: event.name,
            color,
          },
          {
            x: momentZ(event.date),
            y: metrics.maxY,
            name: event.name,
            color,
          },
        ]}
      />;
    });
  };
  const domainPadding = {x: (selectedChart[ChartType.BARS] ? Object.values(metrics.allDates).length < 10 ? 50 : 20 : 0)};
  return <div style={style} key={key}>
    {renderLegend()}
    {Object.values(metrics.sources).map((source) => {
      return getAreaMetricsGradient(source.color, 'metricsAreaGradientId' + source.color);
    })}
    <VictoryChart
      padding={{top: 15, bottom: isShowXAxis ? 60 : 20, left: 45, right: 0}}
      width={size.width}
      height={size.height}
      domainPadding={domainPadding}
    >
      <VictoryAxis dependentAxis
        label={name}
        style={{
          axis: {stroke: '#756f6a'},
          axisLabel: {fontSize: 14, padding: 33},
          ticks: {stroke: 'grey', size: 5},
          tickLabels: {fontSize: 7, padding: 2},
        }}
      />
      {isShowXAxis ? <VictoryAxis
        tickValues={axisXDates}
        tickFormat={(t) => `${firstMonthDatesObj[momentZ(t).format('YYYY-MM-DD')] ? momentZ(t).format('MMM DD') : momentZ(t).format('DD')}`}
        style={{
          axis: {stroke: '#756f6a'},
          axisLabel: {fontSize: 15, padding: 3},
          ticks: {stroke: 'grey', size: 7},
          tickLabels: {fontSize: 15, padding: 5, fontFamily: '\'Fira Sans\', sans-serif'},
        }}
      /> : null
      }
      {
          selectedChart[ChartType.BARS] ? renderBars() : [renderArea(), renderScatter()]
      }
      {
        renderEvents()
      }

    </VictoryChart>
    {
      !isShowXAxis ? <div className={'line-separator-charts'}/> : null
    }
  </div>;
};

export default (sizeMe({monitorHeight: true, monitorWidth: true}))(MetricsChart);
