import React from 'react';
import {Input} from '../general/Input';

export const IncentAppUnits = ({incentAppUnitsParams, changeIncentAppUnitsParams}) => {
  if (!incentAppUnitsParams) {
    return null;
  }
  const itemStyle = {display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, margin: '5px'};
  const renderAppUnits = (prefixName, title) => {
    const appUnits = incentAppUnitsParams[`${prefixName}AppUnits`];
    const price = incentAppUnitsParams[`${prefixName}Price`];

    return <div style={{display: 'flex'}}>
      <div style={itemStyle}>{title}</div>
      <Input
        value={appUnits === 0 ? '' : appUnits}
        debounce={500}
        style={itemStyle}
        type={'number'}
        handleChange={(value) => {
          if (!isNaN(value)) {
            changeIncentAppUnitsParams(Number(value), `${prefixName}AppUnits`);
          }
        }}
      />
      <Input
        value={price === 0 ? '' : price}
        debounce={500}
        style={itemStyle}
        type={'number'}
        handleChange={(value) => {
          if (!isNaN(value)) {
            changeIncentAppUnitsParams(Number(value), `${prefixName}Price`);
            if (prefixName === 'after') {
              changeIncentAppUnitsParams(Number(value), `beforePrice`);
            }
          }
        }}
      />
      <div style={itemStyle}>{appUnits * price}</div>
    </div>;
  };

  return <div className={'incent-app-units-container'} style={{marginTop: '10px', width: '100%'}}>
    <div style={{display: 'flex'}}>
      <div style={itemStyle}></div>
      <div style={itemStyle}>App units</div>
      <div style={itemStyle}>Price</div>
      <div style={itemStyle}>Total</div>
    </div>
    {
      [
        renderAppUnits('after', 'After'),
        renderAppUnits('before', 'Before'),
      ]
    }
    <div style={{display: 'flex'}}>
      <div style={itemStyle}></div>
      <div style={itemStyle}>{incentAppUnitsParams.afterAppUnits + incentAppUnitsParams.beforeAppUnits}</div>
      <div style={itemStyle}></div>
      <div style={itemStyle}>{(incentAppUnitsParams.afterAppUnits * incentAppUnitsParams.afterPrice) + (incentAppUnitsParams.beforeAppUnits * incentAppUnitsParams.beforePrice)}</div>
    </div>
  </div>;
};
