import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {selectStyles} from '../../utils/StyleUtils';
import {AppleSourceTypes, GoogleSourceTypes, Store} from '../../models/store';
import {compareString} from '../../utils/ArrayUtils';

const animatedComponents = makeAnimated();

const getSourceTypes = (store, isWithASA) => {
  const storeSourceTypes = Object.values(store === Store.APPLE.value ? AppleSourceTypes : GoogleSourceTypes);

  if (isWithASA && Store.APPLE.value === store) {
    const newStoreSourceTypes = [...storeSourceTypes, ...[{value: 'ASA', color: '#3463fc', order: 5}]];
    storeSourceTypes.push(newStoreSourceTypes);
  }

  return storeSourceTypes;
};

export const MetricsSourceTypesSelector = ({selectedSourceTypes, changeSourceTypes, isDisabled=false, isMulti=true, style, store = Store.APPLE.value, isWithASA=false}) => {
  const [storeSourceTypes, setStoreSourceTypes] = useState(getSourceTypes(store, isWithASA));
  useEffect(() => {
    setStoreSourceTypes(getSourceTypes(store, isWithASA));
  }, [store]);

  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        if (isMulti) {
          changeSourceTypes(!selectedOption ? [] : selectedOption.map((option) => option.value));
        } else {
          changeSourceTypes(selectedOption.value);
        }
      }}
      isMulti={isMulti}
      value={selectedSourceTypes.map((sourceType) => {
        return {value: sourceType, label: sourceType};
      })}
      isDisabled={isDisabled}
      components={animatedComponents}
      styles={selectStyles()}
      name="source types"
      options={storeSourceTypes.sort(compareString('order')).map((sourceType) => {
        return {value: sourceType.value, label: sourceType.value};
      })}
      placeholder={'Source'}
      className="basic-multi-select select-label"
      classNamePrefix="select"
    />
  </div>;
};


