import React from 'react';
import {typeModal} from '../../actions/general';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import {AppSelector} from '../general/AppSelector';

export const WhatsNewHeader = ({showModal, onelightApps, selectedAppId, selectAppId}) => {
  return <div className={'whats-new-head-panel'}>
    <AppSelector
      selectedApps={!selectedAppId ? [] : [selectedAppId]}
      style={{width: '390px'}}
      allApps={onelightApps}
      isMulti={false}
      changeApps={(appId) => {
        selectAppId(appId);
      }}
      placeholder={'Filter by All Apps'}
    />
    <div className={'add-whats-new-btn'} onClick={() => {
      showModal(typeModal.WHATS_NEW_ADD, true, {} );
    }}>
      <div>
        Add Version
      </div>
      <PlusIcon/>
    </div>
  </div>;
};
