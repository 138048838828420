import React from 'react';
import AsoReportPieCharts from './AsoReportPieCharts';
import {countryColor} from '../../utils/StyleUtils';

export const AsoReportCharts = ({asoReportDataStatsByRegion, searchParams, isShow}) => {
  if (!isShow) {
    return null;
  }

  const renderLegend = (regions) => {
    return <div className={'aso-report-country-legend-container'}>
      {regions.map((region) => {
        return <div key={region} className={'aso-report-country-legend'}>
          <div
            className={'aso-report-country-legend-circle'}
            style={{backgroundColor: countryColor[region], marginRight: '3px'}}
          />
          {region === 'GB' ? 'UK' : region}
        </div>;
      })}
    </div>;
  };

  const renderCountriesCharts = () => {
    if (searchParams.countries.length === 0) {
      return [];
    }

    return <div className={'aso-report-charts'}>
      {renderLegend(searchParams.countries)}
      {[
        <AsoReportPieCharts
          key={'appUnits'}
          asoReportDataStatsByRegion={asoReportDataStatsByRegion}
          regions={searchParams.countries}
          propName={'appUnits'}
          title={'Downloads'}
          countRegions={searchParams.countries.length + searchParams.regions.length }
          style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '20px'}}
        />,
        <AsoReportPieCharts
          key={'impressions'}
          asoReportDataStatsByRegion={asoReportDataStatsByRegion}
          regions={searchParams.countries}
          propName={'impressions'}
          title={'Impressions'}
          countRegions={searchParams.countries.length + searchParams.regions.length }
          style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '24px'}}
        />,
        <AsoReportPieCharts
          key={'pageViews'}
          asoReportDataStatsByRegion={asoReportDataStatsByRegion}
          regions={searchParams.countries}
          propName={'pageViews'}
          title={'Page views'}
          countRegions={searchParams.countries.length + searchParams.regions.length }
          style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '24px'}}
        />,
      ]}
    </div>;
  };

  const renderRegionsCharts = () => {
    if (searchParams.regions.length === 0) {
      return [];
    }
    return <div className={'aso-report-charts'} style={{marginTop: '11px'}}>
      {renderLegend(searchParams.regions)}
      {
        [
          <AsoReportPieCharts
            key={'appUnits_regions'}
            asoReportDataStatsByRegion={asoReportDataStatsByRegion}
            regions={searchParams.regions}
            propName={'appUnits'}
            title={'Downloads'}
            countRegions={searchParams.countries.length + searchParams.regions.length }
            style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '24px'}}
          />,
          <AsoReportPieCharts
            key={'impressions_regions'}
            asoReportDataStatsByRegion={asoReportDataStatsByRegion}
            regions={searchParams.regions}
            propName={'impressions'}
            title={'Impressions'}
            countRegions={searchParams.countries.length + searchParams.regions.length }
            style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '24px'}}
          />,
          <AsoReportPieCharts
            key={'pageViews_regions'}
            asoReportDataStatsByRegion={asoReportDataStatsByRegion}
            regions={searchParams.regions}
            propName={'pageViews'}
            title={'Page views'}
            countRegions={searchParams.countries.length + searchParams.regions.length }
            style={{width: '100%', display: 'flex', flexDirection: 'column', paddingTop: '24px'}}
          />,
        ]}
    </div>;
  };
  return <div className={'aso-report-charts-container'}>
    {
      renderCountriesCharts()
    } {
      renderRegionsCharts()
    }
  </div>;
};
