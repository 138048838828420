import React from 'react';
import DropdownWrapper from './DropdownWrapper';
import {OnelightDatePicker} from './OnelightDatePicker';
import {ReactComponent as CalendarIcon} from '../../resources/imgs/calendar-icon.svg';
import momentZ from 'moment-timezone';

export const SingleDatePicker = ({date, onChange, style, styleInput, dropdownStyle}) => {
  return <DropdownWrapper style={dropdownStyle} isRerender={true}>
    <div className={'calendar-single-input'} style={styleInput}>
      <div style={{marginLeft: '6px', marginRight: '13px'}}>
        {momentZ(date).format('DD/MM/yyyy')}
      </div>
      <CalendarIcon/>
    </div>
    <OnelightDatePicker
      style={style}
      date={date}
      inline={true}
      shouldCloseOnSelect={false}
      onChange={(date) => onChange(date)}
    />
  </DropdownWrapper>;
};
