const SEPARATOR = '_';

export const wrapId = (sourceId, externalId) => {
  return sourceId + SEPARATOR + externalId;
};

export const unwrapId = (id) => {
  const parts = String(id).split(SEPARATOR);
  if (!parts[0]) {
    throw new Error('Method unwrapId: Id is uncorrected: ' + id);
  }

  const sourceId = Number(parts[0]);
  return isNaN(sourceId) ? parts[0] : sourceId;
};

export const unwrapSourceId = (id) => {
  const parts = String(id).split(SEPARATOR);
  if (!parts[1]) {
    throw new Error('Method unwrapId: Id is uncorrected: ' + id);
  }

  return parts[1];
};

