import React from 'react';
import {ReactComponent as ArrowIcon} from '../../resources/imgs/arrows-hor.svg';

export const MoveButton = ({text, style, onClick}) => (
  <div className={'onelight-move-btn'} style={style} onClick={onClick}>
    <ArrowIcon/>
    <div style={{paddingLeft: '3px'}}>
      {text}
    </div>
  </div>
);
