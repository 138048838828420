import React, {useCallback, useEffect, useRef, useState} from 'react';
import Portal from './Portal';
import debounce from 'throttle-debounce/debounce';
import deepEqual from 'deep-equal';

function equalDropdown(prev, next) {
  return !next.isRerender && deepEqual(prev.coords, next.coords);
}

const DropdownWrapper = ({children, style = {}, isRevert = false, isRerender = false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [coords, setCoords] = useState({});

  const btnRef = React.createRef();

  const updateTooltipCoords = (button) => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2,
      top: rect.y + window.scrollY,
    });
  };

  if (!children) {
    return null;
  }
  if (React.Children.count(children) === 1) {
    return children;
  }

  const arrayChildren = React.Children.toArray(children);

  if (!isOpen) {
    return <div onClick={(e) => {
      e.stopPropagation();
      updateTooltipCoords(e.target);
      setIsOpen(!isOpen);
    }}>
      <div>
        {arrayChildren[0]}
      </div>
    </div>;
  }

  return <div>
    <div onClick={(e) => {
      e.stopPropagation();
      updateTooltipCoords(e.target);
      setIsOpen(!isOpen);
    }
    }
    ref={btnRef}
    >
      {arrayChildren[0]}
    </div>

    <Dropdown
      style={style}
      coords={coords}
      updateTooltipCoords={updateTooltipCoords}
      onClose={() => {
        setIsOpen(false);
      }}
      isRevert={isRevert}
      isRerender={isRerender}
    >
      {arrayChildren[1]}
    </Dropdown>
  </div>;
};

// eslint-disable-next-line react/display-name
const Dropdown = React.memo(({onClose, style, children, coords, updateTooltipCoords, isRevert, isRerender}) => {
  const ref = useRef(null);
  const newStyle = {...{...(isRevert ? styles.popoverRevert : styles.popover), ...coords}, ...style};
  const updateCoords = debounce(updateTooltipCoords, 100);
  const escapeListener = useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);

  const clickListener = useCallback(
      (e) => {
        if (ref.current && !ref.current.contains(e.target) && !e.target.id.includes('select')) {
          onClose();
        }
      },
      [ref.current],
  );

  useEffect(() => {
    window.addEventListener('resize', updateCoords);
    return () => window.removeEventListener('resize', updateCoords);
  }, []);

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  return (
    <Portal>
      <div
        ref={ref}
        style={newStyle}
        onClick={(e)=>e.stopPropagation()}
      >
        {React.cloneElement(children, {onClose: onClose})}
      </div>
    </Portal>
  );
}, equalDropdown);


const styles = {
  popover: {
    position: 'absolute',
    zIndex: 2000,
    transform: 'translate(0, 25px)',
  },
  popoverRevert: {
    position: 'absolute',
    zIndex: 2000,
    transform: 'translate(0, -100%)',
  },
};

export default DropdownWrapper;

/*
import React, {useCallback, useEffect, useRef, useState} from 'react';

export const DropdownWrapper = ({children, style = {}}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!children) {
    return null;
  }
  if (React.Children.count(children) === 1) {
    return children;
  }

  const arrayChildren = React.Children.toArray(children);

  if (!isOpen) {
    return <div onClick={(e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }}
    className={'onelight-dropdown-wrapper'}>
      <div style={{width: '100%'}}>
        {arrayChildren[0]}
      </div>
    </div>;
  }

  return <div className={'onelight-dropdown-wrapper'}>
    <div onClick={(e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }}
    style={{width: '100%'}}
    >
      {arrayChildren[0]}
    </div>

    <Dropdown
      style={style}
      onClose={() => {
        setIsOpen(false);
      }}>
      {arrayChildren[1]}
    </Dropdown>
  </div>;
};

export const Dropdown = ({onClose, children, style}) => {
  const ref = useRef(null);
  const escapeListener = useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);
  const clickListener = useCallback(
      (e) => {
        if (ref.current && !ref.current.contains(e.target) && !e.target.id.includes('select')) {
          onClose();
        }
      },
      [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  return (
    <div className={'onelight-dropdown'}
      ref={ref}
      style={style}
      onClick={(e)=>e.stopPropagation()}
    >
      {React.cloneElement(children, {onClose: onClose})}
    </div>
  );
};
*/
