import React, {useState} from 'react';
import {Rank} from './Rank';
import {Input} from '../general/Input';
import {KeywordRankHistory} from './KeywordsRankHistory';
import KeywordRankSettings from './KeywordRankSettings';
import {Popularity} from './Popularity';
import {KeywordStatus} from './KeywordStatus';
import {KeywordsReportType} from '../../models/onelight';

export const KeywordRank = ({keywordRank, isNotes, isHistory, changePropKeyword, selectedReportType,
  onelightApps, setKeywordForEdit, setKeywordToDelete, keywordsIdsForDelete}) => {
  const [isHover, setIsHover] = useState(false);
  const [onelightApp, setOnelightApp] = useState(onelightApps.find((onelightApp) => onelightApp.id === keywordRank.appId));

  return <div className={'keyword-rank-container'}>
    <div className={'keyword-rank-value'}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {keywordRank.keyword}
      {!isHover && keywordsIdsForDelete.indexOf(keywordRank.id) === -1 ? null : <KeywordRankSettings
        keywordRank={keywordRank}
        onelightApp={onelightApp}
        setKeywordForEdit={setKeywordForEdit}
        setKeywordToDelete={setKeywordToDelete}
      />}
    </div>

    <div className={'keyword-rank-position'}>
      {
          !keywordRank.asoKeywordRanks && keywordRank.asoKeywordRanks.length === 0 ? null :
              <Rank
                currentRank={keywordRank.asoKeywordRanks[0]}
                prevRank={keywordRank.asoKeywordRanks[1]}
              />
      }
    </div>

    <div className={'keyword-rank-position'}>
      {
        !keywordRank.asoKeywordRanks && keywordRank.asoKeywordRanks.length === 0 ? null :
            <Popularity
              currentRank={keywordRank.asoKeywordRanks[0]}
              prevRank={keywordRank.asoKeywordRanks[1]}
              isNegative={true}
            />
      }
    </div>

    {selectedReportType === KeywordsReportType.INCENT_MONITOR ? <div className={'keyword-rank-status'}>
      <KeywordStatus
        status={keywordRank.status}
        onChange={(status) => {
          changePropKeyword('status', status, keywordRank.id);
        }}
      />
    </div>: null}

    {
        isNotes || selectedReportType === KeywordsReportType.INCENT_MONITOR ? <div className={'keyword-rank-notes'} style={{flex: !isNotes ? 1 : undefined}}>

          <Input
            style={{width: '90%', border: 'none'}}
            handleChange={(value) => {
              changePropKeyword('notes', value, keywordRank.id);
            }}
            value={keywordRank.notes}
          />
        </div> : null
    }
    {
      isHistory ? <KeywordRankHistory
        keywordRank={keywordRank}
        selectedReportType={selectedReportType}
      /> : null
    }
  </div>;
};
