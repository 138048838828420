import React, {useState} from 'react';
import {Input} from '../general/Input';
import {ColorPicker} from '../general/ColorPicker';
import {EditImg} from '../general/EditImg';
import {parseJsonFile} from '../../utils/FilesUtils';
import {CrossPromoEditorLocales} from './CrossPromoEditorLocales';
import {saveAs} from 'file-saver';
import {Badge} from '../general/Badge';
import {saveImageMarketingConfigToS3 } from '../../actions/general';

export const CrossPromoEditor = ({crossPromo, updateCrossPromo, updateCrossPromoBatch, importCrossPromo, backgroundImageNameSelected, selectBackGroundImageName}) => {
  const [activeImgSize, setActiveImgSize] = useState('x1');
  const [isSavingImage, setIsSavingImage] = useState({});

  if (Object.keys(crossPromo).length === 0) {
    return null;
  }
  const nameImageA = `background_image_a_${activeImgSize}`;
  const nameImageB = `background_image_b_${activeImgSize}`;

  const updateIsSavingImage = (nameImage, isSaving) => {
    setIsSavingImage({...{[nameImage]: isSaving}, ...isSavingImage})
  }

  const renderSizesImagesSwitcher = () => {
    return <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '20px'}}>
      {
        ['x1', 'x2', 'x3'].map(size => {
          return <Badge
            value={size}
            key={size}
            onChange={() => {
              selectBackGroundImageName(backgroundImageNameSelected.replace(activeImgSize, size));
              setActiveImgSize(size);
            }}
            debounce={10}
            style={{marginBottom: '10px'}}
            isActive={activeImgSize === size}
          />
        })
      }
    </div>
  }

  return <div className={'cross-promo-editor-container'}>
    <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
      <div className={'onelight-second-text'}>
        Main title
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
        <Input
          handleChange={(value) => {
            updateCrossPromo('main_title', {...crossPromo.main_title, ...{en: value}});
          }}
          value={crossPromo.main_title.en}
        />
        <ColorPicker
          onChange={(color) => {
            updateCrossPromo('main_title_color', color);
          }}
          style={{marginLeft: '10px'}}
          color={crossPromo.main_title_color}
        />
      </div>
    </div>

    <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
      <div className={'onelight-second-text'}>
        Main button
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
        <Input
          handleChange={(value) => {
            updateCrossPromo('main_button_title', {...crossPromo.main_button_title, ...{en: value}});
          }}
          value={crossPromo.main_button_title.en}
        />
        <div style={{display: 'flex', marginLeft: '15px', alignItems: 'center'}}>
          <div className={'onelight-second-text'}>
            Title color
          </div>
          <ColorPicker
            style={{marginLeft: '5px'}}
            onChange={(color) => {
              updateCrossPromo('main_button_title_color', color);
            }}
            color={crossPromo.main_button_title_color}
          />
        </div>

        <div style={{display: 'flex', marginLeft: '15px', alignItems: 'center'}}>
          <div className={'onelight-second-text'}>
            Background color
          </div>
          <ColorPicker
            style={{marginLeft: '5px'}}
            onChange={(color) => {
              updateCrossPromo('main_button_background_color', color);
            }}
            color={crossPromo.main_button_background_color}
          />
        </div>
      </div>
    </div>

    <div style={{display: 'flex', marginTop: '13px'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Close button background color
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
          <ColorPicker
            onChange={(color) => {
              updateCrossPromo('close_button_background_color', color);
            }}
            style={{marginLeft: '10px'}}
            color={crossPromo.close_button_background_color}
          />
        </div>
      </div>

      <div style={{display: 'flex', marginLeft: '20px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Close button color
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
          <ColorPicker
            onChange={(color) => {
              updateCrossPromo('close_button_color', color);
            }}
            style={{marginLeft: '10px'}}
            color={crossPromo.close_button_color}
          />
        </div>
      </div>
    </div>

    <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
      <div className={'onelight-second-text'}>
        Screen background color
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
        <ColorPicker
          onChange={(color) => {
            updateCrossPromo('background_color', color);
          }}
          style={{marginLeft: '10px'}}
          color={crossPromo.background_color}
        />
      </div>
    </div>

    <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
      <div className={'onelight-second-text'}>
        Main button url
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
        <Input
          handleChange={(value) => {
            updateCrossPromo('main_button_url', value);
          }}
          value={crossPromo.main_button_url}
        />
      </div>
    </div>

    <div style={{display: 'flex', marginTop: '13px'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'onelight-second-text'} style={{color: (backgroundImageNameSelected === nameImageA) ? '#00AEAE' : '#bcc1c1'}}
             onClick={() => {
               selectBackGroundImageName(nameImageA)
             }}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>{'Background image > 9:17'}</div>
            <div>{"(iPhone 6, 7, 8)"}</div>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
          <EditImg imgUrl={crossPromo[nameImageA]}
                   style={{marginTop: '5px', height: '124px', width: '164px'}}
                   heightImg={'124px'}
                   widthImg={'164px'}
                   text={'png only'}
                   onDrop={(file) => {
                     updateIsSavingImage(nameImageA, true)
                     saveImageMarketingConfigToS3(crossPromo.id, file, `temp-${nameImageA}`, (url) => {
                       updateIsSavingImage(nameImageA, false)
                       updateCrossPromo(nameImageA, url);
                     });
                   }}
                   isLoading={isSavingImage[nameImageA]}
                   onClickImage={() => {
                     selectBackGroundImageName(nameImageA)
                   }}
                   clear={() => {
                     updateCrossPromo(nameImageA, null);
                   }}
          />
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
        <div className={'onelight-second-text'} style={{color: (backgroundImageNameSelected === nameImageB) ? '#00AEAE' : '#bcc1c1'}}
             onClick={() => {
              selectBackGroundImageName(nameImageB)
             }}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
              {"Background image <= 9:17"}
            </div>
            <div>
              {"(iPhone X - 13)"}
            </div>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
          <EditImg imgUrl={crossPromo[nameImageB]}
                   style={{marginTop: '5px', height: '124px', width: '164px'}}
                   heightImg={'124px'}
                   widthImg={'164px'}
                   text={'png only'}
                   onDrop={(file) => {
                     updateIsSavingImage(nameImageB, true);
                     saveImageMarketingConfigToS3(crossPromo.id, file, `temp-${nameImageB}`, (url) => {
                       updateIsSavingImage(nameImageB, false);
                       updateCrossPromo(nameImageB, url);
                     });
                   }}
                   isLoading={isSavingImage[nameImageB]}
                   onClickImage={() => {
                     selectBackGroundImageName(nameImageB);
                   }}
                   clear={() => {
                     updateCrossPromo(nameImageB, null);
                   }}
          />
        </div>
      </div>
      {renderSizesImagesSwitcher()}
    </div>


    <CrossPromoEditorLocales
      style={{marginTop: '15px'}}
      crossPromo={crossPromo}
      updateCrossPromoBatch={updateCrossPromoBatch}
      updateCrossPromo={updateCrossPromo}
    />

    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
      <div className={'add-btn'} style={{width: '38%'}} onClick={() => {
        const file = new Blob([JSON.stringify(crossPromo)], {type: 'text/plain;charset=utf-8'});
        saveAs(file, `${crossPromo.app_id}_${crossPromo.id}.json`);
      }}>
        Export
      </div>
      <div className={'add-btn'} style={{width: '38%', position: 'relative'}}>
        Import
        <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
          onChange={(event) => {
            parseJsonFile(event.target.files[0])
                .then((parsedCrossPromo) => {
                  importCrossPromo(parsedCrossPromo);
                }).catch((err) => {
                  console.log(err);
                });
          }}/>
      </div>
    </div>
  </div>;
};
