import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {
  changePropKeyword, clearKeywordForDelete, deleteSelectedKeywords,
  getKeywordsRanks, getKeywordsRanksRemote,
  saveKeywords,
  selectKeywordApp,
  setIsDetailsKeywords,
  setIsHideKeywords,
  setIsShowChangesOnly,
  selectReport,
  setKeywordForEdit,
  setKeywordToDelete,
  setOrderKeywords,
  changeCompareParams,
} from '../../actions/keywords';
import {KeywordsRanksTables} from './KeywordsRanksTables';
import {showModal} from '../../actions/general';
import {KeywordsRanksHeader} from './KeywordsRanksHeader';
import momentZ from 'moment-timezone';

const KeywordsRanks = ({keywordsRanksByCountry, isLoadingAddKeywordCountry, selectedApp, isDetailsKeywords, changePropKeyword,
  onelightApps, isLoadingKeywordsRanks, getKeywordsRanks, showModal, selectKeywordApp, setIsDetailsKeywords, keywordsForSave,
  saveKeywords, isSaveKeywords, selectedReportType, selectReport, setKeywordForEdit, setKeywordToDelete, isHideKeywords, emptyDates,
  setIsHideKeywords, setOrderKeywords, orderKeywordsCountries, lastUpdateDate, isLoadingKeywordsRanksRemote, getKeywordsRanksRemote,
  keywordsIdsForDelete, deleteSelectedKeywords, clearKeywordForDelete, changeCompareParams, compareParams, setIsShowChangesOnly,
  isShowChangesOnly, loadedKeywordsParams,
}) => {
  useEffect(() => {
    if (selectedApp) {
      getKeywordsRanks(selectedApp.id, momentZ().utc().subtract(1, 'days').format('yyyy-MM-DD'));
      getKeywordsRanksRemote(selectedApp.vendorId);
    }
  }, [selectedApp]);

  useEffect(() => {
    if (isSaveKeywords) {
      const keywordIds = [];
      Object.keys(keywordsForSave).forEach((id) => {
        if (keywordsForSave[id]) {
          keywordIds.push(id);
        }
      });
      saveKeywords(keywordIds);
    }
  }, [isSaveKeywords]);

  const returnKeywordsRanksHeader = () => {
    return <KeywordsRanksHeader
      selectKeywordApp={selectKeywordApp}
      onelightApps={onelightApps}
      selectedApp={selectedApp}
      showModal={showModal}
      isDetailsKeywords={isDetailsKeywords}
      setIsDetailsKeywords={setIsDetailsKeywords}
      isHideKeywords={isHideKeywords}
      setIsHideKeywords={setIsHideKeywords}
      selectedReportType={selectedReportType}
      selectReport={selectReport}
      lastUpdateDate={lastUpdateDate}
      keywordsIdsForDelete={keywordsIdsForDelete}
      clearKeywordForDelete={clearKeywordForDelete}
      deleteSelectedKeywords={deleteSelectedKeywords}
      changeCompareParams={changeCompareParams}
      compareParams={compareParams}
      setIsShowChangesOnly={setIsShowChangesOnly}
      isShowChangesOnly={isShowChangesOnly}
      emptyDates={emptyDates}
    />;
  };

  if (isLoadingKeywordsRanks || isLoadingKeywordsRanksRemote) {
    return (
      <div className={'metrics-container'}>
        {returnKeywordsRanksHeader()}

        <div className='spinner-container' style={{marginTop: '250px'}}>
          <MDSpinner size={100} singleColor={'#00DADA'}/>
        </div>
      </div>
    );
  }

  return (
    <div className={'metrics-container'}>
      {returnKeywordsRanksHeader()}
      <KeywordsRanksTables
        showModal={showModal}
        isDetailsKeywords={isDetailsKeywords}
        isLoadingAddKeywordCountry={isLoadingAddKeywordCountry}
        keywordsRanksByCountry={keywordsRanksByCountry}
        changePropKeyword={changePropKeyword}
        onelightApps={onelightApps}
        setKeywordForEdit={setKeywordForEdit}
        setKeywordToDelete={setKeywordToDelete}
        isHideKeywords={isHideKeywords}
        setOrderKeywords={setOrderKeywords}
        orderKeywordsCountries={orderKeywordsCountries}
        selectedReportType={selectedReportType}
        keywordsIdsForDelete={keywordsIdsForDelete}
        compareParams={compareParams}
        loadedKeywordsParams={loadedKeywordsParams}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  keywordsRanksByCountry: state.keywordsRanks.keywordsRanksByCountry,
  onelightApps: state.appState.onelightApps,
  isLoadingKeywordsRanks: state.keywordsRanks.isLoadingKeywordsRanks,
  isLoadingAddKeywordCountry: state.keywordsRanks.isLoadingAddKeywordCountry,
  selectedApp: state.keywordsRanks.selectedApp,
  isDetailsKeywords: state.keywordsRanks.isDetailsKeywords,
  keywordsForSave: state.keywordsRanks.keywordsForSave,
  isSaveKeywords: state.keywordsRanks.isSaveKeywords,
  selectedReportType: state.keywordsRanks.selectedReportType,
  isHideKeywords: state.keywordsRanks.isHideKeywords,
  orderKeywordsCountries: state.keywordsRanks.orderKeywordsCountries,
  lastUpdateDate: state.keywordsRanks.lastUpdateDate,
  isLoadingKeywordsRanksRemote: state.keywordsRanks.isLoadingKeywordsRanksRemote,
  keywordsIdsForDelete: state.keywordsRanks.keywordsIdsForDelete,
  compareParams: state.keywordsRanks.compareParams,
  isShowChangesOnly: state.keywordsRanks.isShowChangesOnly,
  emptyDates: state.keywordsRanks.emptyDates,
  loadedKeywordsParams: state.keywordsRanks.loadedKeywordsParams,
});

const mapDispatchToProps = (dispatch) => ({
  getKeywordsRanks: bindActionCreators(getKeywordsRanks, dispatch),
  getKeywordsRanksRemote: bindActionCreators(getKeywordsRanksRemote, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  selectKeywordApp: bindActionCreators(selectKeywordApp, dispatch),
  setIsDetailsKeywords: bindActionCreators(setIsDetailsKeywords, dispatch),
  changePropKeyword: bindActionCreators(changePropKeyword, dispatch),
  saveKeywords: bindActionCreators(saveKeywords, dispatch),
  selectReport: bindActionCreators(selectReport, dispatch),
  setKeywordForEdit: bindActionCreators(setKeywordForEdit, dispatch),
  setKeywordToDelete: bindActionCreators(setKeywordToDelete, dispatch),
  setIsHideKeywords: bindActionCreators(setIsHideKeywords, dispatch),
  setOrderKeywords: bindActionCreators(setOrderKeywords, dispatch),
  deleteSelectedKeywords: bindActionCreators(deleteSelectedKeywords, dispatch),
  clearKeywordForDelete: bindActionCreators(clearKeywordForDelete, dispatch),
  changeCompareParams: bindActionCreators(changeCompareParams, dispatch),
  setIsShowChangesOnly: bindActionCreators(setIsShowChangesOnly, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsRanks);

export const generalLocales = ['US', 'MX', 'GB', 'CA', 'AU', 'DE', 'RU', 'UA', 'FR', 'IT', 'ES', 'PT', 'BR'];
