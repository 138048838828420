import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'
import TextareaAutosize from 'react-textarea-autosize';

export class TextArea extends Component {

    constructor(props) {
        super(props)
        this.state = {inputValue: this.props.value}
    }

    handleChange = debounce(this.props.debounceTime ?
      this.props.debounceTime : 250, () => this.props.handleChange(this.state.inputValue)
    )

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        //console.log(nextProps.value)
        if(nextProps.value !== this.props.value){
            this.setState({inputValue: nextProps.value})
        }
        return nextProps.value !== this.props.value || this.state.inputValue !== nextState.inputValue
    }

    handleChangeInput = (value) => {
        this.setState({inputValue: value}, () => this.handleChange())
    }

    render () {
        const {minRows = 1} = this.props
        return (
            <TextareaAutosize
                   value={this.state.inputValue}
                   onScroll={this.props.onScroll}
                   onChange={(event) => (
                       this.handleChangeInput(event.target.value)
                   )}
                   minRows={minRows}
                   className="onelight-input"
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   style={this.props.style}
            />
        )
    }
}