import React from 'react';
import {KeywordsRanksCountryTable} from './KeywordsRanksCountryTable';
import {generalLocales} from './index';
import {KeywordsReportType} from '../../models/onelight';
import {KeywordsCompareCountryTable} from './KeywordsCompareCountryTable';
import {CountriesCompareTable} from './CountriesCompareTable';

export const KeywordsRanksTables = ({keywordsRanksByCountry, showModal, isLoadingAddKeywordCountry, orderKeywordsCountries,
  isDetailsKeywords, changePropKeyword, onelightApps, setKeywordForEdit, setKeywordToDelete, isHideKeywords, setOrderKeywords,
  selectedReportType, keywordsIdsForDelete, compareParams, loadedKeywordsParams,
}) => {
  const countries = Object.keys(keywordsRanksByCountry);

  return <div className={'keywords-tables-container'}>
    {countries
        .sort((countryA, countryB) => {
          if (countryA === 'all') {
            return -1;
          }
          if (countryB === 'all') {
            return 1;
          }
          const indexA = generalLocales.indexOf(countryA.toUpperCase());
          const indexB = generalLocales.indexOf(countryB.toUpperCase());
          if (indexA === -1 && indexB === -1) {
            return countryA > countryB;
          }
          if (indexA === -1) {
            return 1;
          }
          if (indexB === -1) {
            return -1;
          }
          return indexA - indexB;
        })
        .map((country) => {
          switch (selectedReportType) {
            case KeywordsReportType.INCENT_MONITOR: {
              if (country === 'all') {
                break;
              }
              return <KeywordsRanksCountryTable
                key={country + selectedReportType} country={country}
                showModal={showModal}
                isDetailsKeywords={isDetailsKeywords}
                isLoading={isLoadingAddKeywordCountry[country]}
                keywordsRanks={keywordsRanksByCountry[country]}
                changePropKeyword={changePropKeyword}
                onelightApps={onelightApps}
                setKeywordForEdit={setKeywordForEdit}
                setKeywordToDelete={setKeywordToDelete}
                isHideKeywords={isHideKeywords}
                orderKeywords={orderKeywordsCountries[country] ? orderKeywordsCountries[country] : {}}
                setOrderKeywords={setOrderKeywords}
                keywordsIdsForDelete={keywordsIdsForDelete}
                selectedReportType={selectedReportType}
                loadedKeywordsParams={loadedKeywordsParams}
              />;
            }

            case KeywordsReportType.POSITION_REPORT: {
              if (country === 'all') {
                break;
              }
              return <KeywordsRanksCountryTable
                key={country + selectedReportType}
                country={country}
                showModal={showModal}
                isDetailsKeywords={isDetailsKeywords}
                isLoading={isLoadingAddKeywordCountry[country]}
                keywordsRanks={keywordsRanksByCountry[country]}
                changePropKeyword={changePropKeyword}
                onelightApps={onelightApps}
                setKeywordForEdit={setKeywordForEdit}
                setKeywordToDelete={setKeywordToDelete}
                isHideKeywords={isHideKeywords}
                orderKeywords={orderKeywordsCountries[country] ? orderKeywordsCountries[country] : {}}
                setOrderKeywords={setOrderKeywords}
                keywordsIdsForDelete={keywordsIdsForDelete}
                selectedReportType={selectedReportType}
                loadedKeywordsParams={loadedKeywordsParams}
              />;
            }

            case KeywordsReportType.COMPARATIVE_REPORT: {
              if (country === 'all') {
                return <CountriesCompareTable
                  isHideKeywords={isHideKeywords}
                  allCountries={keywordsRanksByCountry.all}
                />;
              }

              return <KeywordsCompareCountryTable
                key={country + selectedReportType} country={country}
                showModal={showModal}
                isDetailsKeywords={isDetailsKeywords}
                isLoading={isLoadingAddKeywordCountry[country]}
                keywordsRanks={keywordsRanksByCountry[country]}
                changePropKeyword={changePropKeyword}
                onelightApps={onelightApps}
                setKeywordForEdit={setKeywordForEdit}
                setKeywordToDelete={setKeywordToDelete}
                isHideKeywords={isHideKeywords}
                orderKeywords={orderKeywordsCountries[country] ? orderKeywordsCountries[country] : {}}
                setOrderKeywords={setOrderKeywords}
                keywordsIdsForDelete={keywordsIdsForDelete}
                selectedReportType={selectedReportType}
                firstDate={compareParams.firstDate}
                secondDate={compareParams.secondDate}
                avgDiff={keywordsRanksByCountry.all[country]}
                loadedKeywordsParams={loadedKeywordsParams}
              />;
            }
          }
        })}
  </div>;
};
