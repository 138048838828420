import React from 'react';
import {DiffCompare} from './DiffCompare';

export const CountryCompare = ({country, avgSapPercent, avgRankPercent}) => {
  return <div className={'keyword-rank-container'}>
    <div className={'keyword-rank-value'}>
      {country.toUpperCase()}
    </div>

    <div className={'keyword-rank-position'}>
      <DiffCompare
        value={avgRankPercent}
        postfix={' %'}
        isNegative={true}
      />
    </div>

    <div className={'keyword-rank-position'}>
      <DiffCompare
        value={avgSapPercent}
        postfix={' %'}
        isNegative={false}
        isRevert={true}
      />
    </div>
  </div>;
};
