import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';
import queue from 'async/queue';
import {SET_ERROR} from './general';

export const SAVE_ASO_EXPERIMENTS = 'SAVE_ASO_EXPERIMENTS';
export const SAVE_ASO_EXPERIMENT = 'SAVE_ASO_EXPERIMENT';
export const SET_IS_LOADING_ASO_EXPERIMENTS = 'SET_IS_LOADING_ASO_EXPERIMENTS';
export const SET_IS_LOADING_ASO_EXPERIMENT = 'SET_IS_LOADING_ASO_EXPERIMENT';
export const UPDATE_ASO_EXPERIMENT = 'UPDATE_ASO_EXPERIMENT';
export const ADD_ASO_EXPERIMENT = 'ADD_ASO_EXPERIMENT';
export const DELETE_ASO_EXPERIMENT = 'DELETE_ASO_EXPERIMENT';
export const SET_DELETE_ASO_EXPERIMENT = 'SET_DELETE_ASO_EXPERIMENT';
export const SET_EDIT_ASO_EXPERIMENT = 'SET_EDIT_ASO_EXPERIMENT';
export const SET_COPY_ASO_EXPERIMENT = 'SET_COPY_ASO_EXPERIMENT';

export const CHANGE_PHRASES_POSITIONS = 'CHANGE_PHRASES_POSITIONS';
export const CHANGE_ACTIVE_SUB_FORMS = 'CHANGE_ACTIVE_SUB_FORMS';
export const CHANGE_PHRASES_RANKS = 'CHANGE_PHRASES_RANKS';
export const CHANGE_PHRASES = 'CHANGE_PHRASE';
export const CHECK_KEYWORD = 'CHECK_KEYWORD';
export const CHANGE_FORM_INPUT = 'CHANGE_FORM_INPUT';
export const SET_IS_SAVE_ASO_EXPERIMENT = 'SET_IS_SAVE_ASO_EXPERIMENT';
export const ORDER_ASO_KEYWORDS = 'ORDER_ASO_KEYWORDS';
export const ORDER_ASO_PHRASES = 'ORDER_ASO_PHRASES';
export const SET_IS_CHANGED = 'SET_IS_CHANGED';
export const MOVE_MAIN_TO_OLD = 'MOVE_MAIN_TO_OLD';
export const MOVE_OLD_TO_MAIN = 'MOVE_OLD_TO_MAIN';
export const DELETE_PHRASE = 'DELETE_PHRASE';

export const IS_LOADING_ASO_REPORT_DATA = 'IS_LOADING_ASO_REPORT_DATA';
export const SAVE_ASO_REPORT_DATA = 'SAVE_ASO_REPORT_DATA';
export const CHANGE_SEARCH_PARAMS_ASO_REPORT = 'CHANGE_SEARCH_PARAMS_ASO_REPORT';
export const CHANGE_INCENT_APP_UNITS_PARAM_ASO_REPORT = 'CHANGE_INCENT_APP_UNITS_PARAM_ASO_REPORT';
export const CONVERT_ASO_REPORT_TO_XML = 'CONVERT_ASO_REPORT_TO_XML';
export const SET_IS_HIDE_TABLE_DAYS_ASO_REPORT = 'SET_IS_HIDE_TABLE_DAYS_ASO_REPORT';

export const getAsoExperiments = () => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_ASO_EXPERIMENTS,
    isLoading: true,
  });

  const stateId = getState().aso.stateId;
  const isIndividual = getState().aso.isIndividualAsoExperiment;
  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_ASO_EXPERIMENTS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      asoExperiments: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/aso${isIndividual ? '/individual' : ''}/experiments?withData=false`, onload);

  xhr.send();
};

export const getAsoExperiment = (uuid) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_ASO_EXPERIMENT,
    isLoading: true,
  });

  const stateId = getState().aso.stateId;
  const isIndividual = getState().aso.isIndividualAsoExperiment;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_ASO_EXPERIMENT,
      requestTimeEpoch: timeStampRequest,
      stateId,
      asoExperiment: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/aso${isIndividual ? '/individual' : ''}/experiment/${uuid}`, onload);

  xhr.send();
};

export const deleteAsoExperiment = (uuid) => (dispatch, getState) => {
  dispatch({
    type: DELETE_ASO_EXPERIMENT,
    uuid,
  });
  const isIndividual = getState().aso.isIndividualAsoExperiment;

  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'DELETE', `/aso${isIndividual ? '/individual' : ''}/experiment/${uuid}`, onload);

  xhr.send();
};

export const saveAsoExperiment = (asoExperiment, isUpdate = false) => (dispatch, getState) => {
  dispatch({
    type: isUpdate ? UPDATE_ASO_EXPERIMENT : ADD_ASO_EXPERIMENT,
    asoExperiment,
  });
  const isIndividual = getState().aso.isIndividualAsoExperiment;

  const onload = (responseText) => {
    const responseAsoExperiment = JSON.parse(responseText);
    asoExperiment.updatedAt = responseAsoExperiment.updatedAt;
    dispatch({
      type: isUpdate ? UPDATE_ASO_EXPERIMENT : ADD_ASO_EXPERIMENT,
      asoExperiment,
    });
    dispatch({
      type: SET_IS_SAVE_ASO_EXPERIMENT,
      isSave: false,
    });
    dispatch({
      type: SET_IS_CHANGED,
      isChanged: false,
    });
  };
  const onerror = (responseText) => {
    dispatch({
      type: SET_IS_SAVE_ASO_EXPERIMENT,
      isSave: false,
    });
    dispatch({
      type: SET_IS_CHANGED,
      isChanged: false,
    });

    const error = JSON.parse(responseText);
    console.log(error);
    dispatch({
      type: SET_ERROR,
      error: error.error.description,
    });
  }
  const xhr = createRequest(dispatch, 'POST', `/aso${isIndividual ? '/individual' : ''}/experiment`, onload, true, onerror);

  xhr.send(JSON.stringify(asoExperiment));
};

export const copyAsoExperiment = (asoExperiment, byUUID) => (dispatch, getState) => {
  dispatch({
    type: ADD_ASO_EXPERIMENT,
    asoExperiment,
  });
  const isIndividual = getState().aso.isIndividualAsoExperiment;

  const onload = (responseText) => {
    dispatch({
      type: SET_IS_SAVE_ASO_EXPERIMENT,
      isSave: false,
    });
  };
  const xhr = createRequest(dispatch, 'POST', `/aso${isIndividual ? '/individual' : ''}/experiment/copy/${byUUID}`, onload);

  xhr.send(JSON.stringify(asoExperiment));
};

export const changePhrases = (phrases) => (dispatch) => {
  dispatch({
    type: CHANGE_PHRASES,
    phrases,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const changePhrasesRanks = (ranks) => (dispatch) => {
  dispatch({
    type: CHANGE_PHRASES_RANKS,
    ranks,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const changePhrasesPositions = (positions) => (dispatch) => {
  dispatch({
    type: CHANGE_PHRASES_POSITIONS,
    positions,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};


export const checkKeyword = (isActive, keyword, propName) => (dispatch) => {
  dispatch({
    type: CHECK_KEYWORD,
    isActive,
    keyword,
    propName,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const changeFormInput = (value, name, typeInput) => (dispatch) => {
  dispatch({
    type: CHANGE_FORM_INPUT,
    value,
    name,
    typeInput,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const setDeleteAsoExperiment = (asoExperiment) => (dispatch) => {
  dispatch({
    type: SET_DELETE_ASO_EXPERIMENT,
    asoExperiment,
  });
};

export const setEditAsoExperiment = (asoExperiment) => (dispatch) => {
  dispatch({
    type: SET_EDIT_ASO_EXPERIMENT,
    asoExperiment,
  });
};

export const setCopyAsoExperiment = (asoExperiment) => (dispatch) => {
  dispatch({
    type: SET_COPY_ASO_EXPERIMENT,
    asoExperiment,
  });
};

export const orderAsoKeywords = (orderParams) => (dispatch) => {
  dispatch({
    type: ORDER_ASO_KEYWORDS,
    orderParams,
  });
};

export const orderAsoPhrases = (orderParams) => (dispatch) => {
  dispatch({
    type: ORDER_ASO_PHRASES,
    orderParams,
  });
};

export const moveMainToOld = () => (dispatch) => {
  dispatch({
    type: MOVE_MAIN_TO_OLD,
    meta: {
      debounce: {
        time: 500,
      },
    },
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const setIsSave = (isSave) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave,
  });
};

export const moveOldToMain = () => (dispatch) => {
  dispatch({
    type: MOVE_OLD_TO_MAIN,
    meta: {
      debounce: {
        time: 500,
      },
    },
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

// ASO REPORT

export const searchAsoReport = (searchParams, region, cb = () => {}) => (dispatch, getState) => {
  dispatch({
    type: IS_LOADING_ASO_REPORT_DATA,
    region: region,
    isLoading: true,
  });
  const stateId = getState().asoReport.stateId;

  const onload = (responseText) => {
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_ASO_REPORT_DATA,
      asoReportData: JSON.parse(responseText),
      requestTimeEpoch: timeStampRequest,
      region,
      stateId,
    });
    cb();
  };
  const xhr = createRequest(dispatch, 'POST', `/aso/report/search`, onload);
  const searchParamsCopy = JSON.parse(JSON.stringify(searchParams));

  searchParamsCopy.isWithASA = false;

  if (searchParamsCopy.appleSourceTypes && (searchParamsCopy.appleSourceTypes.indexOf('ASA') !== -1)) {
    searchParamsCopy.appleSourceTypes = searchParamsCopy.appleSourceTypes.filter((type) => type !== 'ASA');
    searchParamsCopy.isWithASA = true;
  }
  xhr.send(JSON.stringify(searchParamsCopy));
};

export const searchAsoReportQueue = (() => {
  const q = queue((task, cb) => {
    (searchAsoReport(task.searchParams, task.region, cb))(task.dispatch, task.getState);
  }, 1);

  return (searchParams, region) => (dispatch, getState) => {
    q.push({
      searchParams,
      region,
      dispatch,
      getState,
    });
  };
})();

export const changeSearchParams = (value, prop) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PARAMS_ASO_REPORT,
    value,
    prop,
  });
};

export const changeIncentAppUnitsParams = (value, prop, region) => (dispatch) => {
  dispatch({
    type: CHANGE_INCENT_APP_UNITS_PARAM_ASO_REPORT,
    value,
    prop,
    region,
  });
};

export const createAsoReportXML = () => (dispatch) => {
  dispatch({
    type: CONVERT_ASO_REPORT_TO_XML,
  });
};

export const setIsHideTabelDays = (isHide) => (dispatch) => {
  dispatch({
    type: SET_IS_HIDE_TABLE_DAYS_ASO_REPORT,
    isHide,
  });
};

export const changeActiveSubForms = (countActiveSubForms) => (dispatch) => {
  dispatch({
    type: CHANGE_ACTIVE_SUB_FORMS,
    countActiveSubForms,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

export const deletePhrase = (phraseIndexes) => (dispatch) => {
  dispatch({
    type: DELETE_PHRASE,
    phraseIndexes,
  });

  dispatch({
    type: SET_IS_CHANGED,
    isChanged: true,
  });

  dispatch({
    type: SET_IS_SAVE_ASO_EXPERIMENT,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });
};

