import React from 'react';
import momentZ from 'moment-timezone';
import {FromToDatePicker} from '../general/FromToDatePicker';
import {AppSelector} from '../general/AppSelector';
import {CountrySelector} from '../general/CountrySelector';
import {MetricsSourceTypesSelector} from '../general/MetricsSourceTypesSelector';
import {typeModal} from '../../actions/general';
import {Switcher} from '../general/Switcher';
import {colors} from '../../utils/StyleUtils';
import {ChartType} from '../../reducers/metrics';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import {StoreSelector} from '../general/StoreSelector';
import {Store} from '../../models/store';

export const MetricsHeader = ({
  searchParams, changeSearchParams, onelightApps, selectedChart, isHideEvents,
  changeFromToDate, changeSelectedCharts, showModal, isHideEventsNames, setIsHideEventsNames, setIsHideEvents,
}) => {
  const changeDate = (date, propName) => {
    changeSearchParams(
        momentZ(date).format('YYYY-MM-DD'),
        propName,
    );
  };

  return <div style={{display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 3}}>
    <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}}>
      <div style={{display: 'flex'}}>
        <div className={'metrics-header-tab' + (selectedChart[ChartType.LINE] ? '-active' : '')} onClick={() => {
          changeSelectedCharts(ChartType.LINE, true);
        }}>
          Line
        </div>
        <div className={'metrics-header-tab' + (selectedChart[ChartType.AREA] ? '-active' : '')} onClick={() => {
          changeSelectedCharts(ChartType.AREA, true);
        }}>
          Area
        </div>
        <div className={'metrics-header-tab' + (selectedChart[ChartType.BARS] ? '-active' : '')} onClick={() => {
          changeSelectedCharts(ChartType.BARS, true);
        }}>
          Bar
        </div>
      </div>
      <FromToDatePicker
        style={{display: 'flex', alignItems: 'center', paddingBottom: '4px', alignSelf: 'flex-end'}}
        fromDate={searchParams.fromDate}
        toDate={searchParams.toDate}
        changeFromDate={(date) => changeDate(date, 'fromDate')}
        changeToDate={(date) => changeDate(date, 'toDate')}
        changeLastDays={changeFromToDate}
      />
    </div>
    <div className={'metrics-header-container'}>
      <StoreSelector
        style={{paddingLeft: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40px'}}
        selectedValue={searchParams.store}
        change={(store) => {
          changeSearchParams([], 'appIds');
          changeSearchParams(store, 'store');
        }}
      />
      <AppSelector
        selectedApps={searchParams.appIds}
        style={{paddingLeft: '10px'}}
        allApps={onelightApps.filter((app) => {
          return app.store === searchParams.store;
        })}
        changeApps={(apps) => {
          changeSearchParams(apps, 'appIds');
        }}
      />
      <CountrySelector
        selectedCountries={searchParams.countries}
        style={{paddingLeft: '10px'}}
        changeCountries={(countries) => {
          changeSearchParams(countries, 'countries');
        }}
      />
      <MetricsSourceTypesSelector
        style={{paddingLeft: '10px'}}
        selectedSourceTypes={searchParams[(searchParams.store === Store.APPLE.value ? 'appleSourceTypes' : 'googleSourceTypes')]}
        changeSourceTypes={(sourceTypes) => {
          const type = searchParams.store === Store.APPLE.value ? 'appleSourceTypes' : 'googleSourceTypes';
          changeSearchParams(sourceTypes, type);
        }}
        store={searchParams.store}
        isWithASA={true}
      />
      <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
        <div style={{display: 'flex', alignItems: 'center', paddingRight: '20px'}}>
          <div className={'switcher-label-text'}>
            Hide events
          </div>
          <Switcher
            isActive={isHideEvents}
            colorOn={colors.defSwitcherActive}
            colorOff={colors.defSwitcherUnActive}
            switch={(isActive) => {
              setIsHideEvents(isActive);
            }}
            id={'event-display'}
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingRight: '20px'}}>
          <div className={'switcher-label-text'}>
            Hide Indicators
          </div>
          <Switcher
            isActive={isHideEventsNames}
            colorOn={colors.defSwitcherActive}
            colorOff={colors.defSwitcherUnActive}
            switch={(isActive) => {
              setIsHideEventsNames(isActive);
            }}
            id={'event-names-display'}
          />
        </div>
      </div>

    </div>

    <div className={'add-event-container'} onClick={() => {
      showModal(typeModal.EVENT_ADD, true, {});
    }}>
      <span>Add Event</span>
      <PlusIcon
        style={{paddingLeft: '9px'}}
      />
    </div>
  </div>;
};


