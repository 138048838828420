import momentZ from 'moment-timezone';
import {SET_ERROR} from './general';
import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {SET_IS_LOADING_APPS_WITH_RANKS} from './ranks';
import {SET_IS_LOADING_APPS_WITH_RATINGS} from './ratings';
import {configs} from '../resources/configs';

export const SAVE_APP = 'SAVE_APP';
export const SAVE_ONELIGHT_APPS = 'SAVE_ONELIGHT_APPS';
export const REMOVE_APP_FROM_GROUP = 'REMOVE_APP_FROM_GROUP';
export const SAVE_OPENAPI = 'SAVE_OPENAPI';
export const SET_IS_LOADING_OPENAPI = 'SET_IS_LOADING_OPENAPI';

export const appleGenreIdName = {
  '6008': 'Photo & Video',
  '6002': 'Utilities',
  '6027': 'Graphics & Design',
};

export const googleGenreIdName = {
  PHOTOGRAPHY: 'Photography',
  APPLICATION: 'Application',
  TOOLS: 'Tools',
  VIDEO_PLAYERS: 'Video Players',
  GAME: 'Game',
};

export const AppGroupRelationType = {
  RANKS: 'RANKS',
  RATINGS: 'RATINGS',
};

export const addApp = (
    vendorId,
    store,
    isNewGroup,
    groupId,
    genreId = '6008',
    appGroupRelationType = AppGroupRelationType.RANKS,
    groupOrder,
) => (dispatch, getState) => {
  const setIsLoading = appGroupRelationType === AppGroupRelationType.RANKS ?
    SET_IS_LOADING_APPS_WITH_RANKS : SET_IS_LOADING_APPS_WITH_RATINGS;

  dispatch({
    type: setIsLoading,
    isLoading: true,
  });

  const stateId = appGroupRelationType === AppGroupRelationType.RANKS ?
    getState().ranks.stateId : getState().ratings.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_APP,
      requestTimeEpoch: timeStampRequest,
      stateId,
      app: response,
      appGroupRelationType,
    });
  };

  const onerror = (responseText) => {
    dispatch({
      type: setIsLoading,
      isLoading: false,
    });

    const error = JSON.parse(responseText);
    console.log(error);
    dispatch({
      type: SET_ERROR,
      error: error.error.description,
    });
  };

  const xhr = createRequest(dispatch, 'POST', `/metrics/migrate/app`, onload, true, onerror);

  xhr.send(JSON.stringify({
    store,
    vendorId: vendorId,
    isNewGroup,
    groupId,
    genreId: genreId,
    appGroupRelationType,
    groupOrder,
  }));
};

export const removeAppFromGroup = (
    appId,
    groupId,
) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_APP_FROM_GROUP,
    appId,
    groupId,
  });

  const onload = (responseText) => {};

  const onerror = (responseText) => {
    const error = JSON.parse(responseText);
    console.log(error);
    dispatch({
      type: SET_ERROR,
      error: error.error.description,
    });
  };

  const xhr = createRequest(
      dispatch,
      'DELETE',
      `/metrics/app-to-group?appId=${appId}&groupId=${groupId}`,
      onload,
      true,
      onerror,
  );

  xhr.send();
};

export const getOnelightApps = () => (dispatch) => {
  const onload = (responseText) => {
    dispatch({
      type: SAVE_ONELIGHT_APPS,
      apps: JSON.parse(responseText),
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/metrics/onelight/apps`, onload);

  xhr.send();
};


export const getOpenapi = (projectName) => (dispatch, getState) => {
  let openApiUrl = configs.openapiUrlCommon;
  switch (projectName) {
    case 'beat': {
      openApiUrl = configs.openapiUrlBeat;
      break;
    }
    case 'inpreview': {
      openApiUrl = configs.openapiUrlInpreview;
      break;
    }
  }
  dispatch({type: SET_IS_LOADING_OPENAPI, isLoading: true});
  const timeStampRequest = momentZ().format('x');
  const stateId = getState().appState.stateId;

  const onload = (responseText) => {
    let openapi;
    try {
      openapi = new TextDecoder().decode(responseText);
    } catch (e) {
      openapi = {};
    }
    dispatch({type: SAVE_OPENAPI, openapi, timeStampRequest, stateId});
  };

  getFileArrayBuffer(openApiUrl, onload);
};
