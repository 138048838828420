import React, {useEffect, useState} from 'react';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {AppBadge} from '../general/AppBadge';
import {copyToClipboard} from '../../utils/StringUtils';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete-icon.svg';

export const PromoListItem = ({
  promoCode, setDeletePromoCode, admins, onelightApps,
}) => {
  const [app, setApp] = useState(null);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (onelightApps.length !== 0) {
      const experimentApp = onelightApps.find((app) => app.appIdentifier === promoCode.appIdentifier);
      if (experimentApp) {
        setApp(experimentApp);
      }
    }
  }, [onelightApps, promoCode.appIdentifier]);

  useEffect(() => {
    if (admins.length !== 0) {
      const promoAdmin = admins.find((admin) => admin.id === promoCode.creatorId);
      if (promoAdmin) {
        setAdmin(promoAdmin);
      }
    }
  }, [admins, promoCode.creatorId]);

  const renderApp = () => {
    if (!app) {
      return <div>{promoCode.appIdentifier}</div>;
    }

    return <AppBadge
      app={app}
      style={{paddingLeft: '6px'}}
    />;
  };

  return <div className={'promo-list-item'}>
    <div className={'promo-list-item-col'} style={{justifyContent: 'flex-start'}}>
      {renderApp()}
    </div>
    <div className={'promo-list-item-col'}>
      {promoCode.code}
    </div>
    <div className={'promo-list-item-col'}>
      {promoCode.activations}
    </div>
    <div className={'promo-list-item-col'}>
      {toUserTimeZoneDate(promoCode.expirationDate).format('DD/MM/yyyy')}
    </div>
    <div className={'promo-list-item-col'}>
      <div className={'onelight-second-btn'} onClick={() => {
        copyToClipboard(promoCode.code);
      }}>
        Copy code
      </div>
    </div>
    <div className={'promo-list-item-col'}>
      <div className={'onelight-second-btn'} onClick={() => {
        copyToClipboard(promoCode.onelink);
      }}>
        Copy link
      </div>
    </div>
    <div className={'promo-list-item-date-col'}>
      {toUserTimeZoneDate(promoCode.updatedAt).format('DD/MM/yyyy')}
      <div style={{paddingLeft: '10px'}}>
        {toUserTimeZoneDate(promoCode.updatedAt).format('HH:mm')}
      </div>
    </div>

    <DeleteIcon
      style={{alignSelf: 'center', minWidth: '18px'}}
      onClick={(e) => {
        e.stopPropagation();
        setDeletePromoCode(promoCode);
      }}
    />
  </div>;
};
