import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {getMarketingConfig, showModal} from '../../actions/general';
import {CrossPromoHeader} from './CrossPromoHeader';
import {
  createCrossPromo, importCrossPromo,
  moveCrossPromo,
  selectCrossPromo,
  selectCrossPromoApp, setDeleteCrossPromo,
  updateCrossPromo, updateCrossPromoBatch,
} from '../../actions/crossPromo';
import CrossPromoList from './CrossPromoList';
import {CrossPromoEditor} from './CrossPromoEditor';
import {CrossPromoPreview} from './CrossPromoPreview';

const CrossPromo = ({
  getCrossPromo, isLoadingCrossPromo, selectedApp, selectApp, selectedCrossPromo, createCrossPromo, importCrossPromo,
  marketingConfig, showModal, setDeleteCrossPromo, onelightApps, selectCrossPromo, moveCrossPromo, updateCrossPromo, updateCrossPromoBatch,
}) => {
  const [backgroundImageNameSelected, selectBackGroundImageName] = useState('background_image_a_x1');

  useEffect(() => {
    if (selectedApp && selectedApp.id) {
      getCrossPromo(selectedApp.vendorId);
    }
  }, [selectedApp]);

  if (isLoadingCrossPromo) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }
  return (
    <div className={'aso-container'}>
      <CrossPromoHeader
        showModal={showModal}
        onelightApps={onelightApps}
        selectedApp={selectedApp}
        selectApp={selectApp}
        marketingConfig={marketingConfig}
      />
      <div style={{minHeight: '0px', display: 'flex'}}>
        <CrossPromoList
          crossPromoList={marketingConfig ? marketingConfig.cross_promo_waterfall : []}
          setDeleteCrossPromo={setDeleteCrossPromo}
          selectedCrossPromo={selectedCrossPromo}
          selectCrossPromo={selectCrossPromo}
          createCrossPromo={createCrossPromo}
          moveCrossPromo={moveCrossPromo}
          selectedApp={selectedApp}
          onelightApps={onelightApps}
        />
        <CrossPromoEditor
          crossPromo={selectedCrossPromo}
          updateCrossPromo={updateCrossPromo}
          updateCrossPromoBatch={updateCrossPromoBatch}
          importCrossPromo={importCrossPromo}
          selectBackGroundImageName={selectBackGroundImageName}
          backgroundImageNameSelected={backgroundImageNameSelected}
        />
        <CrossPromoPreview
          crossPromo={selectedCrossPromo}
          backgroundImageNameSelected={backgroundImageNameSelected}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingCrossPromo: state.crossPromo.isLoadingMarketingConfig,
  selectedApp: state.crossPromo.selectedAppCrossPromo,
  onelightApps: state.appState.onelightApps,
  selectedCrossPromo: state.crossPromo.selectedCrossPromo,
  marketingConfig: state.crossPromo.selectedAppCrossPromo ? state.crossPromo.marketingConfigByAppStoreId[state.crossPromo.selectedAppCrossPromo.vendorId] : null,
});

const mapDispatchToProps = (dispatch) => ({
  getCrossPromo: bindActionCreators(getMarketingConfig, dispatch),
  selectApp: bindActionCreators(selectCrossPromoApp, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  selectCrossPromo: bindActionCreators(selectCrossPromo, dispatch),
  createCrossPromo: bindActionCreators(createCrossPromo, dispatch),
  moveCrossPromo: bindActionCreators(moveCrossPromo, dispatch),
  updateCrossPromo: bindActionCreators(updateCrossPromo, dispatch),
  importCrossPromo: bindActionCreators(importCrossPromo, dispatch),
  setDeleteCrossPromo: bindActionCreators(setDeleteCrossPromo, dispatch),
  updateCrossPromoBatch: bindActionCreators(updateCrossPromoBatch, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrossPromo);
