import React from 'react';
import momentZ from 'moment-timezone';
import {AsoExperimentListItem} from './AsoExperimentAsoListItem';

export const AsoExperimentsList = ({
  asoExperiments, setDeleteAsoExperiment, setEditAsoExperiment, setCopyAsoExperiment, onelightApps, admins,
}) => {
  return <div className={'aso-experiments-list-container'}>
    <div className={'aso-experiment-list-header'}>
      <div className={'aso-experiment-list-item-col-header'}>App</div>
      <div className={'aso-experiment-list-item-col-header'}>Country</div>
      <div className={'aso-experiment-list-item-col-header'}>Manager</div>
      <div className={'aso-experiment-list-item-col-header'}>Last Updated</div>
      <div className={'aso-experiment-list-item-btns-col'}></div>
    </div>
    <div className={'aso-experiments-list'}>
      {asoExperiments
          .sort((asoExperimentA, asoExperimentB) => {
            if (momentZ(asoExperimentA.updatedAt).isBefore(momentZ(asoExperimentB.updatedAt))) {
              return 1;
            } else return -1;
          })
          .map((asoExperiment) => {
            return <AsoExperimentListItem
              key={asoExperiment.uuid}
              asoExperiment={asoExperiment}
              setDeleteAsoExperiment={setDeleteAsoExperiment}
              setEditAsoExperiment={setEditAsoExperiment}
              setCopyAsoExperiment={setCopyAsoExperiment}
              onelightApps={onelightApps}
              admins={admins}
            />;
          })}
    </div>
  </div>;
};
