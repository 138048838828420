import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';

export const SET_IS_LOADING_PROMO_CODES = 'SET_IS_LOADING_PROMO_CODES';
export const SAVE_PROMO_CODES = 'SAVE_PROMO_CODES';
export const SAVE_PROMO_CODE = 'ADD_PROMO_CODE';
export const DELETE_PROMO_CODE = 'DELETE_PROMO_CODE';
export const SET_DELETE_PROMO_CODE = 'SET_DELETE_PROMO_CODE';

export const getPromoCodes = () => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_PROMO_CODES,
    isLoading: true,
  });

  const stateId = getState().promo.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_PROMO_CODES,
      requestTimeEpoch: timeStampRequest,
      stateId,
      promoCodes: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/promo`, onload);

  xhr.send();
};

export const savePromoCode = (promoCode) => (dispatch) => {
  dispatch({
    type: SAVE_PROMO_CODE,
    promoCode,
  });
  console.log(promoCode);
  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'POST', `/promo`, onload);

  xhr.send(JSON.stringify(promoCode));
};

export const deletePromoCode = (id) => (dispatch) => {
  dispatch({
    type: DELETE_PROMO_CODE,
    id,
  });

  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'DELETE', `/promo/${id}`, onload);

  xhr.send();
};

export const setDeletePromoCode = (promoCode) => (dispatch) => {
  dispatch({
    type: SET_DELETE_PROMO_CODE,
    promoCode,
  });
};
