import React from 'react';
import {typeModal} from '../../actions/general';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';

export const PromoHeader = ({showModal}) => {
  return <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '26px'}}>
    <div className={'add-metadata-container'} style={{padding: '0px 20px  0px 0px'}} onClick={() => {
      showModal(typeModal.PROMO_ADD, true, {} );
    }}>
      <div>
        Add Promo
      </div>
      <PlusIcon/>
    </div>
  </div>;
};
