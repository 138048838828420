import React from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import useGlobalDndState, {sourceDndDroppable, typeDndAction} from './DndState';
import {unwrapId, unwrapSourceId} from '../../../utils/DragAndDropUtils';
import {ComponentType} from '../../../actions/general';


export const DndContainer = ({children}) => {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobalDndState();

  const onBeforeCapture = (result) => {
    globalActions.changeDroppableState(
        unwrapSourceId(result.draggableId),
        {
          action: typeDndAction.START_DRAGGING,
        },
    );
  };

  const onDragEnd = (result) => {
    const {source, destination, type} = result;

    if (sourceDndDroppable.ListPacks === source.droppableId) {
      globalActions.changeDroppableState(
          source.droppableId,
          {
            action: typeDndAction.END_DRAGGING,
          },
      );
    }

    if (!destination) {
      return;
    }

    const draggableId = unwrapId(result.draggableId);
    const destinationDroppableId = destination.droppableId;
    const sourceDroppableId = source.droppableId;
    if (destinationDroppableId === sourceDroppableId) {
      globalActions.changeDroppableState(
          sourceDroppableId,
          {
            action: typeDndAction.MOVE,
            draggableId,
            toIndex: destination.index,
          },
      );
    } else {
      if (!sourceDndDroppable.hasOwnProperty(sourceDroppableId)) {
        const sourceId = unwrapId(sourceDroppableId);
        const destinationId = unwrapId(destinationDroppableId);
        if (destinationId !== sourceId) {
          globalActions.changeDroppableState(
              sourceDroppableId,
              {
                action: typeDndAction.REMOVE_LOCAL,
                draggableId,
              },
          );
          globalActions.changeDroppableState(
              destinationDroppableId,
              {
                action: typeDndAction.REPLACE,
                toIndex: destination.index,
                draggableId,
                sourceDroppableId: sourceDroppableId,
              },
          );
        }
      } else {
        if (type === ComponentType.ELEMENT) {
          globalActions.changeDroppableState(
              sourceDroppableId,
              {
                action: typeDndAction.REMOVE_LOCAL,
                draggableId,
              },
          );
        }

        globalActions.changeDroppableState(
            destinationDroppableId,
            {
              action: typeDndAction.ADD,
              draggableId,
              toIndex: destination.index,
            },
        );
      }
    }
  };


  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
};
