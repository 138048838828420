import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
  changeFromToDate,
  changeSearchParams,
  changeSelectedMetricsCharts,
  getMetrics,
  getAsaSubscribeMetrics, setIsHideEvents, setIsHideEventsNames,
} from '../../actions/metrics';
import {MetricsHeader} from './MetricsHeader';
import MDSpinner from 'react-md-spinner';
import MetricsDashboard from './MetricsDashboard';
import {showModal} from '../../actions/general';
import {searchEvents} from '../../actions/events';

const Metrics = ({getMetrics, getAsaSubscribeMetrics, isLoadingMetrics, isLoadingAsaSubscribeMetric, searchParamsEvents,
  searchParams, changeSearchParams, onelightApps, changeFromToDate, setIsHideEventsNames, setIsHideEvents,
  changeSelectedCharts, showModal, searchEvents, isHideEventsNames, isHideEvents, selectedChart,
}) => {
  useEffect(() => {
    getMetrics(searchParams);
    getAsaSubscribeMetrics(searchParams);
  }, [searchParams]);

  useEffect(() => {
    searchEvents(searchParamsEvents);
  }, [searchParamsEvents]);

  if (isLoadingMetrics || isLoadingAsaSubscribeMetric) {
    return (
      <div className={'metrics-container'}>
        <MetricsHeader
          changeSearchParams={changeSearchParams}
          searchParams={searchParams}
          onelightApps={onelightApps}
          changeFromToDate={changeFromToDate}
          changeSelectedCharts={changeSelectedCharts}
          showModal={showModal}
          setIsHideEventsNames={setIsHideEventsNames}
          setIsHideEvents={setIsHideEvents}
          isHideEventsNames={isHideEventsNames}
          isHideEvents={isHideEvents}
          selectedChart={selectedChart}
        />
        <div className='spinner-container' style={{marginTop: '250px'}}>
          <MDSpinner size={100} singleColor={'#00DADA'}/>
        </div>
      </div>
    );
  }

  return (
    <div className={'metrics-container'}>
      <MetricsHeader
        changeSearchParams={changeSearchParams}
        searchParams={searchParams}
        onelightApps={onelightApps}
        changeFromToDate={changeFromToDate}
        changeSelectedCharts={changeSelectedCharts}
        showModal={showModal}
        setIsHideEventsNames={setIsHideEventsNames}
        isHideEventsNames={isHideEventsNames}
        selectedChart={selectedChart}
        setIsHideEvents={setIsHideEvents}
      />
      <MetricsDashboard/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchParams: state.metrics.searchParams,
  searchParamsEvents: state.metrics.searchParamsEvents,
  onelightApps: state.appState.onelightApps,
  isLoadingMetrics: state.metrics.isLoadingMetrics,
  isLoadingAsaSubscribeMetric: state.metrics.isLoadingAsaSubscribeMetric,
  isHideEventsNames: state.metrics.isHideEventsNames,
  isHideEvents: state.metrics.isHideEvents,
  selectedChart: state.metrics.selectedChart,
});

const mapDispatchToProps = (dispatch) => ({
  getMetrics: bindActionCreators(getMetrics, dispatch),
  getAsaSubscribeMetrics: bindActionCreators(getAsaSubscribeMetrics, dispatch),
  changeSearchParams: bindActionCreators(changeSearchParams, dispatch),
  changeFromToDate: bindActionCreators(changeFromToDate, dispatch),
  changeSelectedCharts: bindActionCreators(changeSelectedMetricsCharts, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  searchEvents: bindActionCreators(searchEvents, dispatch),
  setIsHideEventsNames: bindActionCreators(setIsHideEventsNames, dispatch),
  setIsHideEvents: bindActionCreators(setIsHideEvents, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);

export const convertPropNameToNameChart = (propName) => {
  return NAMES_TO_NAME_CHART[propName] ? NAMES_TO_NAME_CHART[propName] : propName;
};

const NAMES_TO_NAME_CHART = {
  'pageViewUnique': 'Page views',
  'impressionsTotalUnique': 'Impressions',
  'installs': 'Installs',
  'units': 'Installs',
  'CTR': 'CTR',
  'CVR': 'CVR',
  'organicRevenue': 'Revenue organic',
  'revenueTotal': 'Revenue subscribe',
  'revenueLatOff': 'Revenue subscribe Lat Off',
  'revenueLatOn': 'Revenue subscribe Lat On',
  'countOrganic': 'Organic subscribe',
  'countTotal': 'Subscribe',
  'countLatOff': 'Subscribe Lat Off',
  'countLatOn': 'Subscribe Lat On',
  'Store listing visitors': 'Visitors',
  'Store listing acquisitions': 'Acquisitions',
  'Store listing conversion rate': 'Conversion rate',
};


export const NAMES_TO_POSITION = {
  pageViewUnique: 2,
  impressionsTotalUnique: 1,
  installs: 3,
  units: 4,
  CTR: 3,
  CVR: 5,
  organicRevenue: 10,
  revenueTotal: 9,
  revenueLatOff: 8,
  revenueLatOn: 9,
  countOrganic: 7,
  countTotal: 6,
};
