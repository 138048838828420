import {createRequest} from '../utils/HttpRequestUtills';
export const SET_EVENT_TO_EDIT = 'SET_EVENT_TO_EDIT';
export const SET_EVENT_TO_DELETE = 'SET_EVENT_TO_DELETE';
export const SAVE_EVENTS = 'SAVE_EVENTS';
export const SAVE_EVENT = 'SAVE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const setEventForEdit = (event) => (dispatch) => {
  dispatch({
    type: SET_EVENT_TO_EDIT,
    event,
  });
};

export const setEventForDelete = (event) => (dispatch) => {
  dispatch({
    type: SET_EVENT_TO_DELETE,
    event,
  });
};

export const saveEvent = (newEvent) => (dispatch) => {
  dispatch({
    type: SAVE_EVENT,
    event: newEvent,
  });

  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'POST', `/metrics/event`, onload);
  xhr.send(JSON.stringify(newEvent));
};

export const searchEvents = (searchParams) => (dispatch) => {
  const onload = (responseText) => {
    dispatch({
      type: SAVE_EVENTS,
      events: JSON.parse(responseText),
    });
  };
  const xhr = createRequest(dispatch, 'POST', `/metrics/events/search`, onload);

  xhr.send(JSON.stringify(searchParams));
};

export const deleteEvent = (eventId) => (dispatch) => {
  dispatch({
    type: DELETE_EVENT,
    eventId,
  });
  const xhr = createRequest(dispatch, 'DELETE', `/metrics/event/${eventId}`, () => {});

  xhr.send();
};
