import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete-icon.svg';
import {AppBadge} from '../general/AppBadge';

export const CrossPromoListItem = ({crossPromo, selectCrossPromo, isSelected,
  setDeleteCrossPromo, draggableId, arrayIndex, renderDraggable, onelightapps}) => {
  const [isMouseOver, setMouseOver] = useState(false);

  return (
    <Draggable
      key={crossPromo.id}
      draggableId={draggableId}
      index={arrayIndex}
    >
      {renderDraggable((provided, snapshot) => (
        <div className={'cross-promo-list-item' + (isSelected ? '-selected' : '')}
          onClick={() => {
            selectCrossPromo(crossPromo);
          }}
          onMouseLeave={() => setMouseOver(false)}
          onMouseEnter={() => setMouseOver(true)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{...provided.draggableProps.style}}
          {...provided.dragHandleProps}
        >
          <div className={'drag-dots'}
            style={{opacity: (isMouseOver ? 1 : 0)}}
          >
          </div>
          <AppBadge
            app={onelightapps.find((app) => app.vendorId === crossPromo.app_id)}
            isIconOnlyV2={true}
            style={{paddingLeft: '6px', paddingRight: '6px'}}
          />
          <div style={{maxWidth: '160px', whiteSpace: 'break-spaces'}}>{crossPromo.main_title.en}</div>
          <DeleteIcon
            style={{opacity: (isMouseOver ? 1 : 0), alignSelf: 'center', minWidth: '18px', marginLeft: 'auto', paddingRight: '5px'}}
            onClick={(e) => {
              if (isMouseOver) {
                e.stopPropagation();
                setDeleteCrossPromo(crossPromo);
              }
            }}
          />
        </div>
      ))}
    </Draggable>
  );
};
