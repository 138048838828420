import React from 'react';
import {FormListItem} from './FormListItem';
import {splitKeywords} from '../../../utils/StringUtils';
import {colors} from '../../../utils/StyleUtils';
import {MoveButton} from '../../general/MoveButton';

const FormsList = ({forms, changeFormInput, moveMainToOld, moveOldToMain, changeActiveSubForms}) => {
  const findOverlapping = () => {
    const wordsOverlapping = {};
    let wordsOverlappingString = '';

    forms.forEach((form) => {
      if (form.name !== 'old') {
        splitKeywords(form.keywords)
            // .unique()
            .concat(form.overlapping ? form.overlapping : [])
            .forEach((word) => {
              const wordLowercase = word.toLowerCase();
              if (!wordsOverlapping[wordLowercase]) {
                wordsOverlapping[wordLowercase] = 1;
              } else {
                if (wordsOverlapping[wordLowercase] === 1) {
                  wordsOverlappingString += (wordLowercase + ' ');
                }
                wordsOverlapping[wordLowercase] = 2;
              }
            });
      }
    });

    return wordsOverlappingString.trim();
  };

  const overlappingWords = findOverlapping();

  return <div className={'aso-experiment-forms-list'}>
    <div style={{display: 'flex', alignItems: 'center', paddingBottom: '26px'}}>
      <div className={'aso-overlapping-container'} style={{width: '60%'}}>
        {overlappingWords}
      </div>
      <div className={'aso-forms-buttons-container'} style={{width: '40%'}}>
        <MoveButton
          onClick={() => {
            moveMainToOld();
          }}
          text={'main to old'}
          style={{backgroundColor: colors.asoKeywordsMain}}
        />

        <MoveButton
          onClick={() => {
            moveOldToMain();
          }}
          text={'old to main'}
          style={{backgroundColor: colors.asoKeywordsOld, marginLeft: '60px'}}
        />
        <div className={'onelight-btn-apply'} style={{marginLeft: '60px'}} onClick={changeActiveSubForms}>
            New
        </div>
      </div>
    </div>
    {forms.map((form) => {
      return <FormListItem
        key={'aso_form_' + form.name}
        form={form}
        changeFormInput={changeFormInput}
      />;
    })}
  </div>;
};

export default FormsList;
