import React, {useState} from 'react';
import KeywordRankSettings from './KeywordRankSettings';
import {Rank} from './Rank';
import {Popularity} from './Popularity';

export const KeywordCompare = ({keywordRank,
  onelightApps, setKeywordForEdit, setKeywordToDelete, keywordsIdsForDelete}) => {
  const [isHover, setIsHover] = useState(false);
  const [onelightApp, setOnelightApp] = useState(onelightApps.find((onelightApp) => onelightApp.id === keywordRank.appId));

  return <div className={'keyword-rank-container'}>
    <div className={'keyword-rank-value'}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {keywordRank.keyword}
      {!isHover && keywordsIdsForDelete.indexOf(keywordRank.id) === -1 ? null : <KeywordRankSettings
        keywordRank={keywordRank}
        onelightApp={onelightApp}
        setKeywordForEdit={setKeywordForEdit}
        setKeywordToDelete={setKeywordToDelete}
      />}
    </div>

    <div className={'keyword-rank-position'}>
      <Rank
        currentRank={keywordRank.firstRankCompare}
        prevRank={keywordRank.secondRankCompare}
      />
    </div>

    <div className={'keyword-rank-position'}>
      <Popularity
        currentRank={keywordRank.firstRankCompare}
        prevRank={keywordRank.secondRankCompare}
        isNegative={false}
      />
    </div>
  </div>;
};
