import uuid from 'uuid/v4';
import momentZ from 'moment-timezone';
import {
  CHANGE_FROM_TO_DATE_METRICS, CHANGE_IS_HIDE_EVENTS, CHANGE_IS_HIDE_EVENTS_NAMES,
  CHANGE_SEARCH_PARAMS_METRICS, CHANGE_SELECTED_METRICS_CHARTS,
  SAVE_METRICS,
  SAVE_ASA_SUBSCRIBE_METRICS,
  SET_IS_LOADING_METRICS,
  SET_IS_LOADING_ASA_SUBSCRIBE_METRICS,
} from '../actions/metrics';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {DELETE_EVENT, SAVE_EVENT, SAVE_EVENTS} from '../actions/events';
import {SourceTypes, Store} from '../models/store';

export const ChartType = {
  LINE: 'LINE',
  AREA: 'AREA',
  BARS: 'BARS',
};

const getInitialState = () => {
  return {
    stateId: uuid(),
    metrics: {},
    asaSubscribeMetrics: {},
    events: [],
    selectedChart: getItem('metricSelectedChart', {
      [ChartType.LINE]: false,
      [ChartType.AREA]: false,
      [ChartType.BARS]: true,
    }),
    isLoadingMetrics: false,
    isLoadingAsaSubscribeMetric: false,
    timeStampSaveMetrics: 0,
    timeStampSaveAsaSubscribeMetrics: 0,
    searchParamsEvents: (() => {
      const toDate = momentZ().format('YYYY-MM-DD');
      const fromDate = momentZ().subtract(90, 'days').format('YYYY-MM-DD');
      const searchParams = getItem('metricEventsSearchParams', {
        toDate,
        fromDate,
        types: [],
        appIds: [],
      });
      searchParams.toDate = toDate;
      searchParams.fromDate = fromDate;

      return searchParams;
    })(),
    searchParams: (() => {
      const toDate = momentZ().format('YYYY-MM-DD');
      const fromDate = momentZ().subtract(90, 'days').format('YYYY-MM-DD');
      const searchParams = getItem('metricSearchParams', {
        toDate,
        fromDate,
        appIds: [],
        countries: [],
        appleSourceTypes: [],
        googleSourceTypes: [],
        store: Store.APPLE.value,
        isGroupBySource: true,
      });
      searchParams.toDate = toDate;
      searchParams.fromDate = fromDate;

      if (!searchParams.googleSourceTypes) {
        searchParams.googleSourceTypes = [];
      }

      if (!searchParams.appleSourceTypes) {
        searchParams.appleSourceTypes = [];
      }

      if (!searchParams.store) {
        searchParams.store = Store.APPLE.value;
      }

      if (!searchParams.isGroupBySource) {
        searchParams.isGroupBySource = true;
      }

      return searchParams;
    })(),
    isHideEventsNames: getItem('isHideEventsNames', true),
    isHideEvents: getItem('isHideEvents', true),
  };
};

export default function aso(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_METRICS:
      const timeStampSaveMetrics = action.requestTimeEpoch;

      if (timeStampSaveMetrics > state.timeStampSaveMetrics &&
          action.stateId === state.stateId
      ) {
        const metrics = {};
        action.metrics
            .filter((metric) => {
              return metric.type !== 'installs';
            })
            .forEach((metric) => {
              if (!metrics[metric.type]) {
                metrics[metric.type] = [];
              }
              metrics[metric.type].push(metric);
            });
        return Object.assign({}, state, {
          metrics: processMetrics(
              metrics,
              state.searchParams.fromDate,
              state.searchParams.toDate,
          ),
          isLoadingMetrics: false,
          timeStampSaveMetrics: timeStampSaveMetrics,
        });
      }

    case SET_IS_LOADING_METRICS:
      return Object.assign({}, state, {
        isLoadingMetrics: action.isLoading,
      });

    case SAVE_ASA_SUBSCRIBE_METRICS:
      const timeStampSaveAsaSubscribeMetrics = action.requestTimeEpoch;

      if (timeStampSaveAsaSubscribeMetrics > state.timeStampSaveAsaSubscribeMetrics &&
          action.stateId === state.stateId
      ) {
        const asaSubscribeMetrics = {
          organicRevenue: [],
          revenueTotal: [],
          countOrganic: [],
          countTotal: [],
        };

        action.metrics.forEach((metric) => {
          asaSubscribeMetrics.organicRevenue.push({date: metric.date, value: metric.organic_revenue, source: 'none'});
          asaSubscribeMetrics.revenueTotal.push({date: metric.date, value: metric.revenue_total, source: 'none'});
          asaSubscribeMetrics.countOrganic.push({date: metric.date, value: metric.count_organic, source: 'none'});
          asaSubscribeMetrics.countTotal.push({date: metric.date, value: metric.count_total, source: 'none'});
        });

        return Object.assign({}, state, {
          asaSubscribeMetrics: processMetrics(
              asaSubscribeMetrics,
              state.searchParams.fromDate,
              state.searchParams.toDate,
          ),
          isLoadingAsaSubscribeMetrics: false,
          timeStampSaveAsaSubscribeMetrics,
        });
      }

    case SET_IS_LOADING_ASA_SUBSCRIBE_METRICS:
      return Object.assign({}, state, {
        isLoadingAsaSubscribeMetrics: action.isLoading,
      });

    case CHANGE_SEARCH_PARAMS_METRICS:
      const newSearchParams = Object.assign({}, state.searchParams, {
        [action.prop]: action.value,
      });
      const newEventsSearchParams = Object.assign({}, state.searchParamsEvents, {
        [action.prop]: action.value,
      });

      saveItem(newSearchParams, 'metricSearchParams');
      saveItem(newEventsSearchParams, 'metricEventsSearchParams');

      return Object.assign({}, state, {
        searchParams: newSearchParams,
        searchParamsEvents: newEventsSearchParams,
      });

    case CHANGE_SELECTED_METRICS_CHARTS: {
      const isLineActive = action.chartType === ChartType.LINE && action.isActive;
      const isAreaActive = action.chartType === ChartType.AREA && action.isActive;
      const isBarActive = !isAreaActive && !isLineActive;

      const newMetricSelectedChart = Object.assign({}, state.selectedChart, {
        [ChartType.LINE]: isLineActive,
        [ChartType.AREA]: isAreaActive,
        [ChartType.BARS]: isBarActive,
      });
      saveItem(newMetricSelectedChart, 'metricSelectedChart');

      return Object.assign({}, state, {
        selectedChart: newMetricSelectedChart,
      });
    }

    case CHANGE_FROM_TO_DATE_METRICS: {
      const newSearchParams = Object.assign({}, state.searchParams, {
        fromDate: action.fromDate,
        toDate: action.toDate,
      });
      const newEventsSearchParams = Object.assign({}, state.searchParamsEvents, {
        fromDate: action.fromDate,
        toDate: action.toDate,
      });

      saveItem(newSearchParams, 'metricSearchParams');
      saveItem(newEventsSearchParams, 'metricEventsSearchParams');

      return Object.assign({}, state, {
        searchParams: newSearchParams,
        searchParamsEvents: newEventsSearchParams,
      });
    }

    case SAVE_EVENTS: {
      return Object.assign({}, state, {
        events: action.events,
      });
    }

    case SAVE_EVENT: {
      let isExistEvent = false;
      let newEvents = state.events
          .filter((event) => {
            return event.id !== action.event.oldId;
          })
          .map((event) => {
            if (event.id === action.event.id) {
              return action.event;
              isExistEvent = true;
            }
            return event;
          });

      if (!isExistEvent) {
        newEvents.push(action.event);
      }
      newEvents = newEvents.filter((event) => {
        const app = state.searchParamsEvents.appIds.find((appId) => {
          return appId === event.appId;
        });
        const isApp = !event.appId || state.searchParamsEvents.appIds.length === 0 || app;
        return isApp;
      });

      return Object.assign({}, state, {
        events: newEvents,
      });
    }

    case DELETE_EVENT: {
      const filteredEvents = state.events
          .filter((event) => event.id !== action.eventId);

      return Object.assign({}, state, {
        events: filteredEvents,
      });
    }

    case CHANGE_IS_HIDE_EVENTS_NAMES: {
      saveItem(action.isHide, 'isHideEventsNames');

      return Object.assign({}, state, {
        isHideEventsNames: action.isHide,
      });
    }

    case CHANGE_IS_HIDE_EVENTS: {
      saveItem(action.isHide, 'isHideEvents');

      return Object.assign({}, state, {
        isHideEvents: action.isHide,
      });
    }

    default: return state;
  }
}

const processMetrics = (metricsObj, fromDate, toDate) => {
  const newMetricsObj = {};
  Object.keys(metricsObj).forEach((metricName) => {
    const metrics = metricsObj[metricName];
    const filledMetrics = {};
    let isNotEndDate = true;
    const date = momentZ(fromDate);
    while (isNotEndDate) {
      const dateString = date.format('YYYY-MM-DD');
      filledMetrics[dateString] = [];
      date.add(1, 'days');
      isNotEndDate = dateString !== toDate;
    }

    metrics.forEach((metric) => {
      metric.value = Number(metric.value);
      metric.x = new Date(metric.date);
      metric.y = (Number(metric.value));
      filledMetrics[metric.date].push(metric);
    });

    Object.keys(filledMetrics).forEach((date) => {
      if (filledMetrics[date].length === 0) {
        filledMetrics[date].push({value: 0, date: date, source: 'none', x: new Date(date), y: 0});
      }
    });

    let maxY = null;
    let maxX = null;
    let minY = null;
    let minX = null;
    let maxLengthDataSource = 0;
    const sourceTypes = {};
    let isLastEmpty = false;
    const allDates = {};

    newMetricsObj[metricName] = {};
    Object.keys(filledMetrics)
        .reverse()
        .forEach((date) => {
          const metricsByGroups = {};
          const valueSum = filledMetrics[date].reduce((previousValue, currentMetric) => {
            if (!sourceTypes[currentMetric['source']]) {
              if (currentMetric['source'] === 'none') {
                sourceTypes['none'] = {value: 'none', order: -1, color: '#599fe4'};
              } else {
                sourceTypes[currentMetric['source']] = {...SourceTypes[currentMetric['source']]};
              }
              sourceTypes[currentMetric['source']].data = [];
            }

            if (currentMetric.value !== 0 || isLastEmpty) {
              isLastEmpty = true;
              sourceTypes[currentMetric['source']].data.unshift(currentMetric);
              allDates[date] = momentZ(date).toDate();
            }
            return previousValue + currentMetric.value;
          }, 0);
          if (!maxY) {
            maxY = valueSum;
            maxX = date;
            minY = valueSum;
            minX = date;
          } else {
            maxY = valueSum > maxY ? valueSum : maxY;
            maxX = date > maxX ? date : maxX;

            minY = valueSum > minY ? minY : valueSum;
            minX = date > minX ? minX : date;
          }
          return metricsByGroups;
        });
    Object.values(sourceTypes).forEach((source) => {
      maxLengthDataSource = maxLengthDataSource < source.data.length ? source.data.length : maxLengthDataSource;
    });
    newMetricsObj[metricName].maxX = maxX;
    newMetricsObj[metricName].maxY = maxY;
    newMetricsObj[metricName].minY = minY;
    newMetricsObj[metricName].minX = minX;
    newMetricsObj[metricName].maxLengthDataSource = maxLengthDataSource;
    newMetricsObj[metricName].allDates = Object.values(allDates);
    newMetricsObj[metricName].sources = sourceTypes;
    newMetricsObj[metricName].middleX = Number(momentZ(minX).format('x')) +
        Number((momentZ(maxX).format('x') - momentZ(minX).format('x')) / 2);
  });
  return newMetricsObj;
};
