import {
  SAVE_ADMINS,
  SET_ERROR,
  SHOW_MODAL,
  SET_IS_LOADING_ADMINS,
  typeModal, SET_IS_LOADING_MARKETING_CONFIG,
} from '../actions/general';
import {SET_COPY_ASO_EXPERIMENT, SET_DELETE_ASO_EXPERIMENT, SET_EDIT_ASO_EXPERIMENT} from '../actions/aso';
import {SAVE_ONELIGHT_APPS, SAVE_OPENAPI, SET_IS_LOADING_OPENAPI} from '../actions/apps';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {SET_KEYWORD_TO_DELETE, SET_KEYWORD_TO_EDIT} from '../actions/keywords';
import {SET_EVENT_TO_DELETE, SET_EVENT_TO_EDIT} from '../actions/events';
import {SET_DELETE_PROMO_CODE} from '../actions/promo';
import {SET_DELETE_WHATS_NEW, SET_EDIT_WHATS_NEW} from '../actions/whatsNew';
import {SET_DELETE_CROSS_PROMO} from '../actions/crossPromo';

const getInintialState = () => ({
  isLogged: false,
  admin: undefined,
  modals: (initModalsState()),
  error: null,
  asoExperimentForDelete: null,
  asoExperimentForEdit: null,
  asoExperimentForCopy: null,
  onelightApps: getItem('onelightApps', []),
  keywordForEdit: null,
  keywordForDelete: null,
  eventForEdit: null,
  eventForDelete: null,
  promoCodeForDelete: null,
  crossPromoForDelete: null,
  whatsNewForDelete: null,
  whatsNewForEdit: null,
  admins: [],
  isLoadingAdmins: false,
  isLoadingOpenapi: false,
  openapi: '',
});

export default function appState(state = getInintialState(), action) {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [action.modalType]: {isShow: action.isShow, meta: action.meta},
        }),
      });

    case SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        modals: Object.assign({}, state.modals, {
          [typeModal.ERROR]: {isShow: Boolean(action.error), meta: {}},
        }),
      });

    case SET_DELETE_ASO_EXPERIMENT:
      return Object.assign({}, state, {
        asoExperimentForDelete: action.asoExperiment,
        modals: Object.assign({}, state.modals, {
          [typeModal.ASO_EXPERIMENT_DELETE]: {isShow: Boolean(action.asoExperiment), meta: {}},
        }),
      });

    case SET_EDIT_ASO_EXPERIMENT:
      return Object.assign({}, state, {
        asoExperimentForEdit: action.asoExperiment,
        modals: Object.assign({}, state.modals, {
          [typeModal.ASO_EXPERIMENT_EDIT]: {isShow: Boolean(action.asoExperiment), meta: {}},
        }),
      });

    case SAVE_ONELIGHT_APPS:
      saveItem(action.apps, 'onelightApps');
      return Object.assign({}, state, {
        onelightApps: action.apps,
      });

    case SET_COPY_ASO_EXPERIMENT:
      return Object.assign({}, state, {
        asoExperimentForCopy: action.asoExperiment,
        modals: Object.assign({}, state.modals, {
          [typeModal.ASO_EXPERIMENT_COPY]: {isShow: Boolean(action.asoExperiment), meta: {}},
        }),
      });

    case SET_KEYWORD_TO_EDIT:
      return Object.assign({}, state, {
        keywordForEdit: action.keyword,
        modals: Object.assign({}, state.modals, {
          [typeModal.KEYWORD_EDIT]: {isShow: Boolean(action.keyword), meta: {}},
        }),
      });

    case SET_KEYWORD_TO_DELETE:
      return Object.assign({}, state, {
        keywordForDelete: action.keyword,
        modals: Object.assign({}, state.modals, {
          [typeModal.KEYWORD_DELETE]: {isShow: Boolean(action.keyword), meta: {}},
        }),
      });

    case SAVE_ADMINS: {
      const existAdmin = getItem('admin', undefined);
      if (!existAdmin) {
        return state;
      }

      return Object.assign({}, state, {
        admins: action.admins,
        isLoadingAdmins: false,
      });
    }

    case SET_IS_LOADING_ADMINS:
      return Object.assign({}, state, {
        isLoadingAdmins: action.isLoading,
      });

    case SET_EVENT_TO_EDIT:
      return Object.assign({}, state, {
        eventForEdit: action.event,
        modals: Object.assign({}, state.modals, {
          [typeModal.EVENT_EDIT]: {isShow: Boolean(action.event), meta: {}},
        }),
      });

    case SET_EVENT_TO_DELETE:
      return Object.assign({}, state, {
        eventForDelete: action.event,
        modals: Object.assign({}, state.modals, {
          [typeModal.EVENT_DELETE]: {isShow: Boolean(action.event), meta: {}},
        }),
      });

    case SET_DELETE_PROMO_CODE:
      return Object.assign({}, state, {
        promoCodeForDelete: action.promoCode,
        modals: Object.assign({}, state.modals, {
          [typeModal.PROMO_DELETE]: {isShow: Boolean(action.promoCode), meta: {}},
        }),
      });

    case SET_DELETE_CROSS_PROMO:
      return Object.assign({}, state, {
        crossPromoForDelete: action.crossPromo,
        modals: Object.assign({}, state.modals, {
          [typeModal.CROSS_PROMO_DELETE]: {isShow: Boolean(action.crossPromo), meta: {}},
        }),
      });

    case SET_DELETE_WHATS_NEW:
      return Object.assign({}, state, {
        whatsNewForDelete: action.whatsNew,
        modals: Object.assign({}, state.modals, {
          [typeModal.WHATS_NEW_DELETE]: {isShow: Boolean(action.whatsNew), meta: {}},
        }),
      });

    case SET_EDIT_WHATS_NEW:
      return Object.assign({}, state, {
        whatsNewForEdit: action.whatsNew,
        modals: Object.assign({}, state.modals, {
          [typeModal.WHATS_NEW_EDIT]: {isShow: Boolean(action.whatsNew), meta: {}},
        }),
      });

    case SET_IS_LOADING_OPENAPI:
      return Object.assign({}, state, {
        isLoadingOpenapi: action.isLoading,
      });

    case SAVE_OPENAPI:
      return Object.assign({}, state, {
        openapi: action.openapi,
        isLoadingOpenapi: false,
      });

    default:
      return state;
  }
}

const initModalsState = () => {
  const modalState = {};
  Object.keys(typeModal).forEach((keyModal) => {
    modalState[keyModal] = {isShow: false, meta: {}};
  });

  return modalState;
};
