export const OnelightMediaType = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

export const KeywordsReportType = {
  POSITION_REPORT: 'POSITION_REPORT',
  INCENT_MONITOR: 'INCENT_MONITOR',
  COMPARATIVE_REPORT: 'COMPARATIVE_REPORT',
};

export const locales = [
  'en',
  'ar',
  'be',
  'zh-CN',
  'zh-TW',
  'cs',
  'nl',
  'fr',
  'fr-CA',
  'de',
  'it',
  'ja',
  'kk',
  'ms',
  'no',
  'pl',
  'pt',
  'pt-BR',
  'ru',
  'es',
  'es-MX',
  'el',
  'hu',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'id',
  'fil',
  'he',
  'hi'
];

