import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {showModal} from '../../actions/general';
import {getPromoCodes, setDeletePromoCode} from '../../actions/promo';
import {PromoList} from './PromoList';
import {PromoHeader} from './PromoHeader';


const Promo = ({
  getPromoCodes, isLoadingPromoCodes,
  promoCodes, showModal, setDeletePromoCode, onelightApps, admins,
}) => {
  useEffect(() => {
    getPromoCodes();
  }, []);

  if (isLoadingPromoCodes) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <div className={'aso-container'}>
      <PromoHeader
        showModal={showModal}
      />
      <div style={{minHeight: '0px', display: 'flex'}}>
        <PromoList
          admins={admins}
          promoCodes={promoCodes}
          onelightApps={onelightApps}
          setDeletePromoCode={setDeletePromoCode}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingPromoCodes: state.promo.isLoadingPromoCodes,
  promoCodes: state.promo.promoCodes,
  onelightApps: state.appState.onelightApps.filter((app) => app.store === 'apple'),
  admins: state.appState.admins,
});

const mapDispatchToProps = (dispatch) => ({
  getPromoCodes: bindActionCreators(getPromoCodes, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setDeletePromoCode: bindActionCreators(setDeletePromoCode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Promo);
