import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';

export const SAVE_USERS = 'SAVE_USERS';
export const SAVE_PURCHASES_EVENTS = 'SAVE_PURCHASES_EVENTS';
export const SET_IS_LOADING_USERS = 'SET_IS_LOADING_USERS';
export const SET_IS_LOADING_PURCHASES_EVENTS = 'SET_IS_LOADING_PURCHASES_EVENTS';

export const searchUsers = (deviceId) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_USERS,
    isLoading: true,
  });

  const stateId = getState().qa.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_USERS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      users: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/users/${deviceId}`, onload);

  xhr.send();
};

export const searchPurchasesEvents = (deviceId) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_PURCHASES_EVENTS,
    isLoading: true,
  });

  const stateId = getState().qa.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_PURCHASES_EVENTS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      purchasesEvents: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/metrics/purchase/events/${deviceId}`, onload);

  xhr.send();
};
