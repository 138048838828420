import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';

export const SET_IS_LOADING_APPS_WITH_RATINGS = 'SET_IS_LOADING_APPS_WITH_RATINGS';
export const SAVE_APPS_WITH_RATINGS = 'SAVE_APPS_WITH_RATINGS';

export const getAppsWithRatings = (
    fromDate = new Date(),
    toDate = new Date(),
) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_APPS_WITH_RATINGS,
    isLoading: true,
  });

  const stateId = getState().ratings.stateId;

  const fromDateString = momentZ(fromDate).subtract(3, 'days').utc().format('YYYY-MM-DD');
  const toDateString = momentZ(toDate).utc().format('YYYY-MM-DD');

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_APPS_WITH_RATINGS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      apps: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/metrics/apps-ratings?fromDate=${fromDateString}&toDate=${toDateString}`, onload);

  xhr.send();
};
