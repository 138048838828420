import React, {useState} from 'react';
import DropdownWrapper from './DropdownWrapper';
import {OnelightDatePicker} from './OnelightDatePicker';
import {ReactComponent as CalendarIcon} from '../../resources/imgs/calendar-icon.svg';
import momentZ from 'moment-timezone';

export const FromToDatePicker = ({fromDate, toDate, changeFromDate, changeToDate, changeLastDays, style, daysAgo=[0, 7, 30, 90], isReverseDates=false}) => {
  return <DropdownWrapper style={{transform: 'translate(-80%, 50px)'}}>
    <div className={'calendar-from-to-input'}>
      <div style={{marginLeft: '6px', marginRight: '8px'}}>
        {momentZ(isReverseDates ? toDate : fromDate).format('DD/MM/yyyy')}
      </div>
      -
      <div style={{marginLeft: '8px', marginRight: '13px'}}>
        {momentZ(isReverseDates ? fromDate : toDate).format('DD/MM/yyyy')}
      </div>
      <CalendarIcon/>
    </div>
    <FromToDatePickerBody
      fromDate={fromDate}
      toDate={toDate}
      changeFromDate={changeFromDate}
      changeToDate={changeToDate}
      changeLastDays={changeLastDays}
      style={style}
      daysAgo={daysAgo}
      isReverseDates={isReverseDates}
    />
  </DropdownWrapper>;
};

const FromToDatePickerBody = ({fromDate, toDate, changeLastDays, style, onClose, daysAgo, isReverseDates=false}) => {
  const [fromDateCurrent, setFromDateCurrent] = useState(fromDate);
  const [toDateCurrent, setToDateCurrent] = useState(toDate);

  const isActivePeriod = (days) => {
    return momentZ(fromDateCurrent).format('YYYY-MM-DD') === momentZ().subtract(days, 'day').format('YYYY-MM-DD');
  };

  const changeDateInterval = (daysAgo) => {
    setFromDateCurrent(momentZ().subtract(daysAgo, 'days'));
    setToDateCurrent(momentZ());
  };

  const renderFooter = () => {
    return <div style={{display: 'flex', width: '100%', margin: '20px 0px 20px 0px'}}>
      <div style={{display: 'flex', width: '344px', height: '26px', justifyContent: 'space-between', marginLeft: '20px'}}>
        {daysAgo.map((days) => {
          const label = days === 0 ? 'Today' : `Last ${days} days`;
          return <div key={days} className={'onelight-btn-primary' + (isActivePeriod(days) ? '' : '-unactive')} onClick={() => {
            changeDateInterval(days);
          }}>{label}</div>;
        })}
      </div>

      <div style={{display: 'flex', width: '270px', marginLeft: '50px', justifyContent: 'space-around'}}>
        <div onClick={() => {
          onClose();
        }} className={'onelight-second-btn'}>Cancel</div>
        <div onClick={() => {
          changeLastDays(
              momentZ(fromDateCurrent).format('YYYY-MM-DD'),
              momentZ(toDateCurrent).format('YYYY-MM-DD'),
          );
          onClose();
        }} className={'onelight-btn-apply'}>Apply</div>
      </div>
    </div>;
  };

  return <div style={style} className={'calendar-from-to'}>
    <div style={{display: 'flex', flexDirection: (isReverseDates ? 'row-reverse' : 'row')}}>
      <OnelightDatePicker
        date={fromDateCurrent}
        inline={true}
        shouldCloseOnSelect={false}
        onChange={(date) => setFromDateCurrent(date)}
        style={{filter: 'none'}}
      />
      <OnelightDatePicker
        date={toDateCurrent}
        inline={true}
        shouldCloseOnSelect={false}
        onChange={(date) => setToDateCurrent(date)}
        style={{filter: 'none'}}
      />
    </div>
    {
      renderFooter()
    }
  </div>;
};


