import {generalCountries} from '../models/territory';

export const removeDuplicates = (arr, key) => {
  const flag = {};
  const unique = [];

  arr.forEach((elem) => {
    if (!flag[elem[key]]) {
      flag[elem[key]] = true;
      unique.push(elem);
    }
  });
  return unique;
};

export function compareString(propName) {
  return (a, b) => {
    if ( a[propName] < b[propName] ) {
      return -1;
    }
    if ( a[propName] > b[propName] ) {
      return 1;
    }
    return 0;
  };
}

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

export const getEvery = (arr, each, propName) => {
  let eachSum = 0;
  const resultArr = [];
  arr.forEach((element) => {
    eachSum++;
    if (eachSum === each) {
      eachSum = 0;
      if (propName) {
        resultArr.push(element[propName]);
      } else {
        resultArr.push(element);
      }
    }
  });

  return resultArr;
};

export const countrySort = (countryA, countryB) => {
  const indexA = generalCountries.indexOf(countryA.iso2);
  const indexB = generalCountries.indexOf(countryB.iso2);
  if (indexA === -1 && indexB === -1) {
    return (countryA.country > countryB.country ? 1 : -1);
  }
  if (indexA === -1) {
    return 1;
  }

  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
};
