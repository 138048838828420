import React, {useEffect, useState} from 'react';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {useHistory} from 'react-router-dom';
import {ReactComponent as EditIcon} from '../../resources/imgs/edit-icon.svg';
import {ReactComponent as CopyIcon} from '../../resources/imgs/copy-icon.svg';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete-icon.svg';
import {AppBadge} from '../general/AppBadge';

export const AsoExperimentListItem = ({
  asoExperiment, setDeleteAsoExperiment, setEditAsoExperiment, setCopyAsoExperiment, admins, onelightApps,
}) => {
  const [app, setApp] = useState(null);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (onelightApps.length !== 0) {
      const experimentApp = onelightApps.find((app) => app.id === asoExperiment.appId);
      if (experimentApp) {
        setApp(experimentApp);
      }
    }
  }, [onelightApps, asoExperiment.appId]);

  useEffect(() => {
    if (admins.length !== 0) {
      const experimentAdmin = admins.find((admin) => admin.id === asoExperiment.creatorId);
      if (experimentAdmin) {
        setAdmin(experimentAdmin);
      }
    }
  }, [admins]);

  const renderApp = () => {
    if (!app) {
      return <div>{asoExperiment.appId}</div>;
    }

    return <AppBadge
      app={app}
      style={{paddingLeft: '6px'}}
    />;
  };

  const history = useHistory();
  const lastUpdateDate = toUserTimeZoneDate(asoExperiment.updatedAt);

  return <div className={'aso-experiment-list-item'} onClick={() => history.push(`aso/experiment/${asoExperiment.uuid}`)}>
    <div className={'aso-experiment-list-item-col'} style={{justifyContent: 'flex-start'}}>
      {renderApp()}
    </div>
    <div className={'aso-experiment-list-item-col'}>
      {asoExperiment.country ? asoExperiment.country.toUpperCase() === 'GB' ? 'UK' : asoExperiment.country.toUpperCase() : '-'}
    </div>
    <div className={'aso-experiment-list-item-col'}>
      {admin ? admin.nickname : '-'}
    </div>
    <div className={'aso-experiment-list-item-date-col'}>
      {lastUpdateDate ? lastUpdateDate.format('DD/MM/YYYY') : '-'}
      <div style={{paddingLeft: '10px'}}>
        {lastUpdateDate ? lastUpdateDate.format('HH:mm') : '-'}
      </div>
    </div>
    <div className={'aso-experiment-list-item-btns-col'}>
      <CopyIcon className={'aso-experiment-list-item-icon'}
        onClick={(e) => {
          e.stopPropagation();
          setCopyAsoExperiment(asoExperiment);
        }}
      />
      <EditIcon className={'aso-experiment-list-item-icon'}
        onClick={(e) => {
          e.stopPropagation();
          setEditAsoExperiment(asoExperiment);
        }}
      />
      <DeleteIcon
        style={{alignSelf: 'center'}}
        onClick={(e) => {
          e.stopPropagation();
          setDeleteAsoExperiment(asoExperiment);
        }}
      />
    </div>
  </div>;
};
