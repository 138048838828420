import React from 'react';
import {colors} from '../../../utils/StyleUtils';
import {Input} from '../../general/Input';
import {CopyToBufferButton} from '../../general/CopyToBufferButton';
import {capitalizeFirstLetter} from '../../../utils/StringUtils';
import {TextArea} from '../../general/TextArea';

export const FormListItem = ({form, changeFormInput}) => {
  const renderInputWithLimit = (value, placeholder, limit, name, type, width = '200px') => {
    return <div className={'aso-input-with-limit-container'} style={{paddingLeft: '11px', width}}>
      <div className={'aso-input-with-limit'} style={
        {
          border: `1px solid ${getColorByName(form.name)}`,
          width: '100%',
        }
      }>
        <TextArea
          value={value}
          style={{width: '100%', height: '34px', border: 'none', resize: 'none'}}
          handleChange={(value) => {
            changeFormInput(value, name, type);
          }}
          placeholder={placeholder}
        />
        <div className={'aso-input-limit'}>
          {`${limit - value.length}/${limit}`}
        </div>
      </div>

      <CopyToBufferButton
        style={{paddingLeft: '10px'}}
        value={value}
      />
    </div>;
  };

  const renderInput = (value, placeholder, className, name, type, width) => {
    return <div className={className} style={width}>
      <Input
        value={value}
        style={{width: '100%', height: '34px'}}
        handleChange={(value) => {
          changeFormInput(value, name, type);
        }}
        placeholder={placeholder}
      />
      <div className={'tooltiptext'} style={{maxWidth: '300px'}}>{value}</div>
    </div>;
  };

  return <div className={'aso-experiment-forms-list-item'}>
    <div className={'aso-experiment-forms-list-item-name'}>
      {capitalizeFirstLetter(form.name)}
    </div>
    {renderInputWithLimit(form.title, 'Title', 30, form.name, 'title', '20%')}
    {renderInputWithLimit(form.subtitle, 'Subtitle', 30, form.name, 'subtitle', '20%')}
    {renderInputWithLimit(form.keywords, 'Keywords', 100, form.name, 'keywords', '40%')}
    {renderInput(form.notes, 'Notes', 'aso-form-notes-container tooltip', form.name, 'notes')}
  </div>;
};

const getColorByName = (name) => {
  switch (name) {
    case 'main':
      return colors.asoKeywordsMain;

    case 'additional':
      return colors.asoKeywordsAdditional;

    case 'old':
      return colors.asoKeywordsOld;

    case 'sub_1':
      return colors.asoKeywordsSub1;

    case 'sub_2':
      return colors.asoKeywordsSub2;

    case 'sub_3':
      return colors.asoKeywordsSub3;

    default: return 'black';
  }
};
