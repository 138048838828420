import React from 'react';
import {connect} from 'react-redux';
import {DebugHeader} from './DebugHeader';
import {DebugUserInfoView} from './DebugUserInfoView';
import {DebugUserPurchasesView} from './DebugUserPurchasesView';
import {bindActionCreators} from 'redux';
import {searchPurchasesEvents, searchUsers} from '../../../actions/qa';

const QADebug = ({users, isLoadingUsers, purchasesEvents, isLoadingPurchasesEvents, searchUsers, searchPurchasesEvents}) => {
  return (
    <div className={'qa-container'}>
    <DebugHeader
      search={(deviceId) => {
        if(deviceId && deviceId.length !== 0) {
          searchUsers(deviceId);
          searchPurchasesEvents(deviceId);
        }
      }}
    />
    <DebugUserInfoView
      users={users}
      isLoadingUsers={isLoadingUsers}
    />
    <DebugUserPurchasesView
      purchasesEvents={purchasesEvents}
      isLoadingPurchasesEvents={isLoadingPurchasesEvents}
    />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.qa.users,
  isLoadingUsers: state.qa.isLoadingUsers,
  purchasesEvents: state.qa.purchasesEvents,
  isLoadingPurchasesEvents: state.qa.isLoadingPurchasesEvents,
});

const mapDispatchToProps = (dispatch) => ({
  searchUsers: bindActionCreators(searchUsers, dispatch),
  searchPurchasesEvents: bindActionCreators(searchPurchasesEvents, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QADebug);
