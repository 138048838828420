import React from 'react';

export const Rank = ({currentRank, prevRank, style={}, isNegative=false}) => {
  if ((!currentRank && !prevRank) || (!currentRank && prevRank)) {
    return <div className={'rank-container'} style={style}>-</div>;
  }

  if (!prevRank) {
    return <div className={'rank-container'} style={style}>{currentRank.rank}</div>;
  }

  const diffRank = isNegative ? prevRank.rank - currentRank.rank : currentRank.rank - prevRank.rank;
  const isUp = isNegative ? diffRank > 0 : diffRank <= 0;
  const zeroColor = diffRank === 0 ? 'gray' : undefined;
  const zeroColorStyle = {color: zeroColor, borderColor: zeroColor};

  const rankForRender = isNegative ? prevRank.rank : currentRank.rank;

  return <div className={'rank-container'} style={style}>
    <div>
      {rankForRender === 500 ? '-' : rankForRender}
    </div>
    <div className={'keyword-rank-position-diff-' + (isUp ? 'up' : 'down')} style={zeroColorStyle}>
      {diffRank < 0 ? diffRank * -1 : diffRank}
      <div className={'arrow-rank ' + (isUp ? 'up-rank' : 'down-rank')} style={zeroColorStyle}></div>
    </div>
  </div>;
};
