import React, {useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {AppBadge} from '../../general/AppBadge';

export const AsoExperimentHeader = ({asoExperiment, isSave, isChanged, setIsSave, onelightApps}) => {
  const [schedule, setSchedule] = useState(true);
  const [app, setApp] = useState(null);

  useEffect(() => {
    if (onelightApps.length !== 0) {
      const experimentApp = onelightApps.find((app) => app.id === asoExperiment.appId);
      if (experimentApp) {
        setApp(experimentApp);
      }
    }
  }, [onelightApps, asoExperiment.appId]);
  setTimeout(() => setSchedule(!schedule), 5000);

  const renderAppCountry = () => {
    return <div style={{display: 'flex', alignItems: 'center', fontSize: '16px'}}>
      <AppBadge
        app={app}
        style={{paddingLeft: '10px'}}
      />
      <div style={{marginLeft: '24px', fontWeight: 500}}>
        {asoExperiment.country.toUpperCase() === 'GB' ? 'UK' : asoExperiment.country.toUpperCase()}
      </div>
    </div>;
  };

  return <div className={'aso-experiment-header'}>
    <div className={'onelight-head-text'}>
      {asoExperiment.country && app ? renderAppCountry() : asoExperiment.name}
    </div>
    <div style={{width: '77px', paddingRight: '24px'}}>
      {
        isSave ?
            <div className='spinner-container'>
              <MDSpinner size={30} singleColor={'#00DADA'}/>
            </div> :
            <button
              className={'onelight-btn-apply'}
              disabled={!isChanged}
              onClick={() => setIsSave(true)}
              style={{width: '100%'}}
            >
              Save
            </button>
      }
    </div>
  </div>;
};
