import React from 'react';
import {Rank} from './Rank';
import {configs} from '../../resources/configs';
import {KeywordsReportType} from '../../models/onelight';

export const KeywordRankHistory = ({keywordRank, selectedReportType}) => {
  const periodsKeywordsHistory = selectedReportType === KeywordsReportType.POSITION_REPORT ? configs.periodsKeywordsPositionReportHistory : configs.periodsKeywordsIncentMonitorHistory;

  return <div className={'keyword-rank-history'}>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {periodsKeywordsHistory.map((period) => {
        return <Rank
          style={{flex: 1}}
          key={period -1}
          isNegative={true}
          prevRank={keywordRank.asoKeywordRanks[period - 1]}
          currentRank={keywordRank.asoKeywordRanks[0]}
        />;
      })}
    </div>
  </div>;
};
