import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Input extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputValue: this.props.value
        }
    }

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        if(nextProps.value !== this.props.value){
            this.setState({inputValue: nextProps.value})
        }
        return true
    }

    handleChange = debounce(
        this.props.debounce ? this.props.debounce : 200,
        () => {
            this.props.handleChange(this.state.inputValue)
        }
    )

    handleChangeInput = (value) => {
        const {excludeCharacters = []} = this.props
        excludeCharacters.forEach(excludeCharacter => {
            value = value.replace(excludeCharacter, '');
        })

        this.setState({inputValue: value}, () => this.handleChange())
    }

    render () {
        return (
            <input type={this.props.type ? this.props.type : "text"}
                   value={this.state.inputValue}
                   onChange={(event) => (
                       this.handleChangeInput(event.target.value)
                   )}
                   style={this.props.style}
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   className={this.props.className ? this.props.className :"onelight-input"}/>
        )
    }
}
