export const Store = {
  APPLE: {
    value: 'apple',
    label: 'App Store',
  },
  GOOGLE_PLAY: {
    value: 'google_play',
    label: 'Google Play',
  },
};
export const GoogleSourceTypes = {
  'Google Play search': {value: 'Google Play search', color: '#f7db47', order: 0},
  'Google Play explore': {value: 'Google Play explore', color: '#e47259', order: 1},
  'Third-party referrals': {value: 'Third-party referrals', color: '#59e49f', order: 2},
};

export const AppleSourceTypes = {
  'App Store Search': {value: 'App Store Search', color: '#59e49f', order: 0},
  'App Store Browse': {value: 'App Store Browse', color: '#e47259', order: 1},
  'App Referrer': {value: 'App Referrer', color: '#f7db47', order: 2},
  'Web Referrer': {value: 'Web Referrer', color: '#aeece2', order: 3},
  'Unavailable': {value: 'Unavailable', color: '#9859e4', order: 4},
  'ASA': {value: 'ASA', color: '#0240ff', order: 5},
};

export const SourceTypes = {...GoogleSourceTypes, ...AppleSourceTypes};
