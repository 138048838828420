import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import momentZ from 'moment-timezone';
import {AppSelector} from '../general/AppSelector';
import {Input} from '../general/Input';
import uuid from 'uuid/v4';
import {EditMedia} from '../general/EditMedia';
import {prepareFileToUpload} from '../../utils/FilesUtils';
import {saveWhatsNew} from '../../actions/whatsNew';
import {changeLocalization, getLocalizedValue} from '../../utils/LocalizationUtils';
import {TextArea} from '../general/TextArea';
import {Switcher} from '../general/Switcher';
import {colors} from '../../utils/StyleUtils';

const handleFileDrop = (file, action, actionUrl, setIsLoading) => {
  prepareFileToUpload(file, action, actionUrl, setIsLoading, uuid(), true, 405715200, false);
};

const DetailsWhatsNew = ({
  whatsNew, saveWhatsNew, deleteWhatsNew, onelightApps, cancel, mode,
}) => {
  const [version, setVersion] = useState(whatsNew.version);
  const [name, setName] = useState(whatsNew.name);
  const [description, setDescription] = useState(whatsNew.description);
  const [localization, setLocalization] = useState(whatsNew.localization);
  const [appId, setAppId] = useState(whatsNew.appId);
  const [mediaUrl, setMediaUrl] = useState(whatsNew.mediaUrl);
  const [isActiveTryNow, setIsActiveTryNow] = useState(whatsNew.isActiveTryNow);
  const [mediaTempPath, setMediaTempPath] = useState(null);
  const [mediaType, setMediaType] = useState(whatsNew.mediaType);
  const [isLoadingMedia, setIsLoadingMedia] = useState(false);

  const handleSave = () => {
    const whatsNewForAdd = {...whatsNew};
    whatsNewForAdd.appId = appId;
    whatsNewForAdd.version = version;
    whatsNewForAdd.name = name;
    whatsNewForAdd.localization = localization;
    whatsNewForAdd.description = description;
    whatsNewForAdd.mediaUrl = mediaUrl;
    whatsNewForAdd.isActiveTryNow = isActiveTryNow;
    whatsNewForAdd.mediaTempPath = mediaTempPath;
    whatsNewForAdd.mediaType = mediaType;

    const newId = `${appId}_${name}_${version}`;
    whatsNewForAdd.oldId = undefined;
    if (whatsNewForAdd.id && whatsNewForAdd.id !== newId) {
      whatsNewForAdd.oldId = whatsNewForAdd.id;
    }
    whatsNewForAdd.id = newId;
    whatsNewForAdd.updatedAt = momentZ();
    whatsNewForAdd.createdAt = whatsNewForAdd.createdAt ? whatsNewForAdd.createdAt : momentZ();

    saveWhatsNew(whatsNewForAdd);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        {mode === 'ADD' ? 'Add Version' : 'Update Version'}
      </div>
    </div>;
  };

  const renderDescription = (lang) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={lang + 'input'}>
      <div className={'onelight-second-text'}>
        {lang.toUpperCase()}
      </div>
      <TextArea
        value={getLocalizedValue(localization, 'description', lang)}
        style={{marginTop: '7px', resize: 'none'}}
        handleChange={(value) => {
          changeLocalization(value, 'description', localization, setDescription, setLocalization, lang);
        }}
      />
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          App
        </div>
        <AppSelector
          selectedApps={!appId ? [] : [appId]}
          style={{paddingTop: '7px', zIndex: 5000}}
          allApps={onelightApps}
          isMulti={false}
          changeApps={(appId) => {
            setAppId(appId);
          }}
        />
      </div>

      <div style={{display: 'flex', marginTop: '30px', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={'onelight-second-text'}>
            Media (optional)
          </div>
          <EditMedia mediaUrl={mediaUrl}
            style={{marginTop: '5px', height: '164px', width: '124px'}}
            heightMedia={'164px'}
            widthMedia={'124px'}
            mediaType={mediaType}
            onDrop={(file, mediaType) => {
              handleFileDrop(file, setMediaTempPath, setMediaUrl, setIsLoadingMedia);
              setMediaType(mediaType);
            }}
            isLoading={isLoadingMedia}
            clear={() => {
              setMediaTempPath(null);
              setMediaUrl(null);
            }}
          />
        </div>

        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '30px', width: '-webkit-fill-available',
        }}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className={'onelight-second-text'}>
              Version
            </div>
            <Input style={{marginTop: '7px', width: '47px'}}
              handleChange={setVersion}
              value={version}
            />
          </div>
          <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
            <div className={'onelight-second-text'}>
              Name
            </div>
            <Input style={{marginTop: '7px'}}
              handleChange={setName}
              value={name}
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center', paddingTop: '30px'}}>
            <Switcher
              isActive={isActiveTryNow}
              colorOn={colors.defSwitcherActive}
              colorOff={colors.defSwitcherUnActive}
              switch={(isActive) => {
                setIsActiveTryNow(isActive);
              }}
              id={'is-active-try-button'}
            />
            <div className={'switcher-label-text'} style={{fontSize: '14px', paddingLeft: '14px'}}>
              Try Now Button
            </div>
          </div>
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        {['en', 'de', 'ru'].map((locale) => {
          return renderDescription(locale);
        })}
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'} style={{justifyContent: 'space-between'}}>
      {
        mode === 'ADD' ? <button className={'onelight-second-btn'}
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </button> :
            <button className={'onelight-second-delete-btn'}
              onClick={() => {
                deleteWhatsNew();
              }}
            >
              Delete
            </button>
      }
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={handleSave}
        disabled={!appId || name === '' || version === ''}
      >
        {mode === 'ADD' ? 'Add' : 'Update'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({
  onelightApps: state.appState.onelightApps,
});

const mapDispatchToProps = (dispatch) => ({
  saveWhatsNew: bindActionCreators(saveWhatsNew, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsWhatsNew);
