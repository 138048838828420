import uuid from 'uuid/v4';
import {SAVE_APPS_WITH_RANKS, SET_IS_LOADING_APPS_WITH_RANKS} from '../actions/ranks';
import momentZ from 'moment-timezone';
import {AppGroupRelationType, REMOVE_APP_FROM_GROUP, SAVE_APP} from '../actions/apps';

const getInitialState = () => {
  return {
    stateId: uuid(),
    apps: {},
    groups: {},
    isLoadingApps: false,
    timeStampSaveApps: 0,
    timeStampSaveApp: 0,
    isLoadApps: true,
    maxRanksByCountries: {},
    lastUpdateAppsDate: null,
    countries: ['us', 'gb', 'ca', 'au', 'de', 'ru', 'ua', 'mx', 'es', 'pt', 'br', 'cn', 'it', 'fr'],
  };
};

const unwrapApp = (app, countries) => {
  const ranks = {};
  countries.map((country) => {
    ranks[country] = [];
  });
  app.ranks
      .sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        return 0;
      })
      .forEach((rank) => {
        if (rank && ranks[rank.country]) {
          const length = ranks[rank.country].length;
          const rankNew = {
            position: rank.pos,
            genreId: rank.genreId,
            delta: length === 0 ? 0 : ranks[rank.country][length - 1].position - rank.pos,
            updatedAt: rank.updatedAt,
          };
          ranks[rank.country].push(rankNew);
        }
      });

  return {
    id: app.id,
    name: app.name,
    icon: app.icon,
    vendorId: app.vendorId,
    ranks,
  };
};

const findMetaDataApps = (apps, countries, groups) => {
  const meta = {
    maxByGroups: {},
    lastUpdateDate: momentZ().subtract(7, 'days'),
  };

  Object.keys(apps).forEach((group) => {
    meta.maxByGroups[group] = {};

    countries.map((country) => {
      meta.maxByGroups[group][country] = 1000;
    });

    apps[group].forEach((app) => {
      Object.keys(app.ranks).forEach((country) => {
        const ranksByGenre = app.ranks[country].filter((rank) => {
          return rank.genreId === groups[group].genreId;
        });
        const lastRank = ranksByGenre[ranksByGenre.length -1];

        // LastUpdateDate
        if (lastRank && momentZ(lastRank.updatedAt).isAfter(meta.lastUpdateDate)) {
          meta.lastUpdateDate = momentZ(lastRank.updatedAt);
        }

        // MaxRankByGroup
        if (lastRank && meta.maxByGroups[group][country] > lastRank.position) {
          meta.maxByGroups[group][country] = lastRank.position;
        }
      });
    });
  });

  return meta;
};

const getRationSortApp = (ranks, genreId) => {
  const rations = {
    us: 0.33, uk: 0.04, ca: 0.04, au: 0.06,
    de: 0.06, ru: 0.07, ua: 0.01, mx: 0.02,
    es: 0.02, pt: 0.01, br: 0.06, cn: 0.01,
    it: 0.03, fr: 0.04, def: 0.08,
  };
  return Object.keys(ranks).reduce((sum, country) => {
    const ranksByCountry = ranks[country].filter((rank) => {
      return rank.genreId === genreId;
    });
    if (ranksByCountry.length === 0) {
      return sum + 0;
    }

    const ratio = rations[country.toLowerCase()] ? rations[country.toLowerCase()] : rations.def;
    return sum + ranksByCountry[ranksByCountry.length - 1].position * (1 - ratio);
  }, 0);
};

const sortApps = (apps, groups) => {
  Object.keys(apps).forEach((groupId) => {
    apps[groupId] = apps[groupId].sort((appA, appB) => {
      const sortValA = getRationSortApp(appA.ranks, groups[groupId].genreId);
      const sortValB = getRationSortApp(appB.ranks, groups[groupId].genreId);

      if (sortValA < sortValB) {
        return -1;
      }
      if (sortValA > sortValB) {
        return 1;
      }

      return 0;
    });
  });
};

export default function ranks(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_APPS_WITH_RANKS:
      const timeStampSaveApps = action.requestTimeEpoch;
      const stateIdRequestedSaveApps = action.stateId;

      if (
        timeStampSaveApps > state.timeStampSaveApps &&
                stateIdRequestedSaveApps === state.stateId
      ) {
        const apps = {};
        const groups = {};
        action.apps
            .forEach((app) => {
              const unwrappedApp = unwrapApp(app, state.countries);
              app.appToGroups.forEach((appToGroup) => {
                groups[appToGroup.groupId] = appToGroup.group;
                if (!apps[appToGroup.groupId]) {
                  apps[appToGroup.groupId] = [];
                }
                apps[appToGroup.groupId].push(unwrappedApp);
              });
            });
        sortApps(apps, groups);
        const metaApps = findMetaDataApps(apps, state.countries, groups);

        return Object.assign({}, state, {
          apps: apps,
          groups: groups,
          isLoadingApps: false,
          timeStampSaveApps: timeStampSaveApps,
          maxRanksByCountries: metaApps.maxByGroups,
          lastUpdateAppsDate: metaApps.lastUpdateDate,
          isLoadApps: false,
        });
      }

    case SAVE_APP:
      const timeStampSaveApp = action.requestTimeEpoch;

      if (action.appGroupRelationType === AppGroupRelationType.RANKS &&
                timeStampSaveApp > state.timeStampSaveApp &&
                action.stateId === state.stateId
      ) {
        const apps = {...state.apps};
        const groups = {...state.groups};

        const unwrappedApp = unwrapApp(action.app, state.countries);

        action.app.appToGroups.forEach((appToGroup) => {
          if (!apps[appToGroup.groupId]) {
            apps[appToGroup.groupId] = [];
          }
          groups[appToGroup.groupId] = appToGroup.group;

          let isExistApp = false;
          apps[appToGroup.groupId] = apps[appToGroup.groupId].map((appFromState) => {
            if (appFromState.name === unwrappedApp.name) {
              isExistApp = true;
              return unwrappedApp;
            } else {
              return appFromState;
            }
          });
          if (!isExistApp) {
            apps[appToGroup.groupId].push(unwrappedApp);
          }
        });

        sortApps(apps, groups);
        const metaApps = findMetaDataApps(apps, state.countries, groups);

        return Object.assign({}, state, {
          apps: apps,
          groups: groups,
          isLoadingApps: false,
          timeStampSaveApp: timeStampSaveApp,
          isLoadApps: false,
          lastUpdateAppsDate: metaApps.lastUpdateDate,
          maxRanksByCountries: metaApps.maxByGroups,
        });
      }

    case REMOVE_APP_FROM_GROUP: {
      const apps = {...state.apps};
      const groups = {...state.groups};

      Object.keys(apps).forEach((group) => {
        if (group === String(action.groupId)) {
          apps[group] = apps[group].filter((app) => app.id !== action.appId);
          if (apps[group].length === 0) {
            delete apps[group];
            delete groups[group];
          }
        }
      });

      sortApps(apps, groups);
      const metaApps = findMetaDataApps(apps, state.countries, groups);

      return Object.assign({}, state, {
        apps: apps,
        maxRanksByCountries: metaApps.maxByGroups,
      });

      break;
    }

    case SET_IS_LOADING_APPS_WITH_RANKS:
      return Object.assign({}, state, {
        isLoadingApps: action.isLoading,
      });

    default: return state;
  }
}
