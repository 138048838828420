import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';
export const SET_IS_LOADING_METRICS = 'SET_IS_LOADING_APPLE_METRICS';
export const SET_IS_LOADING_ASA_SUBSCRIBE_METRICS = 'SET_IS_LOADING_ASA_SUBSCRIBE_METRICS';
export const SAVE_METRICS = 'SAVE_APPLE_METRICS';
export const SAVE_ASA_SUBSCRIBE_METRICS = 'SAVE_ASA_SUBSCRIBE_METRICS';
export const CHANGE_SEARCH_PARAMS_METRICS = 'CHANGE_SEARCH_PARAMS_METRICS';
export const CHANGE_SELECTED_METRICS_CHARTS = 'CHANGE_SELECTED_METRICS_CHARTS';
export const CHANGE_FROM_TO_DATE_METRICS = 'CHANGE_FROM_TO_DATE_METRICS';
export const CHANGE_IS_HIDE_EVENTS_NAMES = 'CHANGE_IS_HIDE_EVENTS_NAMES';
export const CHANGE_IS_HIDE_EVENTS = 'CHANGE_IS_HIDE_EVENTS';

export const getMetrics = (searchParams) => (dispatch, getState) => {
  const searchParamsCopy = JSON.parse(JSON.stringify(searchParams));
  dispatch({
    type: SET_IS_LOADING_METRICS,
    isLoading: true,
  });

  const stateId = getState().metrics.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_METRICS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      metrics: response,
    });
  };
  const xhr = createRequest(dispatch, 'POST', `/metrics/search`, onload);
  searchParamsCopy.isWithASA = false;

  if (searchParams.appleSourceTypes && searchParamsCopy.appleSourceTypes.indexOf('ASA') !== -1) {
    searchParamsCopy.appleSourceTypes = searchParamsCopy.appleSourceTypes.filter((type) => type !== 'ASA');
    searchParamsCopy.isWithASA = true;
  }

  xhr.send(JSON.stringify(searchParamsCopy));
};

export const getAsaSubscribeMetrics = (searchParams) => (dispatch, getState) => {
  const searchParamsCopy = JSON.parse(JSON.stringify(searchParams));
  dispatch({
    type: SET_IS_LOADING_ASA_SUBSCRIBE_METRICS,
    isLoading: true,
  });

  const stateId = getState().metrics.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_ASA_SUBSCRIBE_METRICS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      metrics: response,
    });
  };

  searchParamsCopy.isWithASA = false;
  const xhr = createRequest(dispatch, 'POST', `/metrics/asa/subscribe/search`, onload);
  if (searchParams.appleSourceTypes && searchParamsCopy.appleSourceTypes.indexOf('ASA') !== -1) {
    searchParamsCopy.appleSourceTypes = searchParamsCopy.appleSourceTypes.filter((type) => type !== 'ASA');
  }
  xhr.send(JSON.stringify(searchParamsCopy));
};

export const changeSearchParams = (value, prop) => (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PARAMS_METRICS,
    value,
    prop,
  });
};

export const changeFromToDate = (fromDate, toDate) => (dispatch) => {
  dispatch({
    type: CHANGE_FROM_TO_DATE_METRICS,
    fromDate,
    toDate,
  });
};

export const changeSelectedMetricsCharts = (chartType, isActive) => (dispatch) => {
  dispatch({
    type: CHANGE_SELECTED_METRICS_CHARTS,
    chartType,
    isActive,
  });
};

export const setIsHideEventsNames = (isHide) => (dispatch) => {
  dispatch({
    type: CHANGE_IS_HIDE_EVENTS_NAMES,
    isHide,
  });
};

export const setIsHideEvents = (isHide) => (dispatch) => {
  dispatch({
    type: CHANGE_IS_HIDE_EVENTS,
    isHide,
  });
};


