import React from 'react';
import momentZ from 'moment-timezone';
import {addCommas} from '../../utils/StringUtils';
import {Store} from '../../models/store';

export const AsoReportRow = ({asoReportDay, asoReportDataStats, isAfter, isRenderSeparator, store}) => {
  const getColor = (propName) => {
    if (!asoReportDataStats || !asoReportDataStats[propName]) {
      return 'white';
    }
    return (isAfter ? asoReportDataStats[propName].colorsByDayAfter[asoReportDay.date] : asoReportDataStats[propName].colorsByDayBefore[asoReportDay.date]);
  };

  const valueContainerStyle = (propName) => ({
    backgroundColor: getColor(propName),
    width: '100%',
    height: '35px',
    margin: '0px 2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  return <div className={'aso-report-row'} style={{borderTop: (isRenderSeparator ? 'black solid 1px' : undefined)}}>
    <div className={'aso-report-date'}>
      {asoReportDay.date}
    </div>
    <div className={'aso-report-day'}>
      {momentZ(asoReportDay.date).format('ddd')}
    </div>
    {store === Store.APPLE.value ? <div className={'aso-report-impressions'}>
      <div style={valueContainerStyle('impressions')}>
        {addCommas(asoReportDay.impressions)}
      </div>
    </div> : null}
    <div className={'aso-report-pageviews'}>
      <div style={valueContainerStyle('pageViews')}>
        {addCommas(asoReportDay.pageViews)}
      </div>
    </div>
    <div className={'aso-report-appunits'}>
      <div style={valueContainerStyle('appUnits')}>
        {addCommas(asoReportDay.appUnits)}
      </div>
    </div>
    <div className={'aso-report-ratio'}
    >
      <div style={valueContainerStyle('ratio')}>
        {asoReportDay.ratio.toFixed(2) + '%'}
      </div>
    </div>
  </div>;
};
