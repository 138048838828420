import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {isActivePath} from './Head';

export const SelectPathNav = ({isActive, className, label='', paths=[], navs = [], isLocal = false}) => {
  const [isActiveLocal, setIsActiveLocal] = useState(isActive);

  const renderLocalNavs = () => {
    return  navs.map(nav => {
      return <NavLink
        isActive={isActivePath(nav.isActive)}
        to={nav.to}  className="head-item-link"
        activeClassName={"head-item-link-active"}
        key={nav.name}
      >
        {nav.icon}
        <div>
          {nav.name}
        </div>
      </NavLink>
      })
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: 'min-content'}}>
      <div className={className} onClick={() => {
        setIsActiveLocal(!isActiveLocal);
      }}>
        {label}
      </div>
          <div className={'onelight-transition '} style={{flexDirection: 'column', paddingLeft: '10px', maxHeight: (isActiveLocal ? '100%' : '0px'), display: (isActiveLocal ? 'flex' : 'none')}}>
            {
              isLocal ? renderLocalNavs() :
              paths.map((path) => {
                return <div className={className} style={{paddingBottom: '10px', fontSize: '14px'}} onClick={() => {
                  window.open(path.url);
                }}>
                  {path.name}
                </div>
              })
            }
          </div>
    </div>
  );
};
