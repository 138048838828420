import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {Region} from '../../models/territory';
import {selectStyles} from '../../utils/StyleUtils';

const animatedComponents = makeAnimated();

export const RegionSelector = ({changeRegions, selectedRegions, isDisabled=false, isMulti=true, style}) => {
  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        if (isMulti) {
          changeRegions(!selectedOption ? [] : selectedOption.map((option) => option.value));
        } else {
          changeRegions(selectedOption.value);
        }
      }}
      isMulti={isMulti}
      value={selectedRegions.map((region) => {
        return {value: region, label: region};
      })}
      isDisabled={isDisabled}
      components={animatedComponents}
      styles={selectStyles()}
      name="regions"
      options={Object.keys(Region).map((region) => {
        return {value: region, label: region};
      })}
      placeholder={'Region'}
      className="basic-multi-select select-label"
      classNamePrefix="select"
    />
  </div>;
};
