import React from 'react';
import {Switcher} from '../../general/Switcher';
import {colors, phrasesColored} from '../../../utils/StyleUtils';
import {range} from '../../../utils/ArrayUtils';

export const KeywordsListItem = ({
  keyword, word, checkKeyword, phrases, ranks, positions, formsWords,
  wordsMain = [], wordsAdditional = [], wordsOLd = [], countActiveSubForms,
  wordsSub1 = [], wordsSub2 = [], wordsSub3 = [], allPhrases,
}) => {
  let color = wordsSub3.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsSub3 : undefined;
  color = wordsSub2.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsSub2 : color;
  color = wordsSub1.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsSub1 : color;
  color = wordsOLd.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsOld : color;
  color = wordsAdditional.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsFind : color;
  color = wordsMain.indexOf(word.toLowerCase()) !== -1 ? colors.asoKeywordsMain : color;


  return <div className={'keyword'}>
    <div className={'keyword-list-value-checkbox'}>
      <Switcher
        isActive={keyword.isChecked1}
        colorOn={colors.asoKeywordsMain}
        colorOff={colors.asoKeywordsMainSecond}
        switch={(isActive) => {
          checkKeyword(isActive, word, 'isChecked1');
        }}
        id={'main_' + word}
      />
    </div>
    <div className={'keyword-list-value-checkbox'}>
      <Switcher
        isActive={keyword.isChecked2}
        colorOn={colors.asoKeywordsAdditional}
        colorOff={colors.asoKeywordsAdditionalSecond}
        switch={(isActive) => {
          checkKeyword(isActive, word, 'isChecked2');
        }}
        id={'additional_' + word}
      />
    </div>
    <div className={'keyword-list-value-checkbox'}>
      <Switcher
        isActive={keyword.isChecked3}
        colorOn={colors.asoKeywordsOld}
        colorOff={colors.asoKeywordsOldSecond}
        switch={(isActive) => {
          checkKeyword(isActive, word, 'isChecked3');
        }}
        id={'old_' + word}
      />
    </div>

    {
      countActiveSubForms ? range(countActiveSubForms, 1).map((subIndex) => {
        return <div className={'keyword-list-value-checkbox'}>
          <Switcher
            isActive={keyword['isChecked' + (subIndex + 3)]}
            colorOn={colors['asoKeywordsSub' + subIndex]}
            colorOff={colors['asoKeywordsSub' + subIndex + 'Second']}
            switch={(isActive) => {
              checkKeyword(isActive, word, 'isChecked' + (subIndex + 3));
            }}
            id={'sub_' + subIndex + word}
          />
        </div>;
      }) : null
    }

    <div className={'keyword-list-value-word tooltip'}
      style={{color}}
    >
      {word}
      <div className={'tooltiptext'}>
        <div style={{whiteSpace: 'pre-wrap', position: 'absolute', zIndex: '2', width: '600px'}}
          dangerouslySetInnerHTML={{__html: phrasesColored(
              phrases.reduce((accumulator, currentValue) => {
                return accumulator + currentValue + '\n';
              }, ''),
              [],
              formsWords,
          )}}
        >
        </div>
        <div style={{width: '600px'}}>
          {
            phrases
                .map( (phrase) => {
                  const index = allPhrases.indexOf(phrase);
                  const rank = ranks[index] ? 'Rank: ' + ranks[index] : '';
                  const position = positions[index] ? 'P: ' + positions[index] : '';

                  return <div key={phrase}>
                    <span style={{opacity: '0'}}>{phrase}</span>
                    {' | ' + rank + ' ' + position}
                  </div>;
                })
          }
        </div>
      </div>
    </div>

    <div className={'keyword-list-value-count'}>
      {keyword.count}
    </div>

    <div className={'keyword-list-value-length'}>
      {keyword.length + 1}
    </div>
  </div>;
};
