import React from 'react';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import {selectStyles} from '../../utils/StyleUtils';

const animatedComponents = makeAnimated();

export const SingleSelector = ({change, selectedValue, selectedLabel, isDisabled=false, style, options, placeholder = '', control, isClearable = false}) => {
  return <div style={style}>
    <CreatableSelect
      isClearable={isClearable}
      onChange={(selectedOption) => {
        change(selectedOption.value);
      }}

      onCreateOption={(value) => {
        change(value);
      }}

      isMulti={false}
      value={{value: selectedValue, label: selectedLabel ? selectedLabel : selectedValue}}
      isDisabled={isDisabled}
      components={animatedComponents}
      styles={selectStyles({control})}
      name="onelight_selector"
      options={options}
      placeholder={placeholder}
      className="select-label"
      classNamePrefix="select"
    />
  </div>;
};
