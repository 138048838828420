import momentZ from 'moment-timezone';
import {createRequest} from '../utils/HttpRequestUtills';

export const SET_IS_LOADING_WHATS_NEW = 'SET_IS_LOADING_WHATS_NEW';
export const SAVE_WHATS_NEW = 'SAVE_WHATS_NEW';
export const ADD_WHATS_NEW = 'ADD_WHATS_NEW';
export const DELETE_WHATS_NEW = 'DELETE_WHATS_NEW';
export const SET_DELETE_WHATS_NEW = 'SET_DELETE_WHATS_NEW';
export const SET_EDIT_WHATS_NEW = 'SET_EDIT_WHATS_NEW';

export const getWhatsNew = () => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_WHATS_NEW,
    isLoading: true,
  });

  const stateId = getState().whatsNew.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_WHATS_NEW,
      requestTimeEpoch: timeStampRequest,
      stateId,
      whatsNew: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/whats-new`, onload);

  xhr.send();
};

export const saveWhatsNew = (whatsNew) => (dispatch) => {
  dispatch({
    type: ADD_WHATS_NEW,
    whatsNew,
  });
  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'POST', `/whats-new`, onload);

  xhr.send(JSON.stringify(whatsNew));
};

export const deleteWhatsNew = (id) => (dispatch) => {
  dispatch({
    type: DELETE_WHATS_NEW,
    id,
  });

  const onload = (responseText) => {};
  const xhr = createRequest(dispatch, 'DELETE', `/whats-new/${id}`, onload);

  xhr.send();
};

export const setDeleteWhatsNew = (whatsNew) => (dispatch) => {
  dispatch({
    type: SET_DELETE_WHATS_NEW,
    whatsNew,
  });
};

export const setWhatsNewForEdit = (whatsNew) => (dispatch) => {
  dispatch({
    type: SET_EDIT_WHATS_NEW,
    whatsNew,
  });
};
