
export const Region = {
  'China, Singapore': 'China, Singapore',
  'UK, Ireland': 'UK, Ireland',
  'Germany, Austria': 'Germany, Austria',
  'Arabic world': 'Arabic world',
  'Russia, Ukraine': 'Russia, Ukraine',
  'Australia, New Zealand': 'Australia, New Zealand',
  'Hong Kong, Taiwan, Macau': 'Hong Kong, Taiwan, Macau',
  'Hispanic America': 'Hispanic America',
  'Netherlands, Suriname': 'Netherlands, Suriname',
  'Croatia, Bosnia and Herzegovina, Montenegro, Serbia': 'Croatia, Bosnia and Herzegovina, Montenegro, Serbia',
};

export const countriesByRegion = {
  [Region['China, Singapore']]: ['CN', 'SG'],
  [Region['UK, Ireland']]: ['GB', 'IE'],
  [Region['Germany, Austria']]: ['DE', 'AT'],
  [Region['Arabic world']]: ['DZ', 'BH', 'EG', 'JA', 'KW', 'LB', 'MR', 'OM', 'QA', 'SA', 'TH', 'AE', 'YEe'],
  [Region['Russia, Ukraine']]: ['RU', 'UA'],
  [Region['Australia, New Zealand']]: ['AU', 'NZ'],
  [Region['Hong Kong, Taiwan, Macau']]: ['HK', 'TW', 'MC'],
  [Region['Hispanic America']]: ['AR', 'BZ', 'BO', 'CL', 'CO', 'CR', 'DO', 'EC', 'SV', 'GT', 'HN', 'MX', 'NI', 'PA', 'PY', 'PE', 'UY', 'VE'],
  [Region['Netherlands, Suriname']]: ['NL', 'SR'],
  [Region['Croatia, Bosnia and Herzegovina, Montenegro, Serbia']]: ['HR', 'BA', 'ME', 'RS'],
};

export const getCountryFlagImgUrl = (country) => {
  return require(`../resources/imgs/flags/h40/${country.toLowerCase()}.png`).default;
};

export const generalCountries = ['US', 'GB', 'CA', 'AU', 'DE', 'FR', 'IT', 'ES', 'PT', 'RU', 'UA', 'MX', 'BR'];
