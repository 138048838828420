import React from 'react';
import {addCommas} from '../../utils/StringUtils';
import {Store} from '../../models/store';

export const AsoReportStats = ({asoReportDataStats, store}) => {
  const renderRow = (period, title, prop, colorProp, pref = '', isBold = false) => {
    if (Object.keys(asoReportDataStats).length === 0) {
      return null;
    }

    const valueContainerStyle = (color) => ({
      backgroundColor: color,
      width: '100%',
      height: '35px',
      margin: '0px 2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    });

    return <div className={'aso-report-row'} key={title}>
      <div className={'aso-report-date'} style={{fontWeight: (isBold ? 500 : undefined)}}>{period}</div>
      <div className={'aso-report-day'} style={{fontWeight: (isBold ? 500 : undefined)}}>{title}</div>
      {
        store === Store.APPLE.value ? <div className={'aso-report-impressions'}>
          <div style={valueContainerStyle(asoReportDataStats.impressions[colorProp])}>
            {addCommas(asoReportDataStats.impressions[prop] + pref)}
          </div>
        </div> : null
      }

      <div className={'aso-report-pageviews'}>
        <div style={valueContainerStyle(asoReportDataStats.pageViews[colorProp])}>
          {addCommas(asoReportDataStats.pageViews[prop] + pref)}
        </div>
      </div>
      <div className={'aso-report-appunits'}>
        <div style={valueContainerStyle(asoReportDataStats.appUnits[colorProp])}>
          {addCommas(asoReportDataStats.appUnits[prop] + pref)}
        </div>
      </div>
      <div className={'aso-report-ratio'}>
        <div style={valueContainerStyle(asoReportDataStats.ratio[colorProp])}>
          {Number(asoReportDataStats.ratio[prop]).toFixed(2) + '%'}
        </div>
      </div>
    </div>;
  };
  return <div style={{display: 'flex', flexDirection: 'column', borderTop: ('black solid 2px')}}>
    {
      [
        renderRow(asoReportDataStats.meta.beforePeriod, 'Before', 'sumBefore', 'sumBeforeColor'),
        renderRow(asoReportDataStats.meta.afterPeriod, 'After', 'sumAfter', 'sumAfterColor', '', true),
        renderRow('', 'Change', 'diff', 'diffColor'),
        renderRow('', 'Change %', 'diffPercent', 'diffColor', '%'),
      ]
    }
  </div>;
};
