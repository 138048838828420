import React, {useEffect, useState} from 'react';
import {VictoryPie, VictorySharedEvents, VictoryTooltip} from 'victory';
import sizeMe from 'react-sizeme';
import {countryColor} from '../../utils/StyleUtils';
import {addCommas} from '../../utils/StringUtils';

const HeadPie = ({
  title,
}) => {
  return <div style={{alignSelf: 'center'}} className={'aso-report-charts-pie-head-title'}>{title}</div>;
};

const LabelPie = ({label}) => {
  if (label.title === '') {
    return null;
  }
  return <div className={'chart-pie-country-label'} style={{paddingBottom: '30px'}}>
    <div className={'aso-report-charts-pie-title'}>{addCommas(label.title)}</div>
    <div className={'chart-pie-country-label-region-text'} style={{paddingTop: '3px'}}>
      {label.country === 'GB' ? 'UK' : label.country}
    </div>
  </div>;
};

LabelPie.defaultEvents = VictoryTooltip.defaultEvents;

const AsoReportPieCharts = ({
  asoReportDataStatsByRegion, className, propName, regions, style, title, countRegions,
}) => {
  const [labelAfter, setLabelAfter] = useState({title: '', country: 'worldwide'});
  const [labelBefore, setLabelBefore] = useState({title: '', country: 'worldwide'});

  useEffect(() => {
    if ( asoReportDataStatsByRegion['worldwide'] && countRegions === (Object.keys(asoReportDataStatsByRegion).length - 1)) {
      setTimeout(() => {
        const labelAfter = {title: asoReportDataStatsByRegion['worldwide'][propName]['sumAfter'], country: 'worldwide'};
        const labelBefore = {title: asoReportDataStatsByRegion['worldwide'][propName]['sumBefore'], country: 'worldwide'};
        setLabelAfter(labelAfter);
        setLabelBefore(labelBefore);
      }, 0);
    }
  }, [asoReportDataStatsByRegion]);

  const getData = (prefix) => {
    let allSum = 0;
    const data = regions.map((region) => {
      let sum = asoReportDataStatsByRegion[region] ? asoReportDataStatsByRegion[region][propName][prefix] : 0;
      sum = sum ? sum : 0;
      allSum += sum;
      return {
        x: region, y: sum, prefix, color: countryColor[region],
      };
    });

    if (asoReportDataStatsByRegion['worldwide']) {
      data.push({
        x: 'other', y: asoReportDataStatsByRegion['worldwide'][propName][prefix] - allSum, prefix, color: countryColor['other'],
      });
    }

    return data;
  };

  const renderPie = (prefix, title) => {
    return <g style={{display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', width: '180px'}} key={prefix}>
      <VictoryPie
        standalone={true}
        name={prefix}
        padding={10}
        innerRadius={130}
        data={getData(prefix)}
        labels={({datum}) => ``}
        animate={{
          duration: 500,
        }}
        style={{
          data: {
            fill: (d) => {
              return d.datum.color;
            },
          }}}
      />
      <LabelPie
        label={prefix === 'sumAfter' ? labelAfter : labelBefore}
      />
      <HeadPie title={title} className={'aso-report-charts-pie'}/>
    </g>;
  };

  return <div className={className} style={style}>
    <div className={'aso-report-charts-pie'}>
      {title}
    </div>
    <VictorySharedEvents
      events={[{
        childName: ['sumAfter', 'sumBefore'],
        target: 'data',
        eventHandlers: {
          onMouseOver: () => {
            return [{
              childName: ['sumAfter', 'sumBefore'],
              mutation: (props) => {
                if (props.datum) {
                  const label = {title: props.datum.y, country: props.datum.x};
                  props.datum.prefix === 'sumAfter' ? setLabelAfter(label) : setLabelBefore(label);
                  return {
                    style: Object.assign({}, props.style, {fill: props.datum.color}),
                    innerRadius: 120,
                    radius: 200,
                  };
                } else return null;
              },
            }];
          },
          onMouseOut: () => {
            return [{
              childName: ['sumAfter', 'sumBefore'],
              mutation: (props) => {
                if (props.datum && asoReportDataStatsByRegion['worldwide']) {
                  const label = {title: asoReportDataStatsByRegion['worldwide'][propName][props.datum.prefix], country: 'worldwide'};
                  props.datum.prefix === 'sumAfter' ? setLabelAfter(label) : setLabelBefore(label);
                }
                return null;
              },
            }];
          },
        },
      }]}
    >
      <g style={{display: 'flex', justifyContent: 'space-around'}}>
        {[
          renderPie('sumBefore', 'Before'),
          renderPie('sumAfter', 'After'),
        ]}
      </g>

    </VictorySharedEvents>
  </div>;
};

export default (sizeMe({monitorHeight: true, monitorWidth: true}))(AsoReportPieCharts);
