import uuid from 'uuid/v4';
import momentZ from 'moment-timezone';
import {
  SAVE_KEYWORDS_RANKS,
  SET_IS_LOADING_KEYWORDS_RANKS,
  UPSERT_KEYWORD_RANKS,
  SET_IS_LOADING_ADD_KEYWORD,
  SELECT_KEYWORD_APP,
  SET_IS_DETAILS_KEYWORDS,
  CHANGE_PROP_KEYWORD,
  SET_KEYWORD_IDS_FOR_SAVE,
  SET_IS_SAVE_KEYWORDS,
  SELECT_KEYWORDS_REPORT_TYPE,
  DELETE_KEYWORD,
  SET_IS_HIDE_RANKS,
  SET_ORDER_KEYWORDS,
  SET_IS_LOADING_REMOTE_KEYWORDS_RANKS,
  SAVE_REMOTE_KEYWORDS_RANKS,
  ADD_KEYWORD_FOR_DELETE,
  CLEAR_KEYWORDS_FOR_DELETE,
  DELETE_KEYWORDS,
  REMOVE_KEYWORD_FOR_DELETE,
  CHANGE_COMPARE_PARAMS,
  SET_IS_SHOW_CHANGES_ONLY, SET_LOADED_KEYWORDS_COUNTS,
} from '../actions/keywords';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {KeywordsReportType} from '../models/onelight';
import React from 'react';
import {compareString} from '../utils/ArrayUtils';

const getInitialState = () => {
  return {
    stateId: uuid(),
    keywordsRanks: [],
    keywordsRanksRemote: [],
    mergedKeywords: [],
    keywordsRanksByCountry: {},
    isLoadingKeywordsRanks: false,
    isLoadingKeywordsRanksRemote: false,
    isLoadingAddKeywordCountry: {},
    timeStampSaveKeywordsRanks: 0,
    timeStampSaveRemoteKeywordsRanks: 0,
    isDetailsKeywords: false,
    selectedApp: getItem('keywordsSelectedApp', null),
    keywordsForSave: {},
    isSaveKeywords: false,
    selectedReportType: KeywordsReportType.POSITION_REPORT,
    isHideKeywords: true,
    isShowChangesOnly: false,
    orderKeywordsCountries: {},
    emptyDates: [],
    lastUpdateDate: null,
    keywordsIdsForDelete: [],
    loadedKeywordsParams: {
      loaded: 0,
      total: 0,
    },
    compareParams: (()=> {
      return {
        firstDate: momentZ().subtract(1, 'days').format('YYYY-MM-DD'),
        secondDate: momentZ().subtract(30, 'days').format('YYYY-MM-DD'),
      };
    })(),
  };
};

export default function keywordsRanks(state = getInitialState(), action) {
  switch (action.type) {
    case SET_LOADED_KEYWORDS_COUNTS:
      return Object.assign({}, state, {
        loadedKeywordsParams: {
          loaded: action.loaded,
          total: action.total,
        },
      });
    case SAVE_KEYWORDS_RANKS: {
      const timeStamp = action.requestTimeEpoch;
      const stateId = action.stateId;

      if (timeStamp > state.timeStampSaveKeywordsRanks && stateId === state.stateId) {
        const mergedKeywords = mergeKeywords(action.keywordsRanks, state.keywordsRanksRemote);
        return Object.assign({}, state, {
          keywordsRanks: action.keywordsRanks,
          mergedKeywords: mergedKeywords,
          keywordsRanksByCountry: compareKeywordsByCountry(
              groupKeywordsByCountry(mergedKeywords, state.selectedReportType, state.orderKeywordsCountries),
              state.compareParams,
              state.isShowChangesOnly,
              state.selectedReportType,
          ),
          isLoadingKeywordsRanks: false,
          timeStampSaveKeywordsRanks: timeStamp,
          lastUpdateDate: mergedKeywords.reduce((date, rank) => {
            if (!rank.asoKeywordRanks || !rank.asoKeywordRanks.length) {
              return date;
            }
            const rankDate = momentZ(rank.asoKeywordRanks[rank.asoKeywordRanks.length - 1].updatedAt);
            return rankDate.isBefore(date) ? rankDate : date;
          }, momentZ().add(2, 'day')),
        });
      } else {
        return state;
      }
      break;
    }

    case SAVE_REMOTE_KEYWORDS_RANKS: {
      const timeStamp = action.timeStampRequest;
      const stateId = action.stateId;

      if (timeStamp > state.timeStampSaveRemoteKeywordsRanks && stateId === state.stateId) {
        const mergedKeywords = mergeKeywords(state.keywordsRanks, action.keywordsRanksRemote);

        return Object.assign({}, state, {
          emptyDates: findEmptyDates(action.keywordsRanksRemote),
          keywordsRanksRemote: action.keywordsRanksRemote,
          mergedKeywords: mergedKeywords,
          keywordsRanksByCountry: compareKeywordsByCountry(
              groupKeywordsByCountry(mergedKeywords, state.selectedReportType, state.orderKeywordsCountries),
              state.compareParams,
              state.isShowChangesOnly,
              state.selectedReportType,
          ),
          isLoadingKeywordsRanksRemote: false,
          timeStampSaveRemoteKeywordsRanks: timeStamp,
          lastUpdateDate: mergedKeywords.reduce((date, rank) => {
            if (!rank.asoKeywordRanks || !rank.asoKeywordRanks.length) {
              return date;
            }
            const rankDate = momentZ(rank.asoKeywordRanks[0].updatedAt.replace('Z', ''));
            return rankDate.isBefore(date) ? rankDate : date;
          }, momentZ()),
        });
      } else {
        return state;
      }
      break;
    }

    case UPSERT_KEYWORD_RANKS: {
      const stateId = action.stateId;

      if (stateId === state.stateId) {
        let isFind = false;
        const newKeywordsRanks = state.keywordsRanks.map((keywordRanks) => {
          if (keywordRanks.id === action.keywordRanks.id) {
            isFind = true;
            return action.keywordRanks;
          }
          return keywordRanks;
        });
        if (!isFind) {
          newKeywordsRanks.push(action.keywordRanks);
        }

        return Object.assign({}, state, {
          keywordsRanks: newKeywordsRanks,
          keywordsRanksByCountry: compareKeywordsByCountry(
              groupKeywordsByCountry(newKeywordsRanks, state.selectedReportType, state.orderKeywordsCountries),
              state.compareParams,
              state.isShowChangesOnly,
              state.selectedReportType,
          ),
        });
      } else {
        return state;
      }
      break;
    }

    case SET_IS_LOADING_ADD_KEYWORD:
      return Object.assign({}, state, {
        isLoadingAddKeywordCountry: Object.assign({}, state.isLoadingAddKeywordCountry, {
          [action.country]: action.isLoading,
        }),
        keywordsRanksByCountry: state.keywordsRanksByCountry[action.country] ? state.keywordsRanksByCountry :
            Object.assign({}, state.keywordsRanksByCountry, {
              [action.country]: [],
            }),
      });

    case SELECT_KEYWORD_APP:
      saveItem(action.app, 'keywordsSelectedApp');
      return Object.assign({}, state, {
        selectedApp: action.app,
      });

    case SET_IS_LOADING_KEYWORDS_RANKS:
      return Object.assign({}, state, {
        isLoadingKeywordsRanks: action.isLoading,
      });

    case SET_IS_LOADING_REMOTE_KEYWORDS_RANKS:
      return Object.assign({}, state, {
        isLoadingKeywordsRanksRemote: action.isLoading,
      });

    case SET_IS_DETAILS_KEYWORDS:
      return Object.assign({}, state, {
        isDetailsKeywords: action.isDetailsKeywords,
      });

    case CHANGE_COMPARE_PARAMS: {
      const newCompareParams = {...state.compareParams, ...action.params};
      return Object.assign({}, state, {
        compareParams: newCompareParams,
        keywordsRanksByCountry: compareKeywordsByCountry(
            state.keywordsRanksByCountry,
            newCompareParams,
            state.isShowChangesOnly,
            state.selectedReportType,
        ),
      });
    }

    case CHANGE_PROP_KEYWORD: {
      let country;
      const newKeywords = state.keywordsRanks.map((keyword) => {
        if (keyword.id === action.keywordId) {
          const newKeyword = {...keyword};
          newKeyword[action.prop] = action.value;
          country = newKeyword.country;
          return newKeyword;
        } else {
          return keyword;
        }
      });

      const newKeywordsRanksByCountry = Object.assign({}, state.keywordsRanksByCountry, {
        [country]: state.keywordsRanksByCountry[country].map((keyword) => {
          if (keyword.id === action.keywordId) {
            const newKeyword = {...keyword};
            newKeyword[action.prop] = action.value;
            return newKeyword;
          } else {
            return keyword;
          }
        }),
      });

      return Object.assign({}, state, {
        keywordsRanksByCountry: newKeywordsRanksByCountry,
        keywordsRanks: newKeywords,
      });
    }

    case SET_KEYWORD_IDS_FOR_SAVE: {
      const newKeywordsForSave = action.keywordIds.reduce(function(result, item) {
        result[item] = action.isSave;
        return result;
      }, {});
      return Object.assign({}, state, {
        keywordsForSave: {...state.keywordsForSave, ...newKeywordsForSave},
      });
    }

    case SET_IS_SAVE_KEYWORDS: {
      return Object.assign({}, state, {
        isSaveKeywords: action.isSave,
      });
    }

    case SELECT_KEYWORDS_REPORT_TYPE: {
      return Object.assign({}, state, {
        selectedReportType: action.reportType,
        keywordsRanksByCountry: compareKeywordsByCountry(
            groupKeywordsByCountry(state.keywordsRanks, action.reportType, state.orderKeywordsCountries),
            state.compareParams,
            state.isShowChangesOnly,
            action.reportType,
        ),
      });
    }

    case DELETE_KEYWORD: {
      const filteredKeywords = state.keywordsRanks.filter((keyword) => keyword.id !== action.keywordId);
      return Object.assign({}, state, {
        keywordsRanks: filteredKeywords,
        keywordsRanksByCountry: compareKeywordsByCountry(
            groupKeywordsByCountry(filteredKeywords, state.selectedReportType, state.orderKeywordsCountries),
            state.compareParams,
            state.isShowChangesOnly,
            state.selectedReportType,
        ),
      });
    }

    case ADD_KEYWORD_FOR_DELETE: {
      return Object.assign({}, state, {
        keywordsIdsForDelete: [...state.keywordsIdsForDelete, ...[action.id]],
      });
    }

    case REMOVE_KEYWORD_FOR_DELETE: {
      return Object.assign({}, state, {
        keywordsIdsForDelete: state.keywordsIdsForDelete.filter((id) => id !== action.id),
      });
    }

    case CLEAR_KEYWORDS_FOR_DELETE: {
      return Object.assign({}, state, {
        keywordsIdsForDelete: [],
      });
    }

    case DELETE_KEYWORDS: {
      const filteredKeywords = state.keywordsRanks.filter((keyword) => state.keywordsIdsForDelete.indexOf(keyword.id) === -1);
      return Object.assign({}, state, {
        keywordsIdsForDelete: [],
        keywordsRanks: filteredKeywords,
        keywordsRanksByCountry: compareKeywordsByCountry(
            groupKeywordsByCountry(filteredKeywords, state.selectedReportType, state.orderKeywordsCountries),
            state.compareParams,
            state.isShowChangesOnly,
            state.selectedReportType,
        ),
      });
    }

    case SET_IS_HIDE_RANKS: {
      return Object.assign({}, state, {
        isHideKeywords: action.isHide,
      });
    }

    case SET_IS_SHOW_CHANGES_ONLY: {
      return Object.assign({}, state, {
        isShowChangesOnly: action.isShow,
        keywordsRanksByCountry: compareKeywordsByCountry(
            groupKeywordsByCountry(state.mergedKeywords, state.selectedReportType, state.orderKeywordsCountries),
            state.compareParams,
            action.isShow,
            state.selectedReportType,
        ),
      });
    }

    case SET_ORDER_KEYWORDS: {
      const newOrderCountries = {...state.orderKeywordsCountries};
      if (!newOrderCountries[action.country]) {
        newOrderCountries[action.country] = {};
      }
      newOrderCountries[action.country].orderBy = action.orderBy;
      newOrderCountries[action.country].isASC = action.isASC;

      return Object.assign({}, state, {
        orderKeywordsCountries: newOrderCountries,
        keywordsRanksByCountry: compareKeywordsByCountry(
            groupKeywordsByCountry(state.keywordsRanks, state.selectedReportType, newOrderCountries),
            state.compareParams,
            state.isShowChangesOnly,
            state.selectedReportType,
        ),
      });
    }

    default: return state;
  }
}

const groupKeywordsByCountry = (keywordsRanks, reportType, orderKeywordsCountries) => {
  const groupedByCountries = {};
  keywordsRanks
      .filter((keywordRank) => {
        if (keywordRank.isHide) {
          return false;
        }
        if (reportType === KeywordsReportType.POSITION_REPORT && keywordRank.isPositionsReport) {
          return true;
        }
        if (reportType === KeywordsReportType.INCENT_MONITOR && keywordRank.isIncentMonitor) {
          return true;
        }
        if (reportType === KeywordsReportType.COMPARATIVE_REPORT && (keywordRank.isIncentMonitor || keywordRank.isPositionsReport)) {
          return true;
        }

        return false;
      })
      .forEach((keywordRank) => {
        if (!groupedByCountries[keywordRank.country]) {
          groupedByCountries[keywordRank.country] = [];
        }
        groupedByCountries[keywordRank.country].push(keywordRank);
      });

  Object.keys(groupedByCountries).forEach((country) => {
    if (orderKeywordsCountries[country]) {
      const orderBy = orderKeywordsCountries[country].orderBy;
      const isASC = orderKeywordsCountries[country].isASC;
      groupedByCountries[country] = groupedByCountries[country].sort((keywordA, keywordB) => {
        let valueA;
        let valueB;
        if (orderBy === 'keywords') {
          valueA = keywordA.keyword;
          valueB = keywordB.keyword;
        }
        if (orderBy === 'ranks') {
          valueA = keywordA.asoKeywordRanks[0] ? keywordA.asoKeywordRanks[0].rank : 1000;
          valueB = keywordB.asoKeywordRanks[0] ? keywordB.asoKeywordRanks[0].rank : 1000;
        }
        if (orderBy === 'popularity') {
          valueA = keywordA.asoKeywordRanks[0] ? keywordA.asoKeywordRanks[0].popularity : -1000;
          valueB = keywordB.asoKeywordRanks[0] ? keywordB.asoKeywordRanks[0].popularity : -1000;
        }

        if (!valueA && !valueB) {
          return 0;
        }
        if (!valueA) {
          return isASC ? 1 : -1;
        }
        if (!valueB) {
          return isASC ? -1 : 1;
        }

        if (valueA < valueB) {
          return isASC ? 1 : -1;
        }
        if (valueA > valueB) {
          return isASC ? -1 : 1;
        }

        return 0;
      });
    }
  });

  return groupedByCountries;
};

const compareKeywordsByCountry = (keywordsByCountry, compareParams, isShowChangesOnly, reportType) => {
  const newKeywordsByCountry = JSON.parse(JSON.stringify(keywordsByCountry));
  newKeywordsByCountry['all'] = {};

  Object.keys(keywordsByCountry).forEach((country) => {
    if (country !== 'all') {
      newKeywordsByCountry[country] = newKeywordsByCountry[country].map((keyword) => {
        let firstRankCompare = null;
        let secondRankCompare = null;
        if (keyword.asoKeywordRanks) {
          keyword.asoKeywordRanks.forEach((rank) => {
            if (rank.date === compareParams.firstDate && rank.rank !== 500) {
              firstRankCompare = rank;
            }
            if (rank.date === compareParams.secondDate && rank.rank !== 500) {
              secondRankCompare = rank;
            }
          });
        }

        if (isShowChangesOnly) {
          const firstRankChanges = reportType === KeywordsReportType.COMPARATIVE_REPORT ? firstRankCompare : keyword.asoKeywordRanks[0];
          const secondRankChanges = reportType === KeywordsReportType.COMPARATIVE_REPORT ? secondRankCompare : keyword.asoKeywordRanks[1];

          if (!firstRankChanges || !secondRankChanges || firstRankChanges.rank === secondRankChanges.rank) {
            return null;
          }
        }

        keyword.firstRankCompare = firstRankCompare;
        keyword.secondRankCompare = secondRankCompare;
        keyword.compareRankDiff = 0;
        keyword.compareRankPercent = 0;
        keyword.compareSapDiff = 0;
        keyword.compareSapPercent = 0;

        if (firstRankCompare && secondRankCompare) {
          if (firstRankCompare.rank && secondRankCompare.rank) {
            keyword.compareRankDiff = secondRankCompare.rank - firstRankCompare.rank;
            keyword.compareRankPercent = (secondRankCompare.rank / 100) * keyword.compareRankDiff;
          }
          if (firstRankCompare.popularity && secondRankCompare.popularity) {
            keyword.compareSapDiff = secondRankCompare.popularity - firstRankCompare.popularity;
            keyword.compareSapPercent = (secondRankCompare.popularity / 100) * keyword.compareSapDiff * -1;
          }
        }

        return keyword;
      })
          .filter((keyword) => {
            return keyword;
          });
      newKeywordsByCountry.all[country] = newKeywordsByCountry[country].reduce((res, currentKeyword, index) => {
        if (currentKeyword.compareRankPercent && currentKeyword.compareRankPercent !== 0) {
          res.sumRankPercent += currentKeyword.compareRankPercent;
          res.avgRankPercent = res.sumRankPercent / (index + 1);
        }

        if (currentKeyword.compareSapPercent && currentKeyword.compareSapPercent !== 0) {
          res.sumSapPercent += currentKeyword.compareSapPercent;
          res.avgSapPercent = res.sumSapPercent / (index + 1);
        }
        return res;
      }, {avgRankPercent: 0, sumRankPercent: 0, avgSapPercent: 0, sumSapPercent: 0});
    }
  });

  return newKeywordsByCountry;
};

const mergeKeywords = (keywords, keywordsWithKeywords) => {
  return keywords.map((keyword) => {
    const keywordWithRanks = keywordsWithKeywords.find((keywordWithRanks) => {
      return keywordWithRanks.id === keyword.id;
    });

    if (keywordWithRanks) {
      keyword.asoKeywordRanks = keywordWithRanks.asoKeywordRanks;
    }
    return keyword;
  });
};

const findEmptyDates = (keywords) => {
  const emptyDates = {};
  const allDates = {};
  keywords.forEach((keyword) => {
    if (keyword.asoKeywordRanks) {
      keyword.asoKeywordRanks.forEach((rank) => {
        if(rank.popularity) {
          allDates[rank.date] = rank.date;
        }
      });
    }
  });

  Object.keys(allDates)
      .sort((dateA, dateB) => {
        return compareString('date')({date: dateA}, {date: dateB});
      })
      .forEach((date, index, array) => {
        try {
          const expectedDate = momentZ(date).add(1, 'day').format('YYYY-MM-DD')
          const nextDate = array[index + 1];
          if (nextDate && expectedDate !== nextDate) {
            emptyDates[expectedDate] = expectedDate;
          }
        } catch {}
      })

  return Object.keys(emptyDates);
};
