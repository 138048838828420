import uuid from 'uuid/v4';
import {ADD_WHATS_NEW, DELETE_WHATS_NEW, SAVE_WHATS_NEW, SET_IS_LOADING_WHATS_NEW} from '../actions/whatsNew';

const getInitialState = () => {
  return {
    stateId: uuid(),
    whatsNew: [],
    selectedWhatsNew: null,
    isLoadingWhatsNew: false,
    timeStampSaveWhatsNew: 0,
  };
};

export default function whatsNew(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_WHATS_NEW:
      const timeStampSaveWhatsNew = action.requestTimeEpoch;
      const stateIdSaveWhatsNew = action.stateId;

      if (
        timeStampSaveWhatsNew > state.timeStampSaveWhatsNew &&
          stateIdSaveWhatsNew === state.stateId
      ) {
        return Object.assign({}, state, {
          whatsNew: action.whatsNew,
          timeStampSaveWhatsNew: timeStampSaveWhatsNew,
          isLoadingWhatsNew: false,
        });
      }

    case ADD_WHATS_NEW: {
      let whatsNew = [...state.whatsNew];
      whatsNew = whatsNew
          .filter((whatsNew) => {
            return whatsNew.id !== action.whatsNew.id;
          });

      whatsNew.unshift(action.whatsNew);

      if (action.whatsNew.oldId) {
        whatsNew = whatsNew.filter((whatsNew) => whatsNew.id !== action.whatsNew.oldId);
      }

      return Object.assign({}, state, {
        whatsNew,
      });
    }

    case DELETE_WHATS_NEW: {
      const whatsNew = state.whatsNew.filter((whatsNew) => {
        return whatsNew.id !== action.id;
      });

      return Object.assign({}, state, {
        whatsNew,
        selectedWhatsNew: state.selectedWhatsNew &&
                state.selectedWhatsNew.id === action.id ? null : state.selectedWhatsNew,
      });
    }

    case SET_IS_LOADING_WHATS_NEW:
      return Object.assign({}, state, {
        isLoadingWhatsNew: action.isLoading,
      });

    default: return state;
  }
}
