import React from 'react';

export const Popularity = ({currentRank, prevRank, style={}, isNegative=false}) => {
  if ((!currentRank && !prevRank) || (!currentRank && prevRank)) {
    return <div className={'rank-container'} style={style}>-</div>;
  }

  if (!prevRank) {
    return <div className={'rank-container'} style={style}>{currentRank.popularity}</div>;
  }

  if ((!currentRank.popularity && !prevRank.popularity) || (!currentRank.popularity && prevRank.popularity)) {
    return <div className={'rank-container'} style={style}>-</div>;
  }

  if (!prevRank.popularity) {
    return <div className={'rank-container'} style={style}>{currentRank.popularity}</div>;
  }
  const diffRank = isNegative ? prevRank.popularity - currentRank.popularity : currentRank.popularity - prevRank.popularity;
  const isUp = isNegative ? diffRank <= 0 : diffRank > 0;

  const zeroColor = diffRank === 0 ? 'gray' : undefined;
  const zeroColorStyle = {color: zeroColor, borderColor: zeroColor};

  return <div className={'rank-container'} style={style}>
    <div>
      {currentRank.popularity}
    </div>
    <div className={'keyword-rank-position-diff-' + (isUp ? 'up' : 'down')} style={zeroColorStyle}>
      {diffRank < 0 ? diffRank * -1 : diffRank}
      <div className={'arrow-rank ' + (isUp ? 'up-rank' : 'down-rank')} style={zeroColorStyle}></div>
    </div>
  </div>;
};
