import React from 'react';
import Select, {components} from 'react-select';
import {selectStyles} from '../../utils/StyleUtils';
const {Option, SingleValue} = components;

const iconStyle = {minWidth: '8px', minHeight: '8px', borderRadius: '50%'};

const IconSingleValue = (props) => {
  return <SingleValue {...props}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div
        style={{...iconStyle, ...{backgroundColor: props.data.color}}}
      />
      <div className={'select-app-label'}>
        {props.data.label}
      </div>
    </div>
  </SingleValue>;
};

const IconOption = (props) => (
  <Option {...props}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div
        style={{...iconStyle, ...{backgroundColor: props.data.color}}}
      />
      <div className={'select-app-label'}>
        {props.data.label}
      </div>
    </div>
  </Option>
);

export const EventTypeSelector = ({selectedEvents, changeTypes, isDisabled=false, style}) => {
  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        changeTypes(selectedOption);
      }}
      isMulti={false}
      value={selectedEvents.map((source) => {
        return {value: source.type, label: source.name, color: source.color};
      })}
      isDisabled={isDisabled}
      components={{Option: IconOption, SingleValue: IconSingleValue}}
      styles={selectStyles()}
      name="source types"
      options={Object.values(eventTypes)}
      placeholder={'Select type'}
      className="basic-multi-select select-label"
      classNamePrefix="select"
    />
  </div>;
};


export const eventTypesEnum = {
  ASO_NOTES: 'ASO_NOTES',
  IOS_RELEASE: 'IOS_RELEASE',
  ANDROID_RELEASE: 'ANDROID_RELEASE',
  APP_RELEASE: 'APP_RELEASE',
  OTHER: 'OTHER',
};

export const eventTypes = {
  [eventTypesEnum.APP_RELEASE]: {value: eventTypesEnum.APP_RELEASE, label: 'App release', color: '#FF3232'},
  [eventTypesEnum.IOS_RELEASE]: {value: eventTypesEnum.IOS_RELEASE, label: 'iOS release', color: '#000000'},
  [eventTypesEnum.ANDROID_RELEASE]: {value: eventTypesEnum.ANDROID_RELEASE, label: 'Android release', color: '#32D74B'},
  [eventTypesEnum.ASO_NOTES]: {value: eventTypesEnum.ASO_NOTES, label: 'Aso notes', color: '#A323DC'},
  [eventTypesEnum.OTHER]: {value: eventTypesEnum.OTHER, label: 'Other', color: '#00CCCC'},
};
