import React, {useEffect, useState} from 'react';
import {useDebouncedEffect} from '../../utils/UseEffects';

export const Checkbox = ({isActive, isDisabled, style, text='', switchCheckbox}) => {
  const [isActiveLocal, setIsActiveLocal] = useState(isActive);
  useEffect(() => {
    if (isActiveLocal !== isActive) {
      setIsActiveLocal(isActive);
    }
  }, [isActive]);

  useDebouncedEffect(() => {
    if (isActiveLocal !== isActive) {
      switchCheckbox(isActiveLocal);
    }
  }, [{isActiveLocal}], 200);

  return (
    <div className={'checkbox'} style={style}>
      <input type="checkbox"
        disabled={isDisabled}
        defaultChecked={isActiveLocal}
        checked={isActiveLocal}
        onChange={() => {
          setIsActiveLocal(!isActiveLocal);
        }}
      />
      {
          text !== '' ? <label style={{color: isActive ? '#212529' : '#7F8489'}}>
            {text}
          </label> : null
      }
    </div>
  );
};
