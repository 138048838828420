import React from 'react';
import {RanksTableHeader} from './RanksTableHeader';
import {Rank} from './Rank';
import {MetaType, typeModal} from '../../actions/general';
import {AppGroupRelationType} from '../../actions/apps';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';

export const RanksBody = ({apps, countries, showModal, deleteApp, group,
  maxRanksByCountries, isNewGroup, totalGroups,
}) => {
  return (
    <div className={'ranks-body'}>

      {apps.length !== 0 ? <RanksTableHeader countries={countries} group={group}/> : null}
      <div className={'rank-list'}>
        {
          apps.map((app) => {
            return <Rank
              key={app.id}
              app={app}
              deleteApp={deleteApp}
              maxRanksByCountries={maxRanksByCountries}
              countries={countries}
              groupId={group.id}
              store={group.store}
              genreId={group.genreId}
            />;
          })
        }
      </div>
      <div className={'add-app-container'} style={{padding: '13px 0px  13px 148px'}} onClick={() => {
        showModal(typeModal.APP_ADD, true, {
          type: MetaType.WithGroup,
          isNewGroup,
          group,
          groupOrderId: totalGroups + 1,
          appGroupRelationType: AppGroupRelationType.RANKS,
        });
      }
      }>
        <div>
              Add App
        </div>
        <PlusIcon/>
      </div>
    </div>
  );
};
