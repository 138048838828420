import React, {useState} from 'react';
import {AppSelector} from '../general/AppSelector';
import {invalidateOnelightResCloudFront, saveMarketingConfigToS3} from '../../actions/general';
import MDSpinner from 'react-md-spinner';
import {configs} from '../../resources/configs';

export const CrossPromoHeader = ({onelightApps, selectedApp, selectApp, marketingConfig}) => {
  const [isSaving, setIsSaving] = useState(false);
  return <div className={'cross-promo-head-panel'}>
    <AppSelector
      selectedApps={!selectedApp || !selectedApp.id ? [] : [selectedApp.id]}
      style={{width: '390px'}}
      allApps={onelightApps}
      isMulti={false}
      changeApps={(appId) => {
        selectApp(onelightApps.find((app) => app.id === appId));
      }}
      placeholder={'Filter by All Apps'}
    />
    {
          isSaving ? <MDSpinner size={30} singleColor={'#00DADA'}/> :
              <div className={'publish-btn'} onClick={() => {
                setIsSaving(true);
                const path = `marketing-configs`;
                const preFileName = `${configs.marketingConfigVersion}_${selectedApp.vendorId}_marketing_config`;
                const fileName = `${preFileName}.json`;
                saveMarketingConfigToS3(marketingConfig, fileName, path, () => {
                  invalidateOnelightResCloudFront(`/${path}*`, () => {
                    setIsSaving(false);
                  });
                })
              }}>
                  Save
              </div>
    }
  </div>;
};
