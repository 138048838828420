import React, {useEffect, useState} from 'react';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {AppBadge} from '../general/AppBadge';

export const WhatsNewListItem = ({
  whatsNew, admins, onelightApps, setWhatsNewForEdit,
}) => {
  const [app, setApp] = useState(null);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (onelightApps.length !== 0) {
      const appWhatsNew = onelightApps.find((app) => app.id === whatsNew.appId);
      if (appWhatsNew) {
        setApp(appWhatsNew);
      }
    }
  }, [onelightApps, whatsNew.appId]);

  useEffect(() => {
    if (admins.length !== 0) {
      const adminWhatsNew = admins.find((admin) => admin.id === whatsNew.creatorId);
      if (admin) {
        setAdmin(adminWhatsNew);
      }
    }
  }, [admins, whatsNew.creatorId]);

  const renderApp = () => {
    if (!app) {
      return <div>{whatsNew.appIdentifier}</div>;
    }

    return <AppBadge
      app={app}
      style={{paddingLeft: '6px'}}
      isIconOnly={true}
    />;
  };

  return <div className={'whats-new-list-item'} onClick={() => {
    setWhatsNewForEdit(whatsNew);
  }}>
    <div className={'whats-new-list-item-col'}>
      {renderApp()}
    </div>
    <div className={'whats-new-list-item-col'}>
      {whatsNew.version}
    </div>
    <div className={'whats-new-list-item-col'} style={{width: '200px'}}>
      {whatsNew.name}
    </div>

    <div className={'whats-new-list-item-date-col'}>
      {toUserTimeZoneDate(whatsNew.createdAt).format('DD/MM/yyyy')}
      <div style={{paddingLeft: '10px'}}>
        {toUserTimeZoneDate(whatsNew.createdAt).format('HH:mm')}
      </div>
    </div>
  </div>;
};
