import React from 'react';
import {WhatsNewListItem} from './WhatsNewListItem';

export const WhatsNewList = ({
  whatsNew, setWhatsNewForEdit, onelightApps, admins,
}) => {
  return <div className={'promo-list-container'}>
    <div className={'whats-new-list-header'}>
      <div className={'whats-new-list-item-col-header'}>App</div>
      <div className={'whats-new-list-item-col-header'}>Version</div>
      <div className={'whats-new-list-item-col-header'} style={{width: '200px'}}>Name</div>
      <div className={'whats-new-list-item-date-col-header'}>Create Date</div>
      <div style={{width: '18px'}}></div>
    </div>
    <div className={'promo-list'}>
      {whatsNew
          .sort((whatsNewA, whatsNewB) => {
            if (whatsNewA.version < whatsNewB.version) {
              return 1;
            } else return -1;
          })
          .map((whatsNew) => {
            return <WhatsNewListItem
              key={whatsNew.id}
              whatsNew={whatsNew}
              setWhatsNewForEdit={setWhatsNewForEdit}
              onelightApps={onelightApps}
              admins={admins}
            />;
          })}
    </div>
  </div>;
};
