import React, {useEffect, useState} from 'react';
import update from 'immutability-helper';
import {CrossPromoListItem} from './CrossPromoListItem';
import useGlobalDndState, {sourceDndDroppable, typeDndAction, useDraggableInPortal} from '../general/Dnd/DndState';
import deepEqual from 'deep-equal';
import {Droppable} from 'react-beautiful-dnd';
import {wrapId} from '../../utils/DragAndDropUtils';
import {AddCrossPromo} from './AddCrossPromo';
import {ComponentType} from '../../actions/general';

function crossPromoListEqual(prev, next) {
  return deepEqual(prev, next);
}

class InnerList extends React.PureComponent {
  render() {
    return this.props.crossPromoList.map((crossPromo, index) => {
      return <CrossPromoListItem
        key={crossPromo.id}
        setDeleteCrossPromo={this.props.setDeleteCrossPromo}
        crossPromo={crossPromo}
        isSelected={crossPromo.id === this.props.selectedCrossPromo.id}
        selectCrossPromo={this.props.selectCrossPromo}
        draggableId={wrapId(crossPromo.id, this.props.droppableId)}
        arrayIndex={index}
        renderDraggable={this.props.renderDraggable}
        onelightapps={this.props.onelightApps}
      />;
    });
  }
}

const CrossPromoList = ({crossPromoList, selectCrossPromo, selectedApp, onelightApps,
  selectedCrossPromo, createCrossPromo, moveCrossPromo, setDeleteCrossPromo,
}) => {
  const droppableId = sourceDndDroppable.ListCrossPromo;
  const [localCrossPromoList, setCrossPromoList] = useState(crossPromoList);
  const [dndState] = useGlobalDndState(droppableId);

  const renderDraggable = useDraggableInPortal();

  useEffect(() => {
    setCrossPromoList(crossPromoList);
  }, [crossPromoList]);


  useEffect(() => {
    if (dndState) {
      if (dndState.action === typeDndAction.MOVE) {
        moveDnd(dndState.draggableId, dndState.toIndex);
      }
    }
  }, [dndState]);

  if (!selectedApp || !selectedApp.id) {
    return null;
  }

  const findCrossPromo = (id) => {
    const crossPromo = localCrossPromoList.filter((c) => c.id === id)[0];
    return {
      crossPromo,
      index: localCrossPromoList.indexOf(crossPromo),
    };
  };

  const moveDnd = (id, atIndex) => {
    const {crossPromo, index} = findCrossPromo(id);
    const newLocalCrossPromoList = update(localCrossPromoList, {
      $splice: [[index, 1], [atIndex, 0, crossPromo]],
    });

    moveCrossPromo(newLocalCrossPromoList);
    setCrossPromoList(newLocalCrossPromoList);
  };
  return <div>
    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
      <AddCrossPromo
        style={{padding: '0px 20px  0px 0px', width: '60px'}}
        onelightapps={onelightApps.filter((app) => {
          if(app.store !== selectedApp.store || selectedApp.id === app.id) {
            return false;
          }
          const appf = crossPromoList.find((crossPromo) => {
            return crossPromo.app_id === app.vendorId;
          });
          return appf === undefined;
        })}
        onChange={(appVendorId) => {
          createCrossPromo(appVendorId);
        }}
      />
    </div>
    <Droppable droppableId={droppableId} type={ComponentType.CROSS_PROMO}>
      {(provided) => (
        <div className={'cross-promo-list-container'}
          ref={provided.innerRef}
        >
          <InnerList
            crossPromoList={localCrossPromoList}
            selectedCrossPromo={selectedCrossPromo}
            selectCrossPromo={selectCrossPromo}
            renderDraggable={renderDraggable}
            droppableId={droppableId}
            setDeleteCrossPromo={setDeleteCrossPromo}
            onelightApps={onelightApps}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>;
};
export default (React.memo(CrossPromoList, crossPromoListEqual));
