import React, {useEffect, useState} from 'react';
import {KeywordsListItem} from './KeywordsListItem';
import {ReactComponent as SortIcon} from '../../../resources/imgs/sort-icon.svg';
import {isTextContainWord, splitBySeparator} from '../../../utils/StringUtils';
import {range} from '../../../utils/ArrayUtils';

export const KeywordsList = ({
  keywords, formsWords, checkKeyword, phrasesColoring, countActiveSubForms,
  orderParams, orderAsoKeywords, phrases = '', ranks, positions, search,
}) => {
  const [phrasesArray, setPhrasesArray] = useState(splitBySeparator(phrases, '\n'));
  const [ranksArray, setRanksArray] = useState(splitBySeparator(ranks, '\n'));
  const [positionsArray, setPositionsArray] = useState(splitBySeparator(positions, '\n'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formsWords]);

  useEffect(() => {
    if (phrases) {
      setPhrasesArray(splitBySeparator(phrases, '\n'));
    }
  }, [phrases]);

  useEffect(() => {
    if (ranks) {
      setRanksArray(splitBySeparator(ranks, '\n'));
    }
  }, [ranks]);

  useEffect(() => {
    if (positions) {
      setPositionsArray(splitBySeparator(positions, '\n'));
    }
  }, [positions]);

  const renderOrderArrows = (orderParam) => {
    return <div className={'aso-keywords-list-header-arrow-container'}>
      <SortIcon/>
    </div>;
  };

  const onClickOrderHeader = (param) => {
    if (orderParams.orderBy !== param) {
      orderAsoKeywords({
        orderBy: param,
        orderIndex: 'DESC',
      });
    } else {
      orderAsoKeywords({
        orderBy: param,
        orderIndex: orderParams.orderIndex === 'DESC' ? 'ASC' : 'DESC',
      });
    }
  };

  return <div className={'aso-experiment-keywords-list-container'}>
    <div className={'keywords-list-header'}>
      <div className={'keyword-list-header-checkbox'}
        onClick={() => onClickOrderHeader('isChecked1')}
      >
        <div style={{paddingRight: '3px'}}>Main</div>
        {renderOrderArrows('isChecked1')}
      </div>

      <div className={'keyword-list-header-checkbox'}
        onClick={() => onClickOrderHeader('isChecked2')}
      >
        <div style={{paddingRight: '3px'}}>Add’l</div>
        {renderOrderArrows('isChecked2')}
      </div>

      <div className={'keyword-list-header-checkbox'}
        onClick={() => onClickOrderHeader('isChecked3')}
      >
        <div style={{paddingRight: '3px'}}>Old</div>
        {renderOrderArrows('isChecked3')}
      </div>
      {
        countActiveSubForms ?
        range(countActiveSubForms, 1).map((subIndex) => {
          return <div className={'keyword-list-header-checkbox'}
            onClick={() => onClickOrderHeader('isChecked' + (subIndex + 3))}
          >
            <div style={{paddingRight: '3px'}}>Sub {subIndex}</div>
            {renderOrderArrows('isChecked' + (subIndex + 3))}
          </div>;
        }) : null
      }
      <div className={'keyword-list-header-word'}
        onClick={() => onClickOrderHeader('keyword')}
      >
        <div style={{paddingRight: '3px'}}>Keywords</div>
        {renderOrderArrows('keyword')}
      </div>

      <div className={'keyword-list-header-count'}
        onClick={() => onClickOrderHeader('count')}
      >
        <div style={{paddingRight: '3px'}}>Count</div>
        {renderOrderArrows('count')}
      </div>

      <div className={'keyword-list-header-length'}
        onClick={() => onClickOrderHeader('length')}
      >
        <div style={{paddingRight: '3px'}}>Length</div>
        {renderOrderArrows('length')}
      </div>
    </div>

    <div className={'aso-experiment-keywords-list'}>
      {Object.keys(keywords)
          .filter((keyword) => keyword.includes(search.toLowerCase()))
          .sort((keywordA, keywordB) => {
            const keywordAParam = keywords[keywordA][orderParams.orderBy];
            const keywordBParam = keywords[keywordB][orderParams.orderBy];

            if (keywordAParam === keywordBParam) {
              return 0;
            } else {
              if (orderParams.orderIndex === 'DESC') {
                return keywordAParam > keywordBParam ? 1 : -1;
              } else {
                return keywordAParam < keywordBParam ? 1 : -1;
              }
            }
          })
          .map((keyword, arrayId) => {
            return <KeywordsListItem
              key={keyword + '_keyword'}
              keyword={keywords[keyword]}
              word={keyword}
              checkKeyword={checkKeyword}
              wordsMain={formsWords.main}
              wordsAdditional={formsWords.additional}
              wordsOLd={formsWords.old}
              wordsSub1={formsWords.sub_1 ? formsWords.sub_1 : []}
              wordsSub2={formsWords.sub_2 ? formsWords.sub_2 : []}
              wordsSub3={formsWords.sub_3 ? formsWords.sub_3 : []}
              countActiveSubForms={countActiveSubForms}
              formsWords={formsWords}
              phrases={phrasesArray.filter((phrase) => {
                return isTextContainWord(phrase, keyword);
              })}
              allPhrases={phrasesArray}
              ranks={ranksArray}
              positions={positionsArray}
              phrasesColoring={phrasesColoring}
            />;
          })}
    </div>
  </div>;
};
