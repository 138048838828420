import React from 'react';
import {ReactComponent as SelectArrowIcon} from '../../resources/imgs/arrow-select.svg';
import DropdownWrapper from '../general/DropdownWrapper';

export const KeywordStatus = ({status, onChange, style, dropdownStyle={transform: 'translate(-50%, 40px)'}, withSelect=false}) => {
  return <DropdownWrapper style={dropdownStyle}>
    <div className={'keyword-status-input' + (withSelect ? '-select' : '')} style={style}>
      <KeywordStatusSingle
        status={status}
      />
      {withSelect ? <SelectArrowIcon style={{paddingLeft: '8px', paddingRight: '8px'}}/> : null}
    </div>
    <KeywordStatusList
      onChange={onChange}
    />
  </DropdownWrapper>;
};

const KeywordStatusSingle = ({status}) => {
  return <div className={'keyword-status'} style={{backgroundColor: AsoKeywordStatusDisplayParam[status].color}}>
    {AsoKeywordStatusDisplayParam[status].label}
  </div>;
};

const KeywordStatusList = ({onChange, onClose}) => {
  return <div>
    {Object.keys(AsoKeywordStatus).map((status) => {
      return <div className={'keyword-status-list'} onClick={() => {
        onChange(status);
        onClose();
      }}>
        <KeywordStatusSingle
          status={status}
        />
      </div>;
    })}
  </div>;
};


export const AsoKeywordStatus = {
  DONE: 'DONE',
  OPEN: 'OPEN',
  TO_DO: 'TO_DO',
};

export const AsoKeywordStatusDisplayParam = {
  DONE: {
    label: 'Done',
    color: '#CFEED6',
  },
  OPEN: {
    label: 'Open',
    color: '#FFF0B3',
  },
  TO_DO: {
    label: 'To do',
    color: '#D7EFFA',
  },
};
