import React from 'react';
import {KeywordRank} from './KeywordRank';
import {typeModal} from '../../actions/general';
import MDSpinner from 'react-md-spinner';
import {configs} from '../../resources/configs';
import {ReactComponent as SortIcon} from '../../resources/imgs/sort-icon.svg';
import {KeywordsReportType} from '../../models/onelight';

export const KeywordsRanksCountryTable = ({keywordsRanks, country, showModal, setKeywordForEdit, setKeywordToDelete,
  isLoading, isDetailsKeywords, changePropKeyword, onelightApps, isHideKeywords, setOrderKeywords, orderKeywords, selectedReportType,
  keywordsIdsForDelete, loadedKeywordsParams,
}) => {
  const onOrder = (propName) => {
    setOrderKeywords(country, propName, !orderKeywords.isASC);
  };

  const renderOrderArrows = (orderParam) => {
    return <SortIcon
      style={{paddingRight: '6px', marginLeft: 'auto'}}
    />;
  };

  const periodsKeywordsHistory = selectedReportType === KeywordsReportType.POSITION_REPORT ? configs.periodsKeywordsPositionReportHistory : configs.periodsKeywordsIncentMonitorHistory;

  return <div className={'keywords-country-container'} style={{width: (isDetailsKeywords ? '100%' : KeywordsReportType.INCENT_MONITOR === selectedReportType ? '460px' : undefined)}}>
    <div className={'keywords-country-header'}>
      <div style={{marginLeft: 'auto'}}>
        {country.toUpperCase()}
      </div>
      {isLoading ? <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
        <div style={{fontSize: '15px', paddingRight: '10px'}}>
          {
            `${loadedKeywordsParams.loaded} / ${loadedKeywordsParams.total}`
          }
        </div>
        <MDSpinner size={20} singleColor={'#00DADA'}/>
      </div> :
          <div className={'add-keyword-button'} style={{marginLeft: 'auto'}} onClick={() => {
            showModal(typeModal.KEYWORD_ADD, true, {
              country,
            });
          }}>
            Add +
          </div>
      }

    </div>
    <div className={'keywords-country-table-header'}>
      <div className={'keywords-country-table-header-kws'} onClick={() => {
        onOrder('keywords');
      }}>
        Keywords
        {renderOrderArrows('keywords')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('ranks');
      }}>
        Ranks
        {renderOrderArrows('ranks')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('popularity');
      }}>
        SAP
        {renderOrderArrows('popularity')}
      </div>

      {KeywordsReportType.INCENT_MONITOR === selectedReportType ? <div className={'keywords-country-table-header-status'}>
        Status
      </div> : null
      }

      {isDetailsKeywords || KeywordsReportType.INCENT_MONITOR === selectedReportType ? <div className={'keywords-country-table-header-notes'} style={{flex: !isDetailsKeywords ? 1 : undefined}}>Note</div> : null}
      {isDetailsKeywords ? <div className={'keywords-country-table-header-history'} style={{flexDirection: 'column'}}>
        <div>Rank History days:</div>
        <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '10px', flexWrap: 'wrap'}}>
          {periodsKeywordsHistory.map((period) => {
            return <div style={{'display': 'flex', 'flex': 1, 'justifyContent': 'center'}}>
              {period}
            </div>;
          })}
        </div>
      </div> : null}
    </div>
    <div className={'keywords-country-table-list'} style={{maxHeight: isHideKeywords ? undefined : 'none'}}>
      {keywordsRanks
          .map((keywordRank) => {
            return <KeywordRank
              key={keywordRank.id}
              setKeywordForEdit={setKeywordForEdit}
              setKeywordToDelete={setKeywordToDelete}
              onelightApps={onelightApps}
              keywordRank={keywordRank}
              isNotes={isDetailsKeywords}
              isHistory={isDetailsKeywords}
              changePropKeyword={changePropKeyword}
              selectedReportType={selectedReportType}
              keywordsIdsForDelete={keywordsIdsForDelete}
            />;
          })}
    </div>
  </div>;
};
