import React from 'react';

export const UpdateTimeHeader = ({date}) => {
  if (!date) {
    return null;
  }
  return <div className={'update-time-header'}>
    <div>UPD:</div>
    <div style={{paddingLeft: '10px'}}>{date.format('DD/MM/YYYY')}</div>
    <div style={{paddingLeft: '10px', paddingRight: '10px'}}>{date.format('HH:mm')}</div>
  </div>;
};
