import React from 'react';
import momentZ from 'moment-timezone';
import {PromoListItem} from './PromoListItem';

export const PromoList = ({
  promoCodes, setDeletePromoCode, onelightApps, admins,
}) => {
  return <div className={'promo-list-container'}>
    <div className={'promo-list-header'}>
      <div className={'promo-list-item-col-header'}>App</div>
      <div className={'promo-list-item-col-header'}>Code</div>
      <div className={'promo-list-item-col-header'}>Activations</div>
      <div className={'promo-list-item-col-header'}>Expiration</div>
      <div className={'promo-list-item-col-header'}></div>
      <div className={'promo-list-item-col-header'}></div>
      <div className={'promo-list-item-col-header'}>Last Updated</div>
      <div style={{width: '18px'}}></div>
    </div>
    <div className={'promo-list'}>
      {promoCodes
          .sort((promoCodeA, promoCodeB) => {
            if (momentZ(promoCodeA.updatedAt).isBefore(momentZ(promoCodeB.updatedAt))) {
              return 1;
            } else return -1;
          })
          .map((promoCode) => {
            return <PromoListItem
              key={promoCode.id}
              promoCode={promoCode}
              setDeletePromoCode={setDeletePromoCode}
              onelightApps={onelightApps}
              admins={admins}
            />;
          })}
    </div>
  </div>;
};
