import React, {useEffect, useRef, useState} from 'react';
import {bindActionCreators} from 'redux';
import {
  changeFormInput,
  changePhrases,
  checkKeyword,
  getAsoExperiment,
  moveMainToOld,
  moveOldToMain,
  orderAsoKeywords,
  saveAsoExperiment,
  changePhrasesPositions,
  changePhrasesRanks,
  setIsSave,
  orderAsoPhrases,
  deletePhrase,
  changeActiveSubForms,
} from '../../../actions/aso';
import {showModal} from '../../../actions/general';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {AsoExperimentHeader} from './AsoExperimentHeader';
import PhrasesList from './PhrasesList';
import {KeywordsList} from './KeywordsList';
import FormsList from './FormsList';
import PhrasesPositionsRanks from './PhrasesPositionsRanks';
import {FaArrowCircleUp} from 'react-icons/fa';
import {UpdateTimeHeader} from '../../general/UpdateTimeHeader';
import {toUserTimeZoneDate} from '../../../utils/TimeUtils';
import {Input} from '../../general/Input';

const AsoExperiment = ({
  match, selectedAsoExperiment, getAsoExperiment, checkKeyword, changeFormInput, saveAsoExperiment,
  isLoadingAsoExperiment, changePhrase, orderAsoKeywords, moveMainToOld, moveOldToMain, setIsSave,
  selectedAsoExperimentData, isSave, orderParams, isChanged, changePhrasesRanks, changePhrasesPositions,
  orderPhrasesParams, orderAsoPhrases, onelightApps, deletePhrase, changeActiveSubForms,
}) => {
  const [formsWords, setFormsWords] = useState({});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (match.params.asoExperimentUUID) {
      getAsoExperiment(match.params.asoExperimentUUID);
    }
  }, [match.params.asoExperimentUUID]);

  useEffect(() => {
    if (isSave) {
      const newAsoExperiment = {...selectedAsoExperiment};
      newAsoExperiment.data = {...selectedAsoExperimentData};
      saveAsoExperiment(newAsoExperiment, true);
    }
  }, [isSave]);

  useEffect(() => {
    if (selectedAsoExperimentData && selectedAsoExperimentData.forms) {
      const formsWords = {};
      selectedAsoExperimentData.forms.forEach((form) => {
        formsWords[form.name] = form.words.map((word) => word.toLowerCase());
      });
      setFormsWords(formsWords);
    }
  }, [selectedAsoExperimentData.forms]);

  function handleBackClick(ref) {
    ref.current.scrollIntoView({behavior: 'smooth'});
  }

  const topRef = useRef();

  if (isLoadingAsoExperiment || !selectedAsoExperimentData.keywords) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return <div className={'aso-experiment-body'} style={{padding: '30px 80px 0px 30px'}}>
    <UpdateTimeHeader
      date={toUserTimeZoneDate(selectedAsoExperiment.updatedAt)}
    />
    <AsoExperimentHeader
      asoExperiment={selectedAsoExperiment}
      isSave={isSave}
      isChanged={isChanged}
      setIsSave={setIsSave}
      onelightApps={onelightApps}
    />
    <FormsList
      forms={selectedAsoExperimentData.forms}
      changeFormInput={changeFormInput}
      moveMainToOld={moveMainToOld}
      moveOldToMain={moveOldToMain}
      changeActiveSubForms={() => {
        const countActiveSubForms = selectedAsoExperimentData.countActiveSubForms ? selectedAsoExperimentData.countActiveSubForms : 0;
        changeActiveSubForms(countActiveSubForms + 1);
      }}
    />
    <Input
      value={search}
      className={'search-keyword-input'}
      style={{marginBottom: '6px', width: '230px'}}
      handleChange={(value) => {
        setSearch(value);
      }}
      placeholder={'Search Keyword'}
    />
    <div style={{display: 'flex', minHeight: 0, overflowX: 'auto'}}>
      <div ref={topRef}></div>
      <KeywordsList
        keywords={selectedAsoExperimentData.keywords}
        checkKeyword={checkKeyword}
        formsWords={formsWords}
        orderParams={orderParams}
        orderAsoKeywords={orderAsoKeywords}
        phrases={selectedAsoExperimentData.phrases}
        ranks={selectedAsoExperimentData.ranks}
        positions={selectedAsoExperimentData.positions}
        countActiveSubForms={selectedAsoExperimentData.countActiveSubForms}
        search={search}
      />
      <div style={{backgroundColor: 'white', borderRadius: '8px', display: 'flex', width: '50%'}}>
        <PhrasesList
          phrases={selectedAsoExperimentData.phrases}
          changePhrase={changePhrase}
          formsWords={formsWords}
          orderParams={orderPhrasesParams}
          orderAsoPhrases={orderAsoPhrases}
        />
        <PhrasesPositionsRanks
          orderAsoPhrases={orderAsoPhrases}
          phrases={selectedAsoExperimentData.phrases}
          ranks={selectedAsoExperimentData.ranks}
          positions={selectedAsoExperimentData.positions}
          changePhrasesPositions={changePhrasesPositions}
          changePhrasesRanks={changePhrasesRanks}
          orderParams={orderPhrasesParams}
          countPhrases={selectedAsoExperimentData.phrases.split('\n').length}
          deletePhrase={deletePhrase}
        />
      </div>
    </div>
    <FaArrowCircleUp className={'scroll-up-button'}
      onClick={() => {
        handleBackClick(topRef);
      }}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  isLoadingAsoExperiment: state.aso.isLoadingAsoExperiment,
  selectedAsoExperiment: state.aso.selectedAsoExperiment,
  selectedAsoExperimentData: state.aso.selectedAsoExperimentData ? state.aso.selectedAsoExperimentData : {forms: []},
  isSave: state.aso.isSave,
  orderParams: state.aso.orderParams,
  isChanged: state.aso.isChanged,
  orderPhrasesParams: state.aso.orderPhrasesParams,
  onelightApps: state.appState.onelightApps,
});

const mapDispatchToProps = (dispatch) => ({
  getAsoExperiment: bindActionCreators(getAsoExperiment, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  changePhrase: bindActionCreators(changePhrases, dispatch),
  changePhrasesPositions: bindActionCreators(changePhrasesPositions, dispatch),
  changePhrasesRanks: bindActionCreators(changePhrasesRanks, dispatch),
  checkKeyword: bindActionCreators(checkKeyword, dispatch),
  changeFormInput: bindActionCreators(changeFormInput, dispatch),
  saveAsoExperiment: bindActionCreators(saveAsoExperiment, dispatch),
  orderAsoKeywords: bindActionCreators(orderAsoKeywords, dispatch),
  moveMainToOld: bindActionCreators(moveMainToOld, dispatch),
  moveOldToMain: bindActionCreators(moveOldToMain, dispatch),
  setIsSave: bindActionCreators(setIsSave, dispatch),
  orderAsoPhrases: bindActionCreators(orderAsoPhrases, dispatch),
  deletePhrase: bindActionCreators(deletePhrase, dispatch),
  changeActiveSubForms: bindActionCreators(changeActiveSubForms, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsoExperiment);
