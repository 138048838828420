import React from 'react';
import {AiFillAndroid, AiFillApple} from 'react-icons/ai';
import {Store} from '../../models/store';

export const StoreSelector = ({change, selectedValue, style}) => {
  return <div style={style}>
    <AiFillAndroid className={'store-selector-icon' + (selectedValue === Store.GOOGLE_PLAY.value ? '-active' : '-unactive')}
      onClick={() => {
        change(Store.GOOGLE_PLAY.value);
      }}/>
    <AiFillApple className={'store-selector-icon' + (selectedValue === Store.APPLE.value ? '-active' : '-unactive')}
      onClick={() => {
        change(Store.APPLE.value);
      }}/>
  </div>;
};
