import React from 'react';
import {Store} from '../../models/store';
import {AiFillAndroid, AiFillApple} from 'react-icons/ai';

export const RatingsTableHeader = ({countries, group}) => {
  return <div className={'rank-list-header'}>
    <div className={'rank-list-header-app'}>
            Apps
      {group.store === Store.APPLE.value ? <AiFillApple/> : <AiFillAndroid/>}
    </div>

    {countries.map((country) => {
      return <div className={'rank-list-header-country'} key={country}>
        {country}
      </div>;
    })}
  </div>;
};
