import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {copyAsoExperiment, saveAsoExperiment, setDeleteAsoExperiment} from '../../actions/aso';
import uuid from 'uuid/v4';
import {CountrySelector} from '../general/CountrySelector';
import {AppSelector} from '../general/AppSelector';
import {getItem} from '../../utils/LocalStoargeUtill';

const DetailsAsoExperiment = ({
  mode, asoExperiment, saveAsoExperiment, copyAsoExperiment, cancel, setDeleteAsoExperiment, onelightApps,
}) => {
  const [country, setCountry] = useState(asoExperiment.country);
  const [appId, setAppId] = useState(asoExperiment.appId);
  const admin = getItem('admin', null);

  const handleSaveApp = () => {
    const asoExperimentNew = {...asoExperiment};
    asoExperimentNew.appId = appId;
    asoExperimentNew.country = country;
    asoExperimentNew.updatedAt = new Date();
    if (mode === 'ADD') {
      asoExperimentNew.uuid = uuid();
      asoExperimentNew.creatorId = admin.id;
    }
    if (mode === 'COPY') {
      const copyUUID = asoExperiment.uuid;
      asoExperimentNew.uuid = uuid();
      copyAsoExperiment(asoExperimentNew, copyUUID);
    } else {
      saveAsoExperiment(asoExperimentNew, mode === 'EDIT');
    }
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        {mode === 'EDIT' ? 'Edit experiment' : mode === 'COPY' ? 'Copy experiment' : 'Add experiment'}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          App
        </div>
        <AppSelector
          selectedApps={!appId ? [] : [appId]}
          style={{paddingTop: '7px', zIndex: 5000}}
          allApps={onelightApps}
          isMulti={false}
          changeApps={(appId) => {
            setAppId(appId);
          }}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Country
        </div>
        <CountrySelector
          style={{paddingTop: '7px'}}
          selectedCountries={[country]}
          isMulti={false}
          changeCountries={(country) => {
            setCountry(country.toLowerCase());
          }}
        />
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={mode === 'EDIT' ? 'onelight-delete-btn' : 'onelight-second-btn'}
        style={{marginRight: (mode === 'EDIT' ? 'auto' : undefined)}}
        onClick={() => {
          cancel();
          if (mode === 'EDIT') {
            setDeleteAsoExperiment(asoExperiment);
          }
        }}
      >
        {mode === 'EDIT' ? 'Delete' : 'Cancel'}
      </button>
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={handleSaveApp}
        disabled={!country || !appId}
      >
        {mode === 'EDIT' ? 'Save' : 'Add'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({
  onelightApps: state.appState.onelightApps,
});

const mapDispatchToProps = (dispatch) => ({
  saveAsoExperiment: bindActionCreators(saveAsoExperiment, dispatch),
  copyAsoExperiment: bindActionCreators(copyAsoExperiment, dispatch),
  setDeleteAsoExperiment: bindActionCreators(setDeleteAsoExperiment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsAsoExperiment);
