import lookup from 'country-code-lookup';
import React, {useState} from 'react';
import {ReactComponent as SortIcon} from '../../resources/imgs/sort-icon.svg';
import {CountryCompare} from './CountryCompare';
import {countrySort} from '../../utils/ArrayUtils';

export const CountriesCompareTable = ({allCountries, isHideKeywords}) => {
  const [orderKeywords, setOrder] = useState({
    orderBy: 'countries',
    isASC: true,
  });

  const onOrder = (propName) => {
    setOrder({
      orderBy: propName,
      isASC: !orderKeywords.isASC,
    });
  };

  const renderOrderArrows = (orderParam) => {
    return <SortIcon
      style={{paddingRight: '6px', marginLeft: 'auto'}}
    />;
  };

  return <div className={'keywords-country-container'} style={{width: '600px'}}>
    <div className={'keywords-country-header'}>
      <div style={{marginRight: 'auto'}}>
        {'All countries'.toUpperCase()}
      </div>
    </div>
    <div className={'keywords-country-table-header'}>
      <div className={'keywords-country-table-header-kws'} onClick={() => {
        onOrder('countries');
      }}>
        Countries
        {renderOrderArrows('countries')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('ranks');
      }}>
        Ranks
        {renderOrderArrows('ranks')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('popularity');
      }}>
        SAP
        {renderOrderArrows('popularity')}
      </div>

    </div>
    <div className={'keywords-country-table-list'} style={{maxHeight: isHideKeywords ? undefined : 'none'}}>
      {Object.keys(allCountries)
          .sort((countryA, countryB) => {
            const avgA = {avgSapPercent: allCountries[countryA].avgSapPercent, avgRankPercent: allCountries[countryA].avgRankPercent};
            const avgB = {avgSapPercent: allCountries[countryB].avgSapPercent, avgRankPercent: allCountries[countryB].avgRankPercent};

            return sortCompareCountries(countryA, countryB, avgA, avgB, orderKeywords.orderBy, orderKeywords.isASC);
          })
          .map((country) => {
            return <CountryCompare
              key={country}
              country={country}
              avgSapPercent={allCountries[country].avgSapPercent}
              avgRankPercent={allCountries[country].avgRankPercent}
            />;
          })}
    </div>
  </div>;
};

const sortCompareCountries = (countryA, countryB, avgA, avgB, orderBy, isASC) => {
  let valueA;
  let valueB;
  if (orderBy === 'countries') {
    return countrySort(lookup.byIso(countryA), lookup.byIso(countryB));
  }
  if (orderBy === 'ranks') {
    valueA = avgA.avgRankPercent;
    valueB = avgB.avgRankPercent;
  }
  if (orderBy === 'popularity') {
    valueA = avgA.avgSapPercent;
    valueB = avgB.avgSapPercent;
  }

  if (!valueA && !valueB) {
    return 0;
  }
  if (!valueA) {
    return isASC ? 1 : -1;
  }
  if (!valueB) {
    return isASC ? -1 : 1;
  }

  if (valueA < valueB) {
    return isASC ? 1 : -1;
  }
  if (valueA > valueB) {
    return isASC ? -1 : 1;
  }

  return 0;
};
