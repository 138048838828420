import React from 'react';
import {ReactComponent as Delete} from '../../resources/imgs/delete-icon.svg';
import {Store} from '../../models/store';

export const Rating = ({app, countries, maxRatingsByCountries, deleteApp, groupId, store}) => {
  const openAppInStore = (country = 'us') => {
    const win = store === Store.APPLE.value ?
        window.open(`https://apps.apple.com/${country}/app/id${app.vendorId}`, '_blank') :
        window.open(`https://play.google.com/store/apps/details?id=${app.vendorId}&gl=${country}&hl=${country}`, '_blank');
    win.focus();
  };

  return <div className={'rank'}>
    <div className={'rank-list-value-app'} key={app.name}>
      <img className={'rank-list-img'}
        src={app.icon}
      />
      <div className={'rank-list-value-app-name'} onClick={() => openAppInStore('us')}>
        {app.name}
      </div>
    </div>
    {countries.map((country) => {
      const ratings = app.ratings[country];
      const indexRankForSelect = ratings.length - 1;
      const backgroundColor = ratings.length !== 0 ?
          (ratings[indexRankForSelect].rating >= maxRatingsByCountries[country] ? '#b7f1c0' : '#ffbab7') :
          undefined;
      const color = ratings.length !== 0 ?
          (ratings[indexRankForSelect].rating >= maxRatingsByCountries[country] ? '#0F9A19' : '#DE001B') : undefined;
      return <div className={'rank-list-value-country'}
        key={app.name + '_' + country}
        onClick={() => openAppInStore(country)}
      >
        <div style={{backgroundColor, color, height: '35px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {ratings.length !== 0 ? ratings[indexRankForSelect].rating.toFixed(2) : ''}
        </div>
      </div>;
    })}
    <Delete
      style={{marginLeft: '10px', marginRight: '9px', alignSelf: 'center'}}
      onClick={() => deleteApp(app.id, groupId)}
    />
  </div>;
};
