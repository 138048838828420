import React from 'react'
import { saveAuth } from '../../utils/AuthUtill'
import { configs } from '../../resources/configs'

export class Authenticator extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            password: '',
            login: '',
            errMsg: null
        }
    }

    changeLogin = (login) => {
        this.setState({ login: login})
    }

    changePassword = (password) => {
        this.setState({ password: password})
    }

    authorization = () => {
        const xhr = new XMLHttpRequest();

        xhr.open("POST", configs.apiUrl + '/auth/admin', true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        // to do change to onelight ?
        xhr.setRequestHeader('onelight-app-id', 'onelightapps');

        xhr.onreadystatechange = () => {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200){
                    saveAuth(JSON.parse(xhr.responseText))
                    this.setState({ errMsg: null})
                    this.props.onStateChange(true)
                }
                else {
                    const errResp = JSON.parse(xhr.responseText)

                    if(errResp.error.description){
                        this.setState({ errMsg: errResp.error.description})
                    }
                }
            }
        }

        xhr.send(JSON.stringify({
            password: this.state.password,
            username: this.state.login
        }));
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if(this.state.password.length !== 0 && this.state.length !== 0){
                this.authorization()
            }
        }
    }

    render() {
        return (
            <div className='login-container'>
                    <div className='login-logo-div'>
                        <div className='login-logo'></div>
                        <div className='login-logo-text'>
                            ONELIGHT ADMIN
                        </div>
                    </div>
                    {this.state.errMsg !== null ?
                        <span className={"err-msg"}>
                            {this.state.errMsg}
                        </span>
                        : <div/>}
                    <div className='login-input-div'>
                        <input type="email" placeholder="Email"
                               onChange={(e) => this.changeLogin(e.target.value)}
                               value={this.state.login}
                               className='login-input'
                        />
                        <input type="password" placeholder="Password"
                               onChange={(e) => this.changePassword(e.target.value)}
                               value={this.state.password}
                               className='login-input'
                               onKeyPress={this._handleKeyPress}
                        />
                    </div>
                    <div>
                        <button type="submit"
                                onClick={() => this.authorization()}
                                className='login-btn'
                                disabled={this.state.password.length === 0 || this.state.login.length === 0}
                        >
                            Login
                        </button>
                    </div>
            </div>
        )
    }
}