import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {
  getAsoExperiments,
  setCopyAsoExperiment,
  setDeleteAsoExperiment,
  setEditAsoExperiment,
} from '../../actions/aso';
import {AsoHeader} from './AsoHeader';
import {showModal} from '../../actions/general';
import {AsoExperimentsList} from './AsoExperimentsList';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const AsoExperiments = ({
  getAsoExperiments, isLoadAsoExperiments, isLoadingAsoExperiments, setCopyAsoExperiment,
  asoExperiments, showModal, setDeleteAsoExperiment, setEditAsoExperiment, onelightApps, admins,
}) => {
  useEffect(() => {
    if (!isLoadingAsoExperiments) {
      getAsoExperiments();
    }
  }, []);

  if (isSafari) {
    return (
      <div style={{
        marginBottom: '100px',
        height: '500px',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div className={'onelight-head-text'}>
            Use Chrome or Firefox
        </div>
      </div>
    );
  }

  if (isLoadingAsoExperiments) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <div className={'aso-container'}>
      <AsoHeader
        showModal={showModal}
      />
      <div style={{minHeight: '0px', display: 'flex'}}>
        <AsoExperimentsList
          admins={admins}
          asoExperiments={asoExperiments}
          onelightApps={onelightApps}
          setDeleteAsoExperiment={setDeleteAsoExperiment}
          setEditAsoExperiment={setEditAsoExperiment}
          setCopyAsoExperiment={setCopyAsoExperiment}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingAsoExperiments: state.aso.isLoadingAsoExperiments,
  asoExperiments: state.aso.asoExperiments,
  onelightApps: state.appState.onelightApps,
  admins: state.appState.admins,
});

const mapDispatchToProps = (dispatch) => ({
  getAsoExperiments: bindActionCreators(getAsoExperiments, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setDeleteAsoExperiment: bindActionCreators(setDeleteAsoExperiment, dispatch),
  setEditAsoExperiment: bindActionCreators(setEditAsoExperiment, dispatch),
  setCopyAsoExperiment: bindActionCreators(setCopyAsoExperiment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsoExperiments);
