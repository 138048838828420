import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {configs} from '../resources/configs';
import {getFileNameExt, uploadFileToS3Post, uploadFileToS3Put} from '../utils/FilesUtils';
import momentZ from 'moment-timezone';
import {SAVE_PROMO_CODE} from './promo';
import crossPromo from '../reducers/crossPromo';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SAVE_ADMINS = 'SAVE_ADMINS';
export const SET_ERROR = 'SET_ERROR';
export const SET_IS_LOADING_ADMINS = 'SET_IS_LOADING_ADMINS';
export const SET_IS_LOADING_MARKETING_CONFIG = 'SET_IS_LOADING_MARKETING_CONFIG';
export const SAVE_MARKETING_CONFIG = 'SAVE_MARKETING_CONFIG';

export const typeModal = {
  APP_ADD: 'APP_ADD',
  ASO_EXPERIMENT_ADD: 'ASO_EXPERIMENT_ADD',
  ASO_EXPERIMENT_DELETE: 'ASO_EXPERIMENT_DELETE',
  ASO_EXPERIMENT_EDIT: 'ASO_EXPERIMENT_EDIT',
  ASO_EXPERIMENT_COPY: 'ASO_EXPERIMENT_COPY',
  KEYWORD_ADD: 'KEYWORD_ADD',
  KEYWORD_EDIT: 'KEYWORD_EDIT',
  KEYWORD_DELETE: 'KEYWORD_DELETE',
  ERROR: 'ERROR',
  EVENT_DELETE: 'EVENT_DELETE',
  EVENT_ADD: 'EVENT_ADD',
  EVENT_EDIT: 'EVENT_EDIT',
  PROMO_ADD: 'PROMO_ADD',
  PROMO_DELETE: 'PROMO_DELETE',
  WHATS_NEW_ADD: 'WHATS_NEW_ADD',
  WHATS_NEW_DELETE: 'WHATS_NEW_DELETE',
  WHATS_NEW_EDIT: 'WHATS_NEW_EDIT',
  CROSS_PROMO_DELETE: 'CROSS_PROMO_DELETE',
};

export const MetaType = {
  WithGroup: 'WithGroup',
};

export const ComponentType = {
  CROSS_PROMO: 'CROSS_PROMO',
};

export const showModal = (modalType, isShow, meta) => (dispatch) => {
  dispatch({
    type: SHOW_MODAL,
    modalType,
    isShow,
    meta,
  });
};

export const setError = (error) => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    error,
  });
};

export const getAdmins = () => (dispatch) => {
  const onload = (responseText) => {
    const admins = JSON.parse(responseText);
    dispatch({type: SAVE_ADMINS, admins});
  };
  const xhr = createRequest(dispatch, 'GET', `/admins`, onload);

  xhr.send();
};

export const getMarketingConfig = (appStoreId) => (dispatch) => {
  dispatch({type: SET_IS_LOADING_MARKETING_CONFIG, isLoading: true});
  const onload = (responseText) => {
    let config;
    try {
      config = JSON.parse(new TextDecoder().decode(responseText));
    } catch (e) {
      console.error(e);
      config = null;
    }
    dispatch({type: SAVE_MARKETING_CONFIG, remoteConfig: config, appStoreId});
  };

  getFileArrayBuffer(`${configs.onelightResUrl}/marketing-configs/${configs.marketingConfigVersion}_${appStoreId}_marketing_config.json`, onload);
};

export const invalidateOnelightResCloudFront = (path, callback) => {
  const onload = (responseText) => {
    callback();
  };
  const xhr = createRequest(() => {}, 'POST', `/invalidate/cloudfront`, onload);
  xhr.send(JSON.stringify({
    cloudFrontName: 'ONELIGHT_RES_CLOUDFRONT_ID',
    path,
  }));
};

export const saveMarketingConfigToS3 = (file, fileName, path, callback) => {
  renameTempFilesMarketingConfigS3(file, () => {
    uploadFileToS3Put(
      JSON.stringify(file),
      fileName,
      'RES_ONELIGHT',
      path,
      'application/json;charset=UTF-8',
      callback
    );
  })
};

export const renameTempFilesMarketingConfigS3 = (marketingConfig,  callback) => {
  const fromToFiles = [];
  marketingConfig.cross_promo_waterfall.forEach(crossPromo => {
    Object.keys(crossPromo).forEach(key => {
      if(String(key).includes('background_image') && crossPromo[key] && crossPromo[key].includes('temp-')) {
        const newFileName = crossPromo[key].replace('temp-', '');
        fromToFiles.push({
          from: crossPromo[key].replace(configs.onelightResUrl + '/', ''),
          to: newFileName.replace(configs.onelightResUrl + '/', ''),
        })
        crossPromo[key] = newFileName;
      }
    })
  })
  if(fromToFiles.length !== 0) {
    const xhr = createRequest(() => {}, 'POST', `/utility/rename-crosspromo-files`, callback);
    xhr.send(JSON.stringify({fromTo: fromToFiles}));
  } else {
    callback();
  }
};

export const saveImageMarketingConfigToS3 = (crosspromoId, file, postfixFilename, callback) => {
  const path = `marketing-configs/imgs/${crosspromoId}`;
  const fileName = `${postfixFilename}.${getFileNameExt(file.name)}`;

  uploadFileToS3Put(
    file,
    fileName,
    'RES_ONELIGHT',
    path,
    file.type,
    () => {
      callback(`${configs.onelightResUrl}/${path}/${fileName}`)
    }
  );
};
