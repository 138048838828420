import momentZ from 'moment-timezone';
import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {splitBySeparator} from '../utils/StringUtils';
import {configs} from '../resources/configs';
import {SET_ERROR} from './general';
import {batch} from 'react-redux';

export const SAVE_KEYWORDS_RANKS = 'SAVE_KEYWORDS_RANKS';
export const SET_IS_LOADING_KEYWORDS_RANKS = 'SET_IS_LOADING_KEYWORDS_RANKS';
export const SET_IS_LOADING_ADD_KEYWORD = 'SET_IS_LOADING_ADD_KEYWORD';
export const UPSERT_KEYWORD_RANKS = 'ADD_KEYWORD_RANKS';
export const SET_LOADED_KEYWORDS_COUNTS = 'SET_LOADED_KEYWORDS_COUNTS';

export const SELECT_KEYWORD_APP = 'SELECT_KEYWORD_APP';
export const SET_IS_DETAILS_KEYWORDS = 'SET_IS_DETAILS_KEYWORDS';
export const SET_KEYWORD_IDS_FOR_SAVE = 'SET_KEYWORD_ID_FOR_SAVE';
export const SET_IS_SAVE_KEYWORDS = 'SET_IS_SAVE_KEYWORDS';
export const CHANGE_PROP_KEYWORD = 'CHANGE_PROP_KEYWORD';
export const SELECT_KEYWORDS_REPORT_TYPE = 'SET_IS_POSITIONS_KEYWORDS';
export const SET_KEYWORD_TO_EDIT = 'SET_KEYWORD_TO_EDIT';
export const SET_KEYWORD_TO_DELETE = 'SET_KEYWORD_TO_DELETE';
export const DELETE_KEYWORD = 'DELETE_KEYWORD';
export const SET_IS_HIDE_RANKS = 'SET_IS_HIDE_RANKS';
export const SET_IS_SHOW_CHANGES_ONLY = 'SET_IS_SHOW_CHANGES_ONLY';
export const SET_ORDER_KEYWORDS = 'SET_ORDER_KEYWORDS';
export const SET_IS_LOADING_REMOTE_KEYWORDS_RANKS = 'SET_IS_LOADING_REMOTE_KEYWORDS_RANKS';
export const SAVE_REMOTE_KEYWORDS_RANKS = 'SAVE_REMOTE_KEYWORDS_RANKS';
export const CHANGE_COMPARE_PARAMS = 'CHANGE_COMPARE_PARAMS';

export const ADD_KEYWORD_FOR_DELETE = 'ADD_KEYWORD_FOR_DELETE';
export const REMOVE_KEYWORD_FOR_DELETE = 'REMOVE_KEYWORD_FOR_DELETE';
export const CLEAR_KEYWORDS_FOR_DELETE = 'CLEAR_KEYWORDS_FOR_DELETE';
export const DELETE_KEYWORDS = 'DELETE_KEYWORDS';


export const getKeywordsRanks = (appId, fromDate) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_KEYWORDS_RANKS,
    isLoading: true,
  });

  const stateId = getState().keywordsRanks.stateId;

  const onload = (responseText) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');

    dispatch({
      type: SAVE_KEYWORDS_RANKS,
      requestTimeEpoch: timeStampRequest,
      stateId,
      keywordsRanks: response,
    });
  };
  const xhr = createRequest(dispatch, 'GET', `/aso/keywords/search?appId=${appId}&from=${fromDate}`, onload);

  xhr.send();
};

export const addKeywords = (newKeyword) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_LOADING_ADD_KEYWORD,
    isLoading: true,
    country: newKeyword.country,
  });

  const stateId = getState().keywordsRanks.stateId;
  const newKeywords = splitBySeparator(newKeyword.keyword, '\n');
  addKeyword(newKeyword, newKeywords, 0, dispatch, stateId);
};

const addKeyword = (newKeyword, keywords, currentIndex, dispatch, stateId, retryCount = 10) => {
  if (keywords.length === (currentIndex)) {
    dispatch({
      type: SET_IS_LOADING_ADD_KEYWORD,
      isLoading: false,
      country: newKeyword.country,
    });
  } else {
    const keyword = keywords[currentIndex];
    const onload = (responseText) => {
      const response = JSON.parse(responseText);
      const timeStampRequest = momentZ().format('x');
      batch(() => {
        dispatch({
          type: UPSERT_KEYWORD_RANKS,
          requestTimeEpoch: timeStampRequest,
          stateId,
          keywordRanks: response,
        });
        dispatch({
          type: SET_LOADED_KEYWORDS_COUNTS,
          requestTimeEpoch: timeStampRequest,
          loaded: currentIndex,
          total: keywords.length,
        });
      });

      addKeyword(newKeyword, keywords, currentIndex + 1, dispatch, stateId, 10);
    };
    const onerror = () => {
      if (retryCount !== 0) {
        console.log(`Retry count ${retryCount}, keyword: ${JSON.stringify(newKeyword)}`);
        addKeyword(newKeyword, keywords, currentIndex, dispatch, stateId, retryCount - 1);
      } else {
        batch(() => {
          dispatch({
            type: SET_ERROR,
            error: `Keyword ${keyword.title} not loaded.`,
          });
          dispatch({
            type: SET_IS_LOADING_ADD_KEYWORD,
            isLoading: false,
            country: newKeyword.country,
          });
        });
      }
    };
    const xhr = createRequest(dispatch, 'POST', `/aso/keyword`, onload, true, onerror);
    xhr.send(JSON.stringify({...newKeyword, ...{keyword}}));
  }
};

export const selectKeywordApp = (app) => (dispatch) => {
  dispatch({
    type: SELECT_KEYWORD_APP,
    app,
  });
};

export const setIsDetailsKeywords = (isDetailsKeywords) => (dispatch) => {
  dispatch({
    type: SET_IS_DETAILS_KEYWORDS,
    isDetailsKeywords,
  });
};

export const changePropKeyword = (prop, value, keywordId) => (dispatch) => {
  dispatch({
    type: CHANGE_PROP_KEYWORD,
    value,
    prop,
    keywordId,
  });

  dispatch({
    type: SET_IS_SAVE_KEYWORDS,
    isSave: true,
    meta: {
      debounce: {
        time: 5000,
      },
    },
  });

  dispatch({
    type: SET_KEYWORD_IDS_FOR_SAVE,
    isSave: true,
    keywordIds: [keywordId],
  });
};

export const saveKeywords = (keywordIds) => (dispatch, getState) => {
  dispatch({
    type: SET_KEYWORD_IDS_FOR_SAVE,
    isSave: false,
    keywordIds,
  });

  dispatch({
    type: SET_IS_SAVE_KEYWORDS,
    isSave: false,
  });

  const keywords = getState().keywordsRanks.keywordsRanks;
  const updateEvents = [];


  keywords.forEach((keyword) => {
    if (keywordIds.find((keywordId) => keywordId === keyword.id)) {
      updateEvents.push({
        id: keyword.id,
        notes: keyword.notes,
        status: keyword.status,
      });
    }
  });

  const xhr = createRequest(dispatch, 'PATCH', `/aso/keywords`, (r) =>{});

  xhr.send(JSON.stringify({
    updateEvents,
  }));
};

export const saveKeyword = (keyword) => (dispatch, getState) => {
  const stateId = getState().keywordsRanks.stateId;
  const timeStampRequest = momentZ().format('x');

  dispatch({
    type: UPSERT_KEYWORD_RANKS,
    requestTimeEpoch: timeStampRequest,
    stateId,
    keywordRanks: keyword,
  });
  const xhr = createRequest(dispatch, 'PATCH', `/aso/keyword`, (r) =>{});
  xhr.send(JSON.stringify(keyword));
};

export const deleteKeyword = (keywordId) => (dispatch) => {
  dispatch({
    type: DELETE_KEYWORD,
    keywordId,
  });

  const xhr = createRequest(dispatch, 'DELETE', `/aso/keyword?id=` + keywordId, (r) =>{});
  xhr.send();
};

export const selectReport = (reportType) => (dispatch) => {
  dispatch({
    type: SELECT_KEYWORDS_REPORT_TYPE,
    reportType,
  });
};

export const setKeywordForEdit = (keyword) => (dispatch) => {
  dispatch({
    type: SET_KEYWORD_TO_EDIT,
    keyword,
  });
};

export const setKeywordToDelete = (keyword) => (dispatch) => {
  dispatch({
    type: SET_KEYWORD_TO_DELETE,
    keyword,
  });
};

export const setIsHideKeywords = (isHide) => (dispatch) => {
  dispatch({
    type: SET_IS_HIDE_RANKS,
    isHide,
  });
};

export const setIsShowChangesOnly = (isShow) => (dispatch) => {
  dispatch({
    type: SET_IS_SHOW_CHANGES_ONLY,
    isShow,
  });
};

export const setOrderKeywords = (country, orderBy, isASC) => (dispatch) => {
  dispatch({
    type: SET_ORDER_KEYWORDS,
    orderBy,
    isASC,
    country,
  });
};

export const getKeywordsRanksRemote = (appVendorId) => (dispatch, getState) => {
  dispatch({type: SET_IS_LOADING_REMOTE_KEYWORDS_RANKS, isLoading: true});
  const timeStampRequest = momentZ().format('x');
  const stateId = getState().keywordsRanks.stateId;

  const onload = (responseText) => {
    let keywordsRanksRemote;
    try {
      keywordsRanksRemote = JSON.parse(new TextDecoder().decode(responseText));
    } catch (e) {
      keywordsRanksRemote = [];
    }
    dispatch({type: SAVE_REMOTE_KEYWORDS_RANKS, keywordsRanksRemote, timeStampRequest, stateId});
  };

  getFileArrayBuffer(configs.remoteKeywordsRanksUrl + appVendorId + '.json', onload);
};

export const addKeywordForDelete = (id) => (dispatch) => {
  dispatch({
    type: ADD_KEYWORD_FOR_DELETE,
    id,
  });
};

export const removeKeywordForDelete = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_KEYWORD_FOR_DELETE,
    id,
  });
};

export const clearKeywordForDelete = () => (dispatch) => {
  dispatch({
    type: CLEAR_KEYWORDS_FOR_DELETE,
  });
};

export const deleteSelectedKeywords = () => (dispatch, getState) => {
  const idsForDelete = [...[], ...getState().keywordsRanks.keywordsIdsForDelete];
  dispatch({
    type: DELETE_KEYWORDS,
  });

  const xhr = createRequest(dispatch, 'POST', `/aso/keywords/delete`, (r) =>{});

  xhr.send(JSON.stringify({
    ids: idsForDelete,
  }));
};

export const changeCompareParams = (params) => (dispatch) => {
  dispatch({
    type: CHANGE_COMPARE_PARAMS,
    params,
  });
};
