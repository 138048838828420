import React, { Component } from 'react'
import { Authenticator } from './Authenticator'
import { isAuth, registerLogout } from '../../utils/AuthUtill'

export const withAuthenticator = (Comp) => {
    return class extends Component {
        constructor (props) {
            super(props)
            this.state = {
                signedIn: isAuth()
            }
            registerLogout(this.handleAuthStateChange)
        }

        handleAuthStateChange = (state) => {
            this.setState({ signedIn: state })
        }

        render () {
            const { signedIn } = this.state
            if (signedIn) {
                return (
                    <Comp
                        {...this.props}
                    />
                )
            }

            return <Authenticator
                onStateChange={this.handleAuthStateChange}
            />
        }
    }
}