import React from 'react';
import DropdownWrapper from '../general/DropdownWrapper';
import {AppSelector} from '../general/AppSelector';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';

export const AddCrossPromo = ({onelightapps, onChange, style, dropdownStyle={transform: 'translate(-50%, 40px)'}, withSelect=false}) => {
  return <DropdownWrapper style={dropdownStyle}>
    <div className={'add-metadata-container'} style={style}>
      <div>
        Add
      </div>
      <PlusIcon/>
    </div>
    <DropdownAppSelector
      onChange={onChange}
      onelightapps={onelightapps}
    />
  </DropdownWrapper>;
};

const DropdownAppSelector = ({onChange, onClose, onelightapps}) => {
  return <AppSelector
    style={{backgroundColor: 'white'}}
    selectedApps={[]}
    isMulti={false}
    allApps={onelightapps}
    menuIsOpen={true}
    changeApps={(appId) => {
      onChange(onelightapps.find((app) => app.id === appId));
      onClose();
    }}
  />;
};
