import React, {Component} from 'react';
import {DndContainer} from './DndContainer';

export const withDnd = (Comp) => {
  return class extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <DndContainer>
          <Comp {...this.props}/>
        </DndContainer>
      );
    }
  };
};
