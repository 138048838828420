import React from 'react';
import {ReactComponent as EditIcon} from '../../resources/imgs/edit-icon.svg';
import {ReactComponent as LinkIcon} from '../../resources/imgs/link-icon.svg';
import {ReactComponent as DeleteIcon} from '../../resources/imgs/delete-icon.svg';
import {bindActionCreators} from 'redux';
import {
  addKeywordForDelete, removeKeywordForDelete,

} from '../../actions/keywords';
import {connect} from 'react-redux';
import {Checkbox} from '../general/Checkbox';

const KeywordRankSettings = ({keywordRank, onelightApp,
  setKeywordForEdit, setKeywordToDelete, addKeywordForDelete, removeKeywordForDelete, keywordsIdsForDelete,
}) => {
  return <div className={'keyword-rank-settings'}>
    <LinkIcon className={'keyword-rank-settings-icon'} style={{color: '#42a5f5'}}
      onClick={() => {
        window.open(
            encodeURI(`https://watch.appfollow.io/apps/${onelightApp.vendorId}/aso/tools?country=${keywordRank.country}&device=${(onelightApp.store !== 'apple' ? 'android' : 'iphone')}&term=${keywordRank.keyword}`),
            '_blank',
        );
      }}
    />
    <EditIcon className={'keyword-rank-settings-icon'}
      onClick={() => {
        setKeywordForEdit(keywordRank);
      }}
    />
    <DeleteIcon className={'keyword-rank-settings-icon'}
      style={{marginLeft: 'auto', paddingRight: '20px', color: 'rgba(255, 0, 0, 0.45)'}}
      onClick={() => {
        setKeywordToDelete(keywordRank);
      }}
    />
    <Checkbox
      key={'select-checkbox-' + keywordRank.id}
      switchCheckbox={(isActive) => {
        if (isActive) {
          addKeywordForDelete(keywordRank.id);
        } else {
          removeKeywordForDelete(keywordRank.id);
        }
      }}
      style={{marginRight: '10px'}}
      isActive={keywordsIdsForDelete.indexOf(keywordRank.id) !== -1}
      text={''}
    />
  </div>;
};

const mapStateToProps = (state) => ({
  keywordsIdsForDelete: state.keywordsRanks.keywordsIdsForDelete,
});

const mapDispatchToProps = (dispatch) => ({
  addKeywordForDelete: bindActionCreators(addKeywordForDelete, dispatch),
  removeKeywordForDelete: bindActionCreators(removeKeywordForDelete, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeywordRankSettings);
