import React, {useEffect, useState} from 'react';
import DropdownWrapper from '../general/DropdownWrapper';
import {HexColorPicker} from 'react-colorful';
import {useDebouncedEffect} from '../../utils/UseEffects';

export const ColorPicker = ({color, onChange, style, dropdownStyle={transform: 'translate(-50%, 40px)'}, withSelect=false}) => {
  const [colorLocal, setColorLocal] = useState(color);
  useEffect(() => {
    if (colorLocal !== color) {
      setColorLocal(color);
    }
  }, [color]);

  useDebouncedEffect(() => {
    if (colorLocal !== color) {
      onChange(colorLocal);
    }
  }, [{colorLocal}], 200);

  return <DropdownWrapper style={dropdownStyle}>
    <div className={'keyword-status-input' + (withSelect ? '-select' : '')}
      style={{...{border: 'solid 1px black', borderRadius: '10px', width: '22px', height: '22px', backgroundColor: colorLocal}, ...style}}
    />
    <DropdownColorPicker
      onChange={setColorLocal}
      color={colorLocal}
    />
  </DropdownWrapper>;
};

const DropdownColorPicker = ({onChange, onClose, color}) => {
  return <HexColorPicker
    style={{marginLeft: '10px'}}
    color={color}
    onChange={(color) => {
      onChange(color);
    }}
  />;
};
