import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {logout} from '../../actions/auth'
import {NavLink} from "react-router-dom"
import { configs } from '../../resources/configs'
import {getOnelightApps} from '../../actions/apps';
import {getAdmins} from '../../actions/general';
import {ReactComponent as OnelightLogoIcon} from '../../resources/imgs/onelight-logo.svg';
import {SelectPathNav} from "./SelectPathNav";
import {compareString, removeDuplicates} from "../../utils/ArrayUtils";
import {getAdmin, getAdminIamActions, registerCookiesFromLocalStorage} from '../../utils/AuthUtill';

const NavType = {
    LOCAL_PATH: 'LOCAL_PATH',
    EXTERNAL_PATH: 'EXTERNAL_PATH',
    SELECT_EXTERNAL_PATH: 'SELECT_EXTERNAL_PATH',
    SELECT_LOCAL_PATH: 'SELECT_LOCAL_PATH'

}

const SectionType = {
    MARKETING: 'MARKETING',
    QA: 'QA',
}

const asoSpreadsheetsUrls = (order) => {
    return {
        paths: [
            {name: 'FLTR', url: configs.asoFLTRSpreadsheetsUrl},
            {name: 'INPREVIEW', url: configs.asoINPREVIEWSpreadsheetsUrl},
            {name: 'TON', url: configs.asoTONSpreadsheetsUrl},
            {name: 'FONTS', url: configs.asoFONTSSpreadsheetsUrl},
            {name: 'STR', url: configs.asoSTRSpreadsheetsUrl},
            {name: 'GRID', url: configs.asoGRIDSpreadsheetsUrl},

            {name: 'DesignLab', url: configs.asoDesignLabSpreadsheetsUrl},
            {name: 'MuseCam', url: configs.asoMuseCamSpreadsheetsUrl},
            {name: 'VidLab', url: configs.asoVidLabSpreadsheetsUrl},
            {name: 'Swipemix', url: configs.asoGRIDSpreadsheetsUrl},
        ],
        icon: <div/>, name:'Graphics', type: NavType.SELECT_EXTERNAL_PATH, sectionType: SectionType.MARKETING, order
    };
}

const openapiNavs = (order) => {
    return {
        type: NavType.SELECT_LOCAL_PATH,
        name: 'Openapi',
        order,
        sectionType: SectionType.MARKETING,
        icon: <div/>,
        navs: [
            { isActive: {path:'/openapi/common'}, to: '/openapi/common', name: 'Common'},
            { isActive: {path:'/openapi/beat'}, to: '/openapi/beat', name: 'BEAT'},
            { isActive: {path:'/openapi/inpreview'}, to: '/openapi/inpreview', name: 'Inpreview'}
        ]
    }
}

export const isActivePath = (path) => {
    return (match, location) => {
        return  location.pathname === path.path || (path.subPath ? location.pathname.includes(path.subPath) : false);
    }
}

class Head extends Component {

    constructor (props) {
        super(props)
        this.state = {
            admin: null,
            iamActions: [],
        }
    }

    componentDidMount() {
        registerCookiesFromLocalStorage()
    }

    componentWillMount () {
        registerCookiesFromLocalStorage()
        const admin = getAdmin();
        const iamActions = getAdminIamActions();
        this.setState({
            admin,
            iamActions,
        })
        if (iamActions.indexOf('common:GetOnelightApps') !== -1) {
            this.props.getOnelightApps();
        }
        if(!this.props.isLoadingAdmins && this.props.admins.length === 0 && iamActions.indexOf('common:GetAdmins') !== -1){
            this.props.getAdmins();
        }
    }

    getSection (sectionName, navs) {
        const components = navs.sort(compareString('order')).map((nav) => {
            switch (nav.type) {
                case NavType.LOCAL_PATH: {
                    return <NavLink
                      isActive={isActivePath(nav.isActive)}
                      to={nav.to}  className="head-item-link"
                      activeClassName={"head-item-link-active"}
                      key={nav.name}
                    >
                        {nav.icon}
                        <div>
                            {nav.name}
                        </div>
                    </NavLink>
                }
                case NavType.SELECT_LOCAL_PATH:{
                    return <SelectPathNav
                      isActive={false}
                      label={nav.name}
                      className={"head-item-link"}
                      paths={[]}
                      navs={nav.navs}
                      isLocal={true}
                    />
                }
                case NavType.EXTERNAL_PATH: {
                    return <div key={nav.name} className="head-item-link" onClick={() => {window.open(nav.isActive.path)}}>
                        <div>{nav.name}</div>
                    </div>
                }
                case NavType.SELECT_EXTERNAL_PATH: {
                    return <SelectPathNav
                      isActive={false}
                      label={nav.name}
                      paths={nav.paths}
                      className={"head-item-link"}
                    />
                }
            }
        })

        if(components.length === 0) {
            return null;
        }

        components.unshift(<div className={'head-panel-subtitle'}>{sectionName}</div>);
        components.unshift(<div className={'head-panel-separator'}/>);

        return components;
    }

    render () {
        let navs = [];
        if (this.state.iamActions.length !== 0) {
            this.state.iamActions.forEach(action => {
                if(action.includes('webUI')) {
                    navs = navs.concat(navsTemplates[action] ? navsTemplates[action] : [])
                }
            })
            navs = removeDuplicates(navs, 'name');
        } else {
            navs = navsTemplates[this.state.admin.adminType] ? navsTemplates[this.state.admin.adminType] : [];
        }
        const navsMarketing = navs.filter((nav) => nav.sectionType === SectionType.MARKETING);
        const navsQA = navs.filter((nav) => nav.sectionType === SectionType.QA);

        return (
            <div className='head-panel'>
                <div style={{paddingTop: '73px', width: '175px', height: '28.84px', zIndex: 1, paddingBottom: '58px'}}>
                    <OnelightLogoIcon
                        className={'head-item'}
                    />
                </div>
                {
                    this.state.iamActions.indexOf('webUI:AccessDashboard') !== -1 ? <NavLink isActive={isActivePath({path: "/"})} to={'/'}
                                                                                        style={{zIndex: 100}}
                                                                                        className="head-item-link"
                                                                                        activeClassName={"head-item-link-active"}>
                        <div>Dashboard</div>
                    </NavLink> : null
                }
                {
                    this.getSection('Marketing', navsMarketing)
                }
                {
                    this.getSection('QA', navsQA)
                }

                <div className={'head-panel-separator'}/>

                <div className={'head-panel-subtitle'}>Content</div>

                <div className="head-item-link" onClick={() => {window.open(configs.fltrAdminUrl)}}>
                    <div>FLTR</div>
                </div>

                <div className="head-item-link" onClick={() => {window.open(configs.beatAdminUrl)}}>
                    <div>BEAT</div>
                </div>

                <div className="head-item-link" onClick={() => {window.open(configs.inpreviewAdminUrl)}}>
                    <div>Inpreview</div>
                </div>

                <div className={'head-panel-separator'}/>

                <div className={"head-item-link"} onClick={this.props.logout}>
                    Logout
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    admins: state.appState.admins,
    isLoadingAdmins: state.appState.isLoadingAdmins
})

const mapDispatchToProps = (dispatch) => ({
    logout: bindActionCreators(logout, dispatch),
    getOnelightApps: bindActionCreators(getOnelightApps, dispatch),
    getAdmins: bindActionCreators(getAdmins, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Head)

const navsTemplates = {
    ASO_KEYWORDS_AND_FLTR_MANAGER: [
        {isActive: {path: '/aso', subPath: '/aso/experiment'}, to: '/aso', icon: <div/>, name: 'Metadata', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING, order: 1},
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order:3},
        {isActive: {path:'/keywords'}, to: '/keywords', icon: <div/>, name: 'Keywords', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 4},
        {isActive: {path:'/aso/report'}, to: '/aso/report', icon: <div/>, name: 'ASO report', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 5},
        asoSpreadsheetsUrls(6),
        {isActive: {path:'/whats-new'}, to: '/whats-new', icon: <div/>, name: 'Whats New', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 7}
    ],
    RANKS_RATINGS_MANAGER: [
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1},
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
    ],
    UA_MANAGER: [
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1},
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
        {isActive: {path:'/aso/report'}, to: '/aso/report', icon: <div/>, name: 'ASO report', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 3},
        asoSpreadsheetsUrls(4),
    ],
    ASO_MANAGER: [
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1},
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
        {isActive: {path:'/aso', subPath: '/aso/experiment'}, to: '/aso', icon: <div/>, name: 'Metadata', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 3},
        {isActive: {path:'/keywords'}, to: '/keywords', icon: <div/>, name: 'Keywords', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 4},
        {isActive: {path:'/aso/report'}, to: '/aso/report', icon: <div/>, name: 'ASO report', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 5},
        asoSpreadsheetsUrls(6),
    ],
    SUPERADMIN: [
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1},
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
        {isActive: {path:'/aso', subPath: '/aso/experiment'}, to: '/aso', icon: <div/>, name: 'Metadata', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 3},
        {isActive: {path:'/keywords'}, to: '/keywords', icon: <div/>, name: 'Keywords', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 4},
        {isActive: {path:'/aso/report'}, to: '/aso/report', icon: <div/>, name: 'ASO report', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 5},
        asoSpreadsheetsUrls(6),
        {isActive: {path:'/promo'}, to: '/promo', icon: <div/>, name: 'Promo', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 7},
        {isActive: {path:'/whats-new'}, to: '/whats-new', icon: <div/>, name: 'Whats New', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 8},
        {isActive: {path:'/qa/debug'}, to: '/qa/debug', icon: <div/>, name: 'Debug', type: NavType.LOCAL_PATH, sectionType: SectionType.QA,order: 12}

    ],
    ADMIN: [
        {isActive: {path:'/promo'}, to: '/promo', icon: <div/>, name: 'Promo', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1}
    ],
    INPREVIEW_PROMO_FLTR_REQUESTS: [
        {isActive: {path:'/promo'}, to: '/promo', icon: <div/>, name: 'Promo', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1}
    ],
    'webUI:AccessAppRanks': [
        {isActive: {path:'/ranks'}, to: '/ranks', icon: <div/>, name: 'Ranks', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 1},
    ],
    'webUI:AccessAppRatings': [
        {isActive: {path:'/ratings'}, to: '/ratings', icon: <div/>, name: 'Ratings', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 2},
    ],
    'webUI:AccessAsoMetadata': [
        {isActive: {path:'/aso', subPath: '/aso/experiment'}, to: '/aso', icon: <div/>, name: 'Metadata', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 3},
    ],
    'webUI:AccessIndividualAsoMetadata': [
        {isActive: {path:'/aso', subPath: '/aso/experiment'}, to: '/aso', icon: <div/>, name: 'Metadata', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 3},
    ],
    'webUI:AccessAsoKeywords': [
        {isActive: {path:'/keywords'}, to: '/keywords', icon: <div/>, name: 'Keywords', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 5},
    ],
    'webUI:AccessAsoReport': [
        {isActive: {path:'/aso/report'}, to: '/aso/report', icon: <div/>, name: 'ASO report', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 6},
    ],
    'webUI:AccessAsoGraphics': [
        asoSpreadsheetsUrls(7),
    ],
    'webUI:AccessPromo': [
        {isActive: {path:'/promo'}, to: '/promo', icon: <div/>, name: 'Promo', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 8},
        {isActive: {path:'/cross-promo'}, to: '/cross-promo', icon: <div/>, name: 'Cross promo', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 9},
    ],
    'webUI:AccessWhatsNew': [
        {isActive: {path:'/whats-new'}, to: '/whats-new', icon: <div/>, name: 'Whats New', type: NavType.LOCAL_PATH, sectionType: SectionType.MARKETING,order: 10}
    ],
    'webUI:AccessOpenapi': [
      openapiNavs(11)
    ],
    'webUI:AccessQADashboard': [
        {isActive: {path:'/qa/debug'}, to: '/qa/debug', icon: <div/>, name: 'Debug', type: NavType.LOCAL_PATH, sectionType: SectionType.QA,order: 12}
    ],
}
