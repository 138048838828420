import React from 'react';
import MDSpinner from 'react-md-spinner';
import {saveFile, webpIsSupported} from '../../utils/FilesUtils';

export const EditImg = ({imgUrl, onDrop, clear, style, heightImg, widthImg, isLoading, onClickImage, text=''}) => {
  const isActive = false;

  if (isLoading) {
    return <div
      className={'img-edit-dnd-container-active'}
      style={style}
    >
      <MDSpinner size={40} singleColor={'#00DADA'}/>
    </div>;
  }

  if (!imgUrl) {
    return <div
      className={'img-edit-dnd-container' + (isActive ? '-active' : '') }
      style={style}
    >
      <img style={{width: '46px', height: '31px'}}
        src={require('../../resources/imgs/upload.png').default}
      />
      <div className={'onelight-second-text'}>
        {text}
      </div>
      <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
        onChange={(event) => {
          if (event.target.files[0] && event.target.files[0].type.startsWith('image/png')) {
            webpIsSupported(event.target.files[0], (isSupport, file) => {
              if (isSupport) {
                onDrop(file);
              }
            });
          }
        }}/>
    </div>;
  }

  return <div className= "img-edit-container" style={style}>
    <img className={'img-edit'} src={imgUrl}
      style={{height: heightImg, width: widthImg}}
      onClick={() => {
        if(onClickImage) {
          onClickImage()
        }
      }}
    />
    <img className={'close-img'}
      src={require('../../resources/imgs/close.svg').default}
      onClick={clear}
    />
  </div>;
};
