import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import {showModal} from '../../actions/general';
import {getWhatsNew, setWhatsNewForEdit} from '../../actions/whatsNew';
import {WhatsNewList} from './WhatsNewList';
import {WhatsNewHeader} from './WhatsNewHeader';


const WhatsNew = ({
  getWhatsNew, isLoadingWhatsNew,
  whatsNew, showModal, setWhatsNewForEdit, onelightApps, admins,
}) => {
  const [selectedAppId, selectAppId] = useState(null);

  useEffect(() => {
    getWhatsNew();
  }, []);

  if (isLoadingWhatsNew) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <div className={'aso-container'}>
      <WhatsNewHeader
        showModal={showModal}
        onelightApps={onelightApps}
        selectedAppId={selectedAppId}
        selectAppId={selectAppId}
      />
      <div style={{minHeight: '0px', display: 'flex'}}>
        <WhatsNewList
          admins={admins}
          whatsNew={(!selectedAppId ? whatsNew : whatsNew.filter((whatsNew) => whatsNew.appId === selectedAppId))}
          onelightApps={onelightApps}
          setWhatsNewForEdit={setWhatsNewForEdit}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoadingWhatsNew: state.whatsNew.isLoadingWhatsNew,
  whatsNew: state.whatsNew.whatsNew,
  onelightApps: state.appState.onelightApps,
  admins: state.appState.admins,
});

const mapDispatchToProps = (dispatch) => ({
  getWhatsNew: bindActionCreators(getWhatsNew, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setWhatsNewForEdit: bindActionCreators(setWhatsNewForEdit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);
