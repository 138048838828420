import React from 'react';
import {RatingsTableHeader} from './RatingsTableHeader';
import {Rating} from './Rating';
import {MetaType, typeModal} from '../../actions/general';
import {AppGroupRelationType} from '../../actions/apps';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import {RanksBody} from '../ranks/RanksBody';

export const RatingsBody = ({apps, countries, showModal, deleteApp, group,
  maxRatingsByCountries, isNewGroup, totalGroups,
}) => {
  return (
    <div className={'ranks-body'}>

      {apps.length !== 0 ? <RatingsTableHeader countries={countries} group={group}/> : null}
      <div className={'rank-list'}>
        {
          apps.map((app) => {
            return <Rating
              key={app.id}
              app={app}
              deleteApp={deleteApp}
              maxRatingsByCountries={maxRatingsByCountries}
              countries={countries}
              groupId={group.id}
              store={group.store}
            />;
          })
        }
      </div>
      <div className={'add-app-container'} style={{padding: '13px 0px  13px 148px'}} onClick={() => {
        showModal(typeModal.APP_ADD, true, {
          type: MetaType.WithGroup,
          isNewGroup,
          group,
          groupOrderId: totalGroups + 1,
          appGroupRelationType: AppGroupRelationType.RATINGS,
        });
      }}>
        <div>
                Add App
        </div>
        <PlusIcon/>
      </div>
    </div>
  );
};
