import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class Switcher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive
        }
    }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if(nextProps.isActive !== this.props.isActive){
      this.setState({isActive: nextProps.isActive})
    }
    return true
  }

    switch = debounce(200, () => this.props.switch(this.state.isActive))

    handleChangeSwitch = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.switch());
    }

    render () {
      const {id = ""} = this.props
        return (
            <div style={{display: "flex"}} className={'switcher'}>
                <input type="checkbox" id={id + "_switch"}
                       className={"switcher-input"}
                   disabled={this.props.isDisabled}
                   defaultChecked={this.state.isActive}
                   checked={this.state.isActive}
                   onChange={this.handleChangeSwitch}
                />
                <label
                  className={"switcher-label"}
                  htmlFor={id + "_switch"}
                  style={{backgroundColor: this.state.isActive ? this.props.colorOn : this.props.colorOff}}
                />
            </div>
        )
    }
}