import React from 'react';

export const MetricsLegend = () => {
  return <div className={'legends-container'}>
    {descriptions.map((description) => {
      return <div className={'legend-container'} style={{width: description.width, margin: '5px'}}>
        <div className={'legend-header'}>
          {description.name}
        </div>
        <div className={'legend-description'}>
          {description.source}
        </div>
        <div className={'legend-description'} style={{height: '45px'}}>
          {description.formula}
        </div>
      </div>;
    })}
  </div>;
};

const descriptions = [
  {
    name: 'Page views',
    source: 'itunes',
    formula: '',
    width: '209px',
  },
  {
    name: 'Impressions',
    source: 'itunes',
    formula: '',
    width: '209px',
  },
  {
    name: 'CTR',
    source: 'itunes',
    formula: 'Page Views / Impressions',
    width: '209px',
    marginRight: '10px',
  },
  {
    name: 'CVR',
    source: 'itunes',
    formula: 'Installs / Impressions',
    width: '209px',
  },
  {
    name: 'Revenue subscribe',
    source: 'appsflyer',
    formula: '',
    width: '209px',
  },
  {
    name: 'Revenue organic',
    source: 'appsflyer',
    formula: '(total - (total_lat_Off + total_lat_Off * factor_lat)) - sum_not_asa_companies',
    width: '428px',
  },

  {
    name: 'Organic subscribe',
    source: 'appsflyer',
    formula: '(total - (total_lat_Off + total_lat_Off * factor_lat)) - sum_not_asa_companies',
    width: '428px',
  },
  {
    name: 'Subscribe',
    source: 'appsflyer',
    formula: '',
    width: '209px',
  },
  {
    name: 'Installs',
    source: '',
    formula: 'First-Time Downloads',
    width: '209px',
  },
];
