import {locales} from '../../models/onelight';
import {Input} from '../general/Input';
import React, {useEffect, useState} from 'react';
import DropdownWrapper from '../general/DropdownWrapper';

export const CrossPromoEditorLocales = ({crossPromo, style, updateCrossPromoBatch, updateCrossPromo, dropdownStyle={transform: 'translate(-50%, 40px)'}, withSelect=false}) => {
  return <DropdownWrapper style={dropdownStyle}>
    <div className={'add-btn'} style={style}>
      Edit locales
    </div>
    <DropdownCrossPromoEditorLocales
      updateCrossPromoBatch={updateCrossPromoBatch}
      updateCrossPromo={updateCrossPromo}
      crossPromo={crossPromo}
    />
  </DropdownWrapper>;
};

const DropdownCrossPromoEditorLocales = ({crossPromo, updateCrossPromoBatch, updateCrossPromo}) => {
  const [crossPromoLocal, setCrossPromoLocal] = useState(crossPromo);

  if (Object.keys(crossPromo).length === 0) {
    return null;
  }

  return <div className={'cross-promo-editor-locales-container'}>
    <div className={'cross-promo-editor-locales-item'}>
      <div style={{opacity: 0}}>.</div>
      <div className={'onelight-head-text'} style={{fontSize: '15px'}}>Title</div>
      <div className={'onelight-head-text'} style={{fontSize: '15px'}}>Button</div>
    </div>
    {locales.map((locale) => {
      return <div className={'cross-promo-editor-locales-item'}>
        <div className={'onelight-second-text'}>{locale.toUpperCase()}</div>
        <Input
          handleChange={(value) => {
            const newCrossPromoLocal = {...crossPromoLocal};
            newCrossPromoLocal.main_title = {...crossPromoLocal.main_title, ...{[locale]: value}};
            setCrossPromoLocal(newCrossPromoLocal);
            updateCrossPromo('main_title', newCrossPromoLocal.main_title);
          }}
          value={crossPromoLocal.main_title[locale]}
        />
        <Input
          handleChange={(value) => {
            const newCrossPromoLocal = {...crossPromoLocal};
            newCrossPromoLocal.main_button_title = {...crossPromoLocal.main_button_title, ...{[locale]: value}};
            setCrossPromoLocal(newCrossPromoLocal);
            updateCrossPromo('main_button_title', newCrossPromoLocal.main_button_title);
          }}
          value={crossPromoLocal.main_button_title[locale]}
        />
      </div>;
    })}
  </div>;
};
