import React from 'react';
import DropdownWrapper from '../../components/general/DropdownWrapper';
import {ReactComponent as CalendarIcon} from '../../resources/imgs/calendar-icon.svg';

export const EmptyDates = ({emptyDates = [], className}) => {
  return <DropdownWrapper style={{transform: 'translate(-80%, 50px)'}}>
    <div className={className}>
      <CalendarIcon/>
    </div>
    <EmptyDatesBody
      emptyDates={emptyDates}
    />
  </DropdownWrapper>;
};

const EmptyDatesBody = ({emptyDates}) => {
  return <div className={'empty-dates-body'}>
    {emptyDates.map((date) => {
      return <div>{date}</div>;
    })}
  </div>;
};


