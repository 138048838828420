import React, {useEffect, useState} from 'react';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addApp, AppGroupRelationType, appleGenreIdName, googleGenreIdName} from '../../actions/apps';
import {SingleSelector} from '../general/SingleSelector';
import {Store} from '../../models/store';

const AddApp = ({mode, app, addApp, cancel, meta}) => {
  const [vendorId, setVendorId] = useState(app.vendorId);
  const [genreId, setGenreId] = useState(meta.group ? meta.group.genreId : '6008');
  const [store, setStore] = useState(!meta.isNewGroup && meta.group.store === Store.GOOGLE_PLAY.value ? Store.GOOGLE_PLAY : Store.APPLE);
  const [genreIdName, setGenreIdName] = useState(appleGenreIdName);

  useEffect(() => {
    const newGenderIdName = store.value === Store.APPLE.value ? appleGenreIdName : googleGenreIdName;
    const newGenderIdNameArr = Object.keys(newGenderIdName);
    const newGenderId = newGenderIdNameArr[0];
    setGenreIdName(newGenderIdName);
    setGenreId(newGenderId);
  }, [store.value]);

  const handleAddApp = () => {
    const groupId = meta.group ? meta.group.id : null;
    addApp(vendorId, store.value, meta.isNewGroup, groupId, genreId, meta.appGroupRelationType, meta.groupOrderId);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        {mode === 'EDIT' ? 'Edit App' : 'Add App'}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
                    ID
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={setVendorId}
          value={vendorId}
        />
      </div>
      {!meta.group && meta.appGroupRelationType === AppGroupRelationType.RANKS ?
        <div key={'category'} style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
          <div className={'onelight-second-text'}>
              Category
          </div>
          <SingleSelector
            change={(genreId) => {
              setGenreId(genreId);
            }}
            selectedValue={genreIdName[genreId]}
            style={{
              width: '100%',
              alignItems: 'center',
              paddingTop: '8px',
              zIndex: 2,
            }}
            options={Object.keys(genreIdName).map(((genreId) => {
              return {value: genreId, label: genreIdName[genreId]};
            }))}
          />
        </div> : null
      }
      {!meta.group ?
        <div key={'store'} style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
          <div className={'onelight-second-text'}>
            Store
          </div>
          <SingleSelector
            change={(store) => {
              setStore(store);
            }}
            selectedValue={store}
            selectedLabel={store.label}
            style={{
              width: '100%',
              alignItems: 'center',
              paddingTop: '8px',
              zIndex: 1,
            }}
            options={Object.values(Store).map(((store) => {
              return {value: store, label: store.label};
            }))}
          />
        </div> : null
      }
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={mode === 'EDIT' ? 'onelight-delete-btn' : 'onelight-second-btn'}
        style={{marginRight: (mode === 'EDIT' ? 'auto' : undefined)}}
        onClick={() => {
          cancel();
        }}
      >
        {mode === 'EDIT' ? 'Delete' : 'Cancel'}
      </button>
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={handleAddApp}
        disabled={vendorId === ''}
      >
        {mode === 'EDIT' ? 'Save' : 'Add'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addApp: bindActionCreators(addApp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApp);
