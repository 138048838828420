import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addKeywords, saveKeyword} from '../../actions/keywords';
import {CountrySelector} from '../general/CountrySelector';
import {Checkbox} from '../general/Checkbox';
import {Switcher} from '../general/Switcher';
import {colors} from '../../utils/StyleUtils';
import {TextArea} from '../general/TextArea';
import {KeywordStatus} from './KeywordStatus';

const KeywordDetails = ({app, addKeyword, saveKeyword, keyword, cancel, meta, mode}) => {
  const [keywordTerm, setKeywordTerm] = useState(keyword.keyword);
  const [isPositionsReport, setIsPositionsReport] = useState(keyword.isPositionsReport);
  const [isIncentMonitor, setIsIncentMonitor] = useState(keyword.isIncentMonitor);
  const [isHide, setIsHide] = useState(keyword.isHide);
  const [isSave, setIsSave] = useState(false);
  const [status, setStatus] = useState(keyword.status);

  const [notes, setNotes] = useState(keyword.notes);
  const [country, setCountry] = useState(keyword.country ? keyword.country : meta.country ? meta.country : '');

  useEffect(() => {
    if (isHide) {
      setIsPositionsReport(false);
      setIsIncentMonitor(false);
    } else if (!isPositionsReport && !isIncentMonitor) {
      setIsPositionsReport(true);
    }
  }, [isHide]);

  useEffect(() => {
    if (isSave) {
      handleAddKeyword();
    }
  }, [isSave]);

  const handleAddKeyword = async () => {
    const newKeyword = {...keyword, ...{
      country,
      keyword: keywordTerm,
      notes,
      isHide,
      isIncentMonitor,
      isPositionsReport,
      appId: app.id,
      status,
    }};
    if (mode === 'ADD') {
      addKeyword(newKeyword);
    }
    if (mode === 'EDIT') {
      saveKeyword(newKeyword);
    }
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        {mode === 'ADD' ? 'Add keyword to ' + country : 'Edit keyword'}
      </div>
      {
        mode === 'ADD' ? null : <div className={'keywords-details-switcher-container'} style={{paddingRight: '0px'}}>
          <div className={'keywords-details-switcher-text'} style={{paddingRight: '10px'}}>Hide</div>
          <Switcher
            isActive={isHide}
            colorOn={colors.keywordHideActive}
            colorOff={colors.keywordHideUnActive}
            switch={(isActive) => {
              setIsHide(isActive);
            }}
            id={'keyword_details_hide'}
          />
        </div>
      }

    </div>;
  };

  const renderCheckBoxes = () => {
    return <div style={{display: 'flex'}}>
      <Checkbox
        key={'positions-checkbox'}
        switchCheckbox={(isActive) => {
          setIsPositionsReport(isActive);
        }}
        isDisabled={isHide}
        isActive={isPositionsReport}
        text={'positions report'}
      />
      <Checkbox
        key={'motionc-heckbox'}
        style={{marginLeft: '32px'}}
        switchCheckbox={(isActive) => {
          setIsIncentMonitor(isActive);
        }}
        isDisabled={isHide}
        isActive={isIncentMonitor}
        text={'incent monitor'}
      />
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      {renderCheckBoxes()}
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Locale
        </div>
        <CountrySelector
          style={{paddingTop: '7px'}}
          selectedCountries={[country]}
          isMulti={false}
          isDisabled={mode === 'EDIT'}
          changeCountries={(country) => {
            setCountry(country.toLowerCase());
          }}
        />
      </div>
      <div style={{display: 'flex', maxHeight: '400px', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Keyword
        </div>
        <TextArea style={{marginTop: '7px'}}
          handleChange={setKeywordTerm}
          value={keywordTerm}
          isDisabled={mode === 'EDIT'}
        />
      </div>
      {
        isIncentMonitor ? <div style={{display: 'flex', marginTop: '15px', flexDirection: 'column'}}>
          <div className={'onelight-second-text'}>
            Notes
          </div>
          <TextArea style={{marginTop: '7px'}}
            handleChange={setNotes}
            value={notes}
          />
        </div> : null
      }
      {
        isIncentMonitor ? <div style={{display: 'flex', marginTop: '15px', flexDirection: 'column'}}>
          <div className={'onelight-second-text'}>
            Status
          </div>
          <KeywordStatus style={{marginTop: '7px', width: '93px'}}
            onChange={setStatus}
            status={status}
            withSelect={true}
          />
        </div> : null
      }
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={'onelight-second-btn'}
        onClick={() => {
          cancel();
        }}
      >
        {'Cancel'}
      </button>
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={() => {
          setIsSave(true);
        }}
        disabled={keyword === ''}
      >
        {mode === 'ADD' ? 'Add' : 'Save'}
      </button>
    </div>;
  };
  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addKeyword: bindActionCreators(addKeywords, dispatch),
  saveKeyword: bindActionCreators(saveKeyword, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeywordDetails);
