import React, {useEffect} from 'react';
import 'openapi-explorer';
import MDSpinner from 'react-md-spinner';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getOpenapi} from '../../actions/apps';
import {configs} from '../../resources/configs';

const OpenapiExplorer = ({match, getOpenapi, openapi, isLoadingOpenapi}) => {
  useEffect(() => {
    getOpenapi(match.params.projectName);
  }, [match.params.projectName]);

  if (isLoadingOpenapi) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }
  return (
    <openapi-explorer spec-url={openapi}
      show-authentication={false}
      show-server-selection={false}
      primary-color={'#00DADA'}
      secondary-color={'#00dada'}
      nav-bg-color={'#000000'}
      style={{borderLeft: '#303030 1px solid'}}
      server-url={configs.openapiServerUrl}
    >

    </openapi-explorer>
  );
};

const mapStateToProps = (state) => ({
  isLoadingOpenapi: state.appState.isLoadingOpenapi,
  openapi: state.appState.openapi,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenapi: bindActionCreators(getOpenapi, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenapiExplorer);
