import React from 'react';
import MDSpinner from 'react-md-spinner';
import ReactJson from 'react-json-view';

export const DebugUserPurchasesView = ({purchasesEvents, isLoadingPurchasesEvents}) => {
  return <div style={{display: 'flex', flexDirection: 'column', paddingTop: '30px'}}>
    <div className={'debug-section-title'} style={{paddingBottom: '10px'}}>Purchases</div>
    {
      isLoadingPurchasesEvents ?       <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={60} singleColor={'#00DADA'}/>
      </div> : <div className={'debug-info-container'}>
        <ReactJson src={purchasesEvents} displayDataTypes={false}/>
      </div>
    }
  </div>;
};
