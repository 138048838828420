import React from 'react';
import MDSpinner from 'react-md-spinner';
import {saveFile} from '../../utils/FilesUtils';
import {OnelightMediaType} from '../../models/onelight';

export const EditMedia = ({mediaUrl, onDrop, clear, style, heightMedia, widthMedia, isLoading, mediaType}) => {
  const isActive = false;

  if (isLoading) {
    return <div
      className={'img-edit-dnd-container-active'}
      style={style}
    >
      <MDSpinner size={40} singleColor={'#00DADA'}/>
    </div>;
  }

  if (!mediaUrl) {
    return <div
      className={'img-edit-dnd-container' + (isActive ? '-active' : '') }
      style={style}
    >
      <img style={{width: '46px', height: '31px'}}
        src={require('../../resources/imgs/upload.png').default}
      />

      <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
        onChange={(event) => {
          const file = event.target.files[0];
          if (file && (file.type.startsWith('image/') || file.type.startsWith('video/'))) {
            const mediaType = file.type.startsWith('image/') ? OnelightMediaType.IMAGE : OnelightMediaType.VIDEO;
            onDrop(file, mediaType);
          }
        }}/>
    </div>;
  }

  return <div className="img-edit-container" style={style}>
    {
      mediaType === OnelightMediaType.IMAGE ? <img className={'img-edit'} src={mediaUrl}
        style={{height: heightMedia, width: widthMedia}}
        onClick={() => {
          saveFile(mediaUrl);
        }}
      /> :
          <video className={'img-edit'} width={widthMedia} height={heightMedia} controls>
            <source src={mediaUrl} type="video/mp4"/>
          </video>
    }

    <img className={'close-img'}
      src={require('../../resources/imgs/close.svg').default}
      onClick={clear}
    />
  </div>;
};
