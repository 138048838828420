import uuid from 'uuid/v4';
import {
  CHANGE_INCENT_APP_UNITS_PARAM_ASO_REPORT,
  CHANGE_SEARCH_PARAMS_ASO_REPORT, CONVERT_ASO_REPORT_TO_XML,
  IS_LOADING_ASO_REPORT_DATA,
  SAVE_ASO_REPORT_DATA, SET_IS_HIDE_TABLE_DAYS_ASO_REPORT,
} from '../actions/aso';
import {compareString} from '../utils/ArrayUtils';
import momentZ from 'moment-timezone';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {createAsoReportXML} from '../utils/xmlUtils';
import {Store} from '../models/store';

const getInitialState = () => {
  return {
    stateId: uuid(),
    asoReportDataByRegion: {},
    isLoadingAsoReportDataByRegion: {},
    timeStampSaveAsoReportDataByRegion: {},
    asoReportDataStatsByRegion: {},
    incentAppUnitsParamsByRegion: {},
    isHideTabelDays: false,
    searchParams: (() => {
      const searchParams = getItem('asoReportSearchParams', {
        date: momentZ().format('YYYY-MM-DD'),
        appIds: [],
        countries: [],
        excludeCountries: [],
        appleSourceTypes: [],
        googleSourceTypes: [],
        regions: [],
        period: 7,
        store: Store.APPLE.value,
      });

      if (!searchParams.regions) {
        searchParams.regions = [];
      }

      if (!searchParams.appleSourceTypes) {
        searchParams.appleSourceTypes = [];
      }

      if (!searchParams.googleSourceTypes) {
        searchParams.googleSourceTypes = [];
      }

      if (!searchParams.store) {
        searchParams.store = Store.APPLE.value;
      }

      if (!searchParams.excludeCountries) {
        searchParams.excludeCountries = [];
      }
      return searchParams;
    })(),
  };
};

export default function asoReport(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_ASO_REPORT_DATA:
      const timeStampSaveAsoReportData = action.requestTimeEpoch;
      const timeStampSaveAsoReportDataRegion = state.timeStampSaveAsoReportDataByRegion[action.region] ? state.timeStampSaveAsoReportDataByRegion[action.region] : 0;

      if (
        timeStampSaveAsoReportData > timeStampSaveAsoReportDataRegion &&
                action.stateId === state.stateId
      ) {
        let incentAppUnitsParams = {...state.incentAppUnitsParamsByRegion[action.region]};
        if (!state.incentAppUnitsParamsByRegion[action.region]) {
          incentAppUnitsParams = {
            afterAppUnits: 0,
            beforeAppUnits: 0,
            afterPrice: 0,
            beforePrice: 0,
          };
        }

        action.asoReportData.raw = action.asoReportData.raw.reverse();
        return Object.assign({}, state, {
          asoReportDataByRegion: {
            ...state.asoReportDataByRegion,
            ...{[action.region]: action.asoReportData},
          },
          incentAppUnitsParamsByRegion: {
            ...state.incentAppUnitsParamsByRegion,
            ...{[action.region]: incentAppUnitsParams},
          },
          asoReportDataStatsByRegion: {
            ...state.asoReportDataStatsByRegion,
            ...{[action.region]: calcAsoReportStats(action.asoReportData, incentAppUnitsParams)},
          },
          isLoadingAsoReportDataByRegion: {
            ...state.isLoadingAsoReportDataByRegion,
            ...{[action.region]: false},
          },
          timeStampSaveAsoReportDataByRegion: {
            ...state.timeStampSaveAsoReportDataByRegion,
            ...{[action.region]: timeStampSaveAsoReportData},
          },
        });
      }

    case IS_LOADING_ASO_REPORT_DATA:
      return Object.assign({}, state, {
        isLoadingAsoReportDataByRegion: {
          ...state.isLoadingAsoReportDataByRegion,
          ...{[action.region]: action.isLoading},
        },
      });

    case CHANGE_INCENT_APP_UNITS_PARAM_ASO_REPORT: {
      const incentAppUnitsParams = {...state.incentAppUnitsParamsByRegion[action.region], ...{[action.prop]: action.value}};
      let incentAppUnitsParamsByRegion = {...state.incentAppUnitsParamsByRegion, ...{[action.region]: incentAppUnitsParams}};
      const incentAll = Object.keys(incentAppUnitsParamsByRegion)
          .filter((region) => region !== 'worldwide')
          .reduce((sum, currentKey) => {
            const current = incentAppUnitsParamsByRegion[currentKey];
            Object.keys(current).forEach((key) => {
              if (!sum[key]) {
                sum[key] = 0;
              }
              sum[key] += current[key];
            });
            return sum;
          }, {});

      incentAppUnitsParamsByRegion = {...incentAppUnitsParamsByRegion, ...{worldwide: incentAll}};

      let asoReportDataStatsByRegion = {
        ...state.asoReportDataStatsByRegion,
        ...{[action.region]: calcAsoReportStats(
            state.asoReportDataByRegion[action.region],
            incentAppUnitsParams,
        )},
      };

      asoReportDataStatsByRegion = {
        ...asoReportDataStatsByRegion,
        ...{worldwide: calcAsoReportStats(
            state.asoReportDataByRegion.worldwide,
            incentAppUnitsParams,
        )},
      };
      return Object.assign({}, state, {
        incentAppUnitsParamsByRegion,
        asoReportDataStatsByRegion,
      });
    }

    case CONVERT_ASO_REPORT_TO_XML: {
      createAsoReportXML(
          state.asoReportDataByRegion,
          state.asoReportDataStatsByRegion,
          state.incentAppUnitsParamsByRegion,
          state.searchParams.countries.concat(state.searchParams.regions),
          state.searchParams.store,
      );
      return state;
    }

    case SET_IS_HIDE_TABLE_DAYS_ASO_REPORT: {
      return Object.assign({}, state, {
        isHideTabelDays: action.isHide,
      });
    }

    case CHANGE_SEARCH_PARAMS_ASO_REPORT:
      const newSearchParams = Object.assign({}, state.searchParams, {
        [action.prop]: action.value,
      });

      saveItem(newSearchParams, 'asoReportSearchParams');

      return Object.assign({}, state, {
        searchParams: newSearchParams,
      });

    default: return state;
  }
}

const calcAsoReportStats = (asoReportData, incentAppUnitsParams) => {
  if (!incentAppUnitsParams) {
    incentAppUnitsParams = {
      afterAppUnits: 0,
      beforeAppUnits: 0,
      afterPrice: 0,
      beforePrice: 0,
    };
  }
  const templateStats = JSON.stringify(
      {
        sumAfter: 0,
        sumAfterColor: 'white',
        sumBefore: 0,
        sumBeforeColor: 'white',
        diff: 0,
        diffColor: 'white',
        diffPercent: 0,
        avgAfter: 0,
        avgBefore: 0,
        colorsByDayAfter: {},
        colorsByDayBefore: {},
      },
  );
  const asoReportStats = {
    impressions: JSON.parse(templateStats),
    pageViews: JSON.parse(templateStats),
    appUnits: JSON.parse(templateStats),
    ratio: JSON.parse(templateStats),
  };

  asoReportData.raw.forEach((asoReport) => {
    asoReport.ratio = asoReport.ratio ? asoReport.ratio : 0;
    const impressions = asoReport.impressions === 0 ? (asoReport.ratio !== 0 ? (100 * asoReport.appUnits) / asoReport.ratio : 0) : asoReport.impressions;

    if (asoReport.date > asoReportData.meta.date) {
      asoReportStats.impressions.sumAfter += impressions;
      asoReportStats.pageViews.sumAfter += asoReport.pageViews;
      asoReportStats.appUnits.sumAfter += asoReport.appUnits;
      asoReportStats.ratio.sumAfter += asoReport.ratio;

      asoReportStats.impressions.colorsByDayAfter[asoReport.date] = impressions;
      asoReportStats.pageViews.colorsByDayAfter[asoReport.date] = asoReport.pageViews;
      asoReportStats.appUnits.colorsByDayAfter[asoReport.date] = asoReport.appUnits;
      asoReportStats.ratio.colorsByDayAfter[asoReport.date] = asoReport.ratio;
    } else {
      asoReportStats.impressions.sumBefore += impressions;
      asoReportStats.pageViews.sumBefore += asoReport.pageViews;
      asoReportStats.appUnits.sumBefore += asoReport.appUnits;
      asoReportStats.ratio.sumBefore += asoReport.ratio;

      asoReportStats.impressions.colorsByDayBefore[asoReport.date] = impressions;
      asoReportStats.pageViews.colorsByDayBefore[asoReport.date] = asoReport.pageViews;
      asoReportStats.appUnits.colorsByDayBefore[asoReport.date] = asoReport.appUnits;
      asoReportStats.ratio.colorsByDayBefore[asoReport.date] = asoReport.ratio;
    }
  });
  Object.keys(asoReportStats).forEach((metricName) => {
    asoReportStats[metricName].avgAfter = asoReportStats[metricName].sumAfter / Object.keys(asoReportStats[metricName].colorsByDayAfter).length;
    asoReportStats[metricName].avgBefore = asoReportStats[metricName].sumBefore / Object.keys(asoReportStats[metricName].colorsByDayBefore).length;
    if (metricName === 'ratio') {
      asoReportStats[metricName].sumAfter = ((asoReportStats.appUnits.sumAfter / asoReportStats.impressions.sumAfter) * 100);
      asoReportStats[metricName].sumBefore = ((asoReportStats.appUnits.sumBefore / asoReportStats.impressions.sumBefore) * 100);
    }

    if (metricName === 'appUnits' || metricName === 'impressions' ) {
      asoReportStats[metricName].sumAfter -= incentAppUnitsParams.afterAppUnits;
      asoReportStats[metricName].sumBefore -= incentAppUnitsParams.beforeAppUnits;
    }
    asoReportStats[metricName].diff = asoReportStats[metricName].sumAfter - asoReportStats[metricName].sumBefore;
    asoReportStats[metricName].diffPercent = ((asoReportStats[metricName].diff * 100) / asoReportStats[metricName].sumAfter).toFixed(2);
    asoReportStats[metricName].diffColor = asoReportStats[metricName].diff > 0 ? `rgba(101, 225, 120, 1)` : `rgba(255, 107, 99, 1)`;
    asoReportStats[metricName].sumAfterColor = asoReportStats[metricName].sumAfter > asoReportStats[metricName].sumBefore ? `rgba(101, 225, 120, 1)` : `rgba(255, 107, 99, 1)`;
    asoReportStats[metricName].sumBeforeColor = asoReportStats[metricName].sumBefore > asoReportStats[metricName].sumAfter ? `rgba(101, 225, 120, 1)` : `rgba(255, 107, 99, 1)`;

    asoReportStats[metricName].colorsByDayAfter = coloredAsoReport(
        asoReportStats[metricName].colorsByDayAfter,
        asoReportStats[metricName].avgAfter,
    );
    asoReportStats[metricName].colorsByDayBefore = coloredAsoReport(
        asoReportStats[metricName].colorsByDayBefore,
        asoReportStats[metricName].avgBefore,
    );
  });

  const toDate = momentZ(asoReportData.meta.date).add(asoReportData.meta.period, 'day');
  const fromDate = momentZ(asoReportData.meta.date).subtract(asoReportData.meta.period - 1, 'day');

  asoReportStats.meta = {
    beforePeriod: fromDate.format('MM.DD') + '-' + momentZ(asoReportData.meta.date).format('MM.DD'),
    afterPeriod: momentZ(asoReportData.meta.date).add(1, 'day').format('MM.DD') + '-' + toDate.format('MM.DD'),
  };

  return asoReportStats;
};

const coloredAsoReport = (metricsByDate, avg) => {
  const coloredMetrics = {};
  const metricsCount = Object.keys(metricsByDate).length;
  const ratioOpacity = 1 / (metricsCount / 2);
  let negativeSumRatio = 1;
  let positiveSumRatio = ratioOpacity;

  Object.keys(metricsByDate)
      .map((date) => {
        return {date, value: metricsByDate[date]};
      })
      .sort(compareString('value'))
      .forEach((metric) => {
        if (metric.value < avg) {
          coloredMetrics[metric.date] = `rgba(255, 107, 99, ${negativeSumRatio.toFixed(2)})`;
          negativeSumRatio -= ratioOpacity;
          if (negativeSumRatio <= 0) {
            negativeSumRatio = 0.05;
          }
        } else {
          coloredMetrics[metric.date] = `rgba(101, 225, 120, ${positiveSumRatio.toFixed(2)})`;
          positiveSumRatio += ratioOpacity;
        }
      });

  return coloredMetrics;
};
