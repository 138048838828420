import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {getAppsWithRanks} from '../../actions/ranks';
import {connect} from 'react-redux';
import {RanksBody} from './RanksBody';
import {showModal} from '../../actions/general';
import MDSpinner from 'react-md-spinner';
import {UpdateTimeHeader} from '../general/UpdateTimeHeader';
import {removeAppFromGroup} from '../../actions/apps';

const Ranks = ({
  getAppsWithRanks, isLoadApps, isLoadingApps, deleteApp, groups,
  apps, countries, showModal, maxRanksByCountries, lastUpdateAppsDate,
}) => {
  useEffect(() => {
    if (isLoadApps && !isLoadingApps) {
      getAppsWithRanks();
    }
  }, [isLoadApps]);

  const rendersRanks = () => {
    return Object.keys(apps).sort((a, b) => {
      a = groups[Number(a)].order;
      b = groups[Number(b)].order;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    }).map((groupId) => {
      return <RanksBody
        key={groupId + '_appGroup'}
        isLoadingApps={isLoadingApps}
        apps={apps[groupId]}
        group={groups[Number(groupId)]}
        isNewGroup={false}
        maxRanksByCountries={maxRanksByCountries[groupId]}
        countries={countries}
        showModal={showModal}
        deleteApp={deleteApp}
        totalGroups={Object.keys(groups).length}
      />;
    });
  };

  if (isLoadingApps) {
    return (
      <div className='spinner-container' style={{width: '100%'}}>
        <MDSpinner size={100} singleColor={'#00DADA'}/>
      </div>
    );
  }

  return (
    <div className={'ranks-container'}>
      <UpdateTimeHeader
        date={lastUpdateAppsDate}
      />
      {rendersRanks()}
      <RanksBody
        isLoadingApps={isLoadingApps}
        apps={[]}
        isNewGroup={true}
        group={null}
        maxRanksByCountries={{}}
        countries={countries}
        showModal={showModal}
        totalGroups={Object.keys(groups).length}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoadApps: state.ranks.isLoadApps,
  isLoadingApps: state.ranks.isLoadingApps,
  apps: state.ranks.apps,
  countries: state.ranks.countries,
  maxRanksByCountries: state.ranks.maxRanksByCountries,
  lastUpdateAppsDate: state.ranks.lastUpdateAppsDate,
  groups: state.ranks.groups,
});

const mapDispatchToProps = (dispatch) => ({
  getAppsWithRanks: bindActionCreators(getAppsWithRanks, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  deleteApp: bindActionCreators(removeAppFromGroup, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ranks);
