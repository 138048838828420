import React from 'react';
import Select, {components} from 'react-select';
import {AiFillApple} from 'react-icons/ai';
import {ReactComponent as AndroidIcon} from '../../resources/imgs/androidIcon.svg';
import {selectStyles} from '../../utils/StyleUtils';

const {Option, MultiValueLabel, SingleValue} = components;

const iconStyle = {minWidth: '15px', minHeight: '14px', paddingRight: '6px'};

const IconSingleValue = (props) => {
  return <SingleValue {...props}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img
        src={props.data.icon}
        style={{width: 32, height: 32, marginRight: '5px', borderRadius: '6px'}}
        alt={props.data.label}
      />
      <div className={'select-app-label'}>
        {props.data.isAppstore ? <AiFillApple style={iconStyle}/> : <AndroidIcon style={iconStyle}/>}
        {props.data.label}
      </div>
    </div>
  </SingleValue>;
};

const IconOption = (props) => (
  <Option {...props}>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img
        src={props.data.icon}
        style={{width: 32, height: 32, borderRadius: '6px'}}
        alt={props.data.label}
      />
      <div className={'select-app-label'}>
        {props.data.isAppstore ? <AiFillApple style={iconStyle}/> : <AndroidIcon style={iconStyle}/>}
        {props.data.label}
      </div>
    </div>
  </Option>
);

const IconMultiValueLabel = (props) => (
  <MultiValueLabel {...props}>
    <img
      src={props.data.icon}
      style={{width: 24, borderRadius: '5px'}}
      alt={props.data.label}
    />
  </MultiValueLabel>
);

export const AppSelector = ({
  selectedApps, changeApps, allApps, isMulti = true, menuIsOpen = undefined, style, isAppIdentifier, placeholder='App',
}) => {
  const selector = isAppIdentifier ? 'appIdentifier' : 'id';
  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        if (isMulti) {
          changeApps(!selectedOption ? [] : selectedOption.map((option) => option.value));
        } else {
          changeApps(selectedOption ? selectedOption.value : null );
        }
      }}
      menuIsOpen={menuIsOpen}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      value={selectedApps.map((appId) => {
        const app = allApps.find((app) => app[selector] === appId);
        if (!app) {
          const app = allApps[0];
          return {value: app[selector], icon: app.icon, label: app.name, isAppstore: app.store === 'apple'};
        }
        const icon = app ? app.icon : undefined;
        return {value: app[selector], icon, label: app.name, isAppstore: app.store === 'apple'};
      })}
      isClearable={true}
      components={{Option: IconOption, MultiValueLabel: IconMultiValueLabel, SingleValue: IconSingleValue}}
      styles={selectStyles()}
      name="apps"
      options={allApps.map((app) => {
        return {value: app[selector], label: app.name, icon: app.icon, isAppstore: app.store === 'apple'};
      })}
      className="basic-multi-select select-label"
      classNamePrefix="select"
      placeholder={placeholder}
    />
  </div>;
};


