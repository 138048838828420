import React, {useState} from 'react';
import {SingleSelector} from '../general/SingleSelector';
import {locales} from '../../models/onelight';

export const CrossPromoPreview = ({crossPromo, backgroundImageNameSelected}) => {
  const [locale, setLocale] = useState('en');

  if (Object.keys(crossPromo).length === 0) {
    return null;
  }

  return <div style={{display: 'flex', flexDirection: 'column', marginLeft: '50px'}}>
    <SingleSelector
      change={(locale) => {
        setLocale(locale);
      }}
      selectedValue={locale}
      selectedLabel={locale}
      style={{
        width: '410px',
        alignItems: 'center',
        paddingBottom: '8px',
        zIndex: 1,
      }}
      options={locales.map(((locale) => {
        return {value: locale, label: locale};
      }))}
    />
    <div className={'cross-promo-preview-container'} style={{background: (crossPromo[backgroundImageNameSelected] ? `url(${crossPromo[backgroundImageNameSelected]}) no-repeat` : crossPromo.background_color)}}>
      <div style={{borderRadius: '30px', height: '25px',
        width: '25px', position: 'fixed', right: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center',
        top: '40px', backgroundColor: crossPromo.close_button_background_color}}>
        <svg width="25" height="25" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4L8 8M8 4L4 8" stroke={crossPromo.close_button_color} stroke-width="0.723316" stroke-linecap="round"/>
        </svg>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '25%'}} className={'onelight-head-text'}>
        <div style={{color: crossPromo.main_title_color, paddingBottom: '25px', textAlign: 'center'}}>
          {crossPromo.main_title[locale]}
        </div>

        <div onClick={() => {
          if (crossPromo.main_button_url && crossPromo.main_button_url.length !== 0) {
            window.open(crossPromo.main_button_url);
          }
        }}
        className={'cross-promo-btn'}
        style={{color: crossPromo.main_button_title_color, backgroundColor: crossPromo.main_button_background_color}}>
          {crossPromo.main_button_title[locale]}
        </div>
      </div>
    </div>
  </div>;
};
