import uuid from 'uuid/v4';
import {
  CREATE_CROSS_PROMO, DELETE_CROSS_PROMO, IMPORT_CROSS_PROMO,
  MOVE_CROSS_PROMO,
  SELECT_CROSS_PROMO,
  SELECT_CROSS_PROMO_APP,
  UPDATE_CROSS_PROMO, UPDATE_CROSS_PROMO_BATCH,
} from '../actions/crossPromo';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {SAVE_MARKETING_CONFIG, SET_IS_LOADING_MARKETING_CONFIG} from '../actions/general';

const getInitialState = () => {
  return {
    stateId: uuid(),
    selectedAppCrossPromo: getItem('selectedAppCrossPromo', {}),
    selectedCrossPromo: {},
    isLoadingMarketingConfig: false,
    marketingConfigByAppStoreId: {},
  };
};

const getDefaultCrossPromo = (vendorId) => ({
  'id': uuid(),
  'app_id': vendorId,
  'schema_id': appIdToSchemaId[String(vendorId)],
  'background_image_a_x1': null,
  'background_image_b_x1': null,
  'background_image_a_x2': null,
  'background_image_b_x2': null,
  'background_image_a_x3': null,
  'background_image_b_x3': null,
  'background_color': '#ffffff',
  'main_title': {'en': 'New main title'},
  'main_button_title': {'en': 'Main title button'},
  'main_title_color': '#000000',
  'main_button_title_color': '#000000',
  'main_button_background_color': '#b0adad',
  'close_button_background_color': '#b0adad',
  'close_button_color': '#000000',
  'main_button_url': '',
});

export default function crossPromo(state = getInitialState(), action) {
  switch (action.type) {
    case SELECT_CROSS_PROMO_APP:
      saveItem(action.app ? action.app : {}, 'selectedAppCrossPromo');
      return Object.assign({}, state, {
        selectedAppCrossPromo: action.app,
        selectedCrossPromo: {}
      });

    case SELECT_CROSS_PROMO:
      return Object.assign({}, state, {
        selectedCrossPromo: action.crossPromo,
      });

    case SAVE_MARKETING_CONFIG: {
      const marketingConfig = action.remoteConfig ? action.remoteConfig : {
        cross_promo_waterfall: [],
      };
      marketingConfig.cross_promo_waterfall = marketingConfig.cross_promo_waterfall.map(crossPromo => {
        return {...getDefaultCrossPromo(crossPromo.app_id), ...crossPromo};
      })
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[action.appStoreId]: marketingConfig}},
        isLoadingMarketingConfig: false,
      });
    }

    case CREATE_CROSS_PROMO: {
      const newMarketingConfig = {...state.marketingConfigByAppStoreId[state.selectedAppCrossPromo.vendorId], ...{}};
      const newCrossPromo = {...getDefaultCrossPromo(action.app.vendorId)};
      newMarketingConfig.cross_promo_waterfall.unshift(newCrossPromo);

      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[state.selectedAppCrossPromo.vendorId]: newMarketingConfig}},
        selectedCrossPromo: newCrossPromo,
      });
    }

    case MOVE_CROSS_PROMO: {
      const newMarketingConfig = {...state.marketingConfigByAppStoreId[state.selectedAppCrossPromo.vendorId], ...{}};
      newMarketingConfig.cross_promo_waterfall = action.crossPromoList;
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[state.selectedAppCrossPromo.vendorId]: newMarketingConfig}},
      });
    }

    case UPDATE_CROSS_PROMO: {
      const newMarketingConfig = {...state.marketingConfigByAppStoreId[state.selectedAppCrossPromo.vendorId], ...{}};
      let newSelectedCrossPromo;
      newMarketingConfig.cross_promo_waterfall = newMarketingConfig.cross_promo_waterfall.map((crossPromo) => {
        if (crossPromo.id === state.selectedCrossPromo.id) {
          newSelectedCrossPromo = {...crossPromo, ...{[action.propName]: action.value}};
          return newSelectedCrossPromo;
        }
        return crossPromo;
      });
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[state.selectedAppCrossPromo.vendorId]: newMarketingConfig}},
        selectedCrossPromo: newSelectedCrossPromo,
      });
    }

    case UPDATE_CROSS_PROMO_BATCH: {
      const newMarketingConfig = {...state.marketingConfigByAppStoreId[state.selectedAppCrossPromo.vendorId], ...{}};
      let newSelectedCrossPromo;
      newMarketingConfig.cross_promo_waterfall = newMarketingConfig.cross_promo_waterfall.map((crossPromo) => {
        if (crossPromo.id === state.selectedCrossPromo.id) {
          newSelectedCrossPromo = {...crossPromo, ...{}};
          action.batch.forEach(paramsUpdate => {
            newSelectedCrossPromo = {...newSelectedCrossPromo, ...{[paramsUpdate.propName]: paramsUpdate.value}};
          })
          return newSelectedCrossPromo;
        }
        return crossPromo;
      });
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[state.selectedAppCrossPromo.vendorId]: newMarketingConfig}},
        selectedCrossPromo: newSelectedCrossPromo,
      });
    }

    case IMPORT_CROSS_PROMO: {
      if (!action.crossPromo || !action.crossPromo.id) {
        return state;
      }
      const importedCrossPromo = action.crossPromo;
      importedCrossPromo.id = state.selectedCrossPromo.id;
      importedCrossPromo.app_id = state.selectedCrossPromo.app_id;

      const newMarketingConfig = {...state.marketingConfigByAppStoreId[state.selectedAppCrossPromo.vendorId], ...{}};
      newMarketingConfig.cross_promo_waterfall = newMarketingConfig.cross_promo_waterfall.map((crossPromo) => {
        if (crossPromo.id === state.selectedCrossPromo.id) {
          return importedCrossPromo;
        }
        return crossPromo;
      });
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: {...state.marketingConfigByAppStoreId, ...{[state.selectedAppCrossPromo.vendorId]: newMarketingConfig}},
        selectedCrossPromo: importedCrossPromo,
      });
    }

    case DELETE_CROSS_PROMO: {
      const newMarketingConfigByAppStoreId = {...state.marketingConfigByAppStoreId, ...{}};
      Object.keys(newMarketingConfigByAppStoreId).forEach((appstoreId) => {
        newMarketingConfigByAppStoreId[appstoreId] = {...newMarketingConfigByAppStoreId[appstoreId], ...{
          cross_promo_waterfall: newMarketingConfigByAppStoreId[appstoreId].cross_promo_waterfall.filter((crossPromo) => crossPromo.id !== action.crossPromoId),
        }};
      });
      return Object.assign({}, state, {
        marketingConfigByAppStoreId: newMarketingConfigByAppStoreId,
        selectedCrossPromo: state.selectedCrossPromo.id === action.crossPromoId ? {} : state.selectedCrossPromo,
      });
    }

    case SET_IS_LOADING_MARKETING_CONFIG:
      return Object.assign({}, state, {
        isLoadingMarketingConfig: action.isLoading,
      });

    default: return state;
  }
}

const appIdToSchemaId = {
  'com.feelty': 'com.feelty',
  'io.onelightapps.inpreview': 'io.onelightapps.inpreview',
  'io.onelightapps.fonts': 'io.onelightapps.fonts',
  'io.onelightapps.ton.video.photo.filters': 'io.onelightapps.ton.video.photo.filters',
  'io.onelightapps.video.reels.maker.beat': 'io.onelightapps.video.reels.maker.beat',
  '1448103572': 'fb2322960617957526://',
  '1171686996': 'fb358609218237402://',
  '791557660': 'fb1479106612533521://',
  '1486140157': 'fb997064134004636://',
  '1460724055': 'fb371139905153570://',
  '1585159557': 'fb1950299858470801://',
  '1518549702': 'fb275061187169997://',
  '688319243': 'fb254407011931141://',
  '1482629672': 'fb466837767307821://',
  '1047340501': 'fb1179909332554461://',
  '6443517019': 'fb603674121461767://'
}
