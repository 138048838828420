import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import momentZ from 'moment-timezone';
import {hexify} from './StyleUtils';

const getColorAsoData = (propName, date, stats) => {
  if (!stats[propName]) {
    return 'ffffff';
  }
  let color = stats[propName].colorsByDayAfter[date];
  if (!color) {
    color = stats[propName].colorsByDayBefore[date];
  }
  const hex = hexify(color);

  return hex ? hex : 'ffffff';
};

const createStatsRow = (sheet, asoReportDataStats, period, title, prop, colorProp, pref = '', isRenderSeparator = false) => {
  const row = sheet.addRow([
    period,
    title,
    (asoReportDataStats.impressions[prop] + pref),
    (asoReportDataStats.pageViews[prop] + pref),
    (asoReportDataStats.appUnits[prop] + pref),
    (Number(asoReportDataStats.ratio[prop]).toFixed(2) + '%'),
  ]);
  const columnToProps = {A: 'date', B: 'day', C: 'impressions', D: 'pageViews', E: 'appUnits', F: 'ratio'};

  row.eachCell(function(cell) {
    if (asoReportDataStats[columnToProps[cell.address[0]]]) {
      const color = hexify(asoReportDataStats[columnToProps[cell.address[0]]][colorProp]);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: color,
        },
      };
    }

    if (isRenderSeparator) {
      cell.border = {
        top: {style: 'double', color: {argb: 'FF000000'}},
      };
    }
  });
};

export const createAsoReportXML = (asoReportDataByRegion, asoReportDataStatsByRegion, incentAppUnitsParamsByRegion, countries, store) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Aso report', {properties: {tabColor: {argb: 'FFb0dfc1'}}});
  const columnToProps = {A: 'date', B: 'day', C: 'impressions', D: 'pageViews', E: 'appUnits', F: 'ratio'};

  Object.keys(asoReportDataByRegion).forEach((region, regionIndex) => {
    if (countries.indexOf(region) === -1 && region !== 'worldwide') {
      return;
    }
    const asoReportData = asoReportDataByRegion[region];
    const asoReportDataStats = asoReportDataStatsByRegion[region];
    const incentAppUnitsParams = incentAppUnitsParamsByRegion[region];

    sheet.addRow([region]);
    sheet.addRow(['Date', 'Day', 'Impressions', 'Page views', 'Downloads', 'Ratio'] );
    asoReportData.raw.forEach((data) => {
      const isRenderSeparator= data.date === asoReportData.meta.date;
      const row = sheet.addRow([data.date, momentZ(data.date).format('ddd'), data.impressions, data.pageViews, data.appUnits, Number(data.ratio).toFixed(2) + '%']);
      row.eachCell(function(cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: (getColorAsoData(columnToProps[cell.address[0]], data.date, asoReportDataStats)),
          },
        };
        if (isRenderSeparator) {
          cell.border = {
            top: {style: 'double', color: {argb: 'FFff1500'}},
          };
        }
      });
    });

    createStatsRow(sheet, asoReportDataStats, asoReportDataStats.meta.beforePeriod, 'Before', 'sumBefore', 'sumBeforeColor', '', true);
    createStatsRow(sheet, asoReportDataStats, asoReportDataStats.meta.afterPeriod, 'After', 'sumAfter', 'sumAfterColor', '');
    createStatsRow(sheet, asoReportDataStats, '', 'Change', 'diff', 'diffColor', '');
    createStatsRow(sheet, asoReportDataStats, '', 'Change %', 'diffPercent', 'diffColor', '%');

    const totalPriceBefore = incentAppUnitsParams.beforeAppUnits * incentAppUnitsParams.beforePrice;
    const totalPriceAfter = incentAppUnitsParams.afterAppUnits * incentAppUnitsParams.afterPrice;

    sheet.addRow(['Incentive traffic', asoReportDataStats.meta.beforePeriod, incentAppUnitsParams.beforeAppUnits, incentAppUnitsParams.beforePrice, totalPriceBefore]);
    sheet.addRow(['', asoReportDataStats.meta.afterPeriod, incentAppUnitsParams.afterAppUnits, incentAppUnitsParams.afterPrice, totalPriceAfter]);
    sheet.addRow(['', '', incentAppUnitsParams.afterAppUnits + incentAppUnitsParams.beforeAppUnits, '', totalPriceAfter]);

    sheet.addRow(['']);
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    saveAs(blob, 'aso-report.xlsx');
  });
};
