import React, { Component } from 'react'

export class DeleteAttention extends Component {

    renderBody = () => {
        return <div className={"attention-body"}>
            <div className={"onelight-attention-text"}>
                Are you sure ?
            </div>
            <div className={"onelight-attention-description-text"} style={{marginTop: "25px", paddingLeft: '70px'}}>
                Your will not be able to recover this <span className={"onelight-attention-description-text-br"}>{this.props.description}</span>
            </div>
        </div>
    }

    renderFooter = () => {

        return <div className={"attention-footer"}>
            <button className={"onelight-second-btn"} onClick={()=>{this.props.cancel()}}>
                Cancel
            </button>

            <button className={"onelight-delete-btn"} style={{
                marginLeft: "25px",
            }}
                    onClick={() => {
                        this.props.onDelete()
                    }}
            >
                Delete
            </button>
        </div>
    }

    render () {
        return (
            <div className={"attention-container"}>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }
}