import React, { Component } from 'react'
import debounce from 'throttle-debounce/debounce'

export class SearchInput extends Component {

    constructor(props) {
        super(props)
        this.state = {inputValue: this.props.value}
        this.handleChange = debounce(200, this.props.handleChange)
    }

    handleChangeLocal = (value) => {
        const newState = {...this.state}

        newState.inputValue = value

        this.setState(newState)
        this.handleChange(newState.inputValue)
    }


    render () {
        return (
            <input type="text"
                   value={this.state.inputValue}
                   onChange={(event) => (
                       this.handleChangeLocal(event.target.value)
                   )}
                   style={this.props.style}
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   onKeyPress={this.props.handleKeyPress ? this.props.handleKeyPress : undefined}
                   className="onelight-input-search"/>
        )
    }
}
