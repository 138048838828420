import React from 'react';

export const DiffCompare = ({value, postfix='', isNegative, isRevert = false}) => {
  const isUp = isRevert ? (isNegative ? value <= 0 : value > 0) : (isNegative ? value > 0 : value <= 0);
  const zeroColor = value === 0 ? 'gray' : undefined;
  const zeroColorStyle = {color: zeroColor, borderColor: zeroColor};

  return <div className={'rank-container'}>
    <div className={'keyword-rank-position-diff-' + (isUp ? 'up' : 'down')} style={zeroColorStyle}>
      {value.toFixed(2) + postfix}
      <div className={'arrow-rank ' + (isUp ? 'up-rank' : 'down-rank')} style={zeroColorStyle}></div>
    </div>
  </div>;
};
