import uuid from 'uuid/v4';
import {SAVE_PROMO_CODE, DELETE_PROMO_CODE, SAVE_PROMO_CODES, SET_IS_LOADING_PROMO_CODES} from '../actions/promo';

const getInitialState = () => {
  return {
    stateId: uuid(),
    promoCodes: [],
    selectedPromoCode: null,
    isLoadingPromoCodes: false,
    timeStampSavePromoCodes: 0,
  };
};

export default function promo(state = getInitialState(), action) {
  switch (action.type) {
    case SAVE_PROMO_CODES:
      const timeStampSavePromoCodes = action.requestTimeEpoch;
      const stateIdRequestedSaveApps = action.stateId;

      if (
        timeStampSavePromoCodes > state.timeStampSavePromoCodes &&
                stateIdRequestedSaveApps === state.stateId
      ) {
        return Object.assign({}, state, {
          promoCodes: action.promoCodes,
          timeStampSavePromoCodes: timeStampSavePromoCodes,
          isLoadingPromoCodes: false,
        });
      }

    case SAVE_PROMO_CODE: {
      const promoCodes = [...state.promoCodes];
      promoCodes.unshift(action.promoCode);

      return Object.assign({}, state, {
        promoCodes,
      });
    }

    case DELETE_PROMO_CODE: {
      const promoCodes = state.promoCodes.filter((promoCode) => {
        return promoCode.id !== action.id;
      });

      return Object.assign({}, state, {
        promoCodes,
        selectedPromoCode: state.selectedPromoCode &&
                state.selectedPromoCode.id === action.id ? null : state.selectedPromoCode,
      });
    }

    case SET_IS_LOADING_PROMO_CODES:
      return Object.assign({}, state, {
        isLoadingPromoCodes: action.isLoading,
      });

    default: return state;
  }
}
