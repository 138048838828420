import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AppSelector} from '../general/AppSelector';
import {getItem} from '../../utils/LocalStoargeUtill';
import {savePromoCode} from '../../actions/promo';
import {Input} from '../general/Input';
import {SingleDatePicker} from '../general/SingleDatePicker';
import momentZ from 'moment-timezone';

const DetailsPromoCode = ({
  promoCode, savePromoCode, cancel, onelightApps,
}) => {
  const [code, setCode] = useState(promoCode.code);
  const [onelink, setOnelink] = useState(promoCode.onelink);
  const [appIdentifier, setAppIdentifier] = useState(promoCode.appIdentifier);
  const [expirationDate, setExpirationDate] = useState(promoCode.expirationDate);

  const admin = getItem('admin', null);

  const handleSaveApp = () => {
    const promoCode = {...promoCode};
    promoCode.appIdentifier = appIdentifier;
    promoCode.code = code;
    promoCode.expirationDate = momentZ(expirationDate).toDate();
    promoCode.id = `${appIdentifier}_${code}`;
    promoCode.updatedAt = new Date();
    promoCode.creatorId = admin.id;

    const label = {
      "promo_code": code
    }
    const labelName = onelink.includes('go.link') ? 'adj_label' : 'label';
    promoCode.onelink = `${onelink}?${labelName}=${btoa(JSON.stringify(label))}`;

    savePromoCode(promoCode);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        Add promo
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Promocode
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={setCode}
          value={code}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Adjust link
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={setOnelink}
          value={onelink}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          App
        </div>
        <AppSelector
          selectedApps={!appIdentifier ? [] : [appIdentifier]}
          style={{paddingTop: '7px', zIndex: 5000}}
          allApps={onelightApps}
          isMulti={false}
          isAppIdentifier={true}
          changeApps={(appIdentifier) => {
            setAppIdentifier(appIdentifier);
          }}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '133px'}}>
          <div className={'onelight-second-text'}>
            Expiration date
          </div>
          <SingleDatePicker
            date={momentZ(expirationDate)}
            styleInput={{borderRadius: '4px', border: '1px solid #E4EAF0', marginTop: '7px'}}
            style={{zIndex: 4999}}
            dropdownStyle={{transform: 'translate(-50%, -100%)'}}
            onChange={(newDate) => {
              setExpirationDate(momentZ(newDate));
            }}
          />
        </div>
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={'onelight-second-btn'}
        onClick={() => {
          cancel();
        }}
      >
        Cancel
      </button>
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={handleSaveApp}
        disabled={!appIdentifier || code === '' || onelink === ''}
      >
        Add
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({
  onelightApps: state.appState.onelightApps.filter((app) => app.store === 'apple' && app.appIdentifier),
});

const mapDispatchToProps = (dispatch) => ({
  savePromoCode: bindActionCreators(savePromoCode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPromoCode);
