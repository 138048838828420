import React, {useEffect, useState} from 'react';
import deepEqual from 'deep-equal';
import {TextArea} from '../../general/TextArea';
import sizeMe from 'react-sizeme';
import {splitBySeparator} from '../../../utils/StringUtils';
import {phrasesColored} from '../../../utils/StyleUtils';
import {ReactComponent as SortIcon} from '../../../resources/imgs/sort-icon.svg';

function phasesListEqual(prev, next) {
  return deepEqual(prev, next);
}

const PhrasesList = ({phrases, changePhrase, size, formsWords, orderParams, orderAsoPhrases}) => {
  const [phrasesExceededLimit, setPhrasesExceededLimit] = useState([]);

  useEffect(() => {
    if (phrases) {
      const phrasesArray = splitBySeparator(phrases, '\n');
      setPhrasesExceededLimit(phrasesArray.filter((phrase) => {
        return phrase.length > 40;
      }));
    }
  }, [phrases]);

  useEffect(() => {
    if (phrases) {
      const phrasesArray = splitBySeparator(phrases, '\n');
      setPhrasesExceededLimit(phrasesArray.filter((phrase) => {
        return phrase.length > 40;
      }));
    }
  }, [phrases]);

  const renderOrderArrows = (orderParam) => {
    return <div className={'aso-keywords-list-header-arrow-container'}>
      <SortIcon/>
    </div>;
  };

  const onClickOrderHeader = (param) => {
    if (orderParams.orderBy !== param) {
      orderAsoPhrases({
        orderBy: param,
        orderIndex: 'DESC',
      });
    } else {
      orderAsoPhrases({
        orderBy: param,
        orderIndex: orderParams.orderIndex === 'DESC' ? 'ASC' : 'DESC',
      });
    }
  };

  return <div className={'aso-experiment-phrases-list-container'}>
    {phrasesExceededLimit.length === 0 ? <div className={'phrase-list-header-item-container'}
      style={{borderLeft: '2px solid #E4EAF0'}}
      onClick={() => onClickOrderHeader('phrases')}
    >
      <div style={{paddingRight: '3px'}}>Phrases</div>
      {renderOrderArrows('phrases')}
    </div> :
      <div className={'phrase-list-header-item-container'} style={{color: 'red'}}>Phrase length exceeded</div>
    }
    <div className={'aso-experiment-phrases-list'}>
      <TextArea
        value={phrases}
        style={{
          width: '95.5%',
          flexShrink: 0,
          backgroundColor: '#00800000',
          border: 'none',
          zIndex: '3',
          paddingLeft: 0,
          fontSize: '18px',
        }}
        debounceTime={200}
        handleChange={changePhrase}
      />
      <div className={'onelight-input'}
        style={{
          position: 'absolute',
          zIndex: '2',
          width: size.width,
          flexShrink: 0,
          backgroundColor: 'white',
          color: '#00800000',
          border: 'none',
          minHeight: '30px',
          paddingLeft: 0,
          fontSize: '18px',
        }}
      >
        <div style={{marginLeft: '8px', whiteSpace: 'pre-wrap'}}
          dangerouslySetInnerHTML={{__html: phrasesColored(phrases, phrasesExceededLimit, formsWords)}}
        >
        </div>
      </div>
    </div>
  </div>;
};

export default sizeMe({monitorHeight: true})(React.memo(PhrasesList, phasesListEqual));
