import React, {useEffect} from 'react';
import MDSpinner from 'react-md-spinner';
import {IncentAppUnits} from './IncentAppUnits';
import {AsoReportTable} from './AsoReportTable';

export const AsoReportItem = ({
  country, asoReportData, incentAppUnitsParams, asoReportDataStats, searchParams,
  isLoadingAsoReportData, changeIncentAppUnitsParams, searchAsoReport, isHideTabelDays,
}) => {
  useEffect(() => {
    searchAsoReport(searchParams);
  }, [
    searchParams.date, searchParams.period, searchParams.appIds,
    searchParams.appleSourceTypes, searchParams.googleSourceTypes, searchParams.excludeCountries,
  ]);

  if (isLoadingAsoReportData) {
    return <div className='spinner-container' style={{margin: 'auto', width: '700px', height: '600px'}}>
      <MDSpinner size={100} singleColor={'#00DADA'}/>
    </div>;
  }

  return <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '10px'}}>
    <div className={'aso-report-item'}>
      <div className={'aso-report-item-header'}>
        {country.toUpperCase()}
      </div>
      <AsoReportTable
        asoReportData={asoReportData}
        asoReportDataStats={asoReportDataStats}
        store={searchParams.store}
        isHideTabelDays={isHideTabelDays}
      />
    </div>
    <IncentAppUnits
      incentAppUnitsParams={incentAppUnitsParams}
      changeIncentAppUnitsParams={changeIncentAppUnitsParams}
    />
  </div>;
};


