import React, {useEffect, useState} from 'react';
import deepEqual from 'deep-equal';
import {TextArea} from '../../general/TextArea';
import {ReactComponent as SortIcon} from '../../../resources/imgs/sort-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../resources/imgs/delete-icon.svg';
import {Checkbox} from '../../general/Checkbox';

function phasesListEqual(prev, next) {
  return deepEqual(prev.phrases, next.phrases) && deepEqual(prev.ranks, next.ranks) && deepEqual(prev.positions, next.positions) && deepEqual(prev.orderParams, next.orderParams);
}

const PhrasesPositionsRanks = ({ranks, positions, changePhrasesPositions, changePhrasesRanks,
  deletePhrase, orderParams, orderAsoPhrases, countPhrases}) => {
  const [selectedPhrasesIds, setSelectedPhrasesIds] = useState({});

  const [shiftHeld, setShiftHeld] = useState(false);
  const [maxMinShiftSelected, setMaxMinShiftSelected] = useState({});

  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setMaxMinShiftSelected({});
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    const newSelectedPhrasesIds = [...Array(countPhrases).keys()].reduce((res, curr) => {
      return {...res, ...{[curr]: false}};
    }, {});
    setSelectedPhrasesIds(newSelectedPhrasesIds);
    setMaxMinShiftSelected({});
  }, [countPhrases]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  const switchPhrases = (id, isActive) => {
    if (shiftHeld) {
      const selectedMax = maxMinShiftSelected.max ? Number(maxMinShiftSelected.max) : id;
      const selectedMin = maxMinShiftSelected.min ? Number(maxMinShiftSelected.min) : id;

      const max = selectedMax && id < selectedMax ? selectedMax : id;
      const min = selectedMin && id > selectedMin ? selectedMin : id;
      const newSelectedPhrasesIds = {...selectedPhrasesIds};
      Object.keys(newSelectedPhrasesIds).forEach((id) => {
        if (id <= max && id >= min) {
          newSelectedPhrasesIds[id] = isActive;
        }
      });
      setSelectedPhrasesIds(newSelectedPhrasesIds);
      setMaxMinShiftSelected({max, min});
    } else {
      setSelectedPhrasesIds({...selectedPhrasesIds, ...{[id]: isActive}});
    }
  };
  const renderOrderArrows = (orderParam) => {
    return <div className={'aso-keywords-list-header-arrow-container'}>
      <SortIcon/>
    </div>;
  };

  const onClickOrderHeader = (param) => {
    if (orderParams.orderBy !== param) {
      orderAsoPhrases({
        orderBy: param,
        orderIndex: 'DESC',
      });
    } else {
      orderAsoPhrases({
        orderBy: param,
        orderIndex: orderParams.orderIndex === 'DESC' ? 'ASC' : 'DESC',
      });
    }
  };

  return <div className={'aso-experiment-phrases-ranks-positions-list-container'} style={{flexDirection: 'row'}}>
    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <div className={'phrase-list-header-item-container'}
        style={{borderLeft: '2px solid #E4EAF0'}}
        onClick={() => onClickOrderHeader('positions')}
      >
        <div style={{paddingRight: '3px'}}>Popularity</div>
        {renderOrderArrows('positions')}
      </div>
      <div className={'aso-experiment-phrases-list'} style={{borderLeft: '2px solid #E4EAF0'}}>
        <TextArea
          value={positions}
          style={{
            width: '90%',
            flexShrink: 0,
            backgroundColor: 'white',
            border: 'none',
            resize: 'none',
            zIndex: '3',
            textAlign: 'center',
            fontSize: '18px',
          }}
          debounceTime={200}
          handleChange={(value) => {
            changePhrasesPositions(value.replaceAll('_', ''));
          }}
        />
      </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <div className={'phrase-list-header-item-container'}
        style={{borderLeft: '2px solid #E4EAF0'}}
        onClick={() => onClickOrderHeader('ranks')}
      >
        <div style={{paddingRight: '3px'}}>Ranks</div>
        {renderOrderArrows('ranks')}
      </div>
      <div className={'aso-experiment-phrases-list'} style={{borderLeft: '2px solid #E4EAF0'}}>
        <TextArea
          value={ranks}
          style={{
            width: '90%',
            flexShrink: 0,
            backgroundColor: 'white',
            border: 'none',
            resize: 'none',
            zIndex: '3',
            textAlign: 'center',
            fontSize: '18px',
          }}
          debounceTime={200}
          handleChange={(value) => {
            changePhrasesRanks(value.replaceAll('_', ''));
          }}
        />
      </div>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <div className={'phrase-list-header-item-container'}
        style={{borderLeft: '2px solid #E4EAF0', paddingTop: '5px', paddingBottom: '3px'}}
        onClick={() => {
          deletePhrase(Object.keys(selectedPhrasesIds).filter((id) => selectedPhrasesIds[id]));
        }}
      >
        <div style={{paddingRight: '3px'}}>
          <DeleteIcon/>
        </div>
      </div>
      <div style={{borderLeft: '2px solid #E4EAF0', display: 'flex', flexDirection: 'column', zIndex: 1000}}>
        {
          Object.keys(selectedPhrasesIds).map((currentPhraseIndex) => {
            return <Checkbox
              style={{padding: '3px 3px', height: '24px', backgroundColor: 'white'}}
              key={currentPhraseIndex}
              switchCheckbox={(isActive) => {
                switchPhrases(Number(currentPhraseIndex), isActive);
              }}
              isActive={selectedPhrasesIds[currentPhraseIndex]}
            />;
          })
        }
      </div>
    </div>
  </div>;
};

export default React.memo(PhrasesPositionsRanks, phasesListEqual);
