import React, {useState} from 'react';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {EventTypeSelector} from '../general/EventTypeSelector';
import {saveEvent} from '../../actions/events';
import momentZ from 'moment-timezone';
import {AppSelector} from '../general/AppSelector';
import {SingleDatePicker} from '../general/SingleDatePicker';

const EventDetails = ({saveEvent, deleteEvent, event, cancel, meta, mode, onelightApps}) => {
  const [eventName, setEventName] = useState(event.name);
  const [eventType, setEventType] = useState(event.type);
  const [date, setDate] = useState(event.date);
  const [eventMeta, setEventMeta] = useState(event.meta);
  const [appId, setAppId] = useState(event.appId);

  const handleAddEvent = () => {
    const newEvent = {...event, ...{
      name: eventName,
      type: eventType,
      date,
      meta: eventMeta,
      oldId: event.id,
      appId,
    }};
    newEvent.id = `${eventName}_${eventType}_${appId}_${date}`;
    if (mode === 'ADD') {
      saveEvent(newEvent);
    }
    if (mode === 'EDIT') {
      saveEvent(newEvent);
    }
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'onelight-head-text'}>
        {mode === 'ADD' ? 'Add event': 'Edit event'}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Type
        </div>
        <EventTypeSelector
          style={{paddingTop: '7px', zIndex: 5001}}
          selectedEvents={[{name: eventName, type: eventType, color: eventMeta.color}]}
          isMulti={false}
          changeTypes={(type) => {
            setEventType(type.value);
            setEventMeta({color: type.color});
          }}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          Name
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={setEventName}
          value={eventName}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div className={'onelight-second-text'}>
          App
        </div>
        <AppSelector
          selectedApps={!appId ? [] : [appId]}
          style={{paddingTop: '7px', zIndex: 5000}}
          allApps={onelightApps}
          isMulti={false}
          changeApps={(appId) => {
            setAppId(appId);
          }}
        />
      </div>
      <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '133px'}}>
          <div className={'onelight-second-text'}>
            Date
          </div>
          <SingleDatePicker
            date={momentZ(date)}
            styleInput={{borderRadius: '4px', border: '1px solid #E4EAF0', marginTop: '7px'}}
            style={{zIndex: 4999}}
            dropdownStyle={{transform: 'translate(-50%, -100%)'}}
            onChange={(newDate) => {
              setDate(momentZ(newDate).format('yyyy-MM-DD HH:mm'));
            }}
          />
        </div>
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>

      {
        mode === 'EDIT' ? <button className={'onelight-delete-btn'}
          style={{marginRight: 'auto'}}
          onClick={() => {
            deleteEvent(event);
          }}
        >
          {mode === 'EDIT' ? 'Delete' : 'Cancel'}
        </button> : null
      }
      <button className={'onelight-second-btn'}
        onClick={() => {
          cancel();
        }}
      >
        {'Cancel'}
      </button>
      <button className={'onelight-btn-apply'} style={{marginLeft: '24px'}}
        onClick={handleAddEvent}
        disabled={eventName === ''}
      >
        {mode === 'ADD' ? 'Add' : 'Save'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state) => ({
  onelightApps: state.appState.onelightApps,
});

const mapDispatchToProps = (dispatch) => ({
  saveEvent: bindActionCreators(saveEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
