import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import lookup from 'country-code-lookup';

import {
  changeIncentAppUnitsParams,
  changeSearchParams,
  createAsoReportXML,
  searchAsoReportQueue, setIsHideTabelDays,
} from '../../actions/aso';
import {AsoReportHeader} from './AsoReportHeader';
import {countriesByRegion} from '../../models/territory';
import {AsoReportItem} from './AsoReportItem';
import {AsoReportCharts} from './AsoReportCharts';

const AsoReport = ({asoReportDataByRegion, isLoadingAsoReportDataByRegion, searchAsoReport, changeIncentAppUnitsParams,
  onelightApps, asoReportDataStatsByRegion, changeSearchParams, searchParams, incentAppUnitsParamsByRegion, createAsoReportXML,
  isHideTabelDays, setIsHideTabelDays,
}) => {
  return (
    <div className={'aso-report-container'}>
      <AsoReportHeader
        key={'AsoReportHeader'}
        onelightApps={onelightApps}
        changeSearchParams={changeSearchParams}
        searchParams={searchParams}
        createAsoReportXML={createAsoReportXML}
        isHideTabelDays={isHideTabelDays}
        setIsHideTabelDays={setIsHideTabelDays}
      />
      <div style={{display: 'flex', minHeight: '0px', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', width: '70%'}}>
          {
            [{
              region: 'worldwide',
              countries: [],
            }].concat(
                searchParams.countries
                    .map((country) => {
                      return {region: country, countries: [country]};
                    })
                    .concat(searchParams.regions.map((region) => {
                      return {
                        region,
                        countries: countriesByRegion[region],
                      };
                    })),
            ).map((countriesRegion) => {
              const region = countriesRegion.region;
              let regionCountry = region;
              try {
                regionCountry = lookup.byIso(region).country;
              } catch (e) {
                console.log(`Region ${region} not found`);
              };

              return <AsoReportItem
                key={region}
                country={regionCountry}
                asoReportData={asoReportDataByRegion[region]}
                incentAppUnitsParams={incentAppUnitsParamsByRegion[region]}
                asoReportDataStats={asoReportDataStatsByRegion[region]}
                isLoadingAsoReportData={isLoadingAsoReportDataByRegion[region]}
                searchAsoReport={(searchParams) => {
                  searchAsoReport(searchParams, region);
                }}
                isHideTabelDays={isHideTabelDays}
                changeIncentAppUnitsParams={(value, prop) => {
                  changeIncentAppUnitsParams(value, prop, region);
                }}
                searchParams={{...searchParams, ...{countries: countriesRegion.countries}}}
              />;
            })
          }
        </div>
        <AsoReportCharts
          asoReportDataStatsByRegion={asoReportDataStatsByRegion}
          searchParams={searchParams}
          isShow={Object.keys(asoReportDataStatsByRegion).length > 1}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  asoReportDataByRegion: state.asoReport.asoReportDataByRegion,
  isLoadingAsoReportDataByRegion: state.asoReport.isLoadingAsoReportDataByRegion,
  onelightApps: state.appState.onelightApps,
  asoReportDataStatsByRegion: state.asoReport.asoReportDataStatsByRegion,
  searchParams: state.asoReport.searchParams,
  incentAppUnitsParamsByRegion: state.asoReport.incentAppUnitsParamsByRegion,
  isHideTabelDays: state.asoReport.isHideTabelDays,
});

const mapDispatchToProps = (dispatch) => ({
  searchAsoReport: bindActionCreators(searchAsoReportQueue, dispatch),
  changeSearchParams: bindActionCreators(changeSearchParams, dispatch),
  changeIncentAppUnitsParams: bindActionCreators(changeIncentAppUnitsParams, dispatch),
  createAsoReportXML: bindActionCreators(createAsoReportXML, dispatch),
  setIsHideTabelDays: bindActionCreators(setIsHideTabelDays, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsoReport);
