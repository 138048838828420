import React from 'react';
import momentZ from 'moment-timezone';
import DatePicker from 'react-datepicker';
import {DateInput} from './DateInput';
import {range} from '../../utils/ArrayUtils';
import {SingleSelector} from './SingleSelector';

export const OnelightDatePickerHeader = ({date,
  changeYear, changeMonth}) => {
  const years = range(momentZ().year() - 2009, 2010);
  const months = momentZ.months();
  const controlSelectStyle = (base, state) => {
    return {'border': 'none', '&:hover': {
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'rgba(0, 204, 204, 0.1)',
    }};
  };

  return <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
    }}
  >
    <SingleSelector
      change={(value) => {
        changeMonth(momentZ.months().indexOf(value));
      }}
      selectedValue={momentZ(date).tz(momentZ.tz.guess(true)).format('MMMM')}
      options={months.map((month) => ({label: month, value: month}))}
      style={{height: 40, width: 100}}
      control={controlSelectStyle}
    />
    <SingleSelector
      change={(value) => changeYear(value)}
      selectedValue={momentZ(date).year()}
      options={years.map((year) => ({label: year, value: year}))}
      style={{height: 40, width: 80}}
      border={'none'}
      control={controlSelectStyle}
    />
  </div>;
};


export const OnelightDatePicker = ({date, onChange, style, inline, shouldCloseOnSelect=true}) => {
  return <div className={'onelight-date-picker'} style={style}>
    <DatePicker
      renderCustomHeader={OnelightDatePickerHeader}
      dateFormat='yyyy-MM-dd'
      selected={momentZ(date).toDate()}
      onChange={(date) => {
        return onChange(date);
      }}
      customInput={<DateInput/>}
      shouldCloseOnSelect={shouldCloseOnSelect}
      inline={inline}
    />
  </div>;
};
