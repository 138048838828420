import React, {useState} from 'react';
import {typeModal} from '../../actions/general';
import MDSpinner from 'react-md-spinner';
import {ReactComponent as SortIcon} from '../../resources/imgs/sort-icon.svg';
import {KeywordCompare} from './KeywordCompare';
import {DiffCompare} from './DiffCompare';

export const KeywordsCompareCountryTable = ({keywordsRanks, country, showModal, isLoading,
  changePropKeyword, onelightApps, selectedReportType, isHideKeywords,
  keywordsIdsForDelete, setKeywordForEdit, setKeywordToDelete, avgDiff,
}) => {
  const [orderKeywords, setOrder] = useState({});

  const onOrder = (propName) => {
    setOrder({
      orderBy: propName,
      isASC: !orderKeywords.isASC,
    });
  };

  const renderOrderArrows = (orderParam) => {
    return <SortIcon
      style={{paddingRight: '6px', marginLeft: 'auto'}}
    />;
  };

  return <div className={'keywords-country-container'} style={{width: '600px'}}>
    <div className={'keywords-country-header'}>
      <div style={{display: 'flex'}}>
        <div style={{display: 'flex'}}>
          <div style={{paddingRight: '5px'}}>Rank: </div>
          <DiffCompare
            value={avgDiff.avgRankPercent}
            postfix={' %'}
            isNegative={true}
          />
        </div>

        <div style={{display: 'flex', paddingLeft: '30px'}}>
          <div style={{paddingRight: '5px'}}>SAP: </div>
          <DiffCompare
            value={avgDiff.avgSapPercent}
            postfix={' %'}
            isNegative={false}
            isRevert={true}
          />
        </div>
      </div>
      <div style={{marginLeft: 'auto'}}>
        {country.toUpperCase()}
      </div>
      {isLoading ? <div style={{marginLeft: 'auto'}}>
        <MDSpinner size={20} singleColor={'#00DADA'}/>
      </div> :
          <div className={'add-keyword-button'} style={{marginLeft: 'auto'}} onClick={() => {
            showModal(typeModal.KEYWORD_ADD, true, {
              country,
            });
          }}>
            Add +
          </div>
      }

    </div>
    <div className={'keywords-country-table-header'}>
      <div className={'keywords-country-table-header-kws'} onClick={() => {
        onOrder('keywords');
      }}>
        Keywords
        {renderOrderArrows('keywords')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('ranks');
      }}>
        Ranks
        {renderOrderArrows('ranks')}
      </div>
      <div className={'keywords-country-table-header-rank'} onClick={() => {
        onOrder('popularity');
      }}>
        SAP
        {renderOrderArrows('popularity')}
      </div>

    </div>
    <div className={'keywords-country-table-list'} style={{maxHeight: isHideKeywords ? undefined : 'none'}}>
      {keywordsRanks
          .sort((keywordA, keywordB) => {
            return sortCompareKeywords(keywordA, keywordB, orderKeywords.orderBy, orderKeywords.isASC);
          })
          .map((keywordRank) => {
            return <KeywordCompare
              key={keywordRank.id}
              onelightApps={onelightApps}
              keywordRank={keywordRank}
              setKeywordForEdit={setKeywordForEdit}
              setKeywordToDelete={setKeywordToDelete}
              changePropKeyword={changePropKeyword}
              selectedReportType={selectedReportType}
              keywordsIdsForDelete={keywordsIdsForDelete}
              country={country}
            />;
          })}
    </div>
  </div>;
};

const sortCompareKeywords = (keywordA, keywordB, orderBy, isASC) => {
  let valueA;
  let valueB;
  if (orderBy === 'keywords') {
    valueA = keywordA.keyword;
    valueB = keywordB.keyword;
  }
  if (orderBy === 'ranks') {
    valueA = keywordA.firstRankCompare && keywordA.firstRankCompare.rank ? keywordA.firstRankCompare.rank : 1000;
    valueB = keywordB.firstRankCompare && keywordB.firstRankCompare.rank ? keywordB.firstRankCompare.rank : 1000;
  }
  if (orderBy === 'popularity') {
    valueA = keywordA.secondRankCompare && keywordA.secondRankCompare.popularity ? keywordA.secondRankCompare.popularity : -1000;
    valueB = keywordB.secondRankCompare && keywordB.secondRankCompare.popularity ? keywordB.secondRankCompare.popularity : -1000;
  }

  if (!valueA && !valueB) {
    return 0;
  }
  if (!valueA) {
    return isASC ? 1 : -1;
  }
  if (!valueB) {
    return isASC ? -1 : 1;
  }

  if (valueA < valueB) {
    return isASC ? 1 : -1;
  }
  if (valueA > valueB) {
    return isASC ? -1 : 1;
  }

  return 0;
};
