import React from 'react';
import Select, {components} from 'react-select';
import {selectStyles} from '../../utils/StyleUtils';
import {generalCountries} from '../../models/territory';
import {countrySort} from '../../utils/ArrayUtils';
const lookup = require('country-code-lookup');
const {MultiValueLabel} = components;

const IconMultiValueLabel = (props) => {
  console.log(props);
  return <MultiValueLabel {...props}>
    <div className={'multi-select-label'}>{props.data.label === 'GB' ? 'UK' : props.data.label}</div>
  </MultiValueLabel>;
};

export const CountrySelector = ({changeCountries, selectedCountries, isDisabled=false, isMulti=true, style, placeholder='Country'}) => {
  return <div style={style}>
    <Select
      onChange={(selectedOption) => {
        if (isMulti) {
          changeCountries(!selectedOption ? [] : selectedOption.map((option) => option.value));
        } else {
          changeCountries(selectedOption.value);
        }
      }}
      isMulti={isMulti}
      value={selectedCountries.map((country) => {
        return {value: country, label: country === 'gb' ? 'uk' : country};
      })}
      isDisabled={isDisabled}
      components={{MultiValueLabel: IconMultiValueLabel}}
      styles={selectStyles()}
      name="countries"
      options={lookup.countries
          .sort(countrySort)
          .map((country) => {
            const isLastGeneral = generalCountries[generalCountries.length - 1] === country.iso2;
            return {value: country.iso2, label: country.country, isLastGeneral};
          })}
      placeholder={placeholder}
      className="basic-multi-select select-label"
      classNamePrefix="select"
    />
  </div>;
};
