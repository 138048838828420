import React from 'react';
import {AsoReportRow} from './AsoReportRow';
import {AsoReportStats} from './AsoReportStats';
import {Store} from '../../models/store';

export const AsoReportTable = ({asoReportData, asoReportDataStats, store, isHideTabelDays}) => {
  if (!asoReportData || !asoReportDataStats) {
    return null;
  }

  return <div className={'aso-report-data-table'}>
    <div className={'aso-report-header'}>
      <div className={'aso-report-date'}>Date</div>
      <div className={'aso-report-day'}>Day</div>
      {store === Store.APPLE.value ? <div className={'aso-report-impressions'}>Impressions</div> : null}
      <div className={'aso-report-pageviews'}>Page views</div>
      <div className={'aso-report-appunits'}>Downloads</div>
      <div className={'aso-report-ratio'}>Ratio</div>
    </div>
    {
      !isHideTabelDays ? asoReportData.raw.map((asoReportDay) => {
        return <AsoReportRow
          key={asoReportDay.date}
          asoReportDay={asoReportDay}
          asoReportDataStats={asoReportDataStats}
          isAfter={asoReportDay.date > asoReportData.meta.date}
          isRenderSeparator={asoReportDay.date === asoReportData.meta.date}
          store={store}
        />;
      }) : null
    }
    <AsoReportStats
      asoReportDataStats={asoReportDataStats}
      store={store}
    />
  </div>;
};
