import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {withDnd} from './components/general/Dnd/WithDnd';
import {BrowserRouter, Route, useHistory, useLocation} from 'react-router-dom';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import reducer from './reducers';
import {withAuthenticator} from './components/authorization';

import Head from './components/general/Head';
import momentZ from 'moment-timezone';
import Modals from './components/general/Modals';
import Ranks from './components/ranks';
import Ratings from './components/ratings';
import AsoExperiments from './components/aso';
import AsoExperiment from './components/aso/asoExperiment';
import Metrics from './components/metrics';
import KeywordsRanks from './components/keywordsRanks';
import AsoReport from './components/asoReport';
import Promo from './components/promo';
import WhatsNew from './components/whatsNew';
import CrossPromo from './components/crossPromo';
import OpenapiExplorer from './components/openapiExplorer';
import QADebug from './components/qa/debug'
import {compareString} from './utils/ArrayUtils';
import {getAdminIamActions} from './utils/AuthUtill';

momentZ.tz.setDefault('UTC');

const routsTemplates = {
  'default': [
    {path: '/', component: Metrics, order: 0},
    {path: '/ranks', component: Ranks, order: 1},
    {path: '/ratings', component: Ratings, order: 2},
    {path: '/aso', component: AsoExperiments, order: 3},
    {path: '/aso/experiment/:asoExperimentUUID', component: AsoExperiment, order: 4},
    {path: '/keywords', component: KeywordsRanks, order: 5},
    {path: '/aso/report', component: AsoReport, order: 6},
    {path: '/promo', component: Promo, order: 7},
    {path: '/whats-new', component: WhatsNew, order: 8},
    {path: '/cross-promo', component: CrossPromo, order: 9},
    {path: '/qa/debug', component: QADebug, order: 11},
  ],
  'webUI:AccessDashboard': [
    {path: '/', component: Metrics, order: 0},
  ],
  'webUI:AccessAppRanks': [
    {path: '/ranks', component: Ranks, order: 1},
  ],
  'webUI:AccessAppRatings': [
    {path: '/ratings', component: Ratings, order: 2},
  ],
  'webUI:AccessAsoMetadata': [
    {path: '/aso', component: AsoExperiments, order: 3},
    {path: '/aso/experiment/:asoExperimentUUID', component: AsoExperiment, order: 4},
  ],
  'webUI:AccessIndividualAsoMetadata': [
    {path: '/aso', component: AsoExperiments, order: 3},
    {path: '/aso/experiment/:asoExperimentUUID', component: AsoExperiment, order: 4},
  ],
  'webUI:AccessAsoKeywords': [
    {path: '/keywords', component: KeywordsRanks, order: 5},
  ],
  'webUI:AccessAsoReport': [
    {path: '/aso/report', component: AsoReport, order: 6},
  ],
  'webUI:AccessWhatsNew': [
    {path: '/whats-new', component: WhatsNew, order: 7},
  ],
  'webUI:AccessPromo': [
    {path: '/promo', component: Promo, order: 8},
    {path: '/cross-promo', component: CrossPromo, order: 9},
  ],
  'webUI:AccessOpenapi': [
    {path: '/openapi/:projectName', component: OpenapiExplorer, order: 10},
  ],
  'webUI:AccessQADashboard': [
    {path: '/qa/debug', component: QADebug, order: 11},
  ],
};

const HandlerLocation = ({routs}) => {
  const history = useHistory();
  const location = useLocation();

  if (routs.length !== 0 && location.pathname === '/' && routs[0].path !== '/') {
    history.push(routs[0].path);
  }
  return null;
};

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(createDebounce(), thunk)),
);

const WithProvider = () => {
  const iamActions = getAdminIamActions();

  let routs = routsTemplates['default'];

  if (iamActions.length !== 0) {
    const routsSet = {};

    iamActions.forEach((action) => {
      if (action.includes('webUI')) {
        const tempRouts = routsTemplates[action];
        if (tempRouts) {
          tempRouts.forEach((route) => {
            routsSet[route.path] = route;
          });
        }
      }
    });
    routs = Object.values(routsSet).sort(compareString('order'));
  }

  return <Provider store={store}>
    <BrowserRouter>
      <div className="app-container">
        <Modals/>
        <Head/>
        <HandlerLocation
          routs={routs}
        />
        <div className={'body-container'}>
          {
            routs.map((rout) => {
              // eslint-disable-next-line react/jsx-key
              return <Route exact path={rout.path} component={rout.component}/>;
            })
          }
        </div>
      </div>
    </BrowserRouter>
  </Provider>;
};

export default withDnd(withAuthenticator(WithProvider));
